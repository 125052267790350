import { IfCanAccess } from '@eclever/ra-enterprise/ra-rbac'
import React, { useEffect } from 'react'
import {
  CreateButton,
  FilterButton,
  ToolbarProps,
  useListContext,
  useListController,
} from 'react-admin'

import useBreadCrumps from '../../../hooks/useBreadCrumps'
import useFilters from '../../../hooks/useFilters'
import { admin, ColumnsType } from '../../../types'
import ResetButton from '../Buttons/ResetButton'
import SelectColumnsButtons from '../Components/SelectColumns/SelectColumnsButtons'
import DuplicateButton from '../DuplicateButton'
import { General } from './ToolbarStyles'

const drafts = [admin.station_chargegroup_drafts, admin.station_chargepoint_drafts]

const SelectColumnsToolbar: React.FC<
  Omit<ToolbarProps & { reference: string; columns: ColumnsType; preference: string }, 'sx'>
> = ({ reference, columns, preference, ...props }) => {
  const { resource } = useListController()
  const { setFilters } = useListContext()
  const { filter } = useFilters()
  const { breadCrumps } = useBreadCrumps()

  useEffect(() => {
    if (
      ![
        admin.station_chargegroups,
        admin.station_chargepoints,
        admin.station_chargegroup_drafts,
        admin.station_chargepoint_drafts,
      ].includes(resource)
    ) {
      if (resource && !filter[resource] && !breadCrumps[reference]) {
        setFilters({}, {}, false)
      } else if (filter[resource]) {
        setFilters(
          {
            ...(Object.entries(filter[resource]).reduce(
              (prev, [key, value]) => {
                if (typeof value === 'string' && value.startsWith('%')) {
                  return { ...prev, [key]: value.substring(1) }
                } else {
                  return { ...prev, [key]: value }
                }
              },
              { id: breadCrumps[reference] }
            ) || {}),
          },
          {},
          false
        )
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, resource])

  if (!resource) {
    return <></>
  }

  return (
    <div style={{ display: 'flex' }}>
      <General {...props}>
        {!drafts.includes(resource) && (
          <IfCanAccess action="edit" resource={resource}>
            <CreateButton resource={resource} />
          </IfCanAccess>
        )}
        {resource === admin.virtualCpacks && (
          <IfCanAccess action="edit" resource={resource}>
            <CreateButton
              label="cPacks-Vehicle"
              resource={`/${admin.virtualCpacksNewVehicle_cpack}`}
              // resource={resource}
            />
          </IfCanAccess>
        )}
        <FilterButton />
        {![
          admin.vehicle_manufacturers,
          admin.vehicle_models,
          admin.vehicle_designs,
          admin.vehicle_variants,
        ].includes(resource) && <ResetButton resource={resource} />}
        <SelectColumnsButtons preferences={preference} columns={columns} />
      </General>
      <div
        style={{
          display: 'flex',
          minHeight: '64px',
          alignItems: 'flex-end',
          paddingTop: '8px',
          paddingBottom: '4px',
          justifyContent: 'flex-end',
        }}
      >
        {resource === admin.station_chargepoints && <DuplicateButton {...props} />}
      </div>
    </div>
  )
}

export default SelectColumnsToolbar
