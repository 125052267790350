import React, { useEffect } from 'react'
import { NumberInputProps, TextInputProps, InputProps, useInput } from 'react-admin'
import { useController } from 'react-hook-form'

import { plugs } from './Utils'

const ResetInput: React.FC<InputProps & { depends: string }> = ({ depends, ...props }) => {
  const { source } = props
  const {
    field: { value },
    fieldState,
  } = useInput(props)
  const { isTouched, error } = fieldState
  const {
    field: { onChange },
  } = useController({ name: depends })
  const {
    field: { value: plug },
  } = useController({ name: 'plugType' })

  useEffect(() => {
    // eslint-disable-next-line sonarjs/no-collapsible-if
    if (isTouched && !error) {
      if (plugs[plug] && plugs[plug][depends]) {
        onChange(plugs[plug][depends].value)
      } else if (
        source !== 'accessMethods' ||
        (source === 'accessMethods' && depends === 'directPaymentLink' && !value?.includes('QR'))
      ) {
        onChange(null)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, isTouched, error, depends, source, onChange])

  return null
}

const DependsInput: React.FC<InputProps & { depends: string[] }> = ({ depends, ...props }) => {
  const { children } = props

  return (
    <>
      {depends.map((item: string) => (
        <ResetInput key={item} {...props} depends={item} />
      ))}
      {children}
    </>
  )
}

export const DefaultInput: React.FC<TextInputProps & NumberInputProps & InputProps> = props => {
  const { field } = useInput(props)
  const [currentValue, setCurrentValue] = React.useState<typeof field.value>()

  useEffect(() => {
    setCurrentValue(field.value)
  }, [field])

  return (
    <>
      {React.Children.map(props.children, child => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return React.cloneElement(child as React.ReactElement<any>, {
          ...props,
          defaultValue: currentValue,
        })
      })}
    </>
  )
}

export default DependsInput
