import {
  RaRecord,
  Show,
  SimpleShowLayout,
  ShowProps,
  TextFieldProps,
  useRecordContext,
  useGetOne,
  Loading,
} from 'react-admin'

import { admin } from '../../../types'
import { ChangelogType, CREATE, DELETE, UPDATE } from '../../shared/Station/Types'
import DraftsAction from '../../shared/Toolbar/DraftAction'
import StationChargepointsDraftsUpdate from './StationChargepointsDraftsUpdate'

const UpdateField: React.FC<TextFieldProps & { type: ChangelogType }> = props => {
  const record: RaRecord = useRecordContext(props)

  const { data, isLoading, error } = useGetOne(admin.station_chargepoints, {
    id: record.parentChargepointId,
  })

  if (isLoading) {
    return <Loading />
  }

  if (error) {
    return <>ERROR</>
  }

  return <StationChargepointsDraftsUpdate oldData={data} {...props} />
}

const DraftElement: React.FC<TextFieldProps> = props => {
  const record: RaRecord = useRecordContext(props)

  if (record?.delete && record?.parentChargepointId) {
    return <StationChargepointsDraftsUpdate type={DELETE} {...props} />
  }

  if (!record?.parentChargepointId) {
    return <StationChargepointsDraftsUpdate type={CREATE} {...props} />
  }

  if (!record?.delete && record?.parentChargepointId) {
    return <UpdateField type={UPDATE} {...props} />
  }

  return <></>
}

const StationChargepointsDraftsShow: React.FC<ShowProps> = props => {
  return (
    <Show {...props} actions={<DraftsAction approvepath={admin.station_chargepoint_drafts} />}>
      <SimpleShowLayout>
        <DraftElement />
      </SimpleShowLayout>
    </Show>
  )
}

export default StationChargepointsDraftsShow
