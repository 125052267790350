import { Button } from '@mui/material'
import React, { Fragment, useState, ChangeEvent } from 'react'
import {
  Datagrid,
  ListProps,
  RaRecord,
  ReferenceField,
  useGetList,
  FilterButton,
  Identifier,
  ToolbarProps,
  NumberInput,
  SelectInput,
  useNotify,
  useRefresh,
  useRedirect,
  useTranslate,
  sanitizeListRestProps,
  TextInput,
  useListController,
  TextField,
} from 'react-admin'

import { admin, ColumnsType } from '../../../types'
import Pagination from '../../shared/Components/Pagination'
import SelectColumnsButtons from '../../shared/Components/SelectColumns/SelectColumnsButtons'
import useSelectColumns from '../../shared/Components/SelectColumns/useSelectColumns'
import List from '../../shared/Components/StyledComponents/List'
import countriesArray from '../../shared/Utils/getCountries'
import EmpOperatorsCreate from './EmpOperatorsCreate'
import { EmpOperatorsDrawer } from './EmpOperatorsDrawer'
import EmpOperatorsEdit from './EmpOperatorsEdit'
import { columns as columnsNames, Field } from './EmpOperatorsFields'
import { MyTopToolbar } from './EmpOperatorsStyles'
import { DrawerStateProps, ToggleDrawerProps } from './EmpOperatorsTypes'

const EmpOperatorActions: React.FC<
  Omit<ToolbarProps & { data: RaRecord[]; ids: Identifier[] }, 'sx'>
> = ({ data, ...props }) => {
  const { resource } = useListController()

  if (!resource) {
    return <></>
  }

  return (
    <MyTopToolbar {...props}>
      <SelectColumnsButtons preferences="emp_operators.list.columns" columns={columnsNames(data)} />
      <FilterButton />
    </MyTopToolbar>
  )
}

export const EmpOperatorsList: React.FC<ListProps> = props => {
  const notify = useNotify()
  const refresh = useRefresh()
  const redirect = useRedirect()
  const translate = useTranslate()
  const [EmpOperatorsId, setId] = useState<string>('')
  const [empFilter, setEmp] = useState<{ [key: string]: string | number }>({})
  const { data, error } = useGetList(admin.emps, {
    pagination: { page: 1, perPage: -1 },
    sort: { field: 'name', order: 'ASC' },
    filter: Object.entries(empFilter).reduce((pre, [key, value]) => {
      if (typeof value === 'string') {
        return { ...pre, [key]: `%${value}` }
      }
      return { ...pre, [key]: value }
    }, {}),
  })
  const [drawers, setDrawer] = useState<DrawerStateProps>({
    action: false,
    record: null,
    editName: '',
  })

  const toggleDrawer: (props: ToggleDrawerProps) => void = ({ action, editName, record }) => {
    setDrawer({
      ...drawers,
      action,
      editName,
      record,
    })
  }

  const columnsFields =
    data?.reduce((prev, item: RaRecord) => {
      if (item && item?.name) {
        prev[item.name] = (
          <Field
            sortable={false}
            source={item.toString()}
            current={typeof item.id === 'number' ? item.id : undefined}
            data={data}
            setId={setId}
            toggleDrawer={toggleDrawer}
            label={
              <>
                <Button
                  onClick={() => toggleDrawer({ action: true, editName: admin.emps, record: item })}
                >
                  {item.name}
                </Button>
              </>
            }
          />
        )
      }
      return prev
    }, {} as ColumnsType) || []

  const columns = useSelectColumns('emp_operators.list.columns', columnsFields)

  if (error) {
    return <>error</>
  }

  const onSuccess = () => {
    notify('ra.action.save', { type: 'info' })
    redirect(`/${admin.emp_operators}`)
    refresh()
  }

  return (
    <Fragment>
      <List
        {...sanitizeListRestProps(props)}
        pagination={<Pagination />}
        bulkActionButtons={false}
        sort={{ field: 'name', order: 'ASC' }}
        actions={
          <EmpOperatorActions data={data || []} ids={data?.map(element => element?.id) || []} />
        }
        filters={[
          <TextInput source="operatorName" alwaysOn />,
          <TextInput source="evseOperatorId" alwaysOn />,
          <NumberInput
            type="number"
            value={empFilter['id']}
            source="empId"
            onBlur={(event: ChangeEvent<HTMLInputElement>) =>
              setEmp({ ...empFilter, id: parseInt(event.target.value) })
            }
            alwaysOn
          />,
          <TextInput
            value={empFilter['name']}
            source="empName"
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onBlur={(event: any) => setEmp({ ...empFilter, name: event.target.value })}
            alwaysOn
          />,
          <SelectInput choices={countriesArray()} source="country" alwaysOn />,
        ]}
      >
        <Datagrid>
          <ReferenceField
            label={translate(`resources.${admin.operators}.name`)}
            source="id"
            sortBy="operatorName"
            reference={admin.operators}
            link={false}
          >
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField
            source="id"
            sortBy="evseOperatorId"
            label="evseOperatorId"
            reference={admin.operators}
            link={false}
          >
            <TextField source="evseOperatorId" />
          </ReferenceField>
          {columns}
        </Datagrid>
      </List>
      <EmpOperatorsDrawer
        open={drawers['action']}
        toggleDrawer={toggleDrawer}
        record={drawers['record']}
        editName={drawers['editName']}
      />
      <EmpOperatorsEdit
        {...sanitizeListRestProps(props)}
        redirect="list"
        mutationMode="pessimistic"
        mutationOptions={{ onSuccess }}
      />
      <EmpOperatorsCreate id={EmpOperatorsId} props={{ ...sanitizeListRestProps(props) }} />
    </Fragment>
  )
}

export default EmpOperatorsList
