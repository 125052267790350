import { Chip } from '@mui/material/'
import React from 'react'
import { FieldProps, useRecordContext, RaRecord } from 'react-admin'

import { Chips } from '../StationChargepointsStyles'

const ChipsField: React.FC<FieldProps> = props => {
  const record: RaRecord = useRecordContext(props)

  return (
    <Chips>
      {record?.accessMethods?.map((value: string) => (
        <Chip key={value} label={`${value}`} variant="outlined" />
      ))}
    </Chips>
  )
}

export default ChipsField
