import { ResourceProps } from 'react-admin'

import VehicleCpacksCreate from '../shared/VehicleCpacksCreate'
import VehicleEdit from './VehicleEdit'
import VehicleList from './VehicleList'
import VehicleShow from './VehicleShow'

const Vehicle: Omit<ResourceProps, 'name'> = {
  options: { label: 'Virtual cPacks' },
  list: VehicleList,
  edit: VehicleEdit,
  show: VehicleShow,
  create: VehicleCpacksCreate,
}

export default Vehicle
