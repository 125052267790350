import { Chip } from '@mui/material'
import { EmailField, FunctionField, TextField, RaRecord } from 'react-admin'

import RenderAddress from '../../shared/Components/RenderAddress'

export const empNamesColumns = {
  id: <TextField source="id" />,
  name: <TextField source="name" />,
  nameAppStore: <TextField source="nameAppStore" />,
  linkWebApp: <TextField source="linkWebApp" />,
  companyName: <TextField source="companyName" />,
  address: <RenderAddress source="address" />,
  disorderHotline: <TextField source="disorderHotline" />,
  hotline: <TextField source="hotline" />,
  mail: <EmailField source="mail" />,
  accessMethods: (
    <FunctionField
      source="accessMethods"
      render={(record: RaRecord | undefined) => (
        <div>
          {(record &&
            record?.accessMethods?.map((value: string) => (
              <Chip
                key={value}
                style={{ margin: '0.5rem' }}
                label={`${value}`}
                variant="outlined"
              />
            ))) ||
            ''}
        </div>
      )}
    />
  ),
}

export const transformEmpnames = ({ empId, ...data }: RaRecord): RaRecord => ({
  ...data,
})
