import React from 'react'
import { TabbedFormProps, FormTab, TabbedForm } from 'react-admin'

import useBreadCrumps from '../../../hooks/useBreadCrumps'
import { admin } from '../../../types'
import VehicleVariantInputs from './VehicleVariantInputs'

const defaultValues = {
  isDefault: false,
  hasFrontTrunk: false,
  trailHitch: false,
  trailHitchOnlyBikemount: false,
  batteryHeatSerie: false,
  batteryHeatAdditional: false,
  acV2x: false,
  dcV2x: false,
}

const EditFields = VehicleVariantInputs

const VehicleVariantForm: React.FC<Omit<TabbedFormProps, 'children'>> = props => {
  const { breadCrumps } = useBreadCrumps()

  const vehicleDesignId = breadCrumps && breadCrumps[admin.vehicle_designs]

  return (
    <TabbedForm
      {...props}
      defaultValues={{
        ...defaultValues,
        vehicleDesignId: vehicleDesignId,
      }}
    >
      {Object.keys(EditFields).map(tab => (
        <FormTab label={tab} key={tab}>
          {Object.values(EditFields[tab])}
        </FormTab>
      ))}
    </TabbedForm>
  )
}

export default VehicleVariantForm
