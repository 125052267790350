import { AccordionSection } from '@eclever/ra-enterprise/ra-form-layout'
import React from 'react'
import { SimpleForm, SimpleFormProps, useRecordContext } from 'react-admin'

import useBreadCrumps from '../../../hooks/useBreadCrumps'
import { admin } from '../../../types'
import EditToolbar from '../../shared/Toolbar/EditToolbar'
import StationChargepointsInputs from './StationChargepointsInputs'
import { transformApiToShow } from './StationChargepointsUtils'

const Inputs = StationChargepointsInputs

const StationChargepointsForm: React.FC<Omit<SimpleFormProps, 'children'>> = props => {
  const record = useRecordContext()
  const { breadCrumps } = useBreadCrumps()
  const id = breadCrumps && breadCrumps[admin.station_chargegroups]

  return (
    <SimpleForm
      {...props}
      toolbar={<EditToolbar />}
      defaultValues={{
        oh: record?.openingHours ? transformApiToShow(record.openingHours) : undefined,
        ph: record?.parkingHours ? transformApiToShow(record.parkingHours) : undefined,
        chargegroupId: id,
        serviceDescription: {},
      }}
    >
      {Object.keys(Inputs).map((section, index) => (
        <AccordionSection key={`${section} ${index}`} label={section} fullWidth defaultExpanded>
          {Object.values(Inputs[section])}
        </AccordionSection>
      ))}
    </SimpleForm>
  )
}

export default StationChargepointsForm
