import { Resource } from '@eclever/ra-enterprise/ra-rbac'
import * as Sentry from '@sentry/react'
import { Admin, localStorageStore } from 'react-admin'

import CBox from './components/CBox/CBox'
import Documents from './components/Documents'
import EmpNames from './components/Enots/EmpNames'
import EmpOperators from './components/Enots/EmpOperators'
import Emps from './components/Enots/Emps'
import Operators from './components/Enots/Operators'
import Tariffs from './components/Enots/Tariffs'
import StationChargegroups from './components/Stations/StationChargegroups'
import StationChargegroupsDrafts from './components/Stations/StationChargegroupsDrafts'
import StationChargepoints from './components/Stations/StationChargepoints'
import StationChargepointsDrafts from './components/Stations/StationChargepointsDrafts'
import StationManufacturers from './components/Stations/StationManufacturers'
import StationTypes from './components/Stations/StationTypes'
import Users from './components/Users'
import ChargingCurveGroups from './components/Vehicles/ChargingCurveGroups'
import ConsumptionCurveGroups from './components/Vehicles/ConsumptionCurveGroups'
import VehicleCapabilities from './components/Vehicles/VehicleCapabilities'
import VehicleDesigns from './components/Vehicles/VehicleDesigns'
import VehicleManufacturer from './components/Vehicles/VehicleManufacturers'
import VehicleModels from './components/Vehicles/VehicleModels'
import VehicleVariants from './components/Vehicles/VehicleVariants'
import CPacks from './components/Virtual/CPacks'
import Vehicle from './components/Virtual/Vehicle'
import VehicleCpacksCreate from './components/Virtual/shared/VehicleCpacksCreate'
import systemConfig from './config'
import MyLayout from './layout/MyLayout'
import {
  ECleverAuthProvider,
  SimpleDataProvider,
  i18nProvider,
  ThemeProvider,
  SentryLoggerProvider,
} from './provider'
import * as types from './types'

//Sentry init
SentryLoggerProvider.initializeTracker()

const dataProvider = SimpleDataProvider()

function App(): JSX.Element {
  return (
    <Admin
      theme={ThemeProvider}
      i18nProvider={i18nProvider}
      layout={MyLayout}
      dataProvider={dataProvider}
      authProvider={ECleverAuthProvider}
      store={localStorageStore(systemConfig.webAppVersion)}
    >
      <Resource name={types.admin.emps} {...Emps} />
      <Resource name={types.admin.empnames} {...EmpNames} />
      <Resource name={types.admin.emp_operators} {...EmpOperators} />
      <Resource name={types.admin.tariffs} {...Tariffs} />
      <Resource name={types.admin.pricing} />
      <Resource name={types.admin.penalties} />
      <Resource name={types.admin.operators} {...Operators} />
      <Resource name={types.admin.vehicle_manufacturers} {...VehicleManufacturer} />
      <Resource name={types.admin.vehicle_models} {...VehicleModels} />
      <Resource name={types.admin.vehicle_designs} {...VehicleDesigns} />
      <Resource name={types.admin.vehicle_designsImages} />
      <Resource name={types.admin.vehicle_variants} {...VehicleVariants} />
      <Resource name={types.admin.virtualCpacksNewVehicle_cpack} create={VehicleCpacksCreate} />
      <Resource name={types.admin.virtualCpacks} {...CPacks} />
      <Resource name={types.admin.virtualVehicles} {...Vehicle} />
      <Resource name={types.admin.virtualVehiclesRecent} />
      <Resource name={types.admin.station_types} {...StationTypes} />
      <Resource name={types.admin.station_manufacturers} {...StationManufacturers} />
      <Resource name={types.admin.station_chargegroups} {...StationChargegroups} />
      <Resource name={types.admin.station_chargegroupsImages} />
      <Resource name={types.admin.station_chargegroup_drafts} {...StationChargegroupsDrafts} />
      <Resource name={types.admin.station_chargepoints} {...StationChargepoints} />
      <Resource name={types.admin.station_chargepoint_drafts} {...StationChargepointsDrafts} />
      <Resource name={types.admin.charging_curve_groups} {...ChargingCurveGroups} />
      <Resource name={types.admin.consumption_curve_groups} {...ConsumptionCurveGroups} />
      <Resource name={types.admin.vehicle_capabilities} {...VehicleCapabilities} />
      <Resource name={types.admin.users} {...Users} />
      <Resource name={types.admin.usersMe} />
      <Resource name={types.admin.station_locks} />
      <Resource name={types.admin.documents} {...Documents} />
      <Resource name={types.admin.cPacks} {...CBox} />
      {types.enums.map(enumType => (
        <Resource key={enumType} name={enumType} />
      ))}

      <Resource name={types.api.vehicle_adapters} />
      <Resource name={types.api.vehicle_cables} />
    </Admin>
  )
}

export default Sentry.withProfiler(App, { name: 'eClever Backoffice' })
