import { Clear, Done } from '@mui/icons-material'
import { LoadScript } from '@react-google-maps/api'
import * as React from 'react'
import { TextFieldProps, RaRecord, TextField, FunctionField } from 'react-admin'

import systemConfig from '../../../../config'
import { places } from '../../../shared/Constants'
import CustomImageField from '../../StationChargegroups/CustomFields/CustomImageField'
import DescriptionField from '../../StationChargegroups/CustomFields/DescriptionField'
import MapField from '../../StationChargegroups/CustomFields/MapField'
import BoundariesField from './BoundariesField'

const PropertiesField: React.FC<
  TextFieldProps & {
    source: string
    data: RaRecord | undefined
    diffs?: string[]
  }
> = ({ source, data, ...props }) => {
  const newProps = { ...props, record: data }

  switch (source) {
    case 'map':
      if (systemConfig.googleMapsApiKey) {
        return (
          <LoadScript googleMapsApiKey={systemConfig.googleMapsApiKey} libraries={places}>
            <MapField {...newProps} source={source} />
          </LoadScript>
        )
      }
      break
    case 'boundaries':
      return <BoundariesField {...newProps} source={source} />
    case 'approachDescriptions':
      return <DescriptionField {...newProps} source={source} />
    case 'approachPosition':
      return (
        <FunctionField
          sortable={false}
          source="approachPosition"
          label="approachPosition"
          render={(record?: RaRecord) => {
            return (
              <>
                {(record?.approachLatitude && record?.approachLongitude && (
                  <Done data-testid="true" fontSize="small" />
                )) || <Clear data-testid="false" fontSize="small" />}
              </>
            )
          }}
        />
      )
    case 'images':
      return <CustomImageField {...newProps} source={source} isEdit />
  }

  return <TextField {...newProps} source={source} />
}

export default PropertiesField
