import { useEffect, useRef } from 'react'
import { InputProps, useListContext, useListController } from 'react-admin'

import useBreadCrumps from '../../../hooks/useBreadCrumps'
import { activeElements } from './BreadCrumbs'

const FilterWrapper: React.FC<InputProps & { reference: string }> = props => {
  const isInitialMount = useRef(true)
  const { reference, source, children } = props
  const { resource } = useListController()
  const { breadCrumps, removeIds } = useBreadCrumps()
  const { filterValues, setFilters } = useListContext()

  useEffect(() => {
    if (breadCrumps[reference] && resource) {
      setFilters({ ...filterValues, [source]: breadCrumps[reference] }, {}, true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [breadCrumps])

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
    } else if (filterValues[source] !== breadCrumps[reference]) {
      setFilters({ ...filterValues, [source]: filterValues[source] }, {}, false)
      !activeElements(resource) && removeIds([reference])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValues])

  return <>{children}</>
}

export default FilterWrapper
