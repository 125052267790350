import React from 'react'
import { Edit, EditProps, RaRecord, useNotify, useRefresh, useRedirect } from 'react-admin'

import { admin } from '../../../types'
import { OperatorsForm } from './OperatorsForm'

const OperatorsEdit: React.FC<EditProps> = props => {
  const notify = useNotify()
  const refresh = useRefresh()
  const redirect = useRedirect()

  const onSuccess = (data: RaRecord) => {
    notify('ra.action.update', { type: 'info' })
    redirect(`/${admin.operators}`)
    refresh()
  }

  return (
    <Edit {...props} mutationMode="pessimistic" mutationOptions={{ onSuccess }}>
      <OperatorsForm />
    </Edit>
  )
}

export default OperatorsEdit
