import { formatDistanceToNow, formatDuration, intervalToDuration } from 'date-fns'
import React from 'react'
import { FieldProps, useRecordContext } from 'react-admin'

const TimespanField: React.FC<{ source: string } & FieldProps> = props => {
  const { source } = props
  const record = useRecordContext()

  const timeInput = record[source]
  if (!timeInput) return <></>

  if (typeof timeInput === 'number') {
    return <span>{formatDuration(intervalToDuration({ start: 0, end: timeInput }))}</span>
  }

  return <span>{formatDistanceToNow(new Date(timeInput))}</span>
}
export default TimespanField
