import React from 'react'
import {
  FormDataConsumer,
  SimpleFormProps,
  SimpleForm,
  ReferenceInput,
  SelectInput,
} from 'react-admin'

import { getIsoOptions, languageValidation } from '../shared/Utils/localizations'
import { LanguagesInput, InternalizationInput } from './DocumentsInputs'

const DocumentsForm: React.FC<Omit<SimpleFormProps, 'children'>> = props => (
  <SimpleForm {...props}>
    <FormDataConsumer>
      {({ formData, ...rest }) => (
        <ReferenceInput
          {...rest}
          source="key"
          reference="admin/enums/documentKey"
          disabled={formData.id}
        >
          <SelectInput optionText="id" />
        </ReferenceInput>
      )}
    </FormDataConsumer>
    <FormDataConsumer>
      {({ formData, ...rest }) => (
        <LanguagesInput
          {...rest}
          source="localizations"
          label="Languages"
          choices={getIsoOptions(formData.localizations)}
        />
      )}
    </FormDataConsumer>
    <InternalizationInput source="localizations" validate={[languageValidation()]} isRequired />
  </SimpleForm>
)

export default DocumentsForm
