import React from 'react'
import { Create, CreateProps } from 'react-admin'

import VehicleVariantForm from './VehicleVariantForm'
import { transformVariant } from './VehicleVariantInputs'

const VehicleVariantCreate: React.FC<CreateProps> = props => (
  <Create {...props} transform={transformVariant}>
    <VehicleVariantForm />
  </Create>
)

export default VehicleVariantCreate
