import React from 'react'
import {
  ArrayInput,
  required,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  SimpleFormProps,
  FormDataConsumer,
} from 'react-admin'

const EmpsForm: React.FC<Omit<SimpleFormProps, 'children'>> = ({ id, ...props }) => (
  <SimpleForm
    defaultValues={{
      externalIds: [],
    }}
    {...props}
  >
    <FormDataConsumer>
      {({ formData, ...rest }) =>
        formData.id && (
          <TextInput source="id" isRequired validate={[required()]} disabled {...rest} />
        )
      }
    </FormDataConsumer>
    <TextInput source="name" isRequired validate={[required()]} />
    <ArrayInput source="externalIds">
      <SimpleFormIterator>
        <TextInput source="" />
      </SimpleFormIterator>
    </ArrayInput>
  </SimpleForm>
)

export default EmpsForm
