import { Map as MaterialUiMap } from '@mui/icons-material'
import { Button, Modal } from '@mui/material'
import { GoogleMap, Marker, Polygon, Polyline } from '@react-google-maps/api'
import React, { useState } from 'react'
import { RaRecord, useRecordContext, TextFieldProps, useTranslate } from 'react-admin'

import map_approach from '../../../../assets/Icons/map_approach.svg'
import map_search_result_pin from '../../../../assets/Icons/map_search_result_pin.svg'
import { admin } from '../../../../types'
import { CustomPaper, HideButton, ImageBackground } from '../StationChargegroupsStyles'
import { getLatLngShort } from '../StationChargegroupsUtils'

const containerStyle = {
  width: '100%',
  height: '100%',
}

const Map: React.FC<TextFieldProps> = props => {
  const record: RaRecord = useRecordContext(props)
  const approach = record && record?.approachLatitude && record?.approachLongitude
  const boundaries = record?.boundaries ? getLatLngShort(record?.boundaries) : null
  const latlng = { lat: record?.latitude, lng: record?.longitude }

  return (
    <GoogleMap
      id="map"
      mapContainerStyle={containerStyle}
      center={{
        lat: record?.latitude || 51,
        lng: record?.longitude || 13,
      }}
      zoom={20}
    >
      {boundaries && <Polygon editable={false} path={boundaries} />}
      {!boundaries && (
        <Marker
          icon={{
            url: map_search_result_pin,
            scaledSize: new google.maps.Size(60, 60),
            anchor: new google.maps.Point(15, 50),
          }}
          position={latlng}
          draggable={false}
        />
      )}
      {approach && (
        <Marker
          icon={{
            url: map_approach,
            scaledSize: new google.maps.Size(60, 60),
            anchor: new google.maps.Point(15, 50),
          }}
          position={{ lat: record.approachLatitude, lng: record.approachLongitude }}
          draggable={false}
        />
      )}
      {approach && (
        <Polyline
          path={[latlng, { lat: record.approachLatitude, lng: record.approachLongitude }]}
          options={{
            strokeColor: 'green',
          }}
        />
      )}
    </GoogleMap>
  )
}

const MapField: React.FC<TextFieldProps> = props => {
  const [mapShow, setMapShow] = useState<boolean>(false)
  const translate = useTranslate()

  return (
    <CustomPaper elevation={3} variant="elevation">
      <Button style={{ width: '100%' }} onClick={() => setMapShow(true)}>
        <MaterialUiMap />
      </Button>
      <Modal
        open={mapShow}
        onClose={() => setMapShow(false)}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <ImageBackground
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
        >
          <div
            style={{
              objectFit: 'contain',
              position: 'fixed',
              left: '50%',
              top: '50%',
              height: '100%',
              width: '100%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            <Map {...props} />
            <HideButton onClick={() => setMapShow(!mapShow)} color="secondary">
              {translate(`resources.${admin.station_chargegroups}.tags.hide`)}
            </HideButton>
          </div>
        </ImageBackground>
      </Modal>
    </CustomPaper>
  )
}

export default MapField
