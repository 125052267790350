import React from 'react'
import {
  Edit,
  EditProps,
  TextInput,
  SimpleForm,
  email,
  ToolbarProps,
  Toolbar,
  SaveButton,
  DeleteButton,
  required,
  BooleanInput,
  useGetList,
} from 'react-admin'

import { admin } from '../../types'
import { BACKOFFICE_OWNER } from '../shared/Constants'
import { RoleInput } from './UsersInputs'
import { transform } from './UsersUtils'

const EditToolbar: React.FC<ToolbarProps & { checkPermission: boolean }> = ({
  checkPermission,
  ...props
}) => {
  return (
    <Toolbar
      {...props}
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <SaveButton />
      {checkPermission && <DeleteButton />}
    </Toolbar>
  )
}

const UsersEdit: React.FC<EditProps> = props => {
  const { data } = useGetList(admin.usersMe, { pagination: { page: 1, perPage: -1 } })

  const validateDelete = data && props?.id && data[0]?.roles?.includes(BACKOFFICE_OWNER)

  return (
    <Edit {...props} transform={transform}>
      <SimpleForm
        defaultValues={{
          roles: [],
        }}
        toolbar={<EditToolbar checkPermission={!validateDelete} />}
      >
        <TextInput source="id" fullWidth disabled />
        <TextInput source="mail" fullWidth isRequired validate={[required(), email()]} />
        <RoleInput source="roles" sx={{ marginBottom: '1.5rem' }} />
        <TextInput source="lastSeenAt" fullWidth disabled />
        <TextInput source="createdAt" fullWidth disabled />
        <BooleanInput source="confirmed" fullWidth disabled />
      </SimpleForm>
    </Edit>
  )
}

export default UsersEdit
