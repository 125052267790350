import React from 'react'
import {
  Identifier,
  ListProps,
  Loading,
  RaRecord,
  useGetList,
  useListController,
} from 'react-admin'

import { admin } from '../types'
import useBreadCrumps, { BreadCrumpsProps } from './useBreadCrumps'

type UseVehicleBreadCrumpsReturn = {
  loading: React.ReactNode
  error: React.ReactNode
  addId: BreadCrumpsProps['addId']
  data: RaRecord[] | undefined
  id: Identifier
  resource: string
}

type VehicleType =
  | typeof admin.vehicle_variants
  | typeof admin.vehicle_designs
  | typeof admin.vehicle_models
  | typeof admin.vehicle_manufacturers

function useVehicleBreadCrumps(
  props: ListProps,
  list: VehicleType,
  breadCrump: VehicleType,
  errorRes: VehicleType
): UseVehicleBreadCrumpsReturn {
  const { breadCrumps, addId } = useBreadCrumps()
  const { data, isLoading, error } = useGetList(list, { pagination: { page: 1, perPage: -1 } })
  const id = breadCrumps[breadCrump] || 0
  const { resource } = useListController(props)

  return {
    loading: isLoading && <Loading />,
    error: error,
    addId,
    data,
    id,
    resource: resource || '',
  }
}

export default useVehicleBreadCrumps
