import { CircularProgress } from '@mui/material'
import { isEmpty } from 'lodash'
import {
  ArrayInput,
  SimpleFormIterator,
  SelectInput,
  RaRecord,
  useGetList,
  ReferenceInputProps,
} from 'react-admin'

const NewInput: React.FC<Omit<ReferenceInputProps, 'children'>> = ({ reference, ...props }) => {
  const { source } = props
  const { data, isLoading, error } = useGetList(reference, {
    pagination: { page: 1, perPage: 10 },
    sort: { field: 'id', order: 'DESC' },
  })

  if (isLoading) {
    return <CircularProgress />
  }

  if (error || !data || isEmpty(data)) {
    return <>ERROR</>
  }

  return (
    <ArrayInput {...props} source={source}>
      <SimpleFormIterator>
        <SelectInput
          {...props}
          label={reference}
          source="id"
          choices={Object.values(data)?.map((item: RaRecord) => ({
            id: item.id,
            name: JSON.stringify(item, null, '\t'),
          }))}
          fullWidth
        />
      </SimpleFormIterator>
    </ArrayInput>
  )
}

export default NewInput
