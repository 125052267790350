import { Modal } from '@mui/material'
import React, { useState, useEffect } from 'react'
import {
  useUpdate,
  useNotify,
  useRedirect,
  useTranslate,
  Loading,
  useRefresh,
  useDataProvider,
} from 'react-admin'
import { useMutation, useQuery } from 'react-query'

import { Modalbox, ButtonWrapper, Description, CustomButton } from './TimerStyles'

const fifteenMinutes = 900000
const threeMinutes = 180000

type TimerProps = {
  station: 'chargegroup' | 'chargepoint'
  resource: string
  id: string
  identity: string
}

const ShowTime: React.FC<{ openPopup: boolean }> = ({ openPopup }) => {
  const translate = useTranslate()
  const [remainingTime, setRemainingTime] = useState<number>(fifteenMinutes - threeMinutes)

  useEffect(() => {
    const interval = setInterval(() => {
      remainingTime >= 0 && setRemainingTime(remainingTime - 1000)
    }, 1000)

    if (!openPopup) {
      clearInterval(interval)
      setRemainingTime(fifteenMinutes)
    }

    return () => clearInterval(interval)
  }, [openPopup, remainingTime])

  return (
    <Description variant="h5">
      {`${translate('lockmode.processingClose')}${remainingTime / 1000} seconds`}
    </Description>
  )
}

const Timer: React.FC<TimerProps> = props => {
  const translate = useTranslate()
  const notify = useNotify()
  const redirect = useRedirect()
  const refresh = useRefresh()
  const { station, id, identity, resource } = props
  const {
    data: lock,
    isLoading,
    error,
  } = useQuery([resource, 'getLock', { id: id, recordId: id }], () =>
    dataProvider.getLock(resource, { id: id, recordId: id })
  )

  const [setRegenerate, { isLoading: regenerateLoading }] = useUpdate('admin/station-locks', {
    id: `regenerate/${station}/${id}`,
  })
  const dataProvider = useDataProvider()
  const { mutate: setUnlock } = useMutation(
    [
      resource,
      'unlock',
      { id: id, resource: resource, recordId: id, createdAt: new Date(), identity: identity },
    ],
    () =>
      dataProvider.unlock(resource, {
        id: id,
        resource: resource,
        recordId: id,
        createdAt: new Date(),
        identity: identity,
      })
  )
  const { mutate: setLock, isLoading: lockLoading } = useMutation(
    [resource, 'lock', { id: id, recordId: id }],
    () => dataProvider.lock(resource, { id: id, recordId: id })
  )
  const [openPopup, setOpenPopup] = useState(false)
  const messageChannel = new MessageChannel()

  const failed = () => {
    setOpenPopup(false)
    setUnlock()
    notify(`lockmode.discard`)
    redirect(`/${resource}`)
    refresh()
  }

  const extendButtonClick = () => {
    navigator?.serviceWorker?.controller?.postMessage({ type: 'STATION_TIMER_START' }, [
      messageChannel.port2,
    ])
    setOpenPopup(false)
  }

  useEffect(() => {
    //Listen to messages
    messageChannel.port1.onmessage = event => {
      if (event?.data?.type === 'STATION_TIMER_END') {
        failed()
      }

      if (event?.data?.type === 'STATION_TIMER_REMINDER') {
        setOpenPopup(true)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageChannel.port1])

  useEffect(() => {
    // Check allowed user
    if ((!isLoading && lock?.data?.locked && lock?.data?.identity !== identity) || error) {
      lock?.data?.locked && setUnlock()
      notify(`lockmode.discard`)
      redirect(`/${resource}`)
      refresh()
    }

    // isAlready Locked --> regenerate
    if (!isLoading && lock?.data?.locked && lock?.data?.identity === identity) {
      setRegenerate()
      navigator?.serviceWorker?.controller?.postMessage({ type: 'STATION_TIMER_START' }, [
        messageChannel.port2,
      ])
    }

    // Lock User
    if (!isLoading && !lock?.data?.locked) {
      setLock()
      navigator?.serviceWorker?.controller?.postMessage({ type: 'STATION_TIMER_START' }, [
        messageChannel.port2,
      ])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lock])

  if (isLoading || regenerateLoading || lockLoading) {
    return <Loading />
  }

  return (
    <>
      <Modal
        open={openPopup}
        aria-labelledby="chargegroup-title"
        aria-describedby="chargegroup-description"
      >
        <Modalbox elevation={6}>
          <Description variant="h5"> {translate('lockmode.description')} </Description>
          <ShowTime openPopup={openPopup} />
          <ButtonWrapper>
            <CustomButton onClick={extendButtonClick}>
              {' '}
              {translate('lockmode.extend')}{' '}
            </CustomButton>
            <CustomButton onClick={failed}> {translate('lockmode.abort')} </CustomButton>
          </ButtonWrapper>
        </Modalbox>
      </Modal>
    </>
  )
}

export default Timer
