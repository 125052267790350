import * as React from 'react'
import { Show, ShowProps, Tab, TabbedShowLayout } from 'react-admin'

import VehicleShowFields from './CustomFields/VehicleShowFields'

const fields = VehicleShowFields

const VehicleShow: React.FC<ShowProps> = props => (
  <Show {...props}>
    <TabbedShowLayout>
      {Object.keys(fields).map((section, index) => (
        <Tab key={`${section}-${index}`} label={section}>
          {Object.values(fields[section])}
        </Tab>
      ))}
    </TabbedShowLayout>
  </Show>
)

export default VehicleShow
