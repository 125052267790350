import { PlayArrow, Pause } from '@mui/icons-material'
import { Button, ButtonGroup } from '@mui/material'
import React, { useEffect, useState } from 'react'

const SpeedControlButtons: React.FC<{
  setTimeMultiplier: React.Dispatch<
    React.SetStateAction<{
      isStarted: boolean
      interval: number
      overAllProgress: number
      currentLineProgress: number
      lineIndex: number
      timeMultiplier: number
    }>
  >
  init: () => void
  isStarted: boolean
}> = ({ setTimeMultiplier, init, isStarted }) => {
  const [state, setState] = useState({
    isPlaying: false,
    oldMultiplier: 1,
  })

  const onClickPlayButton = () => {
    !isStarted && init()
    if (state.isPlaying) {
      setState(prevState => ({ ...prevState, isPlaying: false }))
      setTimeMultiplier(prevState => ({ ...prevState, timeMultiplier: 0 }))
    } else {
      setState(prevState => ({ ...prevState, isPlaying: true }))
      setTimeMultiplier(prevState => ({ ...prevState, timeMultiplier: state.oldMultiplier }))
    }
  }

  const onClickSpeedButton = (multiplier: number) => {
    setState(prevState => ({ ...prevState, oldMultiplier: multiplier }))
    state.isPlaying &&
      setTimeMultiplier(prevState => ({ ...prevState, timeMultiplier: multiplier }))
  }

  const getColor = (buttonNum: number): 'success' | 'primary' =>
    state.oldMultiplier === buttonNum ? 'success' : 'primary'

  useEffect(() => {
    if (!isStarted) {
      setState(prevState => ({ ...prevState, isPlaying: false }))
    }
  }, [isStarted])

  return (
    <ButtonGroup variant="contained" aria-label="outlined button group">
      <Button onClick={onClickPlayButton}>{state.isPlaying ? <Pause /> : <PlayArrow />}</Button>
      <Button onClick={() => onClickSpeedButton(1)} color={getColor(1)}>
        x1
      </Button>
      <Button onClick={() => onClickSpeedButton(2)} color={getColor(2)}>
        x2
      </Button>
      <Button onClick={() => onClickSpeedButton(20)} color={getColor(20)}>
        x20
      </Button>
      <Button onClick={() => onClickSpeedButton(100)} color={getColor(100)}>
        x100
      </Button>
    </ButtonGroup>
  )
}

export default SpeedControlButtons
