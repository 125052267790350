import { styled, Box, Typography, Button } from '@mui/material'

export const DraggingItem = styled('div')(({ theme }) => ({
  userSelect: 'none',
  padding: theme.spacing(1),
  margin: '0 0 8px 0',
  height: theme.spacing(10),
  color: 'white',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  objectFit: 'cover',
}))

export const DroppableItem = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
  width: theme.spacing(20),
  minHeight: theme.spacing(20),
  overflow: 'hidden',
}))

export const DragWrapper = styled('div')(({ theme }) => ({
  width: '50%',
  display: 'grid',
  gridGap: theme.spacing(2),
  padding: theme.spacing(2),
}))

export const ImageButton = styled(Button)(() => ({
  width: '100%',
  height: '100%',
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  objectFit: 'fill',
}))

export const CustomBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  overflow: 'hidden',
}))

export const GridBox = styled('div')(({ theme }) => ({
  display: 'grid',
  margin: theme.spacing(4),
  gridTemplateColumns: 'repeat(2, 1fr)',
  gridGap: theme.spacing(2),
}))

export const Header = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  display: 'grid',
  gridGap: theme.spacing(2),
  gridTemplateColumns: 'repeat(2, 1fr)',
  width: '100%',
}))

export const Subtitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.white,
  backgroundColor: theme.palette.secondary.main,
  fontWeight: 'bold',
  borderRadius: theme.spacing(2),
  padding: theme.spacing(1),
}))

export const CustomButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(2),
  backgroundColor: theme.palette.common.white,

  '&:hover': {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.success.main,
    border: `1px solid ${theme.palette.common.white}`,
  },
}))

export const DropzoneWrapper = styled('div')(({ theme }) => ({
  height: '100%',
  backgroundColor: theme.palette.grey[200],
  padding: theme.spacing(2),
  textAlign: 'center',
  marginBottom: theme.spacing(4),
}))

export const CustomDate = styled(Typography)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  height: '20%',
  width: '100%',
  textAlign: 'center',
}))

export const ImageMini = styled('img')(() => ({
  objectFit: 'cover',
  objectPosition: 'center',
  height: '80%',
  width: '100%',
}))

export const ImageWrapper = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  height: '100%',
  minHeight: theme.spacing(20),
  maxHeight: theme.spacing(25),
}))
