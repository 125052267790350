import React from 'react'
import { Edit, EditProps } from 'react-admin'

import EmpNamesForm from './EmpNamesForm'

const EmpNamesEdit: React.FC<EditProps> = props => (
  <Edit {...props}>
    <EmpNamesForm />
  </Edit>
)

export default EmpNamesEdit
