import React from 'react'
import { Create, CreateProps } from 'react-admin'

import StationManufacturersForm from './StationManufacturersForm'

const StationManufacturersCreate: React.FC<CreateProps> = props => (
  <Create {...props}>
    <StationManufacturersForm />
  </Create>
)

export default StationManufacturersCreate
