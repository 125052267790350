import { ThumbDownOffAlt, RestartAlt } from '@mui/icons-material'
import {
  Button,
  ButtonProps,
  useTranslate,
  RaRecord,
  useRecordContext,
  useListController,
  useUpdate,
  useNotify,
  useRedirect,
  useRefresh,
} from 'react-admin'

const RejectButton: React.FC<ButtonProps> = props => {
  const translate = useTranslate()
  const { resource } = useListController()
  const record: RaRecord = useRecordContext(props)
  const notify = useNotify()
  const redirect = useRedirect()
  const refresh = useRefresh()
  const [update] = useUpdate()

  if (!record?.id) {
    return <>ERROR</>
  }

  return (
    <Button
      {...props}
      sx={{ color: '#D32F2F' }}
      startIcon={!record.rejected ? <ThumbDownOffAlt /> : <RestartAlt />}
      onClick={() => {
        update(
          resource,
          { id: `${record.id}/reject`, data: { rejected: !record.rejected } },
          {
            onSuccess: () => {
              notify(`tags.rejected`)
              redirect(`/${resource}`)
              refresh()
            },
            onError: () => {
              notify(`ra.message.error`)
            },
          }
        )
      }}
      label={!record.rejected ? translate('tags.reject') : translate('tags.rejectReverse')}
    />
  )
}
export default RejectButton
