import { fetchUtils } from 'react-admin'

import systemConfig from '../config'
import { StorageUtil } from '../utils'

const authHttpClient = (
  url: string,
  options: fetchUtils.Options = {
    headers: {},
  }
): ReturnType<typeof fetchUtils.fetchJson> => {
  const token = StorageUtil.getLocalStorageItem(systemConfig.tokenStorageKey)
  const authOptions: fetchUtils.Options = token
    ? {
        ...options,
        headers: new Headers({
          Accept: 'application/json',
          version: systemConfig.apiVersion,
        }),
        user: { authenticated: true, token: `Bearer ${token}` },
      }
    : options
  return fetchUtils.fetchJson(url, authOptions)
}

export default authHttpClient
