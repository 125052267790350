import { Button } from '@mui/material'
import { styled } from '@mui/system'
import React, { useEffect } from 'react'
import { ImageInputProps, InputProps, useInput, useCreate, useTranslate } from 'react-admin'
import Dropzone from 'react-dropzone'

import { admin } from '../../../../types'

const DropZoneWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  backgroundColor: theme.palette.grey[200],
  padding: 4,
  borderRadius: 10,
}))

const handleDrop = (acceptedFiles: File[], create: ReturnType<typeof useCreate>[0]) => {
  acceptedFiles?.forEach(async (file: File) => {
    create(admin.vehicle_designsImages, { data: file })
  })
}

const ImageInput: React.FC<ImageInputProps & InputProps & { fieldName: string }> = props => {
  const translate = useTranslate()
  const { fieldName } = props
  const [create, { data }] = useCreate()
  const {
    field: { onChange, value },
  } = useInput(props)

  useEffect(() => {
    if (data) {
      onChange(data.url)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <div style={{ width: '50%', display: 'flex', gap: '2rem', flexDirection: 'column' }}>
      <Dropzone
        accept="image/jpeg, image/png, image/gif, image/bmp"
        onDropAccepted={file => handleDrop(file, create)}
      >
        {({ getRootProps, getInputProps }) => (
          <DropZoneWrapper {...getRootProps({ className: 'dropzone' })}>
            <input {...getInputProps()} />
            <p> {fieldName} - Drag'n'drop files, or click to select files</p>
          </DropZoneWrapper>
        )}
      </Dropzone>
      {value && (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
          <img style={{ width: '100%' }} src={value} alt={value} loading="lazy" />
          <Button style={{ width: '100%' }} variant="contained" onClick={() => onChange(null)}>
            {translate('ra.action.delete')} {fieldName}
          </Button>
        </div>
      )}
    </div>
  )
}

export default ImageInput
