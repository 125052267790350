import { isEmpty } from 'lodash'
import React from 'react'
import { useTranslate, InputProps } from 'react-admin'
import { geocodeByLatLng, geocodeByPlaceId } from 'react-google-places-autocomplete'
import { useFormContext } from 'react-hook-form'

import { ThemeProvider } from '../../../../../../provider'
import SentryLoggerProvider from '../../../../../../provider/SentryLoggerProvider'
import { admin } from '../../../../../../types'
import { drag } from '../MapConstants'
import { addressFormat } from '../MapDisplay'
import { SearchButton } from '../MapStyles'
import { MapInputProps } from '../MapTypes'
import { calculatePolygonCenter, castLatLng, getAddressComponents } from '../MapUtils'

const SavePositionButton: React.FC<InputProps & MapInputProps> = ({
  currentPosition,
  setCurrentPosition,
  centerPin,
  showMarker,
  setSave,
  inputSave,
}) => {
  const { getValues, setValue } = useFormContext()
  const value = getValues()
  const translate = useTranslate()

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const onClick = async () => {
    // Take over pin
    if (!showMarker.approach) {
      const LatLng = showMarker.pointPolygon
        ? [
            {
              lat: parseFloat(centerPin.lat().toFixed(6)),
              lng: parseFloat(centerPin.lng().toFixed(6)),
            },
          ]
        : currentPosition.LatLng

      const results = await geocodeByLatLng({
        lat: centerPin.lat(),
        lng: centerPin.lng(),
      }).catch(err => {
        console.error(err)
        SentryLoggerProvider.error(err)
      })

      if (results) {
        const addressWrapper = await geocodeByPlaceId(
          !isEmpty(inputSave.place_id) ? inputSave.place_id : results[0].place_id
        )
        const addressItem = getAddressComponents(addressWrapper[0].address_components)
        const center = calculatePolygonCenter(LatLng)

        setSave({
          save: '',
          place_id: '',
        })

        if (
          value?.addressStreet ||
          value?.addressPostCode ||
          value?.addressCity ||
          value?.addressCountry
        ) {
          setValue('latitude', parseFloat(center.lat.toFixed(6)))
          setValue('longitude', parseFloat(center.lng.toFixed(6)))
          setValue('boundaries', showMarker.pointPolygon ? null : castLatLng(LatLng))
          setValue('approachLatitude', null)
          setValue('approachLongitude', null)
        } else {
          if (addressItem) {
            Object.entries(addressItem).forEach(([key, addressValue]) =>
              setValue(key, addressValue)
            )
          }

          setValue('latitude', parseFloat(center.lat.toFixed(6)))
          setValue('longitude', parseFloat(center.lng.toFixed(6)))
          setValue('boundaries', showMarker.pointPolygon ? null : castLatLng(LatLng))
          setValue('addressFormatted', addressItem ? addressFormat(addressItem) : '')
          setValue('approachLatitude', null)
          setValue('approachLongitude', null)
        }

        setCurrentPosition({
          ...currentPosition,
          type: drag,
          LatLng: LatLng,
          address: addressItem ? addressFormat(addressItem) : '',
          approach: null,
        })
      }
    }

    // Take over approach
    if (showMarker.approach) {
      setValue('approachLatitude', parseFloat(centerPin.lat().toFixed(6)))
      setValue('approachLongitude', parseFloat(centerPin.lng().toFixed(6)))
    }
  }

  return (
    <SearchButton sx={{ backgroundColor: ThemeProvider.palette.secondary.main }} onClick={onClick}>
      <> {translate(`resources.${admin.station_chargegroups}.tags.savePosition`)}*</>
    </SearchButton>
  )
}

export default SavePositionButton
