import iso from 'iso-639-1'

export type optionsType = { id: string; value: string; name: string; firstLetter?: string }
export type castType = { id: string; value: string }
export type localizationsType = { [key: string]: string }

/**
 * Convert newValue as { id: string, value: string, name: string, firstLetter?: string } to {[id] : value}
 * @param values Array of { id: string; value: string, name: string; firstLetter?: string }
 * @returns localizationsType[] as {[key: string] : string}
 */
export const convertIsoOptionsToValues: (values: optionsType[]) => localizationsType = values =>
  values?.reduce((pre: localizationsType, value: optionsType) => {
    pre[value.id] = value.value || ''
    return pre
  }, {})

/**
 * Creates a Array of all languages with values as optionType.
 * @param value as {[key: string] : string}
 * @returns optionsType[] as { id: string, value: string, name: string, firstLetter?: string }[]
 */
export const castInput: (value: localizationsType) => optionsType[] = value =>
  (value &&
    Object.entries(value)?.reduce((pre: optionsType[], [isoName, isoValue]) => {
      const firstLetter = isoName[0].toUpperCase()
      pre.push({
        id: isoName,
        value: isoValue,
        name: iso.getNativeName(isoName),
        firstLetter: /d/.test(firstLetter) ? '0-9' : firstLetter,
      })
      return pre
    }, [] as optionsType[])) ||
  []
