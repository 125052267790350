import { Create, CreateProps, useNotify, useRefresh, useRedirect } from 'react-admin'

import { admin } from '../../../types'
import StationChargepointsForm from './StationChargepointsForm'
import { transform } from './StationChargepointsUtils'

const StationChargepointsCreate: React.FC<CreateProps> = props => {
  const notify = useNotify()
  const refresh = useRefresh()
  const redirect = useRedirect()

  const onSuccess = () => {
    notify('ra.action.create')
    redirect(`/${admin.station_chargepoints}`)
    refresh()
  }

  return (
    <Create {...props} mutationOptions={{ onSuccess }} transform={transform}>
      <StationChargepointsForm />
    </Create>
  )
}

export default StationChargepointsCreate
