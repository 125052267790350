import React from 'react'
import Dropzone from 'react-dropzone'

import { DropzoneWrapper } from './ImageStyles'

const ImageUploader: React.FC<{ handleDrop: (acceptedFiles: File[]) => void }> = ({
  handleDrop,
}) => {
  return (
    <DropzoneWrapper>
      <Dropzone accept="image/jpeg, image/png, image/gif, image/bmp" onDropAccepted={handleDrop}>
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps({ className: 'dropzone' })}>
            <input {...getInputProps()} />
            <p>Drag'n'drop files, or click to select files</p>
          </div>
        )}
      </Dropzone>
    </DropzoneWrapper>
  )
}

export default ImageUploader
