import { AppLocationContext } from '@eclever/ra-enterprise/ra-navigation'
import { LayoutProps } from 'react-admin'

import { Layout } from './LayoutStyles'
import Menu from './Menu'
import MyAppBar from './MyAppBar'

const MyLayout = (props: LayoutProps): JSX.Element => {
  return (
    <AppLocationContext>
      <Layout {...props} menu={Menu} appBar={MyAppBar} />
    </AppLocationContext>
  )
}
export default MyLayout
