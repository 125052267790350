import PaletteIcon from '@mui/icons-material/Palette'
import { ResourceProps } from 'react-admin'

import VehicleDesignCreate from './VehicleDesignCreate'
import VehicleDesignEdit from './VehicleDesignEdit'
import VehicleDesignList from './VehicleDesignList'

const VehicleDesigns: Omit<ResourceProps, 'name'> = {
  options: { label: 'Vehicle Designs' },
  list: VehicleDesignList,
  edit: VehicleDesignEdit,
  create: VehicleDesignCreate,
  icon: PaletteIcon,
}

export default VehicleDesigns
