import EvStationIcon from '@mui/icons-material/EvStation'

import OperatorsCreate from './OperatorsCreate'
import OperatorsEdit from './OperatorsEdit'
import OperatorsList from './OperatorsList'

const Operators = {
  options: { label: 'Operators' },
  list: OperatorsList,
  create: OperatorsCreate,
  edit: OperatorsEdit,
  icon: EvStationIcon,
}

export default Operators
