import DockIcon from '@mui/icons-material/Dock'

import StationChargepointsEdit from '../StationChargepoints/StationChargepointsEdit'
import StationChargepointsList from '../StationChargepoints/StationChargepointsList'
import StationChargepointsDraftsShow from './StationChargepointsDraftsShow'

const StationChargepointsDrafts = {
  options: { label: 'Station Chargepoints Drafts' },
  list: StationChargepointsList,
  show: StationChargepointsDraftsShow,
  edit: StationChargepointsEdit,
  icon: DockIcon,
}

export default StationChargepointsDrafts
