import React from 'react'

export const Box: React.FC<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
    source: string
    label?: string
    children: React.ReactNode
  }
> = ({ children, ...props }) => <div {...props}>{children}</div>

export default Box
