import { useTheme } from '@mui/material'
import { Breadcrumbs, Button } from '@mui/material/'
import * as React from 'react'
import { Identifier, useTranslate } from 'react-admin'
import { Link as LK, useNavigate } from 'react-router-dom'

import useBreadCrumps from '../../../hooks/useBreadCrumps'
import { admin } from '../../../types'

type ResourceType = { [key: string]: number }

const vehicleResources: ResourceType = {
  [admin.vehicle_manufacturers]: 1,
  [admin.vehicle_models]: 2,
  [admin.vehicle_designs]: 3,
  [admin.vehicle_variants]: 4,
}

const stationResources: ResourceType = {
  [admin.station_manufacturers]: 1,
  [admin.station_types]: 2,
}

const stationClusterResources: ResourceType = {
  [admin.station_chargegroups]: 1,
  [admin.station_chargepoints]: 2,
}

const virtualResources: ResourceType = {
  [admin.virtualCpacks]: 1,
  [admin.virtualVehicles]: 2,
}

const enotsResources: ResourceType = {
  [admin.emps]: 1,
  [admin.empnames]: 2,
  [admin.tariffs]: 3,
}

export const handleRowClick: (
  id: Identifier,
  addId: (name: string, id: Identifier) => void,
  removeAllFilterOfResource: (resource: string) => void,
  next: string,
  resource: string,
  navigate: ReturnType<typeof useNavigate>
) => string = (id, addId, removeAllFilterOfResource, next, resource, navigate) => {
  const resources = getResources(resource)
  resources &&
    activeElements(resource) &&
    Object.keys(resources)?.forEach(element => removeAllFilterOfResource(element))
  addId(resource, id)
  navigate(`/${next}`)
  return `/${next}`
}

export const getResources: (
  currentResource: string | undefined
) => ResourceType | undefined = currentResource => {
  if (!currentResource) {
    return undefined
  }

  switch (true) {
    case Object.keys(vehicleResources).includes(currentResource):
      return vehicleResources
    case Object.keys(stationResources).includes(currentResource):
      return stationResources
    case Object.keys(stationClusterResources).includes(currentResource):
      return stationClusterResources
    case Object.keys(enotsResources).includes(currentResource):
      return enotsResources
    case Object.keys(virtualResources).includes(currentResource):
      return virtualResources
    default:
      return undefined
  }
}

const handleClick = (
  resources: ResourceType,
  resource: string,
  removeIds: (names: string[]) => void
) =>
  Object.entries(resources).forEach(([key, value]) => {
    if (resources[resource] <= value) {
      removeIds([key])
    }
  })

export const activeElements: (resource: string) => boolean = resource =>
  !Object.keys(enotsResources).includes(resource) &&
  !Object.keys(stationClusterResources).includes(resource) &&
  !Object.keys(virtualResources).includes(resource)

const BreadCrumbs: React.FC<{ resource?: string }> = ({ resource, ...props }) => {
  const translate = useTranslate()
  const theme = useTheme()
  const { removeIds } = useBreadCrumps()
  const resources = getResources(resource)

  if (resources && resource) {
    return (
      <div role="presentation">
        <Breadcrumbs aria-label="breadcrumb">
          {Object.entries(resources).map(([key, value]) => (
            <Button
              key={key}
              sx={
                resources[resource] === value
                  ? { textDecorationLine: 'underline', color: theme.palette.success.dark }
                  : {}
              }
              component={LK}
              to={`/${key}`}
              color="primary"
              disabled={resources[resource] - 1 < value && activeElements(resource) ? true : false}
              // onClick={() => resources[resource] - 1 < value && activeElements(resource) && handleClick(resources, key, removeIds)}
              onClick={() => activeElements(resource) && handleClick(resources, key, removeIds)}
            >
              {translate(`resources.${key}.name`)}
            </Button>
          ))}
        </Breadcrumbs>
      </div>
    )
  }

  return <></>
}

export default BreadCrumbs
