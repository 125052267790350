import React from 'react'
import { Edit, EditProps } from 'react-admin'

import StationTypesForm from './StationTypesForm'

const StationTypesEdit: React.FC<EditProps> = props => {
  return (
    <Edit {...props}>
      <StationTypesForm />
    </Edit>
  )
}

export default StationTypesEdit
