import React from 'react'
import {
  BooleanField,
  BooleanInput,
  Datagrid,
  DateField,
  List,
  ListProps,
  NumberField,
  NumberInput,
  ShowButton,
  TextField,
  TextInput,
  useTranslate,
} from 'react-admin'

import { admin } from '../../../types'
import LinkRecordField from './CustomDataDisplay/LinkField'
import TimespanField from './CustomDataDisplay/TimespanField'
import UnitField from './CustomDataDisplay/UnitField'

const CBoxFilter = [
  <TextInput source="cBoxName" alwaysOn />,
  <TextInput source="firmware" />,
  <TextInput source="licensePlate" />,
  <BooleanInput source="isStanding" />,
  <BooleanInput source="isCharging" />,
  <BooleanInput source="vehicleNameExist" />,
  <BooleanInput source="cBox12V" label={`resources.${admin.cPacks}.filter.cBox12V`} />,
  <BooleanInput source="cPlug12V" label={`resources.${admin.cPacks}.filter.cPlug12V`} />,
  <NumberInput source="vehicleVariant" />,
]

const CBoxList: React.FC<ListProps> = props => {
  const translate = useTranslate()
  return (
    <List {...props} filters={CBoxFilter}>
      <Datagrid bulkActionButtons={false} isRowSelectable={record => false}>
        <TextField
          source="id"
          sortable={true}
          label={translate(`resources.${admin.cPacks}.list.id`)}
        />
        <TextField
          source="name"
          sortable={true}
          label={translate(`resources.${admin.cPacks}.list.name`)}
        />
        <TextField
          source="vehicleName"
          sortable={true}
          label={translate(`resources.${admin.cPacks}.list.vehicleName`)}
        />
        <TextField
          source="licensePlate"
          sortable={true}
          label={translate(`resources.${admin.cPacks}.list.licensePlate`)}
        />
        <LinkRecordField
          uri={`#/${admin.vehicle_variants}/`}
          source="variantId"
          label={translate(`resources.${admin.cPacks}.list.variantId`)}
          sortable={true}
        ></LinkRecordField>
        <NumberField
          source="mileage"
          sortable={true}
          label={translate(`resources.${admin.cPacks}.list.mileage`)}
        />
        <TimespanField
          source="standing"
          sortable={true}
          label={translate(`resources.${admin.cPacks}.list.standing`)}
        />
        <UnitField
          source="charging"
          sortable={true}
          label={translate(`resources.${admin.cPacks}.list.charging`)}
          unit={'kW'}
        />
        <TextField
          source="firmware"
          sortable={true}
          label={translate(`resources.${admin.cPacks}.list.firmware`)}
        />
        <NumberField
          source="cBoxBattery"
          sortable={true}
          label={translate(`resources.${admin.cPacks}.list.cBoxBattery`)}
        />
        <BooleanField
          source="cBox12VDetected"
          sortable={true}
          label={translate(`resources.${admin.cPacks}.list.cBox12VDetected`)}
        />
        <NumberField
          source="cBoxSpannung"
          sortable={true}
          label={translate(`resources.${admin.cPacks}.list.cBoxSpannung`)}
        />
        <TimespanField
          source="cBoxRuntime"
          sortable={true}
          label={translate(`resources.${admin.cPacks}.list.cBoxRuntime`)}
        />
        <DateField
          source="cBoxTimestamp"
          sortable={true}
          label={translate(`resources.${admin.cPacks}.list.cBoxTimestamp`)}
        />
        <BooleanField
          source="cPlug12VDetected"
          sortable={true}
          label={translate(`resources.${admin.cPacks}.list.cPlug12VDetected`)}
        />
        <NumberField
          source="cPlugSpannung"
          sortable={true}
          label={translate(`resources.${admin.cPacks}.list.cPlugSpannung`)}
        />
        <TimespanField
          source="cPlugRuntime"
          sortable={true}
          label={translate(`resources.${admin.cPacks}.list.cPlugRuntime`)}
        />
        <DateField
          source="cPlugTimestamp"
          sortable={true}
          label={translate(`resources.${admin.cPacks}.list.cPlugTimestamp`)}
        />
        <NumberField
          source="hvSocActualDisplay"
          sortable={true}
          label={translate(`resources.${admin.cPacks}.list.socHvBattery`)}
        />
        <ShowButton />
      </Datagrid>
    </List>
  )
}

export default CBoxList
