import React from 'react'
import { EditProps, Edit } from 'react-admin'

import VehicleModelForm from './VehicleModelForm'

const VehicleModelEdit: React.FC<EditProps> = props => (
  <Edit {...props}>
    <VehicleModelForm />
  </Edit>
)

export default VehicleModelEdit
