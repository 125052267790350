import { AutocompleteArrayInput, LinearProgress, useDataProvider, InputProps } from 'react-admin'
import { useWatch } from 'react-hook-form'
import { useQuery } from 'react-query'

import { admin } from '../../../../types'
import { getValidOperators } from '../Utils'

const ValidEmpOperators = (props: InputProps): JSX.Element => {
  const dataProvider = useDataProvider()
  const empNameId = useWatch<{ empNameId: number }>({ name: 'empNameId' })

  const {
    data: empId,
    isLoading: empIdLoading,
    error: empIdError,
  } = useQuery(
    [admin.empnames, empNameId, 'getOne'],
    () => dataProvider.getOne(admin.empnames, { id: empNameId }),
    {
      enabled: !!empNameId,
      refetchOnWindowFocus: false,
    }
  )

  const {
    data: getEmpOperators,
    isLoading: emp_operatorsLoad,
    error: emp_operatorsError,
  } = useQuery(
    [admin.emp_operators, 'getList'],
    () =>
      dataProvider.getList(admin.emp_operators, {
        pagination: { page: 0, perPage: -1 },
        sort: { field: 'operatorId', order: 'ASC' },
        filter: {},
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  )

  const { data, isLoading, error } = useQuery(
    [admin.emp_operators, admin.operators, admin.empnames, getEmpOperators, empId, 'getList'],
    () =>
      dataProvider.getList(admin.operators, {
        pagination: { page: 0, perPage: -1 },
        sort: { field: 'name', order: 'ASC' },
        filter: { idList: getValidOperators(getEmpOperators, empId?.data?.empId) },
      }),
    {
      enabled: !!getEmpOperators?.data && !!empId?.data?.empId,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  )

  if (isLoading || emp_operatorsLoad || empIdLoading) {
    return <LinearProgress />
  }
  if (error || empIdError || emp_operatorsError) {
    return <p>ERROR</p>
  }

  return (
    <AutocompleteArrayInput {...props} choices={data?.data || []} optionText="name" fullWidth />
  )

  // {(record) => `${record.name} [${record.evseOperatorId}]`}
}

export default ValidEmpOperators
