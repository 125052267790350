import { Drawer, Typography, Table, TableRow, TableCell, TableBody, TableHead } from '@mui/material'
import React, { useEffect, useState } from 'react'
import {
  Button,
  EditButtonProps,
  useTranslate,
  RaRecord,
  LinearProgress,
  useDataProvider,
  useListController,
  useRecordContext,
} from 'react-admin'
import { useMutation } from 'react-query'

import { ThemeProvider } from '../../../../provider'
import { admin } from '../../../../types'
import { ChangeLogPaper, ChangeLogHeader, ChangeLogItemPaper } from './ChangeLogStyles'

const UPDATE = 'UPDATE'
const updated = 'updated'
type updateType = 'OLD' | 'NEW'

const checkobject: (object: unknown, type: updateType) => string = (object, type) => {
  if (typeof object == 'object') {
    switch (type) {
      case 'OLD':
        return ''
      case 'NEW':
        return 'updated'
      default:
        return JSON.stringify(object)
    }
  }

  if (typeof object == 'object') {
    return updated
  }

  return `${object}`
}

const getReferenceObject: (path: string) => {
  hasReference: boolean
  path: string | undefined
  name: string
} = path => {
  const name = 'Name'
  const id = 'Id'

  switch (true) {
    case path.endsWith('typeId'):
      return { hasReference: true, path: admin.station_types, name: path.replace(id, name) }
    case path.endsWith('manufacturerId'):
      return { hasReference: true, path: admin.station_manufacturers, name: path.replace(id, name) }
    case path.endsWith('operatorId'):
      return { hasReference: true, path: admin.operators, name: path.replace(id, name) }
    default:
      return { hasReference: false, path: undefined, name: path }
  }
}

const ReferenceCheckCell: React.FC<{ path: string; element: unknown; header: 'OLD' | 'NEW' }> = ({
  path,
  element,
  header,
}) => {
  const { hasReference, path: newPath } = getReferenceObject(path)
  const dataProvider = useDataProvider()
  const {
    mutate: approve,
    data,
    isLoading,
  } = useMutation([newPath, 'getOne', { id: element }], () =>
    dataProvider.getOne(newPath || '', { id: element })
  )

  useEffect(() => {
    if (hasReference && element && newPath) {
      approve()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasReference, element])

  if (isLoading) return <LinearProgress />

  return (
    <div style={{ width: '100%', wordBreak: 'break-word' }}>
      {checkobject((hasReference && data?.data?.name) || element, header)}
    </div>
  )
}

const ChangeLogItem: React.FC<{ changelogItem: RaRecord }> = ({ changelogItem }) => {
  const translate = useTranslate()

  return (
    <ChangeLogPaper
      sx={{
        backgroundColor:
          changelogItem?.event === UPDATE
            ? ThemeProvider.palette.colorPalette.changelog.update
            : ThemeProvider.palette.colorPalette.changelog.create,
      }}
      elevation={0}
      variant="outlined"
      square
    >
      <ChangeLogHeader variant="h6">{changelogItem?.event}</ChangeLogHeader>
      <div
        style={{
          marginBottom: changelogItem?.event === UPDATE ? '2rem' : '',
          display: 'grid',
          gridTemplateColumns: 'repeat(2, 1fr)',
        }}
      >
        <Typography variant="body2" component="div">
          {translate('changelog.revision')}
        </Typography>
        <Typography variant="body2" component="div">
          {changelogItem?.revision}
        </Typography>
        <Typography variant="body2" component="div">
          {translate('changelog.author')}
        </Typography>
        <Typography variant="body2" component="div">
          {changelogItem?.author}
        </Typography>
        <Typography variant="body2" component="div">
          {translate('changelog.createdAt')}
        </Typography>
        <Typography variant="body2" component="div">
          {changelogItem?.createdAt}
        </Typography>
      </div>
      {changelogItem?.event === UPDATE && (
        <ChangeLogItemPaper variant="outlined" square>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ width: '20%' }}>{translate('changelog.value')}</TableCell>
                <TableCell style={{ width: '40%' }}>{translate('changelog.old')}</TableCell>
                <TableCell style={{ width: '40%' }}>{translate('changelog.new')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                changelogItem?.changes.map((change: any, index: number) => (
                  <TableRow key={index}>
                    <TableCell style={{ width: '20%' }}>
                      {getReferenceObject(change.path).name}
                    </TableCell>
                    <TableCell>
                      <ReferenceCheckCell element={change?.old} header="OLD" path={change.path} />
                    </TableCell>
                    <TableCell>
                      <ReferenceCheckCell element={change?.new} header="NEW" path={change.path} />
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
          </Table>
        </ChangeLogItemPaper>
      )}
    </ChangeLogPaper>
  )
}

const ChangeLogField: React.FC<EditButtonProps> = props => {
  const { resource } = useListController()
  const record = useRecordContext()
  const dataProvider = useDataProvider()
  const {
    mutate: approve,
    data,
    error,
    isLoading,
  } = useMutation([resource, 'getOne'], () =>
    dataProvider.getOne(resource, { id: `${record.id}/history` })
  )

  const [openDrawer, setOpenDrawer] = useState<boolean>()
  const translate = useTranslate()

  if (isLoading) {
    return <LinearProgress />
  }

  if (error && !resource) {
    return <>error</>
  }

  return (
    <>
      <Button
        disabled={!!error || data?.data.total === 0}
        onClick={() => {
          approve()
          setOpenDrawer(!openDrawer)
        }}
      >
        <> {translate('changelog.name')} </>
      </Button>
      <Drawer
        sx={{
          '.MuiDrawer-paper': {
            width: 600,
          },
        }}
        anchor="right"
        open={openDrawer}
        onClose={() => setOpenDrawer(!openDrawer)}
      >
        {data?.data.data?.map((item: unknown, index: number) => (
          <ChangeLogItem key={index} changelogItem={item as RaRecord} />
        ))}
      </Drawer>
    </>
  )
}

export default ChangeLogField
