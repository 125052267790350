import React from 'react'
import { Create, CreateProps } from 'react-admin'

import EmpNamesForm from './EmpNamesForm'

const EmpNamesCreate: React.FC<CreateProps> = props => (
  <Create {...props}>
    <EmpNamesForm />
  </Create>
)

export default EmpNamesCreate
