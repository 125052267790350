import { RestartAlt } from '@mui/icons-material'
import {
  Button,
  ButtonProps,
  useTranslate,
  useListContext,
  useRefresh,
  useListController,
} from 'react-admin'

import useBreadCrumps from '../../../hooks/useBreadCrumps'
import useFilters from '../../../hooks/useFilters'

const ResetButton: React.FC<ButtonProps> = props => {
  const { removeAllFilterOfResource } = useFilters()
  const { resource } = useListController()
  const translate = useTranslate()
  const { setFilters } = useListContext()
  const { reset } = useBreadCrumps()
  const refresh = useRefresh()

  if (!resource) {
    return <>ERROR</>
  }

  const handleClick = () => {
    removeAllFilterOfResource(resource)
    setFilters({}, {}, false)
    reset()
    refresh()
  }

  return (
    <Button
      {...props}
      startIcon={<RestartAlt />}
      onClick={handleClick}
      label={translate('tags.resetfilter')}
    />
  )
}

export default ResetButton
