import { RestartAlt, Delete, ThumbDownOffAlt } from '@mui/icons-material'
import { isEmpty } from 'lodash'
import React, { useEffect } from 'react'
import {
  EditButton,
  EditButtonProps,
  useRecordContext,
  RaRecord,
  BulkActionProps,
  useUpdateMany,
  useDeleteMany,
  Button,
  useUnselectAll,
  useNotify,
  useRefresh,
  useListContext,
  useTranslate,
} from 'react-admin'

import { admin } from '../../types'
import LockResource from '../../utils/LockResource'

export const StationEditButton: React.FC<EditButtonProps & { user: RaRecord | undefined }> = ({
  user,
  ...props
}) => {
  const record: RaRecord = useRecordContext(props)

  return (
    <EditButton
      {...props}
      disabled={!isEmpty(record?.locked) || (record?.locked && record?.lockUserId !== user?.id)}
    />
  )
}

export const PostBulkAction: React.FC<BulkActionProps> = props => {
  const { selectedIds, resource } = props
  const [updateMany] = useUpdateMany()
  const [deleteMany, { isSuccess }] = useDeleteMany(resource, { ids: selectedIds })
  const unselectAll = useUnselectAll(resource || '')
  const notify = useNotify()
  const refresh = useRefresh()

  useEffect(() => {
    refresh()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess])

  const handleLockClick = () => {
    resource &&
      updateMany(
        `admin/station-locks/lock/${LockResource.getType(resource)}`,
        { ids: selectedIds },
        {
          onSuccess: () => deleteMany(),
          onError: error => {
            notify(`Error ${error}`)
            // setErrorIds([...errorIds, id])
            refresh()
          },
        }
      )

    unselectAll()
  }

  return (
    <Button label="ra.action.delete" onClick={handleLockClick} sx={{ color: '#D32F2F' }}>
      <Delete />
    </Button>
  )
}

export const DraftPostBulkAction: React.FC<BulkActionProps> = props => {
  const translate = useTranslate()
  const { selectedIds, resource } = props
  const { filterValues } = useListContext()
  const [updateMany] = useUpdateMany()
  const unselectAll = useUnselectAll(resource || '')
  const notify = useNotify()
  const refresh = useRefresh()

  const handleLockClick = () => {
    if (resource && selectedIds) {
      updateMany(
        admin.station_chargegroup_drafts,
        {
          ids: selectedIds.map(id => `${id}/reject`),
          data: { rejected: filterValues['rejected'] ? false : true },
        },
        {
          onSuccess: () => {
            notify('ra.notification.updated', { messageArgs: { smart_count: selectedIds.length } })
          },
          onError: error => {
            notify(`Error ${error}`)
            refresh()
          },
        }
      )
    }

    unselectAll()
    refresh()
  }

  return (
    <Button
      label={filterValues['rejected'] ? translate('tags.rejectReverse') : translate('tags.reject')}
      onClick={handleLockClick}
      sx={{ color: '#D32F2F' }}
    >
      {filterValues['rejected'] ? <RestartAlt /> : <ThumbDownOffAlt />}
    </Button>
  )
}
