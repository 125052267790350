import { Box, BoxProps, styled } from '@mui/material'

const CustomBox = styled(Box)(({ theme }) => ({
  display: 'grid',
  width: '100%',
  justifyItems: 'left',
  alignItems: 'center',
  gridTemplateColumns: 'repeat(auto-fit, minmax(100px, 1fr))',
  gridGap: theme.spacing(4),
}))

const GridBox: React.FC<BoxProps> = props => {
  const { children } = props

  return <CustomBox {...props}>{children}</CustomBox>
}

export default GridBox
