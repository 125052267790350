import { useCanAccess } from '@eclever/ra-enterprise/ra-rbac'
import {
  BooleanField,
  Datagrid,
  EditButton,
  ListProps,
  TextField,
  useGetList,
  Loading,
  useTranslate,
  sanitizeListRestProps,
  NumberInput,
  TextInput,
} from 'react-admin'
import { useNavigate } from 'react-router-dom'

import useBreadCrumps from '../../../hooks/useBreadCrumps'
import useFilters from '../../../hooks/useFilters'
import { admin } from '../../../types'
import BreadCrumbs, { handleRowClick } from '../../shared/Components/BreadCrumbs'
import FilterWrapper from '../../shared/Components/FilterWrapper'
import Pagination from '../../shared/Components/Pagination'
import StickyColumnWrapper from '../../shared/Components/StickyColumnWrapper'
import List from '../../shared/Components/StyledComponents/List'
import Toolbar from '../../shared/Toolbar/Toolbar'

const vehicleManufacturerFilters = [
  <FilterWrapper reference={admin.vehicle_manufacturers} source="id" alwaysOn>
    <NumberInput source="id" />
  </FilterWrapper>,
  <TextInput source="name" label="ManufacturerName" alwaysOn />,
]

const VehicleManufacturerList: React.FC<ListProps> = props => {
  const navigate = useNavigate()
  const translate = useTranslate()
  const { addId } = useBreadCrumps()
  const { filter, removeAllFilterOfResource } = useFilters()
  const { data, isLoading, error } = useGetList(admin.vehicle_models, {
    pagination: { page: 1, perPage: -1 },
  })
  const { isLoading: loadAccess, canAccess } = useCanAccess({
    action: 'edit',
    resource: admin.vehicle_manufacturers,
  })

  if (isLoading || loadAccess) {
    return <Loading />
  }

  if (error) {
    return <>ERROR</>
  }

  return (
    <>
      <BreadCrumbs resource={admin.vehicle_manufacturers} />
      <List
        {...sanitizeListRestProps(props)}
        filter={filter[admin.vehicle_manufacturers]}
        pagination={<Pagination />}
        title={translate(`resources.${admin.vehicle_manufacturers}.name`)}
        filters={vehicleManufacturerFilters}
        actions={
          <Toolbar reference={admin.vehicle_manufacturers} resource={admin.vehicle_manufacturers} />
        }
        sx={{
          "& div[class*='RaListToolbar-toolbar']": {
            display: 'flex',
            justifyContent: 'flex-end',
            flexDirection: 'column-reverse',
          },
        }}
        empty={false}
      >
        <Datagrid
          rowClick={id =>
            handleRowClick(
              id,
              addId,
              removeAllFilterOfResource,
              admin.vehicle_models,
              admin.vehicle_manufacturers,
              navigate
            )
          }
          isRowSelectable={record =>
            canAccess && data?.filter(item => item.vehicleManufacturerId === record.id).length === 0
          }
        >
          <TextField source="id" />
          <BooleanField source="isDefault" />
          <TextField source="name" />
          <StickyColumnWrapper {...props}>{canAccess && <EditButton />}</StickyColumnWrapper>
        </Datagrid>
      </List>
    </>
  )
}

export default VehicleManufacturerList
