import React from 'react'
import { Create, CreateProps } from 'react-admin'

import ChargingCurveGroupsForm from './ChargingCurveGroupsForm'

const ChargingCurveGroupsCreate: React.FC<CreateProps> = props => (
  <Create {...props}>
    <ChargingCurveGroupsForm />
  </Create>
)

export default ChargingCurveGroupsCreate
