import {
  TextInput,
  BooleanInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  FormDataConsumer,
  required,
} from 'react-admin'

import { admin, api, InputElementType, InputType } from '../../../types'
import convertArrayToInputType from '../../shared/Utils/convert'
import NewInput from './CustomInputs/NewInput'

const mainInputs: InputElementType[] = [
  <BooleanInput source="isNew" defaultValue={true} fullWidth />,
  <FormDataConsumer source="vehicleId" fullWidth>
    {({ formData, ...rest }) =>
      !formData.isNew && <TextInput {...rest} source="vehicleId" fullWidth />
    }
  </FormDataConsumer>,
  <TextInput source="name" fullWidth />,
  <BooleanInput source="scFree" fullWidth />,
  <BooleanInput label="Permissions Complete" source="permissions.complete" fullWidth />,
  <BooleanInput label="Permissions Anonymous" source="permissions.anonymous" fullWidth />,
  <ReferenceArrayInput
    source="vehicleVariantIds"
    reference={admin.vehicle_variants}
    perPage={-1}
    sort={{ field: 'name', order: 'ASC' }}
    fullWidth
  >
    <AutocompleteArrayInput optionText="name" isRequired validate={required()} />
  </ReferenceArrayInput>,
  <NewInput reference={api.vehicle_adapters} source="adapters" fullWidth />,
  <NewInput reference={api.vehicle_cables} source="cables" fullWidth />,
]

const CPacksInputs: Record<string, InputType> = {
  main: mainInputs.reduce(convertArrayToInputType, {}),
}

export default CPacksInputs
