import React from 'react'
import { InputProps } from 'react-admin'

import useFilters from '../../../hooks/useFilters'
import { InputElementType } from '../../../types'

const FilterInputWrapper: React.FC<
  InputProps & { validElement: 'string' | 'number' | 'boolean' | 'select' | 'arrayselect' }
> = ({ validElement, ...props }) => {
  const { children, resource, source } = props
  const { filter, addFilter, removeFilter } = useFilters()

  return (
    (children &&
      resource &&
      React.cloneElement(children as InputElementType, {
        ...props,
        alwaysOn: true,
        defaultValue: (filter[resource] && filter[resource][source]) || null,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onBlur: (event: any) => {
          switch (validElement) {
            case 'string':
              addFilter(resource, source, `%${event?.currentTarget?.value || ''}`)
              break
            case 'number':
              event?.target?.value &&
                addFilter(resource, source, parseInt(event.currentTarget.value))
              break
            case 'boolean':
              if (event?.target?.value === '') {
                removeFilter(resource, source)
                break
              }
              event?.target?.value && addFilter(resource, source, event.target.value === 'true')
              break
            case 'select':
              addFilter(resource, source, event)
              break
            case 'arrayselect':
              event?.target?.value && addFilter(resource, source, event.target.value)
              break
          }
        },
      })) || <></>
  )
}

FilterInputWrapper.defaultProps = {
  alwaysOn: true,
}

export default FilterInputWrapper
