import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import * as React from 'react'
import { useTranslate, RaRecord, useRecordContext, TextFieldProps } from 'react-admin'

import Header from '../../shared/Station/Header'
import { ChangelogType } from '../../shared/Station/Types'
import { getSource } from '../StationChargegroups/StationChargegroupsFields'
import StationChargegroupsDraftsRow from './StationChargegroupsDraftsRow'

const StationChargegroupsDraftsUpdate: React.FC<
  TextFieldProps & { type: ChangelogType; oldData?: RaRecord }
> = props => {
  const newData: RaRecord = useRecordContext(props)
  const { type, oldData } = props
  const translate = useTranslate()

  return (
    <>
      <Header {...props} />
      <TableContainer component={Paper}>
        <Table style={{ overflow: 'hidden' }}>
          <TableHead>
            <TableRow>
              <TableCell style={{ width: '20%' }} />
              {type === 'UPDATE' && (
                <TableCell style={{ width: type === 'UPDATE' ? '40%' : '80%' }}>
                  <Typography variant="h5" component="h5" align="center">
                    {translate('changelog.old')}{' '}
                  </Typography>
                </TableCell>
              )}
              <TableCell style={{ width: type === 'UPDATE' ? '40%' : '80%' }}>
                <Typography variant="h5" component="h5" align="center">
                  {newData?.delete ? translate('changelog.old') : translate('changelog.new')}{' '}
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <StationChargegroupsDraftsRow name="boundaries" oldData={oldData} newData={newData} />
            {getSource.map(key => (
              <StationChargegroupsDraftsRow
                key={key}
                name={key}
                oldData={oldData}
                newData={newData}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default StationChargegroupsDraftsUpdate
