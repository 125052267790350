import { AddCircle, RemoveCircle } from '@mui/icons-material/'
import { MenuItem, FormControl, ListItemText, Checkbox, Select, Typography } from '@mui/material'
import { SelectChangeEvent } from '@mui/material/Select'
import * as React from 'react'
import { useStore } from 'react-admin'

import { ColumnsType } from '../../../../types'
import { all, none } from '../../Constants'

const ITEM_HEIGHT = 60
const ITEM_PADDING_TOP = 1
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

const isArrayOfStrings = (value: unknown): boolean =>
  Array.isArray(value) && value.every(item => typeof item === 'string')

const SelectColumnsButtons: React.FC<{ preferences: string; columns: ColumnsType }> = ({
  preferences,
  columns,
}) => {
  const [columnsStore, setColumnsStore] = useStore<string[]>(preferences, Object.keys(columns))
  const [open, setOpen] = React.useState<boolean>(false)

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event

    if (value && typeof value !== 'string' && isArrayOfStrings(value)) {
      switch (true) {
        case value.includes(all):
          setColumnsStore(Object.keys(columns))
          break
        case value.includes(none):
          setColumnsStore([])
          break
        default:
          setColumnsStore(value)
          break
      }
    }
  }

  return (
    <div
      style={{
        height: '1.718rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
      }}
    >
      <FormControl sx={{ width: 100 }}>
        <Select
          sx={{
            '> .MuiSelect-select': {
              padding: 0,
              fontSize: 14.5,
              display: 'flex',
              justifyContent: 'center',
            },
          }}
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          // renderValue={(selected) => selected.join(', ')}
          displayEmpty
          renderValue={() => (
            <Typography variant="body2" color="secondary">
              SELECT
            </Typography>
          )}
          multiple
          value={columnsStore}
          onChange={handleChange}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          MenuProps={MenuProps}
          variant="filled"
        >
          {[all, none, ...Object.keys(columns)].map((columnname: string) => (
            <MenuItem key={columnname} value={columnname}>
              {columnname === all && <AddCircle color="secondary" sx={{ width: '2.625rem' }} />}
              {columnname === none && <RemoveCircle color="secondary" sx={{ width: '2.625rem' }} />}
              {![all, none].includes(columnname) && (
                <Checkbox checked={columnsStore.indexOf(columnname) > -1} />
              )}
              <ListItemText primary={columnname} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  )
}

export default SelectColumnsButtons
