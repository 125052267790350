import { Clear, Done } from '@mui/icons-material/'
import {
  ArrayField,
  Datagrid,
  FunctionField,
  TextField,
  RaRecord,
  DateField,
  NumberField,
  BooleanField,
} from 'react-admin'

import { FieldType } from '../../../types'
import CustomImageField from './CustomFields/CustomImageField'
import DescriptionField from './CustomFields/DescriptionField'
import IdField from './CustomFields/IdField'
import MapField from './CustomFields/MapField'

const StationChargegroupsFields: FieldType[] = [
  <IdField source="id" />,
  <MapField source="map" sortable={false} />,
  <TextField style={{ whiteSpace: 'nowrap' }} source="addressFormatted" sortable={false} />,
  <FunctionField
    sortable={false}
    source="approachPosition"
    label="approachPosition"
    render={(record?: RaRecord) => {
      return (
        <>
          {(record?.approachLatitude && record?.approachLongitude && (
            <Done data-testid="true" fontSize="small" />
          )) || <Clear data-testid="false" fontSize="small" />}
        </>
      )
    }}
  />,
  <DescriptionField source="approachDescriptions" sortable={false} />,
  <TextField source="locationType" />,
  <CustomImageField source="images" />,
  <ArrayField source="chargePointOverview">
    <Datagrid bulkActionButtons={false}>
      <FunctionField
        sortable={true}
        source="operatorName"
        render={(record?: RaRecord) => `${record?.operatorName} [${record?.evseOperatorId}]`}
      />
      <NumberField source="amount" sortable={false} />
      <NumberField source="qualityCheckedAmount" sortable={false} />
    </Datagrid>
  </ArrayField>,
  <DateField source="createdAt" showTime />,
  <DateField source="updatedAt" showTime />,
  <TextField source="author" />,
  <DateField source="lockedAt" showTime />,
  <TextField source="lockUserId" />,
  <BooleanField source="locked" />,
  <BooleanField source="hasDrafts" />,
]

export const getSource: string[] = StationChargegroupsFields.reduce<string[]>((o, field) => {
  if (
    [
      'id',
      'createdAt',
      'updatedAt',
      'author',
      'lockedAt',
      'lockUserId',
      'locked',
      'hasDrafts',
    ].includes(field.props.source)
  ) {
    return o
  }

  o.push(field.props.source)

  if (field.props.source === 'addressFormatted') {
    o.push('addressPostCode', 'addressStreet', 'addressCity', 'addressCountry')
  }

  return o
}, [] as string[])

export default StationChargegroupsFields
