interface OutputData {
  date: Date
  [key: string]: unknown
}

export const getTableData = (
  responseData: { parameter: string; values: [number, number][] }[]
): OutputData[] => {
  const combinedItems = responseData.reduce(
    (prev, cur) => prev.concat([...castFn(cur.values, cur.parameter)]),
    [] as OutputData[]
  )
  return mergeSort(combinedItems)
}

const castFn = (input: [number, number][], name: string) =>
  input.reduce((pre, cur) => {
    pre.push({ date: new Date(cur[1] * 1000), [name]: cur[0] })
    return pre
  }, [] as OutputData[])

export default function mergeSort(items: OutputData[]): OutputData[] {
  return divide(items)
}

const divide = (items: OutputData[]): OutputData[] => {
  const halfLength = Math.ceil(items.length / 2)
  let low = items.slice(0, halfLength)
  let high = items.slice(halfLength)
  if (halfLength > 1) {
    low = divide(low)
    high = divide(high)
  }
  return combine(low, high)
}

const combine = (low: OutputData[], high: OutputData[]): OutputData[] => {
  let indexLow = 0
  let indexHigh = 0
  const lengthLow = low.length
  const lengthHigh = high.length
  const combined = []
  while (indexLow < lengthLow || indexHigh < lengthHigh) {
    const lowItem = low[indexLow]
    const highItem = high[indexHigh]
    if (lowItem !== undefined) {
      if (highItem === undefined) {
        combined.push(lowItem)
        indexLow++
      } else {
        if (lowItem.date.getTime() === highItem.date.getTime()) {
          combined.push({ ...lowItem, ...highItem })
          indexLow++
          indexHigh++
        } else {
          if (lowItem.date.getTime() <= highItem.date.getTime()) {
            combined.push(lowItem)
            indexLow++
          } else {
            combined.push(highItem)
            indexHigh++
          }
        }
      }
    } else {
      if (highItem !== undefined) {
        combined.push(highItem)
        indexHigh++
      }
    }
  }
  return combined
}

export const getRidePath = (
  data: [number, number, number][]
): {
  distance: number
  timestamp: number
  lat: number
  lng: number
}[] =>
  data.map((coordinates, i, array) => {
    const lat1 = coordinates[1]
    const lng1 = coordinates[0]
    const timestamp = coordinates[2]
    if (i === 0) {
      return { lat: lat1, lng: lng1, timestamp: timestamp, distance: 0 }
    }

    const latLong1 = new google.maps.LatLng(lat1, lng1)

    const latLong2 = new google.maps.LatLng(array[0][1], array[0][0])
    const distance = google.maps.geometry.spherical.computeDistanceBetween(latLong1, latLong2)

    return { lat: lat1, lng: lng1, timestamp: timestamp, distance }
  })
