import { ResourceProps } from 'react-admin'

import StationChargegroupsEdit from '../StationChargegroups/StationChargegroupsEdit'
import StationChargegroupsList from '../StationChargegroups/StationChargegroupsList'
import StationChargegroupsDraftsShow from './StationChargegroupsDraftsShow'

const StationChargegroupsDrafts: Omit<ResourceProps, 'name'> = {
  options: { label: 'StationChargegroupsDraft' },
  list: StationChargegroupsList,
  show: StationChargegroupsDraftsShow,
  edit: StationChargegroupsEdit,
  // icon: DockIcon,
}

export default StationChargegroupsDrafts
