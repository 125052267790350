import { Fingerprint, PermIdentity, AccountTree } from '@mui/icons-material'
import { List, ListItem, ListItemText, Avatar, ListItemAvatar, Chip, Drawer } from '@mui/material'
import { EditButton, useTranslate, RaRecord } from 'react-admin'

import { admin } from '../../../types'
import countriesArray, { CountriesProps } from '../../shared/Utils/getCountries'
import getTranslate from '../../shared/Utils/getTranslate'
import { DrawerProps } from './EmpOperatorsTypes'

const EmpOperatorsDrawer: React.FC<DrawerProps> = ({ open, toggleDrawer, record, editName }) => {
  const translate = useTranslate()

  const externalList = () => {
    if (record && record.externalIds) {
      return record.externalIds.map((id: string) => (
        <ListItem key={id}>
          <Chip label={id} />
        </ListItem>
      ))
    }
  }

  return (
    <Drawer open={open} anchor="right" onClose={() => toggleDrawer({ action: false })}>
      {record ? (
        <List>
          <ListItem key="Edit">
            <ListItem>
              <EditButton resource={`/${editName}`} record={record} />
            </ListItem>
          </ListItem>
          <ListItem key="ID">
            <ListItemAvatar>
              <Avatar>
                <Fingerprint />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={`${translate(getTranslate('id', admin.emps))}: ${record.id}`} />
          </ListItem>
          <ListItem key="Name">
            <ListItemAvatar>
              <Avatar>
                <PermIdentity />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={`${translate(getTranslate('name', admin.emps))}: ${record.name}`}
            />
          </ListItem>
          <ListItem key="ExternalIds">
            <ListItemAvatar>
              <Avatar>
                <AccountTree />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={`${translate(getTranslate('externalIds', admin.emps))}:`} />
          </ListItem>
          {externalList()}
        </List>
      ) : (
        <></>
      )}
    </Drawer>
  )
}

const EmpOperatorButtonDrawer: React.FC<{
  record: RaRecord
  open: boolean
  onClose: React.Dispatch<React.SetStateAction<boolean>>
}> = ({ record, open, onClose }) => {
  const translate = useTranslate()
  let current: CountriesProps[] = []
  if (record && record.countries) {
    if (record.countries.length === 0) {
      current = countriesArray()
    } else {
      current = countriesArray().filter((country: CountriesProps) =>
        record.countries.includes(country.id)
      )
    }
  }

  return (
    <Drawer open={open} anchor="right" onClose={() => onClose(false)}>
      <List>
        {current.map((country: CountriesProps) => {
          return (
            <ListItem
              key={`${translate(getTranslate('countries', admin.emp_operators))}: ${country}`}
            >
              <Chip label={country.name} />
            </ListItem>
          )
        })}
      </List>
    </Drawer>
  )
}

export { EmpOperatorsDrawer, EmpOperatorButtonDrawer }
