import { useCanAccess } from '@eclever/ra-enterprise/ra-rbac'
import React from 'react'
import {
  Datagrid,
  EmailField,
  ListProps,
  TextField,
  Loading,
  EditButton,
  sanitizeListRestProps,
  NumberField,
  TextInput,
  SelectInput,
} from 'react-admin'

import { admin } from '../../../types'
import Pagination from '../../shared/Components/Pagination'
import RenderAddress from '../../shared/Components/RenderAddress'
import StickyColumnWrapper from '../../shared/Components/StickyColumnWrapper'
import List from '../../shared/Components/StyledComponents/List'
import Toolbar from '../../shared/Toolbar/Toolbar'

const OperatorsList: React.FC<ListProps> = props => {
  const { isLoading: loadAccess, canAccess } = useCanAccess({
    action: 'edit',
    resource: admin.operators,
  })

  if (loadAccess) {
    return <Loading />
  }

  return (
    <List
      {...sanitizeListRestProps(props)}
      pagination={<Pagination />}
      filters={[
        ...[
          'name',
          'evseOperatorId',
          'street',
          'postcode',
          'city',
          'country',
          'disorderHotline',
          'mail',
        ].map((source: string) => <TextInput source={source} alwaysOn />),
        <SelectInput
          source="author"
          label="Service"
          choices={[
            { id: 'hubject', name: 'hubject' },
            { id: 'smartlab', name: 'smartlab' },
          ]}
          alwaysOn
        />,
      ]}
      actions={<Toolbar reference={admin.operators} resource={admin.operators} />}
      empty={false}
    >
      <Datagrid isRowSelectable={record => canAccess && record.amountChargepoints === 0}>
        <TextField source="id" label="id" />
        <TextField source="name" label="name" />
        <TextField source="evseOperatorId" label="EVSE ID" />
        <TextField source="companyName" label="Company Name" />
        <RenderAddress label="Address" />
        <TextField source="disorderHotline" label="Disorder Hotline" />
        <EmailField source="mail" label="E-Mail" />
        <NumberField source="amountChargepoints" label="chargepoints" />
        <NumberField source="amountEmps" label="emps" />
        <TextField source="author" label="Author" />
        <StickyColumnWrapper {...props}>{canAccess && <EditButton />}</StickyColumnWrapper>
      </Datagrid>
    </List>
  )
}

export default OperatorsList
