import { Modal } from '@mui/material'
import React from 'react'

import { ImageButton } from './ImageStyles'

const ImageShow: React.FC<{
  image: string
  isShow: boolean
  setIsShow: React.Dispatch<React.SetStateAction<boolean>>
}> = ({ image, isShow, setIsShow }) => {
  return (
    <Modal
      open={isShow}
      onClose={() => setIsShow(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ImageButton onClick={() => setIsShow(false)}>
        <img src={image} alt="" style={{ width: '50%', height: '50%', objectFit: 'fill' }} />
      </ImageButton>
    </Modal>
  )
}

export default ImageShow
