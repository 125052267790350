import { Chip, TextField, Autocomplete } from '@mui/material'
import React from 'react'
import { TextInputProps, InputProps, useInput, useGetList } from 'react-admin'

import usePermissions from '../../hooks/usePermissions'
import { checkPermission, getRoles } from './UsersUtils'

export const RoleInput: React.FC<TextInputProps & InputProps> = props => {
  const { data } = useGetList('admin/enums/userRole', { pagination: { page: 1, perPage: -1 } })

  const { field } = useInput(props)
  const { name } = field
  const role = usePermissions().getPermissions()
  const roleData = (data && getRoles(data)) || []

  if (role && roleData && field) {
    const roles: { id: string; name: string }[] = roleData.filter(currentRole =>
      checkPermission(role, { id: '', roles: [currentRole.name] })
    )
    const values =
      (typeof field.value[0] === 'string' &&
        field.value.map((user: string) => ({ id: user, name: user }))) ||
      field.value ||
      []

    return (
      <Autocomplete
        {...field}
        clearOnEscape
        fullWidth
        disableCloseOnSelect
        multiple
        limitTags={5}
        value={values}
        options={roles}
        getOptionLabel={option => option.name}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={params => (
          <TextField {...params} variant="standard" label={name} placeholder="Roles" />
        )}
        onChange={(_event, newValue) => field.onChange(newValue)}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => <Chip label={option.name} {...getTagProps({ index })} />)
        }
      />
    )
  }

  return <></>
}
