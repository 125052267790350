import DockIcon from '@mui/icons-material/Dock'

import EmpsCreate from './EmpsCreate'
import EmpsEdit from './EmpsEdit'
import EmpsList from './EmpsList'

const Emps = {
  options: { label: 'Emps' },
  list: EmpsList,
  edit: EmpsEdit,
  create: EmpsCreate,
  icon: DockIcon,
}

export default Emps
