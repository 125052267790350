import React from 'react'
import {
  FormDataConsumer,
  NumberInput,
  SimpleFormProps,
  TextInput,
  SimpleForm,
  required,
} from 'react-admin'

import EditToolbar from '../../shared/Toolbar/EditToolbar'

export const OperatorsForm: React.FC<Omit<SimpleFormProps, 'children'>> = props => {
  return (
    <SimpleForm {...props} toolbar={<EditToolbar />}>
      <FormDataConsumer fullWidth>
        {({ formData, ...rest }) =>
          formData.id && (
            <NumberInput
              source="id"
              fullWidth
              isRequired
              validate={[required()]}
              disabled
              {...rest}
            />
          )
        }
      </FormDataConsumer>
      <TextInput source="name" fullWidth isRequired validate={[required()]} />
      <TextInput source="evseOperatorId" fullWidth />
      <TextInput source="companyName" fullWidth label="Company Name" />
      <TextInput source="street" fullWidth label="Street" />
      <TextInput source="number" fullWidth label="Number" />
      <TextInput source="postcode" fullWidth label="Postcode" />
      <TextInput source="city" fullWidth label="City" />
      <TextInput source="country" fullWidth label="Country" />
      <TextInput source="disorderHotline" fullWidth label="Disorder Hotline" />
      <TextInput source="mail" fullWidth label="Mail" />
    </SimpleForm>
  )
}
