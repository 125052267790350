import BusinessIcon from '@mui/icons-material/Business'
import { ResourceProps } from 'react-admin'

import VehicleManufacturerCreate from './VehicleManufacturerCreate'
import VehicleManufacturerEdit from './VehicleManufacturerEdit'
import VehicleManufacturerList from './VehicleManufacturerList'

const VehicleManufacturer: Omit<ResourceProps, 'name'> = {
  options: { label: 'Vehicle Manufacturers' },
  list: VehicleManufacturerList,
  edit: VehicleManufacturerEdit,
  create: VehicleManufacturerCreate,

  icon: BusinessIcon,
}

export default VehicleManufacturer
