import { ResourceProps } from 'react-admin'

import StationManufacturersCreate from './StationManufacturersCreate'
import StationManufacturersEdit from './StationManufacturersEdit'
import StationManufacturersList from './StationManufacturersList'

const StationManufacturers: Omit<ResourceProps, 'name'> = {
  options: { label: 'StationManufacturers' },
  list: StationManufacturersList,
  create: StationManufacturersCreate,
  edit: StationManufacturersEdit,
  // icon: DockIcon,
}

export default StationManufacturers
