import { useCanAccess } from '@eclever/ra-enterprise/ra-rbac'
import React from 'react'
import {
  Datagrid,
  ListProps,
  RaRecord,
  ShowButton,
  useGetList,
  FunctionField,
  sanitizeListRestProps,
  useListController,
} from 'react-admin'

import useFilters from '../../../hooks/useFilters'
import { ThemeProvider } from '../../../provider'
import { admin } from '../../../types'
import RejectButton from '../../shared/Buttons/RejectButton'
import BreadCrumbs from '../../shared/Components/BreadCrumbs'
import ChangeLogField from '../../shared/Components/ChangeLogField/ChangeLogField'
import ColumnsFields from '../../shared/Components/ColumnsFields'
import Pagination from '../../shared/Components/Pagination'
import useSelectColumns from '../../shared/Components/SelectColumns/useSelectColumns'
import StickyColumnWrapper from '../../shared/Components/StickyColumnWrapper'
import List from '../../shared/Components/StyledComponents/List'
import { PostBulkAction, StationEditButton, DraftPostBulkAction } from '../../shared/StationActions'
import SelectColumnsToolbar from '../../shared/Toolbar/SelectColumnsToolbar'
import StationChargepointsFields from './StationChargepointsFields'
import StationChargepointsFilter from './StationChargepointsFilter'

const columnsFields = ColumnsFields(StationChargepointsFields)

const bulkAction: (resource: string) => JSX.Element | false = resource => {
  switch (resource) {
    case admin.station_chargepoints:
      return <PostBulkAction />
    case admin.station_chargepoint_drafts:
      return <DraftPostBulkAction />
    default:
      return false
  }
}

const StationChargepointsList: React.FC<ListProps> = props => {
  const { data: user, error } = useGetList(admin.usersMe, {
    pagination: { page: 1, perPage: -1 },
  })
  const { resource } = useListController()
  const { filter } = useFilters()
  const { isLoading: loadAccess, canAccess } = useCanAccess({
    action: 'edit',
    resource: resource,
  })
  const columns = useSelectColumns('station-chargepoints.list.columns', columnsFields)
  const currentUser = user && user[0]

  const postRowStyle = (record: RaRecord) => {
    if (resource === admin.station_chargepoint_drafts) {
      if (record?.delete && record?.parentChargepointId) {
        return { backgroundColor: ThemeProvider.palette.colorPalette.changelog.delete }
      }

      if (!record?.parentChargepointId) {
        return { backgroundColor: ThemeProvider.palette.colorPalette.changelog.create }
      }

      if (!record?.delete && record?.parentChargepointId) {
        return { backgroundColor: ThemeProvider.palette.colorPalette.changelog.update }
      }
    }

    return {}
  }

  if (error || !currentUser) {
    return <>error</>
  }

  return (
    <>
      {resource === admin.station_chargepoints && <BreadCrumbs resource={resource} />}
      <List
        {...sanitizeListRestProps(props)}
        filter={filter[resource]}
        pagination={<Pagination />}
        filters={StationChargepointsFilter}
        actions={
          <SelectColumnsToolbar
            reference={admin.station_chargegroups}
            preference="station-chargepoints.list.columns"
            columns={columnsFields}
          />
        }
        empty={false}
      >
        <Datagrid
          rowStyle={postRowStyle}
          bulkActionButtons={bulkAction(resource)}
          isRowSelectable={(record: RaRecord) =>
            canAccess &&
            ((resource === admin.station_chargepoints &&
              !record?.hasDrafts &&
              (!record?.locked || (record?.locked && currentUser.id === record?.authorUserId))) ||
              resource === admin.station_chargepoint_drafts)
          }
        >
          {columns}
          <StickyColumnWrapper {...props}>
            {resource === admin.station_chargepoints && !loadAccess && canAccess && (
              <FunctionField
                render={(record: RaRecord | undefined) => (
                  <ChangeLogField resource={resource} record={record} />
                )}
              />
            )}
            {resource === admin.station_chargepoints && !loadAccess && canAccess && (
              <StationEditButton user={currentUser} />
            )}
            {resource === admin.station_chargepoint_drafts && <ShowButton />}
            {resource === admin.station_chargepoint_drafts && <RejectButton />}
          </StickyColumnWrapper>
        </Datagrid>
      </List>
    </>
  )
}

export default StationChargepointsList
