import { IfCanAccess } from '@eclever/ra-enterprise/ra-rbac'
import React, { useEffect } from 'react'
import {
  ToolbarProps,
  CreateButton,
  FilterButton,
  useListContext,
  useListController,
} from 'react-admin'

import useBreadCrumps from '../../../hooks/useBreadCrumps'
import useFilters from '../../../hooks/useFilters'
import { admin } from '../../../types'
import ResetButton from '../Buttons/ResetButton'
import { General } from './ToolbarStyles'

const Toolbar: React.FC<
  Omit<ToolbarProps, 'sx'> & {
    reference: string
  }
> = props => {
  const { reference } = props
  const { resource } = useListController()
  const { setFilters } = useListContext()
  const { filter } = useFilters()
  const { breadCrumps } = useBreadCrumps()
  const { removeAllFilterOfResource } = useFilters()

  useEffect(() => {
    if (resource && !filter[resource]) {
      setFilters({}, {}, false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [removeAllFilterOfResource])

  useEffect(() => {
    if (resource && !filter[resource] && !breadCrumps[reference]) {
      setFilters({}, {}, false)
    } else if (filter[resource]) {
      setFilters(
        {
          ...(Object.entries(filter[resource]).reduce((prev, [key, value]) => {
            if (typeof value === 'string' && value.startsWith('%')) {
              return { ...prev, [key]: value.substring(1) }
            } else {
              return { ...prev, [key]: value }
            }
          }, {}) || {}),
        },
        {},
        false
      )
    } else {
      setFilters({}, {}, false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [breadCrumps, filter, resource])

  if (!resource) {
    return <></>
  }

  return (
    <General {...props}>
      <IfCanAccess action="edit" resource={resource}>
        <CreateButton />
      </IfCanAccess>
      <FilterButton />
      {![
        admin.vehicle_manufacturers,
        admin.vehicle_models,
        admin.vehicle_designs,
        admin.vehicle_variants,
      ].includes(resource) && <ResetButton resource={resource} />}
    </General>
  )
}

export default Toolbar
