import {
  AutocompleteArrayInput,
  BooleanInput,
  NumberInput,
  ReferenceArrayInput,
  ReferenceInput,
  required,
  SelectInput,
  TextInput,
  DateTimeInput,
} from 'react-admin'

import { admin, api, InputElementType, InputType } from '../../../types'
import convertArrayToInputType, { addSource } from '../../shared/Utils/convert'
import NewInput from '../CPacks/CustomInputs/NewInput'

const mainInputs: InputElementType[] = [
  <TextInput source="name" fullWidth />,
  <BooleanInput source="scFree" fullWidth />,
  <TextInput source="licensePlate" fullWidth />,
  <BooleanInput
    label="Permissions Complete"
    source="permissions.complete"
    isRequired
    defaultValue={true}
    validate={[required()]}
    fullWidth
  />,
  <BooleanInput
    label="Permissions Anonymous"
    source="permissions.anonymous"
    isRequired
    defaultValue={true}
    validate={[required()]}
    fullWidth
  />,
  <ReferenceArrayInput
    source="vehicleVariantIds"
    reference={admin.vehicle_variants}
    perPage={-1}
    sort={{ field: 'name', order: 'ASC' }}
    isRequired
    validate={[required()]}
    fullWidth
  >
    <AutocompleteArrayInput optionText="name" isRequired validate={[required()]} />
  </ReferenceArrayInput>,
  <NewInput source="adapters" reference={api.vehicle_adapters} fullWidth />,
  <NewInput source="cables" reference={api.vehicle_cables} fullWidth />,
]

const statusInputs: InputElementType[] = [
  <DateTimeInput defaultValue={new Date()} source="updateTime" fullWidth />,
  <NumberInput defaultValue={0} source="updateDelayInSeconds" fullWidth />,
  <SelectInput
    source="chargingState"
    fullWidth
    choices={[
      { id: 'Charging', name: 'Charging' },
      { id: 'Error', name: 'Error' },
      { id: 'Not Charging', name: 'Not Charging' },
    ]}
    isRequired
    validate={[required()]}
  />,
  <NumberInput source="mileage" fullWidth />,
  <NumberInput source="range" fullWidth />,
  <NumberInput source="consumption" fullWidth />,
  <NumberInput source="soc" fullWidth />,
  <NumberInput source="socMax" fullWidth />,
  <ReferenceInput source="updateState" reference="admin/enums/cPackUpdateState" fullWidth>
    <SelectInput optionText="id" fullWidth />
  </ReferenceInput>,
  <TextInput source="updateVersion" fullWidth />,
  <TextInput source="softwareVersion" fullWidth />,
  <SelectInput
    source="signalStrength"
    fullWidth
    choices={[
      { id: 'excellent', name: 'excellent' },
      { id: 'good', name: 'good' },
      { id: 'fair', name: 'fair' },
      { id: 'poor', name: 'poor' },
      { id: 'no signal', name: 'no signal' },
    ]}
    isRequired
    validate={[required()]}
  />,
  <NumberInput source="latitude" fullWidth />,
  <NumberInput source="longitude" fullWidth />,
  <BooleanInput source="doorLocked" fullWidth />,
  <NumberInput source="chargeRate" fullWidth />,
  <NumberInput source="insideTemperature" fullWidth />,
  <NumberInput source="outsideTemperature" fullWidth />,
  <NumberInput source="chargePower" fullWidth />,
  <SelectInput
    source="chargePortType"
    fullWidth
    choices={[
      { id: 'Ac', name: 'Ac' },
      { id: 'Dc', name: 'Dc' },
    ]}
    isRequired
    validate={[required()]}
  />,
  <SelectInput
    source="driveState"
    fullWidth
    choices={[
      { id: 'Driving', name: 'Driving' },
      { id: 'Not Driving', name: 'Not Driving' },
    ]}
    isRequired
    validate={[required()]}
  />,
  <TextInput source="vin" fullWidth />,
  <NumberInput source="speed" fullWidth />,
  <NumberInput source="socBms" fullWidth />,
  <NumberInput source="socCBox" fullWidth />,
  <NumberInput source="batteryPower" fullWidth />,
  <NumberInput source="batteryRecharged" fullWidth />,
  <NumberInput source="batteryVoltage" fullWidth />,
  <NumberInput source="batteryVoltage12V" fullWidth />,
  <NumberInput source="batteryVoltageCBox" fullWidth />,
  <NumberInput source="batteryCurrent" fullWidth />,
  <NumberInput source="batteryTemperatureMin" fullWidth />,
  <NumberInput source="batteryTemperatureMax" fullWidth />,
  <NumberInput source="coolingTemperature" fullWidth />,
]

const VehicleInput: Record<string, InputType> = {
  main: mainInputs.reduce(convertArrayToInputType, {}),
  status: addSource(statusInputs, 'state').reduce(convertArrayToInputType, {}),
}

export default VehicleInput
