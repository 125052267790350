import React from 'react'
import { Edit, EditProps } from 'react-admin'

import VehicleDesignForm from './VehicleDesignForm'

const VehicleDesignEdit: React.FC<EditProps> = props => {
  return (
    <Edit {...props}>
      <VehicleDesignForm />
    </Edit>
  )
}

export default VehicleDesignEdit
