import { styled, Grid } from '@mui/material'
import { TextField, DateField } from 'react-admin'

export const Header = styled(Grid)(({ theme }) => ({
  fontWeight: 'bolder',
  display: 'grid',
  gridGap: theme.spacing(2),
  justifyContent: 'center',
  alignItems: 'center',
}))

export const GridHeaderItem = styled(Grid)(({ theme }) => ({
  fontSize: theme.spacing(3),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}))

export const GridItem = styled(Grid)(({ theme }) => ({
  fontSize: theme.spacing(2.5),
  display: 'grid',
  gridTemplateColumns: '1fr 2fr',
  gridGap: theme.spacing(2),
}))

export const DateHeader = styled(TextField)(({ theme }) => ({
  fontSize: theme.spacing(2.5),
}))

export const CustomDateField = DateHeader as typeof DateField
