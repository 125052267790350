import { Autocomplete, TextField as MuiTextField, Chip } from '@mui/material'
import React from 'react'
import { RaRecord, InputProps, useInput } from 'react-admin'

export const AutoCompleteInput: React.FC<InputProps & { choices: RaRecord[] }> = ({
  choices,
  ...props
}) => {
  const { field } = useInput(props)
  let current = field && field.value ? field.value : []

  if (field && field.value && field.value[0] && field.value[0].id === undefined) {
    current = choices.filter(item => field.value.includes(item.id))
  }

  return (
    <>
      <Autocomplete
        {...field}
        multiple
        disableCloseOnSelect
        limitTags={5}
        value={current}
        options={[...choices].sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
        groupBy={option => option.firstLetter}
        getOptionLabel={option => option.name}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={params => <MuiTextField {...params} variant="standard" />}
        onChange={(_event, newValue) => field.onChange(newValue)}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => <Chip label={option.name} {...getTagProps({ index })} />)
        }
      />
    </>
  )
}

export const options: (choices: Array<{ id: string; name: string }>) => RaRecord[] = choices =>
  Object.values(choices).reduce((pre: RaRecord[], cur) => {
    const firstLetter = cur.name[0].toUpperCase()
    pre.push({
      id: cur.id,
      name: cur.name,
      firstLetter: /d/.test(firstLetter) ? '0-9' : firstLetter,
    })
    return pre
  }, [])

export const transform: (data: RaRecord) => RaRecord = data => ({
  ...data,
  countries:
    data?.countries?.reduce((pre: Array<string>, cur: { id: string; name: string }) => {
      pre.push(cur.id)
      return pre
    }, []) || [],
})
