import { EditDialog, EditDialogProps } from '@eclever/ra-enterprise/ra-form-layout'
import React from 'react'
import {
  SimpleForm,
  Toolbar,
  SaveButton,
  DeleteButton,
  useRefresh,
  useNotify,
  useRedirect,
} from 'react-admin'

import { admin } from '../../../types'
import countriesArray from '../../shared/Utils/getCountries'
import { AutoCompleteInput, options, transform } from './EmpOperatorsInputFields'

const PostEditToolbar = () => {
  const notify = useNotify()
  const refresh = useRefresh()
  const redirect = useRedirect()

  const onSuccess = () => {
    notify('ra.action.delete', { type: 'info' })
    redirect(`/${admin.emp_operators}`)
    refresh()
  }

  return (
    <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <SaveButton />
      <DeleteButton mutationOptions={{ onSuccess }} />
    </Toolbar>
  )
}

const EmpOperatorsEdit: React.FC<EditDialogProps> = props => {
  return (
    <EditDialog {...props} transform={transform} fullWidth maxWidth="md">
      <SimpleForm toolbar={<PostEditToolbar />}>
        <AutoCompleteInput source="countries" choices={options(countriesArray())} />
      </SimpleForm>
    </EditDialog>
  )
}

export default EmpOperatorsEdit
