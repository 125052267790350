import { admin } from '../types'

const LockResource = {
  getType: (resource: string): string => {
    switch (resource) {
      case admin.station_chargegroups:
        return 'chargegroup'
      case admin.station_chargepoints:
        return 'chargepoint'
      default:
        return ''
    }
  },
}

export default LockResource
