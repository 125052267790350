import DockIcon from '@mui/icons-material/Dock'

import StationChargepointsCreate from './StationChargepointsCreate'
import StationChargepointsEdit from './StationChargepointsEdit'
import StationChargepointsList from './StationChargepointsList'

const StationChargepoints = {
  options: { label: 'Station Chargepoints' },
  list: StationChargepointsList,
  create: StationChargepointsCreate,
  edit: StationChargepointsEdit,
  icon: DockIcon,
}

export default StationChargepoints
