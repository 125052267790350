import { RaRecord } from 'react-admin'

import {
  BACKOFFICE_ADMIN,
  BACKOFFICE_OWNER,
  BACKOFFICE_ROLEMANAGERS_WRITE,
} from '../shared/Constants'

export const getRoles: (data: RaRecord[]) => { id: string; name: string }[] = data =>
  Object.values(data)
    .sort()
    .map((element: RaRecord) => ({ id: element.key, name: element.key }))

export const transform: (data: RaRecord) => RaRecord = data => {
  const { roles } = data

  if (roles && typeof roles[0] !== 'string') {
    return {
      ...data,
      roles: roles.reduce((pre: string[], cur: { id: string; name: string }) => {
        pre.push(cur.id)
        return pre
      }, [] as string[]),
    }
  }
  return data
}

export const checkPermission: (permission: string[], record: RaRecord) => boolean = (
  permission,
  record
) => {
  if (record && record.roles) {
    if (permission.includes(BACKOFFICE_OWNER)) {
      return true
    }

    if (permission.includes(BACKOFFICE_ADMIN) && !record.roles.includes(BACKOFFICE_OWNER)) {
      return true
    }

    if (
      permission.includes(BACKOFFICE_ROLEMANAGERS_WRITE) &&
      !record.roles.includes(BACKOFFICE_OWNER) &&
      !record.roles.includes(BACKOFFICE_ADMIN)
    ) {
      return true
    }
  } else {
    return true
  }

  return false
}
