import { TableHead, TableBody, Table, TableRow, TableCell, TableSortLabel } from '@mui/material'
import React from 'react'
import {
  TextFieldProps,
  useRecordContext,
  RaRecord,
  useTranslate,
  useListContext,
} from 'react-admin'

import { admin } from '../../../../types'
import timer from '../../../../utils/timer'
import BF from '../../../shared/Components/BooleanField'
import { getDirection } from '../StationChargepointsUtils'

const OpeningTimesField: React.FC<TextFieldProps> = props => {
  const record: RaRecord = useRecordContext(props)
  const { sort, setSort } = useListContext(props)
  const translate = useTranslate()

  return (
    <Table>
      <TableHead>
        <TableRow>
          {['openFullTime', 'weekday', 'from', 'to', 'leaveAnytime'].map(recordName => (
            <TableCell key={recordName}>
              {recordName === 'openFullTime' ? (
                <TableSortLabel
                  active={sort.field === recordName}
                  direction={getDirection(sort, recordName)}
                  onClick={() => setSort({ field: recordName, order: sort.order })}
                >
                  {translate(`resources.${admin.station_chargepoints}.fields.${recordName}`)}{' '}
                </TableSortLabel>
              ) : (
                translate(`resources.${admin.station_chargepoints}.fields.${recordName}`)
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow key="openFullTime">
          {/* first column: selection checkbox */}
          <TableCell rowSpan={record?.openingHours?.length + 1} align="center">
            <BF element={record?.openFullTime} />
          </TableCell>
        </TableRow>
        {record?.openingHours?.map(
          (key: { day: string; fromInMinutes: number; untilInMinutes: number }, index: number) => (
            <TableRow key={index}>
              <TableCell rowSpan={1}> {key.day} </TableCell>
              <TableCell rowSpan={1}> {timer(key.fromInMinutes)} </TableCell>
              <TableCell rowSpan={1}> {timer(key.untilInMinutes)} </TableCell>
              {index === 0 && (
                <TableCell rowSpan={record?.openingHours?.length + 4} align="center">
                  <BF element={record?.leaveAnytime} />
                </TableCell>
              )}
            </TableRow>
          )
        )}
      </TableBody>
    </Table>
  )
}

export default OpeningTimesField
