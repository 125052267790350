import TariffsCreate from './TariffsCreate'
import TariffsEdit from './TariffsEdit'
import TariffsList from './TariffsList'

const Tariffs = {
  options: { label: 'Tariffs' },
  list: TariffsList,
  create: TariffsCreate,
  edit: TariffsEdit,
}

export default Tariffs
