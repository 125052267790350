import { useCanAccess } from '@eclever/ra-enterprise/ra-rbac'
import React from 'react'
import {
  Datagrid,
  EditButton,
  ListProps,
  NumberField,
  NumberInput,
  TextField,
  TextInput,
  sanitizeListRestProps,
  useListController,
  ReferenceInput,
  SelectInput,
} from 'react-admin'

import useFilters from '../../../hooks/useFilters'
import { admin } from '../../../types'
import BreadCrumbs from '../../shared/Components/BreadCrumbs'
import FilterInputWrapper from '../../shared/Components/FilterInputWrapper'
import FilterWrapper from '../../shared/Components/FilterWrapper'
import Pagination from '../../shared/Components/Pagination'
import StickyColumnWrapper from '../../shared/Components/StickyColumnWrapper'
import List from '../../shared/Components/StyledComponents/List'
import Toolbar from '../../shared/Toolbar/Toolbar'

const StationTypesFilter = [
  <FilterInputWrapper source="id" validElement="number">
    <NumberInput source="id" alwaysOn />
  </FilterInputWrapper>,
  <FilterWrapper reference={admin.station_manufacturers} source="stationManufacturerId" alwaysOn>
    <ReferenceInput
      source="stationManufacturerId"
      sort={{ field: 'name', order: 'ASC' }}
      perPage={-1}
      reference={admin.station_manufacturers}
      alwaysOn
    >
      <SelectInput optionText="name" disabled />
    </ReferenceInput>
  </FilterWrapper>,
  <FilterInputWrapper source="name" validElement="string">
    <TextInput source="name" />
  </FilterInputWrapper>,
]

const StationTypesList: React.FC<ListProps> = props => {
  const { resource } = useListController()
  const { filter } = useFilters()
  const { canAccess } = useCanAccess({
    action: 'edit',
    resource: resource,
  })

  if (!resource) {
    return <>ERROR</>
  }

  return (
    <>
      <BreadCrumbs resource={resource} />
      <List
        {...sanitizeListRestProps(props)}
        filter={filter[resource]}
        pagination={<Pagination />}
        filters={StationTypesFilter}
        empty={false}
        actions={<Toolbar resource={admin.station_types} reference={admin.station_manufacturers} />}
      >
        <Datagrid isRowSelectable={() => canAccess}>
          <NumberField source="id" />
          <TextField source="name" />
          <StickyColumnWrapper {...props}>{canAccess && <EditButton />}</StickyColumnWrapper>
        </Datagrid>
      </List>
    </>
  )
}

export default StationTypesList
