import React from 'react'
import { Create, CreateProps } from 'react-admin'

import VehicleManufacturerForm from './VehicleManufacturerForm'

const VehicleManufacturerCreate: React.FC<CreateProps> = props => (
  <Create {...props}>
    <VehicleManufacturerForm />
  </Create>
)

export default VehicleManufacturerCreate
