import DockIcon from '@mui/icons-material/Dock'

import EmpNamesCreate from './EmpNamesCreate'
import EmpNamesEdit from './EmpNamesEdit'
import EmpNamesList from './EmpNamesList'

const EmpNames = {
  options: { label: 'Emp Names' },
  list: EmpNamesList,
  create: EmpNamesCreate,
  edit: EmpNamesEdit,
  icon: DockIcon,
}

export default EmpNames
