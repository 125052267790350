import React from 'react'
import { Create, CreateProps } from 'react-admin'

import VehicleDesignForm from './VehicleDesignForm'

const VehicleDesignCreate: React.FC<CreateProps> = props => (
  <Create {...props}>
    <VehicleDesignForm />
  </Create>
)

export default VehicleDesignCreate
