import { isEmpty } from 'lodash'
import { RaRecord, SortPayload } from 'react-admin'

import { getNotNullElements } from '../../shared/Utils/convert'

export type day = 'MONDAY' | 'TUESDAY' | 'WEDNESDAY' | 'THURSDAY' | 'FRIDAY' | 'SATURDAY' | 'SUNDAY'

export type NewTimeProps = {
  day: day[]
  fromInMinutes: number
  untilInMinutes: number
}

export type OldTimeProps = {
  day: day
  fromInMinutes: number
  untilInMinutes: number
}

export const transformApiToShow: (
  value: OldTimeProps[] | undefined
) => NewTimeProps[] | undefined = value =>
  value?.reduce((prev: NewTimeProps[], next: OldTimeProps) => {
    if (isEmpty(prev)) {
      prev.push({
        day: [next.day],
        fromInMinutes: next.fromInMinutes,
        untilInMinutes: next.untilInMinutes,
      })
      return prev
    }

    prev.every((validate: NewTimeProps, idx: number, array: NewTimeProps[]) => {
      if (
        validate.fromInMinutes === next.fromInMinutes &&
        validate.untilInMinutes === next.untilInMinutes &&
        !validate.day.includes(next.day)
      ) {
        validate.day.push(next.day)
        return false
      } else if (idx === array.length - 1) {
        prev.push({
          day: [next.day],
          fromInMinutes: next.fromInMinutes,
          untilInMinutes: next.untilInMinutes,
        })
        return false
      }

      return true
    })

    return prev
  }, [] as NewTimeProps[])

export const transformShowToApi: (value: NewTimeProps[]) => OldTimeProps[] | undefined = value =>
  value?.reduce((pre: OldTimeProps[], cur: NewTimeProps) => {
    cur?.day.forEach(element => {
      pre.push({
        day: element,
        fromInMinutes: cur.fromInMinutes,
        untilInMinutes: cur.untilInMinutes,
      })
    })
    return pre
  }, [] as OldTimeProps[]) || []

export const transform = ({ oh, ph, ...data }: RaRecord): RaRecord => {
  return {
    ...data,
    openingHours: transformShowToApi(oh),
    parkingHours: transformShowToApi(ph),
    appDescription: (data?.appDescription && getNotNullElements(data.appDescription)) || null,
    internalComment: (data?.internalComment && getNotNullElements(data.internalComment)) || null,
  }
}

type ReturnSortorder = 'asc' | 'desc'

export const checkValidSortOrder: (order: string) => ReturnSortorder = order => {
  switch (true) {
    case order.toLowerCase() === 'asc':
      return order.toLowerCase() as ReturnSortorder
    case order.toLowerCase() === 'desc':
      return order.toLowerCase() as ReturnSortorder
    default:
      return 'asc' as ReturnSortorder
  }
}

export const getDirection: (currentSort: SortPayload, recordName: string) => ReturnSortorder = (
  currentSort,
  recordName
) => (currentSort.field === recordName ? checkValidSortOrder(currentSort.order) : 'asc')
