import systemConfig from '../config'

type permissionsType = {
  getPermissions: () => string[] | undefined
  setPermissions: (value: string[]) => void
  removePermissions: () => void
}

const usePermissions = (localstorageKey: string = systemConfig.permissions): permissionsType => ({
  getPermissions: () => {
    const getItem = localStorage.getItem(localstorageKey)
    if (getItem) {
      return JSON.parse(getItem)
    }

    return undefined
  },
  setPermissions: (value: string[]) => {
    localStorage.setItem(localstorageKey, JSON.stringify(value))
  },
  removePermissions: () => {
    localStorage.removeItem(localstorageKey)
  },
})

export default usePermissions
