import { useCanAccess } from '@eclever/ra-enterprise/ra-rbac'
import { LoadScript } from '@react-google-maps/api'
import React from 'react'
import {
  Datagrid,
  ListProps,
  useGetList,
  ShowButton,
  NumberInput,
  SelectInput,
  ReferenceInput,
  FunctionField,
  RaRecord,
  NullableBooleanInput,
  TextInput,
  useListController,
  AutocompleteInput,
} from 'react-admin'

import systemConfig from '../../../config'
import useFiters from '../../../hooks/useFilters'
import { ThemeProvider } from '../../../provider'
import { admin } from '../../../types'
import RejectButton from '../../shared/Buttons/RejectButton'
import BreadCrumbs from '../../shared/Components/BreadCrumbs'
import ChangeLogField from '../../shared/Components/ChangeLogField/ChangeLogField'
import ColumnsFields from '../../shared/Components/ColumnsFields'
import FilterInputWrapper from '../../shared/Components/FilterInputWrapper'
import FilterWrapper from '../../shared/Components/FilterWrapper'
import Pagination from '../../shared/Components/Pagination'
import RejectedFlagField from '../../shared/Components/RejectedFlagField'
import useSelectColumns from '../../shared/Components/SelectColumns/useSelectColumns'
import StickyColumnWrapper from '../../shared/Components/StickyColumnWrapper'
import List from '../../shared/Components/StyledComponents/List'
import { PostBulkAction, StationEditButton, DraftPostBulkAction } from '../../shared/StationActions'
import SelectColumnsActions from '../../shared/Toolbar/SelectColumnsToolbar'
import { places } from './CustomInputs/ChargegroupsMap/MapConstants'
import { librariesProps } from './CustomInputs/ChargegroupsMap/MapTypes'
import StationChargegroupsFields from './StationChargegroupsFields'

const libraries: librariesProps = [places]

const StationChargegroupsFilter = [
  <FilterWrapper
    reference={admin.station_chargegroups}
    resource={admin.station_chargegroups}
    source="id"
    alwaysOn
  >
    <NumberInput source="id" />
  </FilterWrapper>,
  <FilterInputWrapper source="addressFormatted" validElement="string">
    <TextInput source="" />
  </FilterInputWrapper>,
  <TextInput source="addressPostCode" />,
  <TextInput source="addressStreet" />,
  <TextInput source="addressCity" />,
  <TextInput source="addressCountry" />,
  <FilterInputWrapper source="locationType" validElement="arrayselect">
    <ReferenceInput source="locationType" reference="admin/enums/stationLocationType">
      <SelectInput optionText="id" />
    </ReferenceInput>
  </FilterInputWrapper>,
  <NullableBooleanInput source="approachPositionExists" />,
  <NullableBooleanInput source="approachDescriptionExists" />,
  <FilterInputWrapper source="imageExists" validElement="boolean">
    <NullableBooleanInput source="" />
  </FilterInputWrapper>,
  <FilterInputWrapper source="operatorId" validElement="string">
    <ReferenceInput source="operatorId" reference={admin.operators} debounce={200}>
      <AutocompleteInput optionText={choice => `${choice.name} [${choice.evseOperatorId}]`} />
    </ReferenceInput>
  </FilterInputWrapper>,
  <TextInput source="evseOperatorId" alwaysOn />,
  <FilterInputWrapper source="service" validElement="arrayselect">
    <SelectInput
      source="service"
      choices={[
        { id: 'hubject', name: 'hubject' },
        { id: 'smartlab', name: 'smartlab' },
      ]}
    />
  </FilterInputWrapper>,
  <RejectedFlagField source="rejected" drafts="admin/station-chargegroup-drafts" />,
]

const bulkAction: (resource: string) => JSX.Element | false = resource => {
  switch (resource) {
    case admin.station_chargegroups:
      return <PostBulkAction />
    case admin.station_chargegroup_drafts:
      return <DraftPostBulkAction />
    default:
      return false
  }
}

const columnsFields = ColumnsFields(StationChargegroupsFields)

const StationChargegroupsList: React.FC<ListProps> = props => {
  const { resource } = useListController()
  const { filter } = useFiters()
  const { data: user, error } = useGetList(admin.usersMe, {
    pagination: { page: 1, perPage: -1 },
  })
  const { isLoading: loadAccess, canAccess } = useCanAccess({
    action: 'edit',
    resource: admin.station_chargegroups,
  })
  const columns = useSelectColumns('station-chargegroups.list.columns', columnsFields)
  const currentUser = user && user[0]

  const postRowStyle = (record: RaRecord) => {
    if (resource === admin.station_chargegroup_drafts) {
      if (record?.delete && record?.parentChargegroupId) {
        return { backgroundColor: ThemeProvider.palette.colorPalette.changelog.delete }
      }

      if (!record?.parentChargegroupId) {
        return { backgroundColor: ThemeProvider.palette.colorPalette.changelog.create }
      }

      if (!record?.delete && record?.parentChargegroupId) {
        return { backgroundColor: ThemeProvider.palette.colorPalette.changelog.update }
      }
    }

    return {}
  }

  if (error || !currentUser) {
    return <>ERROR</>
  }

  return (
    <>
      {resource === admin.station_chargegroups && <BreadCrumbs resource={resource} />}
      {systemConfig.googleMapsApiKey && (
        <LoadScript googleMapsApiKey={systemConfig.googleMapsApiKey} libraries={libraries}>
          <List
            pagination={<Pagination />}
            filters={StationChargegroupsFilter}
            actions={
              <SelectColumnsActions
                style={{ display: 'flex', flexDirection: 'row' }}
                reference={admin.station_chargegroups}
                resource={admin.station_chargegroups}
                preference="station-chargegroups.list.columns"
                columns={columnsFields}
              />
            }
            filter={filter[resource]}
            empty={false}
          >
            <Datagrid
              bulkActionButtons={bulkAction(resource)}
              rowStyle={postRowStyle}
              isRowSelectable={record =>
                canAccess &&
                ((resource === admin.station_chargegroups &&
                  !record?.hasDrafts &&
                  (!record?.locked ||
                    (record?.locked && currentUser.id === record?.authorUserId)) &&
                  record?.chargePointOverview?.length === 0) ||
                  resource === admin.station_chargegroup_drafts)
              }
            >
              {columns}
              <StickyColumnWrapper {...props}>
                {resource === admin.station_chargegroups && !loadAccess && canAccess && (
                  <FunctionField
                    render={(record: RaRecord | undefined) => (
                      <ChangeLogField resource={admin.station_chargegroups} record={record} />
                    )}
                  />
                )}
                {resource === admin.station_chargegroups && !loadAccess && canAccess && (
                  <StationEditButton user={currentUser} />
                )}
                {resource === admin.station_chargegroup_drafts && <ShowButton />}
                {resource === admin.station_chargegroup_drafts && <RejectButton />}
              </StickyColumnWrapper>
            </Datagrid>
          </List>
        </LoadScript>
      )}
    </>
  )
}

export default StationChargegroupsList
