import { useCanAccess } from '@eclever/ra-enterprise/ra-rbac'
import React from 'react'
import {
  ListProps,
  ReferenceArrayInput,
  EditButton,
  ReferenceInput,
  AutocompleteInput,
  TextInput,
  sanitizeListRestProps,
  NumberInput,
  SelectArrayInput,
} from 'react-admin'
import { useNavigate } from 'react-router-dom'

import useBreadCrumps from '../../../hooks/useBreadCrumps'
import useFilters from '../../../hooks/useFilters'
import { admin } from '../../../types'
import BreadCrumbs, { handleRowClick } from '../../shared/Components/BreadCrumbs'
import FilterInputWrapper from '../../shared/Components/FilterInputWrapper'
import FilterWrapper from '../../shared/Components/FilterWrapper'
import Pagination from '../../shared/Components/Pagination'
import useSelectColumns from '../../shared/Components/SelectColumns/useSelectColumns'
import StickyColumnWrapper from '../../shared/Components/StickyColumnWrapper'
import List from '../../shared/Components/StyledComponents/List'
import SelectColumnsToolbar from '../../shared/Toolbar/SelectColumnsToolbar'
import { empNamesColumns } from './EmpNamesFields'
import { MyDatagrid } from './EmpNamesStyles'

const filters = [
  <FilterWrapper reference={admin.empnames} source="id" alwaysOn>
    <NumberInput source="id" alwaysOn />
  </FilterWrapper>,
  <FilterWrapper reference={admin.emps} source="empId" alwaysOn>
    <ReferenceInput
      source="empId"
      sort={{ field: 'name', order: 'ASC' }}
      perPage={-1}
      reference={admin.emps}
      allowEmpty
      alwaysOn
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
  </FilterWrapper>,
  <FilterInputWrapper source="name" validElement="string">
    <TextInput source="name" />
  </FilterInputWrapper>,
  <TextInput source="nameAppStore" />,
  <TextInput source="linkWebApp" />,
  <ReferenceArrayInput
    label="accessMethods"
    reference="admin/enums/accessMethod"
    source="accessMethods"
  >
    <SelectArrayInput optionText="id" parse={(event: string[]) => [...event].sort()} />
  </ReferenceArrayInput>,
  ...[
    'companyName',
    'street',
    'number',
    'postcode',
    'city',
    'country',
    'disorderHotline',
    'hotline',
    'mail',
  ].map(source => <TextInput key={source} source={source} />),
]

const EmpNamesList: React.FC<ListProps> = props => {
  const navigate = useNavigate()
  const { addId } = useBreadCrumps()
  const { filter, removeAllFilterOfResource } = useFilters()

  const columns = useSelectColumns('empnames.list.columns', empNamesColumns)
  const { canAccess } = useCanAccess({
    action: 'edit',
    resource: admin.empnames,
  })

  return (
    <>
      <BreadCrumbs resource={admin.empnames} />
      <List
        {...sanitizeListRestProps(props)}
        filter={filter[admin.empnames]}
        key={admin.empnames}
        pagination={<Pagination />}
        filters={filters}
        actions={
          <SelectColumnsToolbar
            reference={admin.emps}
            resource={admin.empnames}
            preference="empnames.list.columns"
            columns={empNamesColumns}
          />
        }
        empty={false}
      >
        <MyDatagrid
          rowClick={(id, resource) =>
            handleRowClick(id, addId, removeAllFilterOfResource, admin.tariffs, resource, navigate)
          }
          isRowSelectable={() => canAccess}
        >
          {columns}
          <StickyColumnWrapper {...props}>{canAccess && <EditButton />}</StickyColumnWrapper>
        </MyDatagrid>
      </List>
    </>
  )
}

export default EmpNamesList
