import { Box, Chip, Grid } from '@mui/material'

const List: React.FC<{ list: string[]; isExpanded: boolean }> = ({
  list,
  isExpanded,
  children,
}) => {
  return (
    <Box width={'fit-content'} minWidth={'fit-content'} display="flex">
      <Grid container spacing={1} wrap={isExpanded ? 'wrap' : 'nowrap'}>
        {list.map(data => {
          return (
            <Grid item key={data}>
              <Chip label={data} />
            </Grid>
          )
        })}
        {children}
      </Grid>
    </Box>
  )
}

export default List
