import { Link } from '@mui/material'
import { TextField, RaRecord, useRecordContext, FieldProps, useListController } from 'react-admin'
import { useNavigate } from 'react-router-dom'

import useBreadCrumps from '../../../../hooks/useBreadCrumps'
import useFilters from '../../../../hooks/useFilters'
import { admin } from '../../../../types'
import { handleRowClick } from '../../../shared/Components/BreadCrumbs'

const IdField: React.FC<FieldProps> = props => {
  const record: RaRecord = useRecordContext(props)
  const navigate = useNavigate()
  const { addId } = useBreadCrumps()
  const { removeAllFilterOfResource } = useFilters()
  const { resource } = useListController()

  if (!resource) {
    return <>ERROR</>
  }

  const handeClick = () =>
    record &&
    handleRowClick(
      record.id,
      addId,
      removeAllFilterOfResource,
      admin.station_chargepoints,
      resource,
      navigate
    )

  return (
    <Link href={`#/${admin.station_chargepoints}`} onClick={handeClick}>
      <TextField {...props} />
    </Link>
  )
}

export default IdField
