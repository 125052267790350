import { List as LT } from '@eclever/ra-enterprise/ra-rbac'
import { styled } from '@mui/material'
import { ListProps } from 'react-admin'

const List = styled(LT)<ListProps>(() => ({
  '.RaList-main > .MuiToolbar-root.MuiToolbar-regular': {
    display: 'flex',
    flexDirection: 'column-reverse',
    justifyContent: 'flex-end',
    alignItems: 'baseline',
  },
}))

export default List
