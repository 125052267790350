import { useCanAccess } from '@eclever/ra-enterprise/ra-rbac'
import {
  Datagrid,
  EditButton,
  ListProps,
  ShowButton,
  sanitizeListRestProps,
  TextInput,
} from 'react-admin'

import useFilters from '../../../hooks/useFilters'
import { admin } from '../../../types'
import BreadCrumbs from '../../shared/Components/BreadCrumbs'
import ColumnsFields from '../../shared/Components/ColumnsFields'
import FilterWrapper from '../../shared/Components/FilterWrapper'
import Pagination from '../../shared/Components/Pagination'
import useSelectColumns from '../../shared/Components/SelectColumns/useSelectColumns'
import StickyColumnWrapper from '../../shared/Components/StickyColumnWrapper'
import List from '../../shared/Components/StyledComponents/List'
import SelectColumnsToolbar from '../../shared/Toolbar/SelectColumnsToolbar'
import BulkDeleteButton from '../shared/BulkDeleteButton'
import RecentButton from './Buttons/RecentButton'
import VehicleFields from './VehicleFields'

const virtualVehiclesFilter = [
  <TextInput source="id" alwaysOn />,
  <FilterWrapper reference={admin.virtualCpacks} source="cPackId" alwaysOn>
    <TextInput source="cPackId" alwaysOn />
  </FilterWrapper>,
]

const columnsFields = ColumnsFields(VehicleFields)

const VehicleList: React.FC<ListProps> = props => {
  const { canAccess } = useCanAccess({
    action: 'edit',
    resource: admin.virtualVehicles,
  })
  const { filter } = useFilters()
  const columns = useSelectColumns('virtualVehicle.list.columns', columnsFields)

  return (
    <>
      <BreadCrumbs resource={admin.virtualVehicles} />
      <List
        {...sanitizeListRestProps(props)}
        filter={filter[admin.virtualVehicles]}
        pagination={<Pagination />}
        bulkActionButtons={<BulkDeleteButton request={{ keepVehicleData: false }} />}
        actions={
          <SelectColumnsToolbar
            reference={admin.virtualCpacks}
            resource={admin.virtualCpacksNewVehicle_cpack}
            columns={columnsFields}
            preference="virtualVehicle.list.columns"
          />
        }
        filters={virtualVehiclesFilter}
        empty={false}
      >
        <Datagrid>
          {columns}
          {canAccess && <RecentButton />}
          <StickyColumnWrapper {...props}>{canAccess && <EditButton />}</StickyColumnWrapper>
          <ShowButton />
        </Datagrid>
      </List>
    </>
  )
}

export default VehicleList
