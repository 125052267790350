import { useCanAccess } from '@eclever/ra-enterprise/ra-rbac'
import React from 'react'
import {
  Datagrid,
  EditButton,
  ImageFieldProps,
  ListProps,
  Loading,
  NumberField,
  NumberInput,
  TextInput,
  TextField,
  useRecordContext,
  sanitizeListRestProps,
} from 'react-admin'
import { useNavigate } from 'react-router-dom'

import useFilters from '../../../hooks/useFilters'
import useVehicleBreadCrumps from '../../../hooks/useVehicleBreadCrumps'
import { admin } from '../../../types'
import BreadCrumbs, { handleRowClick } from '../../shared/Components/BreadCrumbs'
import FilterInputWrapper from '../../shared/Components/FilterInputWrapper'
import FilterWrapper from '../../shared/Components/FilterWrapper'
import Pagination from '../../shared/Components/Pagination'
import ReferenceCountField from '../../shared/Components/ReferenceCountField'
import StickyColumnWrapper from '../../shared/Components/StickyColumnWrapper'
import List from '../../shared/Components/StyledComponents/List'
import Toolbar from '../../shared/Toolbar/Toolbar'
import { imageFallback } from './VehicleDesignUtils'

const vehicleVariantsFilters = [
  <FilterInputWrapper source="id" validElement="number">
    <NumberInput source="id" />
  </FilterInputWrapper>,
  <FilterWrapper reference={admin.vehicle_models} source="vehicleModelId" alwaysOn>
    <NumberInput source="vehicleModelId" disabled />
  </FilterWrapper>,
  <TextInput source="name" alwaysOn />,
  <NumberInput source="fromProductionYear" alwaysOn />,
  <NumberInput source="untilProductionYear" alwaysOn />,
]

const ImageField: React.FC<ImageFieldProps> = props => {
  const record = useRecordContext(props)

  if (record) {
    return (
      <img
        alt={record.name}
        title={record.name}
        src={record.image || imageFallback(record.general3dDesign)}
        style={{ margin: '0.5rem', maxHeight: '10rem' }}
      />
    )
  }

  return <></>
}

const ImageChargingField: React.FC<ImageFieldProps> = props => {
  const record = useRecordContext(props)

  if (record) {
    return (
      <img
        alt={''}
        title={record.name}
        src={record.imageCharging}
        style={{ margin: '0.5rem', maxHeight: '10rem' }}
      />
    )
  }

  return <></>
}

const VehicleDesignList: React.FC<ListProps> = props => {
  const navigate = useNavigate()
  const { addId, data, resource, loading, error } = useVehicleBreadCrumps(
    props,
    admin.vehicle_variants,
    admin.vehicle_models,
    admin.vehicle_designs
  )
  const { filter, removeAllFilterOfResource } = useFilters()
  const { isLoading: loadAccess, canAccess } = useCanAccess({
    action: 'edit',
    resource: resource,
  })

  if (loading || loadAccess) {
    return <Loading />
  }

  if (error || !data) {
    return <>{error}</>
  }

  return (
    <>
      <BreadCrumbs resource={resource} />
      <List
        {...sanitizeListRestProps(props)}
        filter={filter[resource]}
        pagination={<Pagination />}
        filters={vehicleVariantsFilters}
        actions={<Toolbar reference={admin.vehicle_models} />}
        empty={false}
      >
        <Datagrid
          rowClick={row =>
            handleRowClick(
              row,
              addId,
              removeAllFilterOfResource,
              admin.vehicle_variants,
              resource,
              navigate
            )
          }
          isRowSelectable={row => canAccess && !data.some(item => item.vehicleDesignId === row.id)}
        >
          <TextField source="id" />
          <TextField source="name" />
          <NumberField source="fromProductionYear" options={{ useGrouping: false }} />
          <NumberField source="untilProductionYear" options={{ useGrouping: false }} />
          <TextField source="general3dDesign" />,
          <ImageField source="image" />
          <ImageChargingField source="imageCharging" />
          <ReferenceCountField
            label="VariantCounts"
            reference={admin.vehicle_variants}
            target="vehicleDesignId"
            perPage={1}
            sortable={false}
          />
          <StickyColumnWrapper {...props}>{canAccess && <EditButton />}</StickyColumnWrapper>
        </Datagrid>
      </List>
    </>
  )
}

export default VehicleDesignList
