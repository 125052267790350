import DockIcon from '@mui/icons-material/Dock'

import ChargingCurveGroupsCreate from './ChargingCurveGroupsCreate'
import ChargingCurveGroupsEdit from './ChargingCurveGroupsEdit'
import ChargingCurveGroupsList from './ChargingCurveGroupsList'

const ChargingCurveGroups = {
  options: { label: 'Charging Curve Groups' },
  list: ChargingCurveGroupsList,
  create: ChargingCurveGroupsCreate,
  edit: ChargingCurveGroupsEdit,
  icon: DockIcon,
}

export default ChargingCurveGroups
