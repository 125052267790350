import { Delete } from '@mui/icons-material'
import { Button, Modal, Switch, FormControlLabel, FormGroup, styled } from '@mui/material'
import React, { useState } from 'react'
import {
  BulkActionProps,
  Button as BDB, // useMutation,
  useRefresh,
  useTranslate,
  useUnselectAll,
  useDataProvider,
} from 'react-admin'
import { useMutation } from 'react-query'

const CustomFormGroup = styled(FormGroup)(({ theme }) => ({
  position: 'absolute',
  left: '50%',
  top: '50%',
  backgroundColor: 'white',
  overflowY: 'auto',
  transform: 'translate(-50%, -50%)',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'inherit',
  width: theme.spacing(50),
  height: theme.spacing(20),
  overflow: 'unset',
  padding: theme.spacing(2),
}))

const CustomFormWrapper = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}))

type RequestBody = { [key: string]: boolean | string | number }

const BulkDeleteButton: React.FC<Omit<BulkActionProps, 'children'> & { request: RequestBody }> = ({
  request,
  ...props
}) => {
  const [open, setOpen] = useState<boolean>(false)
  const [requestBody, setRequestBody] = useState<RequestBody>(request)
  const translate = useTranslate()
  const { resource, selectedIds } = props
  const unselectAll = useUnselectAll(resource || '')
  const dataProvider = useDataProvider()
  const { mutate: approve } = useMutation(
    [resource, 'deleteMany', { ids: selectedIds, data: { ...requestBody } }],
    () =>
      dataProvider.deleteMany(resource || '', { ids: selectedIds || [], meta: { ...requestBody } }),
    {
      onSuccess: () => {
        setOpen(false)
        unselectAll()
        refresh()
      },
    }
  )
  const refresh = useRefresh()

  if (!resource) {
    return <>ERROR</>
  }

  return (
    <>
      <BDB
        startIcon={<Delete />}
        label="Delete"
        onClick={() => {
          setOpen(!open)
        }}
      />
      <Modal open={open} onClose={() => setOpen(false)}>
        <CustomFormGroup sx={{ height: 'auto' }}>
          <CustomFormWrapper>
            {Object.entries(requestBody).map(([key, value]) => (
              <FormControlLabel
                key={key}
                labelPlacement="bottom"
                label={key}
                control={
                  <Switch
                    value={value}
                    onChange={() => setRequestBody({ ...requestBody, [key]: !value })}
                  />
                }
              />
            ))}
          </CustomFormWrapper>
          <Button
            variant="contained"
            sx={{ marginTop: 1, textAlign: 'center' }}
            onClick={() => {
              approve()
            }}
          >
            {' '}
            {translate('tags.approve')}{' '}
          </Button>
        </CustomFormGroup>
      </Modal>
    </>
  )
}

export default BulkDeleteButton
