import { Box, Typography } from '@mui/material'
import {
  ArrayInput,
  FormDataConsumer,
  minValue,
  NumberInput,
  ReferenceInput,
  RaRecord,
  required,
  SelectInput,
  SimpleFormIterator,
  TextInput,
  BooleanInput,
  AutocompleteInput,
} from 'react-admin'

import { admin, dcPlugTypes, InputElementType, InputType } from '../../../types'
import convertArrayToInputType from '../../shared/Utils/convert'

export const transformVariant = ({
  chargePowerAC,
  chargePowerDC,
  ...data
}: RaRecord): RaRecord => ({
  ...data,
})

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const dataExist: (data: any) => boolean = data => {
  if (data !== null) {
    return true
  }

  return false
}

const generalInputElementFields = [
  <TextInput source="name" label="Name" validate={[required()]} fullWidth />,
  <ReferenceInput
    label="Design"
    source="vehicleDesignId"
    reference={admin.vehicle_designs}
    perPage={-1}
  >
    <SelectInput
      optionText="name"
      source="vehicleDesignId"
      fullWidth
      disabled
      validate={[required()]}
    />
  </ReferenceInput>,
  <ReferenceInput
    label="Charging Curve Groups"
    source="chargingCurveGroupId"
    reference={admin.charging_curve_groups}
    perPage={-1}
  >
    <AutocompleteInput optionText="name" fullWidth validate={[required()]} />
  </ReferenceInput>,
  <ReferenceInput
    label="Consumption Curve Groups"
    source="consumptionCurveGroupId"
    reference={admin.consumption_curve_groups}
    perPage={-1}
  >
    <AutocompleteInput optionText="name" fullWidth validate={[required()]} />
  </ReferenceInput>,
  <NumberInput min={0} source="listPrice" validate={minValue(0)} fullWidth />,
  <ReferenceInput source="categoryType" reference="admin/enums/categoryType" isRequired fullWidth>
    <AutocompleteInput optionText="id" fullWidth validate={[required()]} />
  </ReferenceInput>,
]

const plugInputElementFields: InputElementType[] = [
  <ArrayInput source="plugs" label="plugs" validate={[required()]}>
    <SimpleFormIterator>
      <ReferenceInput source="type" reference="admin/enums/plugType" label="type" isRequired>
        <AutocompleteInput optionText="id" fullWidth validate={[required()]} />
      </ReferenceInput>
      ,
      <ReferenceInput
        source="position"
        reference="admin/enums/position"
        label="position"
        isRequired
      >
        <AutocompleteInput optionText="id" fullWidth validate={[required()]} />
      </ReferenceInput>
      ,
      <FormDataConsumer isRequired validate={[required()]}>
        {({
          scopedFormData, // The data for this item of the ArrayInput
          getSource, // A function to get the valid source inside an ArrayInput
          ...rest
        }) =>
          getSource && scopedFormData && scopedFormData.type ? (
            <Box>
              {['CCS2'].includes(scopedFormData.type) && (
                <BooleanInput
                  {...rest}
                  source={getSource('switch')}
                  label="AC"
                  defaultValue={dataExist(scopedFormData.dc)}
                />
              )}
              <Box display="grid" gridTemplateColumns="repeat(2, 1fr)" gap={2}>
                {(!dcPlugTypes.includes(scopedFormData.type) ||
                  ['TYP2_TESLA'].includes(scopedFormData.type) ||
                  (['CCS2'].includes(scopedFormData.type) && scopedFormData.switch)) && (
                  <Box>
                    <Typography variant="subtitle2">AC</Typography>
                    <Box display="grid" gridTemplateColumns="repeat(3, 1fr)" gap={2}>
                      <NumberInput
                        {...rest}
                        source={getSource('ac.kw')}
                        label="kw"
                        min={0}
                        isRequired
                        validate={[required(), minValue(0)]}
                        fullWidth
                      />
                      <NumberInput
                        {...rest}
                        source={getSource('ac.ampere')}
                        label="ampere"
                        min={0}
                        isRequired
                        validate={[required(), minValue(0)]}
                        fullWidth
                      />
                      <NumberInput
                        {...rest}
                        source={getSource('ac.phase')}
                        label="phase"
                        min={0}
                        isRequired
                        validate={[required(), minValue(0)]}
                        fullWidth
                      />
                      <NumberInput
                        {...rest}
                        source={getSource('ac.onlyCP1PhaseAmpere')}
                        label="onlyCP1PhaseAmpere"
                        min={0}
                        validate={[minValue(0)]}
                        fullWidth
                      />
                      <NumberInput
                        {...rest}
                        source={getSource('ac.efficiencyCharging')}
                        label="efficiencyCharging"
                        min={0}
                        validate={[minValue(0)]}
                        fullWidth
                      />
                    </Box>
                  </Box>
                )}
                {dcPlugTypes.includes(scopedFormData.type) && (
                  <Box>
                    <Typography variant="subtitle2">DC</Typography>
                    <Box display="grid" gridTemplateColumns="repeat(3, 1fr)" gap={2}>
                      <NumberInput
                        {...rest}
                        source={getSource('dc.kw')}
                        label="kw"
                        min={0}
                        isRequired
                        validate={[required(), minValue(0)]}
                        fullWidth
                      />
                      <NumberInput
                        {...rest}
                        source={getSource('dc.ampere')}
                        label="ampere"
                        min={0}
                        isRequired
                        validate={[required(), minValue(0)]}
                        fullWidth
                      />
                      <NumberInput
                        {...rest}
                        source={getSource('dc.maxDefaultSoc')}
                        label="maxDefaultSoc"
                        min={0}
                        validate={[minValue(0)]}
                        fullWidth
                      />
                      <NumberInput
                        {...rest}
                        source={getSource('dc.efficiencyCharging')}
                        label="efficiencyCharging"
                        min={0}
                        validate={[minValue(0)]}
                        fullWidth
                      />
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
          ) : null
        }
      </FormDataConsumer>
    </SimpleFormIterator>
  </ArrayInput>,
]

const additionalFields: InputElementType[] = [
  <ReferenceInput source="vehicleDrive" reference="admin/enums/vehicleDrive">
    <AutocompleteInput optionText="id" fullWidth validate={[required()]} />
  </ReferenceInput>,
  <NumberInput min={0} source="doors" validate={[required(), minValue(0)]} fullWidth />,
  <NumberInput min={0} source="airResistanceCwa" validate={[required(), minValue(0)]} fullWidth />,
  <NumberInput min={0} source="emptyWeight" validate={[required(), minValue(0)]} fullWidth />,
  <NumberInput min={0} source="endEnginePower" validate={[minValue(0)]} fullWidth />,
  <NumberInput min={0} source="endTourque" validate={[minValue(0)]} fullWidth />,
  <NumberInput min={0} source="zeroToHundredkmh" validate={[minValue(0)]} fullWidth />,
  <NumberInput min={0} source="hundredToTwohundredkmh" validate={[minValue(0)]} fullWidth />,
  <NumberInput min={0} source="topSpeed" validate={[required(), minValue(0)]} fullWidth />,
  <BooleanInput source="hasFrontTrunk" fullWidth />,
  <BooleanInput source="trailHitch" fullWidth />,
  <BooleanInput source="trailHitchOnlyBikemount" fullWidth />,
  <NumberInput min={0} source="trailHitchLoadKg" validate={[minValue(0)]} fullWidth />,
]
const engineFields: InputElementType[] = [
  <ReferenceInput source="engineType" reference="admin/enums/engineType" fullWidth>
    <AutocompleteInput optionText="id" fullWidth />
  </ReferenceInput>,
  <NumberInput min={0} source="maxEEnginePower" validate={[minValue(0)]} fullWidth />,
  <NumberInput min={0} source="constantEEnginePower" validate={[minValue(0)]} fullWidth />,
  <NumberInput min={0} source="etorque" validate={[minValue(0)]} fullWidth />,
  <NumberInput min={0} source="batteryWeightKg" validate={[minValue(0)]} fullWidth />,
  <NumberInput min={0} source="minBatteryV" validate={[minValue(0)]} fullWidth />,
  <NumberInput min={0} source="maxBatteryV" validate={[minValue(0)]} fullWidth />,
  <BooleanInput source="batteryHeatSerie" fullWidth />,
  <BooleanInput source="batteryHeatAdditional" fullWidth />,
  <NumberInput min={0} source="bruttoKwh" validate={[required(), minValue(0)]} fullWidth />,
  <NumberInput min={0} source="nettoKwh" validate={[required(), minValue(0)]} fullWidth />,
  <NumberInput
    min={0}
    source="consumptionKwh100City"
    validate={[required(), minValue(0)]}
    fullWidth
  />,
  <NumberInput
    min={0}
    source="consumptionKwh100Highway"
    validate={[required(), minValue(0)]}
    fullWidth
  />,
  <BooleanInput source="acV2x" fullWidth />,
  <BooleanInput source="dcV2x" fullWidth />,
  <ReferenceInput source="fillerNeckPosition" reference="admin/enums/position" fullWidth>
    <AutocompleteInput optionText="id" fullWidth />
  </ReferenceInput>,
  <NumberInput min={0} source="fuelEnginePower" validate={[minValue(0)]} fullWidth />,
  <NumberInput min={0} source="fuelEngineTorque" validate={[minValue(0)]} fullWidth />,
  <ReferenceInput source="fuelType" reference="admin/enums/fuelType" fullWidth>
    <AutocompleteInput optionText="id" fullWidth />
  </ReferenceInput>,
  <NumberInput min={0} source="displacementCcm" validate={[minValue(0)]} fullWidth />,
  <NumberInput min={0} source="tankSizeKg" validate={[minValue(0)]} fullWidth />,
  <NumberInput min={0} source="tankPressureBar" validate={[minValue(0)]} fullWidth />,
  <NumberInput min={0} source="tankSizeL" validate={[minValue(0)]} fullWidth />,
]

const VehicleVariantInputs: Record<string, InputType> = {
  general: generalInputElementFields.reduce(convertArrayToInputType, {}),
  plugs: plugInputElementFields.reduce(convertArrayToInputType, {}),
  additional: additionalFields.reduce(convertArrayToInputType, {}),
  'engine&Power': engineFields.reduce(convertArrayToInputType, {}),
}

export default VehicleVariantInputs
