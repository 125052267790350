import React, { useEffect } from 'react'
import { InputProps, useInput } from 'react-admin'
import { useController } from 'react-hook-form'

const ResetInput: React.FC<InputProps & { depends: string }> = ({ depends, ...props }) => {
  const {
    field: { value },
    fieldState,
  } = useInput(props)
  const { isTouched, error } = fieldState
  const {
    field: { onChange },
  } = useController({ name: depends })

  useEffect(() => {
    if (!error && isTouched) {
      onChange(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, isTouched])

  return null
}

const DependsInput: React.FC<InputProps & { depends: string[] }> = ({ depends, ...props }) => {
  const { children } = props

  return (
    <>
      {depends.map((item: string) => (
        <ResetInput key={item} {...props} depends={item} />
      ))}
      {children}
    </>
  )
}

export default DependsInput
