import { MarkdownInput } from '@eclever/ra-enterprise/ra-markdown'
import { TextField, Box, Chip, Autocomplete } from '@mui/material'
import isEmpty from 'lodash/isEmpty'
import React, { useState } from 'react'
import { InputProps, useInput } from 'react-admin'

import { CustomTabs, TabItem } from '../shared/LocalizationInput/LocalizationStyles'
import { LocalizationsType } from '../shared/Types/options'
import {
  OldLocalizationsType,
  getCurrentLanguagesArray, // markdownoptions,
} from '../shared/Utils/localizations'

export const LanguagesInput: React.FC<InputProps & { choices: LocalizationsType[] }> = ({
  choices,
  label,
  ...props
}) => {
  const { field } = useInput(props)
  const { onChange, value: valueInput } = field

  return (
    <Autocomplete
      {...field}
      sx={{ marginBottom: '2rem' }}
      multiple
      disableClearable
      limitTags={5}
      value={valueInput || []}
      options={choices}
      groupBy={option => option.firstLetter}
      onChange={(_event, newValues: LocalizationsType[]) => onChange(newValues)}
      getOptionLabel={option => option.language}
      isOptionEqualToValue={(option: LocalizationsType, value: LocalizationsType) =>
        option.language === value.language
      }
      renderInput={params => (
        <TextField {...params} label={label} placeholder={typeof label === 'string' ? label : ''} />
      )}
      renderTags={(value, getTagProps) =>
        value?.map((option, index) => (
          <Chip
            {...getTagProps({ index })}
            onClick={() => onChange(value.filter(curr => curr.language !== option.language))}
            variant="outlined"
            label={option.language}
          />
        ))
      }
      renderOption={(renderProps, option) => <li {...renderProps}> {option.language} </li>}
    />
  )
}

// Issue onChange type conflict: https://github.com/mui-org/material-ui/issues/17454
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const handleChange = (
  event: React.SyntheticEvent,
  newValue: string,
  setValue: React.Dispatch<React.SetStateAction<string>>
) => {
  event.preventDefault()
  setValue(newValue)
}

export const InternalizationInput: React.FC<InputProps> = props => {
  const {
    field: { value: formValue },
  } = useInput(props)
  const { source } = props
  const locales = getCurrentLanguagesArray(formValue).sort()
  const [value, setValue] = useState<string>(locales[0])
  const tabsValue = locales.includes(value) ? value : locales[0]

  return !isEmpty(locales) ? (
    <Box sx={{ flexGrow: 1, display: 'flex', height: 800 }}>
      <CustomTabs
        orientation="vertical"
        variant="scrollable"
        value={tabsValue}
        aria-label="Vertical tabs example"
        onChange={(event, newValue) => handleChange(event, newValue, setValue)}
      >
        {locales?.map((locale: string) => (
          <TabItem
            value={locale}
            key={locale}
            label={locale}
            id={`vertical-tab-${locale}`}
            aria-controls={`vertical-tabpanel-${locale}`}
          />
        ))}
      </CustomTabs>
      {formValue &&
        (Object.entries(formValue) as unknown as { 0: string; 1: OldLocalizationsType }[])?.map(
          localization => (
            <div
              key={localization[1]['language']}
              role="tabpanel"
              hidden={tabsValue !== localization[1].language}
              id={`vertical-tabpanel-${localization[1].language}`}
              aria-labelledby={`vertical-tab-${localization[1].language}`}
              style={{ width: '100%', height: '100%' }}
            >
              {tabsValue === localization[1].language && (
                <Box sx={{ p: 3 }}>
                  <MarkdownInput
                    // options={options}
                    label={localization[1].language.toUpperCase()}
                    source={`${source}[${localization[0]}].value`}
                  />
                </Box>
              )}
            </div>
          )
        )}
    </Box>
  ) : (
    <></>
  )
}
