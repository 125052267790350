import { ToggleOn } from '@mui/icons-material'
import { Button, CircularProgress } from '@mui/material'
import {
  useTranslate,
  RaRecord,
  useRecordContext,
  FieldProps,
  useRefresh,
  useUpdate,
} from 'react-admin'

import { admin } from '../../../../types'

const RecentButton: React.FC<FieldProps> = props => {
  const translate = useTranslate()
  const refresh = useRefresh()
  const record: RaRecord = useRecordContext(props)
  const [update, { isLoading }] = useUpdate(admin.virtualVehicles, {
    id: `${record.id}/recent`,
    data: {},
  })

  if (isLoading) {
    return <CircularProgress />
  }

  return (
    <Button
      sx={{ whiteSpace: 'nowrap' }}
      startIcon={<ToggleOn />}
      variant="text"
      onClick={() => {
        update()
        refresh()
      }}
    >
      {' '}
      {translate('tags.recent')}{' '}
    </Button>
  )
}

export default RecentButton
