import { useCanAccess } from '@eclever/ra-enterprise/ra-rbac'
import { isEmpty } from 'lodash'
import {
  Datagrid,
  EditButton,
  useRecordContext,
  FieldProps,
  RaRecord,
  sanitizeListRestProps,
  ListProps,
  useListController,
  TextInput,
} from 'react-admin'
import { useNavigate } from 'react-router-dom'

import useBreadCrumps from '../../../hooks/useBreadCrumps'
import useFilters from '../../../hooks/useFilters'
import { admin } from '../../../types'
import BreadCrumbs, { handleRowClick } from '../../shared/Components/BreadCrumbs'
import ColumnsFields from '../../shared/Components/ColumnsFields'
import Pagination from '../../shared/Components/Pagination'
import useSelectColumns from '../../shared/Components/SelectColumns/useSelectColumns'
import StickyColumnWrapper from '../../shared/Components/StickyColumnWrapper'
import List from '../../shared/Components/StyledComponents/List'
import SelectColumnsToolbar from '../../shared/Toolbar/SelectColumnsToolbar'
import BulkDeleteButton from '../shared/BulkDeleteButton'
import CPacksFields from './CPacksFields'

const CPacksFilter = [<TextInput source="id" />]

const columnsFields = ColumnsFields(CPacksFields)

const HasVehicleEditButton: React.FC<FieldProps> = props => {
  const record = useRecordContext(props)
  const { resource } = props

  if (isEmpty(record.vehicles)) {
    return <EditButton resource={resource} record={record} />
  }

  return null
}

const CPacksList: React.FC<ListProps> = props => {
  const { filter, removeAllFilterOfResource } = useFilters()
  const navigate = useNavigate()
  const { resource } = useListController()
  const { addId } = useBreadCrumps()
  const { canAccess } = useCanAccess({
    action: 'edit',
    resource: admin.virtualCpacks,
  })
  const columns = useSelectColumns('cPacks.list.columns', columnsFields)

  return (
    <>
      <BreadCrumbs resource={admin.virtualCpacks} />
      <List
        {...sanitizeListRestProps(props)}
        filter={filter[admin.virtualCpacks]}
        pagination={<Pagination />}
        bulkActionButtons={
          <BulkDeleteButton request={{ deleteVehicle: false, keepVehicleData: false }} />
        }
        actions={
          <SelectColumnsToolbar
            reference={admin.virtualCpacks}
            resource={admin.virtualCpacks}
            columns={columnsFields}
            preference="cPacks.list.columns"
          />
        }
        filters={CPacksFilter}
      >
        <Datagrid
          rowClick={id =>
            handleRowClick(
              id,
              addId,
              removeAllFilterOfResource,
              admin.virtualVehicles,
              resource,
              navigate
            )
          }
          key="CPacks"
          isRowSelectable={(record: RaRecord) =>
            !record.vehicles?.some((vehicle: RaRecord) => vehicle?.recent)
          }
        >
          {columns}
          <StickyColumnWrapper {...props}>
            {canAccess && <HasVehicleEditButton {...props} />}
          </StickyColumnWrapper>
        </Datagrid>
      </List>
    </>
  )
}

export default CPacksList
