import { TableHead, TableBody, Table, TableRow, TableCell, TableSortLabel } from '@mui/material'
import React from 'react'
import {
  TextFieldProps,
  useTranslate,
  useRecordContext,
  RaRecord,
  useListContext,
} from 'react-admin'

import { admin } from '../../../../types'
import BF from '../../../shared/Components/BooleanField'
import { getDirection } from '../StationChargepointsUtils'

const WrapperField: React.FC<
  TextFieldProps & { fieldName: string[]; sortableItems?: string[] }
> = ({ fieldName, sortableItems, ...props }) => {
  const record: RaRecord = useRecordContext(props)
  const { sort, setSort } = useListContext(props)
  const translate = useTranslate()

  return (
    <Table>
      <TableHead>
        <TableRow>
          {fieldName.map((recordName, index) => (
            <TableCell key={`${recordName} ${index}`}>
              {sortableItems && sortableItems.includes(recordName) ? (
                <TableSortLabel
                  active={sort.field === recordName}
                  direction={getDirection(sort, recordName)}
                  onClick={() => setSort({ field: recordName, order: sort.order })}
                >
                  {translate(`resources.${admin.station_chargepoints}.fields.${recordName}`)}{' '}
                </TableSortLabel>
              ) : (
                translate(`resources.${admin.station_chargepoints}.fields.${recordName}`)
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          {fieldName.map((recordName, index) => (
            <TableCell align="center" key={`${recordName} ${index}`}>
              {typeof record[recordName] === 'boolean' ? (
                <BF element={record[recordName]} />
              ) : (
                record[recordName]
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableBody>
    </Table>
  )
}
export default WrapperField
