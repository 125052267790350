import { Box, Typography } from '@mui/material'
import { GoogleMap, LoadScript, Marker, Polyline } from '@react-google-maps/api'
import { useEffect, useRef, useState } from 'react'
import { useQuery } from 'react-query'

import systemConfig from '../../../../config/SystemConfig'
import { admin } from '../../../../types'
import { DataHandlerProps } from '../CBoxTypes'
import ProgressBar from '../CustomInputs/MapInputs/ProgressBar'
import SpeedControlButtons from '../CustomInputs/MapInputs/SpeedControlButtons'
import { fetchMapData } from '../Querys/ShowQuerys'
import { getRidePath } from '../utils/dataFormatter'
import moveCar from '../utils/moveCar'
import { libraries, msPerStep } from './MapConstants'

const containerStyle = {
  width: '100%',
  height: '600px',
}

// data: [lng,lat,timestamp]
const Map = ({ startDate, endDate, id }: DataHandlerProps): JSX.Element => {
  const { data } = useQuery<[number, number, number][]>(
    [admin.cPacks, 'getOne', 'map', startDate, endDate],
    () => fetchMapData(id, startDate, endDate),
    {
      enabled: !!id && !!startDate && !!endDate,
      refetchOnWindowFocus: false,
    }
  )
  const [startMarker, setStartMarker] = useState<{ lat: number; lng: number }>({ lat: 0, lng: 0 })

  const [endMarker, setEndMarker] = useState<{ lat: number; lng: number }>({ lat: 0, lng: 0 })

  const [center, setCenter] = useState<{ lat: number; lng: number }>({
    lat: 0,
    lng: 0,
  })

  const [markerPosition, setMarkerPosition] = useState<{ lat: number; lng: number }>({
    lat: 0,
    lng: 0,
  })

  const [state, setState] = useState({
    isStarted: false,
    interval: -1,
    overAllProgress: 0,
    currentLineProgress: 0,
    lineIndex: 0,
    timeMultiplier: 1,
  })

  const intervalRef = useRef(state.interval)

  const currentLineProgressRef = useRef(state.currentLineProgress)
  currentLineProgressRef.current = state.currentLineProgress

  const lineIndexRef = useRef(state.lineIndex)
  lineIndexRef.current = state.lineIndex

  const timeMultiplierRef = useRef(state.timeMultiplier)
  timeMultiplierRef.current = state.timeMultiplier

  const [currentMap, setMap] = useState<google.maps.Map | null>(null)

  useEffect(() => {
    if (!data || data.length < 1) return

    setStartMarker({ lat: data[0][1], lng: data[0][0] })
    setEndMarker({ lat: data[data.length - 1][1], lng: data[data.length - 1][0] })
    setMarkerPosition({ lat: data[0][1], lng: data[0][0] })

    if (currentMap) {
      currentMap.fitBounds(getBoundsRide(data), 30)
    }
  }, [currentMap, data])

  const getBoundsRide = (ride: [number, number, number][]) => {
    const latitudes = ride.map(pos => pos[1])
    const longitudes = ride.map(pos => pos[0])

    const west = Math.min(...longitudes) // min lng
    const east = Math.max(...longitudes) //max lng
    const south = Math.min(...latitudes) //min lat
    const north = Math.max(...latitudes) //max lat

    const sw = new google.maps.LatLng({ lat: south, lng: west })
    const ne = new google.maps.LatLng({ lat: north, lng: east })

    return new google.maps.LatLngBounds(sw, ne)
  }

  useEffect(() => {
    data &&
      setState(prevState => ({
        ...prevState,
        overAllProgress: (100 / data.length) * state.lineIndex,
      }))
  }, [state.lineIndex, data])

  const setProgressBarValue = (val: number) => {
    if (!data) return

    setState(prevState => ({
      ...prevState,
      lineIndex: Math.round((data.length / 100) * val),
      currentLineProgress: 0,
    }))
    const refs = {
      lineIndexRef: lineIndexRef,
      intervalRef: intervalRef,
      timeMultiplierRef: timeMultiplierRef,
      currentLineProgressRef: currentLineProgressRef,
    }
    moveCar(getRidePath(data), refs, setState, setMarkerPosition)
  }

  const onStart = () => {
    if (data) {
      state.interval && window.clearInterval(state.interval)
      const refs = {
        lineIndexRef: lineIndexRef,
        intervalRef: intervalRef,
        timeMultiplierRef: timeMultiplierRef,
        currentLineProgressRef: currentLineProgressRef,
      }
      setState(prevState => ({
        ...prevState,
        interval: window.setInterval(
          () => moveCar(getRidePath(data), refs, setState, setMarkerPosition),
          msPerStep
        ),
        isStarted: true,
        timeMultiplier: 1,
      }))
    }
  }

  const onLoadPolyline = () => {
    // set camera to the start of the route
    data && setCenter({ lat: data[0][1], lng: data[0][0] })
  }

  const getTimeStamp = () => {
    if (data && state.lineIndex < data.length) {
      let diff = 0
      if (state.lineIndex < data.length - 1) {
        diff = data[state.lineIndex + 1][2] - data[state.lineIndex][2]
      }

      const cp = (diff / 100) * state.currentLineProgress

      return new Date(data[state.lineIndex][2] * 1000 + cp * 1000).toLocaleString()
    }
  }

  return (
    <>
      {data && data.length > 0 && systemConfig.googleMapsApiKey && (
        <>
          <LoadScript googleMapsApiKey={systemConfig.googleMapsApiKey} libraries={libraries}>
            <GoogleMap
              center={center}
              zoom={15}
              mapContainerStyle={containerStyle}
              options={{ streetViewControl: false }}
              onLoad={map => setMap(map)}
              onUnmount={() => setMap(null)}
            >
              <Polyline onLoad={onLoadPolyline} path={data.map(d => ({ lat: d[1], lng: d[0] }))} />
              {<Marker position={markerPosition} />}
              {<Marker title={'Car'} position={markerPosition} />}
              {
                <Marker
                  title={'Start'}
                  icon={'http://maps.google.com/mapfiles/ms/icons/green-dot.png'}
                  position={startMarker}
                />
              }
              {
                <Marker
                  title={'End'}
                  icon={'http://maps.google.com/mapfiles/ms/icons/blue-dot.png'}
                  position={endMarker}
                />
              }
            </GoogleMap>
          </LoadScript>
          <Box>
            <Box display={'flex'} flexDirection={'row'} columnGap={3} alignItems={'center'}>
              <SpeedControlButtons
                setTimeMultiplier={setState}
                init={() => onStart()}
                isStarted={state.isStarted}
              />
              <Typography>{getTimeStamp()}</Typography>
            </Box>
            <ProgressBar
              progress={state.overAllProgress}
              setProgressBarValue={setProgressBarValue}
            />
          </Box>
        </>
      )}
    </>
  )
}

export default Map
