/* eslint-disable sonarjs/no-duplicate-string */
import { MyLocation } from '@mui/icons-material'
import { Typography, TextField } from '@mui/material'
import React from 'react'
import {
  InputProps,
  useTranslate,
  Button,
  TextInput,
  minLength,
  required,
  NumberInput,
} from 'react-admin'
import { geocodeByLatLng, geocodeByPlaceId } from 'react-google-places-autocomplete'
import { useFormContext, useWatch } from 'react-hook-form'

import { SentryLoggerProvider } from '../../../../../provider'
import { admin } from '../../../../../types'
import { addressComponents as addressComponentsConstant, approach, drag } from './MapConstants'
import { SearchFormFields } from './MapStyles'
import { MapInputProps, PinProps, ReturnAddressValue } from './MapTypes'
import { getAddressComponents, getValidData } from './MapUtils'

const handleGetPosition = async (
  centerPin: google.maps.LatLng,
  currentPosition: PinProps,
  setCurrentPosition: React.Dispatch<React.SetStateAction<PinProps>>
) => {
  await geocodeByLatLng({
    lat: centerPin.lat(),
    lng: centerPin.lng(),
  })
    .then(async results => results && geocodeByPlaceId(results[0].place_id))
    .then(addressWrapper => {
      const addressItem = getAddressComponents(addressWrapper[0].address_components)
      if (addressItem) {
        setCurrentPosition({
          ...currentPosition,
          type: drag,
          address: addressFormat(addressItem),
        })
      }
      return addressItem
    })
    .catch(err => {
      console.error(err)
      SentryLoggerProvider.error(err)
    })
}

const hasNextAddressElement: (current: string, addressComponents: ReturnAddressValue) => string = (
  current,
  addressComponents
) => {
  switch (current) {
    case 'addressStreet':
      if (
        addressComponents?.addressPostCode ||
        addressComponents.addressCity ||
        addressComponents.addressCountry
      ) {
        return ', '
      }
      break
    case 'addressPostCode':
      if (!addressComponents.addressCity && addressComponents.addressCountry) {
        return ', '
      }
      if (addressComponents.addressCity) {
        return ' '
      }
      break
    case 'addressCity':
      if (addressComponents.addressCountry) {
        return ', '
      }
      break
  }
  return ''
}

export const addressFormat: (
  addressComponents: ReturnAddressValue
) => string = addressComponents => {
  let addressElements = ''
  if (addressComponents?.addressStreet)
    addressElements +=
      addressComponents.addressStreet + hasNextAddressElement('addressStreet', addressComponents)
  if (addressComponents?.addressPostCode)
    addressElements +=
      addressComponents.addressPostCode +
      hasNextAddressElement('addressPostCode', addressComponents)
  if (addressComponents?.addressCity)
    addressElements +=
      addressComponents.addressCity + hasNextAddressElement('addressCity', addressComponents)
  if (addressComponents?.addressCountry) addressElements += addressComponents.addressCountry
  return addressElements
}
const MapDisplay: React.FC<InputProps & MapInputProps> = ({
  centerPin,
  currentPosition,
  setCurrentPosition,
  ...props
}) => {
  useWatch({ name: 'addressCity' })
  useWatch({ name: 'addressPostCode' })
  useWatch({ name: 'addressStreet' })
  useWatch({ name: 'addressCountry' })
  useWatch({ name: 'addressFormatted' })
  const { getValues, setValue } = useFormContext()
  const value = getValues()
  const validApproach = getValidData(value, approach)
  const validAddressComponents = getValidData(value, addressComponentsConstant)
  const translate = useTranslate()

  React.useEffect(() => {
    setValue('addressFormatted', addressFormat(validAddressComponents))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validAddressComponents])

  return (
    <>
      <SearchFormFields>
        <div style={{ marginRight: '4rem' }}>
          <Typography style={{ marginRight: '2rem' }} variant="h6" component="h6">
            {translate(`resources.${admin.station_chargegroups}.tags.currentPoint`)}*
          </Typography>
          <div>
            <NumberInput source="latitude" isRequired validate={[required()]} fullWidth disabled />
            <NumberInput source="longitude" isRequired validate={[required()]} fullWidth disabled />
          </div>
        </div>

        {validApproach && (
          <div style={{ marginRight: '4rem' }}>
            <Typography style={{ marginRight: '2rem' }} variant="h6" component="h6">
              {translate(`resources.${admin.station_chargegroups}.tags.currentApproach`)}
            </Typography>
            <div>
              <NumberInput source="approachLatitude" fullWidth disabled />
              <NumberInput source="approachLongitude" fullWidth disabled />
            </div>
          </div>
        )}

        <div>
          <Typography style={{ marginRight: '2rem' }} variant="h6" component="h6">
            {translate(`resources.${admin.station_chargegroups}.fields.addressFormatted`)}*
          </Typography>
          <div>
            <TextInput
              source="addressFormatted"
              validate={[required(), minLength(1)]}
              onChange={() => (validAddressComponents ? addressFormat(validAddressComponents) : '')}
              value={validAddressComponents ? addressFormat(validAddressComponents) : ''}
              isRequired
              fullWidth
              disabled
            />
          </div>
        </div>

        <div>
          <Typography style={{ marginRight: '2rem' }} variant="h6" component="h6">
            {translate(`resources.${admin.station_chargegroups}.tags.addressFormattedNotSaved`)}
          </Typography>
          <div style={{ display: 'grid', gridTemplateColumns: '1fr 10fr' }}>
            <Button
              onClick={() => handleGetPosition(centerPin, currentPosition, setCurrentPosition)}
              startIcon={<MyLocation />}
            />
            <TextField fullWidth disabled value={currentPosition?.address || ''} />
          </div>
        </div>
      </SearchFormFields>
      <SearchFormFields>
        {['addressPostCode', 'addressStreet', 'addressCity', 'addressCountry'].map(
          (key: string) => (
            <TextInput source={key} key={key} fullWidth />
          )
        )}
      </SearchFormFields>
    </>
  )
}

export default MapDisplay
