import centerOfMass from '@turf/center-of-mass'
import { polygon as polygonHelper } from '@turf/helpers'
import { FieldValues } from 'react-hook-form'

import { address, approach, location, latlng, addressComponents } from './MapConstants'
import {
  LatLngLiteralProps,
  LatLngProps,
  ParamType,
  ReturnAddressValue,
  ReturnType,
} from './MapTypes'

const baseCenter: LatLngProps = { lat: 51, lng: 10.5 }

export const containerStyle = {
  width: '100%',
  height: '30rem',
}

/**
 * Calculate the center of a polygon (need min. 3 edges)
 * @param polygonPoints
 * @returns Array of a LatLngProps as { lat: number, lng: number }[]
 */
export const calculatePolygonCenter: (
  polygonPoints: LatLngProps[]
) => LatLngProps = polygonPoints => {
  if (polygonPoints.length >= 3) {
    const polygonArray = polygonPoints.reduce((prev: number[][], curr) => {
      prev.push([curr.lat, curr.lng])
      return prev
    }, [] as number[][])
    polygonArray.push(polygonArray[0])

    const {
      geometry: { coordinates },
    } = centerOfMass(polygonHelper([polygonArray]))

    return { lat: coordinates[0], lng: coordinates[1] }
  }

  return polygonPoints[0]
}

/**
 * Calculate and return the center of polygon or a point
 * @param value RaRecord from react-admin
 * @returns LatLngProps as { lat: number, lng: number }
 */
export const calculateCenter: (value: FieldValues) => LatLngProps = value => {
  const validLocation = getValidData(value, location)
  const validLatLng = getValidData(value, latlng)

  if (validLocation && validLocation.length >= 3) {
    return calculatePolygonCenter(validLocation)
  }

  if (validLatLng) {
    return validLatLng
  }

  return baseCenter
}

/**
 * Return true, when the form value has a polygon. Otherwise return false
 * @param value
 * @returns boolean
 */
export const checkedPointPolygon: (value: FieldValues) => boolean = value => {
  const validLocation = getValidData(value, location) as LatLngProps[] | undefined

  if (validLocation && validLocation.length !== 2) {
    return validLocation.length === 1 ? true : false
  }

  return true
}

/**
 * Return the form value, when it exists. Otherwise return the middle of germany
 * @param value
 * @returns LatLngProps as { lat: number, lng: number }
 */
export const basePosition: (value: FieldValues) => LatLngProps[] = value => {
  const validLocation = getValidData(value, location)
  const validLatLng = getValidData(value, latlng)

  if (validLocation) {
    return validLocation
  }

  if (validLatLng) {
    return [validLatLng]
  }

  return [baseCenter]
}

/**
 * Create a polygon based on the current center of the Map
 * @param currentPoint google.maps.LatLng
 * @returns Array of a LatLngProps as { lat: number, lng: number }[]
 */
export const basePolygon: (currentPoint: google.maps.LatLng) => LatLngProps[] = currentPoint => {
  return [
    {
      lat: parseFloat((currentPoint.lat() + 0.00009).toFixed(6)),
      lng: parseFloat(currentPoint.lng().toFixed(6)),
    },
    {
      lat: parseFloat((currentPoint.lat() - 0.00009).toFixed(6)),
      lng: parseFloat((currentPoint.lng() + 0.00009).toFixed(6)),
    },
    {
      lat: parseFloat((currentPoint.lat() - 0.00009).toFixed(6)),
      lng: parseFloat((currentPoint.lng() - 0.00009).toFixed(6)),
    },
  ]
}

export const castLatLng: (currentPoint: LatLngProps[]) => LatLngLiteralProps[] = currentPoint =>
  currentPoint.map(item => ({
    latitude: parseFloat(item.lat.toFixed(6)),
    longitude: parseFloat(item.lng.toFixed(6)),
  }))

export const castLatLngRevert: (
  currentPoint: LatLngLiteralProps[]
) => LatLngProps[] = currentPoint =>
  currentPoint.map(item => ({
    lat: parseFloat(item.latitude.toFixed(6)),
    lng: parseFloat(item.longitude.toFixed(6)),
  }))

/**
 * Check value and the components of value exists and returns it. Otherwise return undefined
 * @param value value should be a station value
 * @param getValue get value of station. It should be: address, approach, location, station as String
 * @returns LatLngProps[] as { lat: number, lng: number }[] | LatLngProps as { lat: number, lng: number } | any | null
 */
export const getValidData = <T extends ParamType>(
  value: FieldValues | undefined,
  getValue: T
): ReturnType<T> => {
  if (value) {
    switch (getValue) {
      case location:
        if (value.boundaries) {
          return castLatLngRevert(value.boundaries) as ReturnType<T>
        }
        break
      case latlng:
        if (value.latitude && value.longitude) {
          return {
            lat: parseFloat(value.latitude.toFixed(6)),
            lng: parseFloat(value.longitude.toFixed(6)),
          } as ReturnType<T>
        }
        break
      case approach:
        if (value.approachLatitude && value.approachLongitude) {
          return {
            lat: parseFloat(value.approachLatitude.toFixed(6)),
            lng: parseFloat(value.approachLongitude.toFixed(6)),
          } as ReturnType<T>
        }
        break
      case address:
        if (value.addressFormatted) {
          return value.addressFormatted as ReturnType<T>
        }
        break
      case addressComponents:
        if (
          value.addressPostCode ||
          value.addressStreet ||
          value.addressCity ||
          value.addressCountry
        ) {
          return {
            addressStreet: value?.addressStreet || '',
            addressPostCode: value?.addressPostCode || '',
            addressCity: value?.addressCity || '',
            addressCountry: value?.addressCountry || '',
          } as ReturnType<T>
        }
        break
    }
  }

  return undefined as ReturnType<T>
}

export const getAddressComponents: (
  addressItem: google.maps.GeocoderAddressComponent[]
) => ReturnAddressValue | undefined = addressItem =>
  addressItem?.reduce((pre: ReturnAddressValue, cur: google.maps.GeocoderAddressComponent) => {
    if (cur.types.includes('postal_code')) {
      return {
        ...pre,
        addressPostCode: cur?.long_name || '',
      }
    }
    if (cur.types.includes('route')) {
      const street: google.maps.GeocoderAddressComponent[] = addressItem.filter(
        (item: google.maps.GeocoderAddressComponent) => item.types.includes('street_number')
      )
      const getNumber = street[0]?.long_name ? ` ${street[0].long_name}` : ''
      return {
        ...pre,
        addressStreet: (cur?.long_name || '') + getNumber,
      }
    }
    if (cur.types.includes('locality')) {
      return {
        ...pre,
        addressCity: cur?.long_name || '',
      }
    }
    if (cur.types.includes('country')) {
      return {
        ...pre,
        addressCountry: cur?.short_name || '',
      }
    }
    return pre
  }, {} as ReturnAddressValue)
