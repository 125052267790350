import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Table, TableBody, TableCell, TableHead, TableRow, Button } from '@mui/material'
import React, { useState } from 'react'
import { useTranslate, RaRecord, useRecordContext, TextFieldProps } from 'react-admin'

import { DescriptionField as DF } from '../StationChargegroupsStyles'

const DescriptionDatagridCell: React.FC<{
  min: number
  max: number
  approachDescriptions?: { [key: string]: string }
}> = ({ min, max, approachDescriptions }) => {
  return (
    <>
      {approachDescriptions &&
        Object.entries(approachDescriptions)
          .slice(min, max)
          .map(([key, value]) => {
            return (
              <TableRow key={key + 3}>
                <TableCell>{key}</TableCell>
                <DF>
                  <abbr title={value}>{value}</abbr>
                </DF>
              </TableRow>
            )
          })}
    </>
  )
}

const DescriptionField: React.FC<Omit<TextFieldProps, 'children'>> = props => {
  const translate = useTranslate()
  const record: RaRecord = useRecordContext(props)
  const [expanded, setExpanded] = useState<boolean>(false)
  const approachLength =
    record?.approachDescriptions && Object.keys(record?.approachDescriptions).length > 3

  return (
    <>
      {record?.approachDescriptions && (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell> {translate('tags.language')} </TableCell>
              <TableCell> {translate('tags.value')} </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <DescriptionDatagridCell
              min={0}
              max={3}
              approachDescriptions={record?.approachDescriptions}
            />
            {expanded && (
              <DescriptionDatagridCell
                min={3}
                max={Object.keys(record?.approachDescriptions).length || 0}
                approachDescriptions={record?.approachDescriptions}
              />
            )}
            {approachLength && (
              <TableRow>
                <TableCell style={{ borderBottom: 'none' }}>
                  <Button
                    style={{ marginTop: '1rem' }}
                    onClick={() => setExpanded(!expanded)}
                    variant="contained"
                    color="secondary"
                  >
                    {translate('tags.expand')}
                    <ExpandMoreIcon
                      style={{
                        transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
                        color: 'white',
                      }}
                    />
                  </Button>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      )}
    </>
  )
}

export default DescriptionField
