import get from 'lodash/get'
import * as React from 'react'
import { FieldProps, useRecordContext } from 'react-admin'

import ChipList from './ChipList'

const ChipListField: React.FC<FieldProps> = props => {
  const { source } = props
  const record = useRecordContext(props)

  // pass none when no data is available
  return <ChipList list={get(record, source || '') || ['none']} />
}

export default ChipListField
