import BurstModeIcon from '@mui/icons-material/BurstMode'
import { ResourceProps } from 'react-admin'

import VehicleVariantCreate from './VehicleVariantCreate'
import VehicleVariantEdit from './VehicleVariantEdit'
import VehicleVariantsList from './VehicleVariantList'

const VehicleVariants: Omit<ResourceProps, 'name'> = {
  options: { label: 'Vehicle Variants' },
  list: VehicleVariantsList,
  edit: VehicleVariantEdit,
  create: VehicleVariantCreate,
  icon: BurstModeIcon,
}

export default VehicleVariants

export { default as VehicleVariantFields } from './VehicleVariantFields'
