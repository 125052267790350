import React from 'react'
import ReactDOM from 'react-dom'

import App from './App'
import systemConfig from './config'
import './index.css'
import reportWebVitals from './reportWebVitals'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
if (systemConfig.serviceWorker) {
  serviceWorkerRegistration.register({
    onUpdate: () => {
      if (window.confirm('Update gefunden, soll die Web-App neugeladen werden?')) {
        window.location.reload()
      }
    },
  })
} else {
  serviceWorkerRegistration.unregister()
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log)
