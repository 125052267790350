import FindInPageIcon from '@mui/icons-material/FindInPage'

import DocumentsCreate from './DocumentsCreate'
import DocumentsEdit from './DocumentsEdit'
import DocumentsList from './DocumentsList'

const Documents = {
  options: { label: 'Documents' },
  list: DocumentsList,
  edit: DocumentsEdit,
  create: DocumentsCreate,
  icon: FindInPageIcon,
}

export default Documents
