import SettingsEthernetIcon from '@mui/icons-material/SettingsEthernet'

import EmpOperatorsList from './EmpOperatorsList'

const EmpOperators = {
  options: { label: 'Emp Operators' },
  list: EmpOperatorsList,
  icon: SettingsEthernetIcon,
}

export default EmpOperators
