import {
  ArrayField,
  BooleanField,
  NumberField,
  ReferenceField,
  TextField,
  Datagrid,
  ImageField,
  SingleFieldList,
  ChipField,
  DateField,
} from 'react-admin'

import { ColumnsType, FieldType } from '../../../../types'
import { customfields } from '../../../shared/Components/ColumnsFields'

export const main: FieldType[] = [
  <TextField source="id" fullWidth />,
  <TextField source="name" fullWidth />,
  <BooleanField source="scFree" fullWidth />,
  <TextField source="licensePlate" fullWidth />,
  <BooleanField source="isOffline" fullWidth />,
]

export const permissions: FieldType[] = [
  <BooleanField label="Permissions Complete" source="complete" fullWidth />,
  <BooleanField label="Permissions Anonymous" source="anonymous" fullWidth />,
]

export const adapters: FieldType[] = [
  <ArrayField source="adapters">
    <Datagrid>
      <NumberField source="id" fullWidth />
      <TextField source="fromType" fullWidth />
      <TextField source="toType" fullWidth />
      <NumberField source="ampere" fullWidth />
      <NumberField source="phase" fullWidth />
    </Datagrid>
  </ArrayField>,
]

export const cables: FieldType[] = [
  <ArrayField source="cables">
    <Datagrid>
      <NumberField source="id" fullWidth />
      <TextField source="type" fullWidth />
      <NumberField source="ampere" fullWidth />
      <NumberField source="phase" fullWidth />
    </Datagrid>
  </ArrayField>,
]

export const vehicleManufacturer: FieldType[] = [
  <NumberField source="id" fullWidth />,
  <TextField source="name" fullWidth />,
  <BooleanField source="isDefault" fullWidth />,
]

export const vehicleModel: FieldType[] = [
  <NumberField source="id" fullWidth />,
  <TextField source="name" fullWidth />,
]

export const vehicleDesign: FieldType[] = [
  <NumberField source="id" fullWidth />,
  <TextField source="name" fullWidth />,
  <ReferenceField source="general3dDesign" reference="admin/enums/general3dDesign">
    <TextField source="key" />
  </ReferenceField>,
  <ImageField source="image" fullWidth />,
  <NumberField source="fromProductionYear" fullWidth />,
  <NumberField source="untilProductionYear" fullWidth />,
]

export const vehicleVariant: FieldType[] = [
  <NumberField source="id" fullWidth />,
  <NumberField source="vehicleDesignId" fullWidth />,
  <ReferenceField source="vehicleDrive" reference="admin/enums/vehicleDrive">
    <TextField source="key" />
  </ReferenceField>,
  <NumberField label="BatteryCapacity gross" source="batteryCapacity.gross" fullWidth />,
  <NumberField label="BatteryCapacity net" source="batteryCapacity.net" fullWidth />,
  <NumberField label="ChargePower ac" source="chargePower.ac" fullWidth />,
  <NumberField label="ChargePower dc" source="chargePower.dc" fullWidth />,
  <ArrayField source="plugs">
    <Datagrid>
      <SingleFieldList>
        <ChipField source="type" />
      </SingleFieldList>
      <NumberField source="phase" fullWidth />
    </Datagrid>
  </ArrayField>,
]

export const state: FieldType[] = [
  <TextField source="signalStrength" fullWidth />,
  <TextField source="chargingState" fullWidth />,
  <TextField source="chargePortType" fullWidth />,
  <TextField source="driveState" fullWidth />,
  <TextField source="connectionState" fullWidth />,
  <TextField source="id" fullWidth />,
  <TextField source="cPackId" fullWidth />,
  <TextField source="name" fullWidth />,
  <NumberField source="mileage" fullWidth />,
  <NumberField source="batteryLevel" fullWidth />,
  <NumberField source="soc" fullWidth />,
  <NumberField source="range" fullWidth />,
  <DateField source="lastUpdateLabel" fullWidth />,
  <TextField source="softwareVersion" fullWidth />,
  <TextField source="updateVersion" fullWidth />,
  <DateField source="lastUpdate" fullWidth />,
  <NumberField source="latitude" fullWidth />,
  <NumberField source="longitude" fullWidth />,
  <NumberField source="chargeRate" fullWidth />,
  <BooleanField source="doorLocked" fullWidth />,
  <NumberField source="insideTemperature" fullWidth />,
  <NumberField source="outsideTemperature" fullWidth />,
  <NumberField source="socMax" fullWidth />,
  <NumberField source="chargePower" fullWidth />,
  <TextField source="chargingStateLabel" fullWidth />,
  <TextField source="driveStateLabel" fullWidth />,
  <TextField source="connectionStateLabel" fullWidth />,
  <NumberField source="remainingChargingTime" fullWidth />,
  <NumberField source="socBms" fullWidth />,
  <NumberField source="batteryPower" fullWidth />,
  <NumberField source="batteryRecharged" fullWidth />,
  <NumberField source="rangeRecharged" fullWidth />,
  <NumberField source="batteryVoltage" fullWidth />,
  <NumberField source="batteryCurrent" fullWidth />,
  <NumberField source="batteryTemperatureMin" fullWidth />,
  <NumberField source="batteryTemperatureMax" fullWidth />,
  <NumberField source="coolingTemperature" fullWidth />,
  <BooleanField source="updateAvailable" fullWidth />,
  <BooleanField source="updating" fullWidth />,
  <NumberField source="socCBox" fullWidth />,
]

export const VehicleShowFields: Record<string, ColumnsType> = {
  main: customfields(main),
  permissions: customfields(permissions, 'permissions.'),
  adapters: customfields(adapters),
  cables: customfields(cables),
  vehicleManufacturer: customfields(vehicleManufacturer, 'vehicleManufacturer.'),
  vehicleModel: customfields(vehicleModel, 'vehicleModel.'),
  vehicleDesign: customfields(vehicleDesign, 'vehicleDesign.'),
  vehicleVariant: customfields(vehicleVariant, 'vehicleVariant.'),
  state: customfields(state, 'state.'),
}

export default VehicleShowFields
