export const all = 'ALL'
export const none = 'NONE'
export const plugType = 'plugType'

export const BACKOFFICE_OWNER = 'BACKOFFICE_OWNER'
export const BACKOFFICE_ADMIN = 'BACKOFFICE_ADMIN'
export const BACKOFFICE_ROLEMANAGERS_WRITE = 'BACKOFFICE_ROLEMANAGERS_WRITE'

export const deactivate = 'status.deactivate'
export const activate = 'status.activate'
export const status = 'status.status'
export const simStatus = 'status.simStatus'
export const extend = 'status.extend'
export const error = 'ra.page.error'

export const notSet = 'not-set'

type Libraries = ('drawing' | 'geometry' | 'localContext' | 'places' | 'visualization')[]
export const places: Libraries = ['places']
