import { styled } from '@mui/material'
import { Layout as LT } from 'react-admin'

export const Layout = styled(LT)(() => ({
  '& div[class^="RaSidebar-fixed"]': {
    width: 'inherit',
  },
}))

export const Version = styled('p')(({ theme }) => ({
  width: '100%',
  paddingLeft: theme.spacing(1.25),
  bottom: 0,
  zIndex: 1100,

  '&__element': {
    fontSize: '0.75rem',
    color: 'rgba(0, 0, 0, 0.54)',
  },
}))
