import React from 'react'
import { Edit, EditProps } from 'react-admin'

import ConsumptionCurveGroupsForm from './ConsumptionCurveGroupsForm'

const ConsumptionCurveGroupsEdit: React.FC<EditProps> = props => (
  <Edit {...props}>
    <ConsumptionCurveGroupsForm />
  </Edit>
)

export default ConsumptionCurveGroupsEdit
