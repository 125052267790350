import merge from 'lodash/merge'

type PlugElement = {
  isModifiable: boolean
  value: number | boolean | null
}

type PlugObjectType = {
  [key: string]: {
    [key: string]: PlugElement
  }
}

const basePlugs: PlugObjectType = {
  CCS2: {
    hasCable: { isModifiable: false, value: true },
    cableLength: { isModifiable: true, value: null },
    isWatercooled: { isModifiable: true, value: false },
    chargingPower: { isModifiable: true, value: null },
    chargingCurrent: { isModifiable: true, value: null },
    chargingVoltage: { isModifiable: true, value: null },
    teslaOnly: { isModifiable: true, value: false },
  },
  CEE_BLAU_16: {
    chargingPower: { isModifiable: false, value: 3.7 },
    chargingCurrent: { isModifiable: false, value: 16 },
    chargingVoltage: { isModifiable: false, value: 230 },
    chargingPhases: { isModifiable: false, value: 1 },
  },
  CEE_ROT_16: {
    chargingPower: { isModifiable: false, value: 11 },
    chargingCurrent: { isModifiable: false, value: 16 },
    chargingVoltage: { isModifiable: false, value: 230 },
    chargingPhases: { isModifiable: false, value: 3 },
  },
  CEE_ROT_32: {
    chargingPower: { isModifiable: false, value: 22 },
    chargingCurrent: { isModifiable: false, value: 32 },
    chargingVoltage: { isModifiable: false, value: 230 },
    chargingPhases: { isModifiable: false, value: 3 },
  },
  CHADEMO: {
    hasCable: { isModifiable: false, value: true },
    cableLength: { isModifiable: true, value: null },
    chargingPower: { isModifiable: true, value: null },
    chargingCurrent: { isModifiable: true, value: null },
    chargingVoltage: { isModifiable: true, value: null },
  },
  INDUKTIV: {
    chargingPower: { isModifiable: true, value: null },
    chargingCurrent: { isModifiable: true, value: null },
    chargingVoltage: { isModifiable: false, value: 230 },
    chargingPhases: { isModifiable: true, value: null },
  },
  SCHUKO: {
    chargingPower: { isModifiable: false, value: 3.7 },
    chargingCurrent: { isModifiable: false, value: 16 },
    chargingVoltage: { isModifiable: false, value: 230 },
    chargingPhases: { isModifiable: false, value: 1 },
  },
  TYP1: {
    hasCable: { isModifiable: false, value: true },
    cableLength: { isModifiable: true, value: null },
    chargingPower: { isModifiable: true, value: null },
    chargingCurrent: { isModifiable: true, value: null },
    chargingVoltage: { isModifiable: false, value: 230 },
    chargingPhases: { isModifiable: false, value: 1 },
  },
  TYP2: {
    hasCable: { isModifiable: true, value: false },
    cableLength: { isModifiable: true, value: null },
    chargingPower: { isModifiable: true, value: null },
    chargingCurrent: { isModifiable: true, value: null },
    chargingVoltage: { isModifiable: false, value: 230 },
    chargingPhases: { isModifiable: true, value: null },
    singlePhaseCurrentLimitation: { isModifiable: true, value: false },
    singlePhaseCurrentValue: { isModifiable: true, value: null },
    teslaOnly: { isModifiable: true, value: false },
  },
  TYP2_TESLA: {
    hasCable: { isModifiable: false, value: true },
    cableLength: { isModifiable: true, value: null },
    chargingPower: { isModifiable: true, value: null },
    chargingCurrent: { isModifiable: true, value: null },
    chargingVoltage: { isModifiable: true, value: null },
    teslaOnly: { isModifiable: true, value: false },
  },
}

export const plugs: PlugObjectType = merge(
  Object.keys(basePlugs).reduce(
    (prev: PlugObjectType, key: string) => ({
      ...prev,
      [key]: {
        hasCable: { isModifiable: false, value: false },
        cableLength: { isModifiable: false, value: null },
        isWatercooled: { isModifiable: false, value: false },
        loadManagement: { isModifiable: false, value: false },
        chargingPower: { isModifiable: false, value: null },
        chargingCurrent: { isModifiable: false, value: null },
        chargingVoltage: { isModifiable: false, value: null },
        chargingPhases: { isModifiable: false, value: null },
        singlePhaseCurrentLimitation: { isModifiable: false, value: null },
        singlePhaseCurrentValue: { isModifiable: false, value: null },
        teslaOnly: { isModifiable: false, value: false },
      },
    }),
    {}
  ),
  { ...basePlugs }
)

export const isVisible: (source: string, prop: string) => boolean = (source, prop) => {
  if (
    plugs[source] &&
    plugs[source][prop] &&
    ((plugs[source][prop].isModifiable && plugs[source][prop].value) ||
      (!plugs[source][prop].isModifiable && plugs[source][prop].value) ||
      plugs[source][prop].isModifiable)
  ) {
    return true
  }

  return false
}
