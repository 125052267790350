import { CreateDialog } from '@eclever/ra-enterprise/ra-form-layout'
import React from 'react'
import {
  useNotify,
  useRefresh,
  useRedirect,
  SimpleForm,
  NumberInput,
  Toolbar,
  SaveButton,
} from 'react-admin'

import { admin } from '../../../types'
import countriesArray from '../../shared/Utils/getCountries'
import { AutoCompleteInput, options, transform } from './EmpOperatorsInputFields'
import { NewCreateProps } from './EmpOperatorsTypes'

const MyToolbar = () => (
  <Toolbar>
    <SaveButton alwaysEnable />
  </Toolbar>
)

const EmpOperatorsCreate: React.FC<NewCreateProps> = ({ id, ...props }) => {
  const notify = useNotify()
  const refresh = useRefresh()
  const redirect = useRedirect()

  const onSuccess = () => {
    notify('ra.action.save', { type: 'info' })
    redirect(`/${admin.emp_operators}`)
    refresh()
  }

  return (
    <CreateDialog
      {...props}
      mutationOptions={{ onSuccess }}
      transform={transform}
      fullWidth
      maxWidth="md"
    >
      <SimpleForm toolbar={<MyToolbar />}>
        <NumberInput defaultValue={id ? parseInt(id.split(':')[0]) : 0} source="empId" disabled />
        <NumberInput
          defaultValue={id ? parseInt(id.split(':')[1]) : 0}
          source="operatorId"
          disabled
        />
        <AutoCompleteInput source="countries" choices={options(countriesArray())} />
      </SimpleForm>
    </CreateDialog>
  )
}

export default EmpOperatorsCreate
