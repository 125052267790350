import { useCanAccess } from '@eclever/ra-enterprise/ra-rbac'
import React from 'react'
import {
  Datagrid,
  ListProps,
  Loading,
  NumberField,
  TextField,
  TextInput,
  NumberInput,
  EditButton,
} from 'react-admin'

import { admin } from '../../../types'
import Pagination from '../../shared/Components/Pagination'
import StickyColumnWrapper from '../../shared/Components/StickyColumnWrapper'
import List from '../../shared/Components/StyledComponents/List'
import Toolbar from '../../shared/Toolbar/Toolbar'

const ChargingCurveGroupsFilter = [
  <NumberInput source="id" alwaysOn />,
  <TextInput source="name" alwaysOn />,
]

const ChargingCurveGroupsList: React.FC<ListProps> = props => {
  const { isLoading: loadAccess, canAccess } = useCanAccess({
    action: 'edit',
    resource: admin.charging_curve_groups,
  })

  if (loadAccess) {
    return <Loading />
  }

  return (
    <List
      pagination={<Pagination />}
      filters={ChargingCurveGroupsFilter}
      actions={
        <Toolbar reference={admin.charging_curve_groups} resource={admin.charging_curve_groups} />
      }
      empty={false}
    >
      <Datagrid>
        <NumberField source="id" />
        <TextField source="name" />
        <StickyColumnWrapper {...props}>{canAccess && <EditButton />}</StickyColumnWrapper>
      </Datagrid>
    </List>
  )
}

export default ChargingCurveGroupsList
