import React from 'react'
import {
  required,
  TextInput,
  SimpleForm,
  SimpleFormProps,
  FormDataConsumer,
  NumberInput,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin'

import { admin } from '../../../types'

const StationTypesForm: React.FC<Omit<SimpleFormProps, 'children'>> = props => (
  <SimpleForm {...props}>
    <FormDataConsumer>
      {({ formData, ...rest }) =>
        formData.id && (
          <NumberInput source="id" isRequired validate={[required()]} disabled {...rest} />
        )
      }
    </FormDataConsumer>
    <ReferenceInput source="stationManufacturerId" reference={admin.station_manufacturers}>
      <AutocompleteInput optionText="name" fullWidth isRequired validate={[required()]} disabled />
    </ReferenceInput>
    <TextInput source="name" isRequired validate={[required()]} fullWidth />
  </SimpleForm>
)

export default StationTypesForm
