import { useCanAccess } from '@eclever/ra-enterprise/ra-rbac'
import React from 'react'
import {
  RaRecord,
  TextField,
  TextFieldProps,
  useListController,
  useRecordContext,
} from 'react-admin'

import { admin, ColumnsType } from '../../../types'
import { all } from '../../shared/Constants'
import { EmpOperatorButtonDrawer } from './EmpOperatorsDrawer'
import { CustomButton, CustomCreateButton, CustomEditButton } from './EmpOperatorsStyles'
import { ToggleDrawerProps } from './EmpOperatorsTypes'

export const columns: (data: RaRecord[]) => ColumnsType = (data: RaRecord[]) =>
  data.reduce((prev: ColumnsType, item: RaRecord) => {
    if (item?.name) {
      prev[item?.name] = <TextField source={item.toString()} label={item.name} />
    }
    return prev
  }, {} as ColumnsType)

export const Field: React.FC<
  TextFieldProps & {
    current: number | undefined
    data: RaRecord[]
    toggleDrawer: (props: ToggleDrawerProps) => void
    setId: React.Dispatch<React.SetStateAction<string>>
  }
> = ({ setId, current, data }) => {
  const [openButton, setButton] = React.useState(false)
  const { resource } = useListController()
  const record: RaRecord = useRecordContext()
  const { isLoading, canAccess } = useCanAccess({
    action: 'edit',
    resource: resource,
  })

  if (!current) {
    return <>ERROR</>
  }

  if (!record || isLoading) {
    return <>null</>
  }

  if (!canAccess) {
    if (record[current] && record[current].countries) {
      return (
        <div>
          <CustomButton
            sx={{ backgroundColor: '#A2BBA3' }}
            onClick={() => setButton(true)}
            label={
              record[current].countries.length !== 0
                ? record[current].countries.length.toString()
                : all
            }
          />
          <EmpOperatorButtonDrawer record={record[current]} open={openButton} onClose={setButton} />
        </div>
      )
    }
    return <CustomButton label={'0'} sx={{ backgroundColor: '#D11507' }} disabled />
  }

  if (record[current] && record[current].countries) {
    return (
      <CustomEditButton
        sx={{ backgroundColor: '#A2BBA3' }}
        record={{
          id: `${current}:${record.id}`,
        }}
        resource={admin.emp_operators}
        onClick={() => setId(`${current}:${record.id}`)}
        label={
          record[current].countries.length !== 0 ? record[current].countries.length.toString() : all
        }
      />
    )
  } else {
    return (
      <CustomCreateButton
        sx={{ backgroundColor: '#D11507' }}
        resource={admin.emp_operators}
        onClick={() => setId(`${current}:${record.id}`)}
        label={'0'}
      />
    )
  }
}
