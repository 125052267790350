/* eslint-disable react/style-prop-object */
import React, { ReactElement } from 'react'

const LocationInactiveIcon: () => ReactElement = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <g>
      <path
        d="M5.113,1H2.339A1.313,1.313,0,0,0,1,2.351v2.7"
        fill="none"
        stroke="#243b56"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.29999995231628px"
      />
      <path
        d="M18.983,23h2.678A1.313,1.313,0,0,0,23,21.649v-2.7"
        fill="none"
        stroke="#243b56"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.29999995231628px"
      />
      <path
        d="M18.983,1h2.678A1.313,1.313,0,0,1,23,2.351V4.956"
        fill="none"
        stroke="#243b56"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.29999995231628px"
      />
      <path
        d="M5.017,23H2.339A1.313,1.313,0,0,1,1,21.649v-2.7"
        fill="none"
        stroke="#243b56"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.29999995231628px"
      />
      <g>
        <path
          d="M12,15.3A3.3,3.3,0,1,0,8.7,12,3.3,3.3,0,0,0,12,15.3Z"
          fill="none"
          stroke="#243b56"
          strokeMiterlimit="10"
          strokeWidth="1.29999995231628px"
        />
        <path
          d="M12,18.6A6.6,6.6,0,1,0,5.4,12,6.6,6.6,0,0,0,12,18.6Z"
          fill="none"
          stroke="#243b56"
          strokeMiterlimit="10"
          strokeWidth="1.29999995231628px"
        />
      </g>
    </g>
  </svg>
)

export default LocationInactiveIcon
