/* eslint-disable @typescript-eslint/no-explicit-any */
import { isEmpty } from 'lodash'
import { GetListResult } from 'react-admin'

export const getValidOperators = (
  data: Pick<GetListResult<any>, 'data'> | undefined,
  id: number | undefined
): number[] =>
  data?.data?.reduce((pre, cur) => {
    Object.entries(cur).some(([key, values]) => {
      if (key === 'id' && id && key !== id.toString()) {
        return false
      }

      if (!isEmpty(values) && key === id?.toString()) {
        pre.push(cur.id as number)
        return true
      }

      return false
    })
    return pre
  }, [] as number[]) || []
