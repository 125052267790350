import { styled, TableCell, Button, ListItem } from '@mui/material'

import Box from './CustomInputs/Box'

export const DescriptionField = styled(TableCell)(() => ({
  '&.MuiToolbar-root.MuiToolbar-regular.MuiToolbar-gutters:not(.MuiTablePagination-toolbar)': {
    flexDirection: 'row',
  },
}))

export const Chips = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gridGap: theme.spacing(1),
}))

export const InfoButton = styled(Button)(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.common.white,

  '& :hover': {
    color: theme.palette.common.black,
  },
}))

export const InfoListButton = styled(ListItem)(({ theme }) => ({
  backgroundColor: theme.palette.grey[300],
  color: theme.palette.common.black,
}))

export const CustomGrid = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gridGap: theme.spacing(4),
  marginBottom: theme.spacing(2),
  // borderBottom: `1px solid ${theme.palette.grey[400]}`,
}))

export const GridShort = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'grid',
  gridTemplateColumns: '1fr 4fr',
  gridGap: theme.spacing(4),
}))

export const GridThree = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  gridGap: theme.spacing(4),
  marginBottom: theme.spacing(2),
}))
