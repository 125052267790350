import countryList from 'react-select-country-list'

export type CountriesProps = {
  id: string
  name: string
}

const countriesArray: () => CountriesProps[] = () => {
  const orderedCountries: CountriesProps[] = []
  Object.entries(countryList().getValueList()).forEach(
    ([key, _value]: [key: string, value: string]) =>
      orderedCountries.push({ id: key.toUpperCase(), name: key.toUpperCase() })
  )
  return orderedCountries
}

export default countriesArray
