import { ResourceProps } from 'react-admin'

import StationChargegroupsCreate from './StationChargegroupsCreate'
import StationChargegroupsEdit from './StationChargegroupsEdit'
import StationChargegroupsList from './StationChargegroupsList'

const StationChargegroups: Omit<ResourceProps, 'name'> = {
  options: { label: 'Station Chargegroups' },
  list: StationChargegroupsList,
  create: StationChargegroupsCreate,
  edit: StationChargegroupsEdit,
  // icon: DockIcon,
}

export default StationChargegroups
