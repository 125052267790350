import MoreIcon from '@mui/icons-material/More'
import {
  Button,
  Drawer,
  Grid,
  Paper,
  Typography,
  TableRow,
  TableCell,
  TableBody,
  Table,
  TableContainer,
  Modal,
} from '@mui/material'
import React, { useState } from 'react'
import { RaRecord, useRecordContext, TextFieldProps, useTranslate } from 'react-admin'

import { admin } from '../../../../types'
import { ImageType } from '../CustomInputs/ImageInput/ImageInput'
import {
  ImageField,
  ImageFullscreen,
  DrawerHeader,
  DrawerGridContainer,
  MoreButton,
} from '../StationChargegroupsStyles'

const ImageButton: React.FC<{ image: string }> = ({ image }) => {
  const [imageShow, setImageShow] = useState<boolean>(false)

  return (
    <div>
      <Button onClick={() => setImageShow(!imageShow)}>
        <ImageField src={image} alt="" />
      </Button>
      <Modal
        open={imageShow}
        onClose={() => setImageShow(!imageShow)}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <ImageFullscreen src={image} alt="" />
      </Modal>
    </div>
  )
}

const ImageDrawer: React.FC<TextFieldProps> = props => {
  const record: RaRecord = useRecordContext(props)
  const translate = useTranslate()

  return (
    <>
      <DrawerHeader variant="h3">
        {translate(`resources.${admin.station_chargegroups}.tags.visible`)}
      </DrawerHeader>
      <Paper elevation={3} variant="elevation">
        <DrawerGridContainer container spacing={2}>
          {record?.images.map((i: ImageType, index: number) => (
            <Grid item key={`visible-${index} ${i}`}>
              <ImageButton image={i.id} />
            </Grid>
          ))}
        </DrawerGridContainer>
      </Paper>
      <DrawerHeader variant="h3">
        {translate(`resources.${admin.station_chargegroups}.tags.invisible`)}
      </DrawerHeader>
      <Paper elevation={3} variant="elevation">
        <DrawerGridContainer container spacing={2}>
          {record?.internalImages.map((i: ImageType, index: number) => (
            <Grid item key={`invisible-${index} ${i}`}>
              <ImageButton image={i.id} />
            </Grid>
          ))}
        </DrawerGridContainer>
      </Paper>
    </>
  )
}

const CustomImageDatagridCell: React.FC<
  TextFieldProps & { label: string; images?: ImageType[]; isEdit?: boolean }
> = ({ label, images, isEdit }) => {
  const [drawer, setDrawer] = useState<boolean>(false)
  const translate = useTranslate()

  return (
    <Table>
      <TableBody>
        <TableRow>
          <TableCell component="th" scope="row">
            <Typography variant="body2" component="span" style={{ width: '3rem', display: 'flex' }}>
              {' '}
              {label}{' '}
            </Typography>
          </TableCell>
          {!isEdit && (
            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)' }}>
              <TableCell component="th" scope="row">
                {images && images[0]?.id && <ImageButton image={images[0].id} />}
              </TableCell>
              <TableCell component="th" scope="row">
                {images && images[1]?.id && <ImageButton image={images[1].id} />}
              </TableCell>
              <TableCell component="th" scope="row">
                {images && images[2]?.id && <ImageButton image={images[2].id} />}
              </TableCell>
              <TableCell
                component="th"
                scope="row"
                style={{ display: 'flex', alignItems: 'center' }}
              >
                {images && images[3]?.id && (
                  <MoreButton startIcon={<MoreIcon />} onClick={() => setDrawer(!drawer)}>
                    <>
                      {' '}
                      {images.length - 3}{' '}
                      {translate(`resources.${admin.station_chargegroups}.tags.more`)}{' '}
                    </>
                  </MoreButton>
                )}
              </TableCell>
            </div>
          )}
          {isEdit && images && (
            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)' }}>
              {images?.map((image: { id: string; createdAt: string }) => (
                <TableCell component="th" scope="row">
                  <ImageButton image={image.id} />
                </TableCell>
              ))}
            </div>
          )}
          <Drawer id="image-drawer" anchor="right" open={drawer} onClose={() => setDrawer(!drawer)}>
            <ImageDrawer />
          </Drawer>
        </TableRow>
      </TableBody>
    </Table>
  )
}

const CustomImageField: React.FC<TextFieldProps & { isEdit?: boolean }> = props => {
  const record: RaRecord = useRecordContext(props)

  return (
    <TableContainer>
      <CustomImageDatagridCell {...props} label={'visible'} images={record?.images} />
      <CustomImageDatagridCell {...props} label={'invisible'} images={record?.internalImages} />
    </TableContainer>
  )
}

export default CustomImageField
