import { WizardForm, WizardFormStep } from '@eclever/ra-enterprise/ra-form-layout'
import React from 'react'
import { EditProps, Edit, required, RaRecord } from 'react-admin'

import { admin } from '../../../types'
import CPacksInputs from './CPacksInputs'
import ActivateButtonInput from './CustomInputs/ActivateButtonInput'
import StatusInput from './CustomInputs/StatusInput'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const onClickValidation = (value: any, _allValues: any) => {
  if (!value) {
    return 'not clicked'
  }

  return undefined
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const statusValidation = (value: any, _allValues: any) => {
  if (!value || !(value?.cPack && value?.cBox && value?.cPlug)) {
    return 'all status should be true'
  }

  return undefined
}

const fields = CPacksInputs

const transform = ({ _isNew, _cpackStatus, ...data }: RaRecord) => data

const CPacksEdit: React.FC<EditProps> = props => {
  return (
    <Edit {...props} transform={transform}>
      <WizardForm>
        <WizardFormStep label="Activate sim">
          <ActivateButtonInput
            source="activate-sim"
            label="Activate sim"
            resourceName={admin.virtualCpacks}
            requestBody={{ activated: true }}
            isRequired
            validate={[required(), onClickValidation]}
          />
        </WizardFormStep>
        <WizardFormStep label="Status update">
          <StatusInput source="cpackStatus" isRequired validate={[required(), statusValidation]} />
        </WizardFormStep>
        <WizardFormStep label="Alarm test">
          <ActivateButtonInput
            source="alarm-test"
            label="Alarm test"
            resourceName={admin.virtualCpacks}
            requestBody={{ triggered: true }}
            isRequired
            validate={[required(), onClickValidation]}
          />
        </WizardFormStep>
        <WizardFormStep label="Create Vehicle">{Object.values(fields['main'])}</WizardFormStep>
      </WizardForm>
    </Edit>
  )
}

export default CPacksEdit
