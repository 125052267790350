import { ChangeHistory } from '@mui/icons-material'
import { styled, Button, Typography, Switch } from '@mui/material'
import { Create } from 'react-admin'

import { ThemeProvider } from '../../../../../provider'

export const FormWrapper = styled('div')(() => ({
  '& .MuiCard-root': {
    overflow: 'visible',
  },
}))

export const FormWrapperCreate = FormWrapper as unknown as typeof Create

export const CustomLocationInactiveIcon = styled(Button)(({ theme }) => ({
  backgroundColor: 'white',
  border: theme.spacing(0),
  margin: theme.spacing(1.25),
  padding: theme.spacing(0),
  textTransform: 'none',
  appearance: 'none',
  cursor: 'pointer',
  userSelect: 'none',
  borderRadius: theme.spacing(0.25),
  height: theme.spacing(5),
  width: theme.spacing(5),
  minWidth: theme.spacing(5),
  boxShadow: 'rgb(0 0 0 / 30%) 0px 1px 4px -1px',
  overflow: 'hidden',
  position: 'absolute',
  top: theme.spacing(7.5),
  right: theme.spacing(0),

  '& svg': {
    height: theme.spacing(3),
    width: theme.spacing(3),
  },
}))

export const Container = styled('div')(({ theme }) => ({
  position: 'absolute',
  zIndex: 1000,
  backgroundColor: theme.palette.background.paper,
  // border: `1px solid ${theme.palette.border}`,
  marginTop: theme.spacing(0.1),
  borderRadius: theme.shape.borderRadius,
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  width: 'inherit',
}))

export const Suggestion = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
  '&:hover': {
    backgroundColor: theme.palette.background,
  },
}))

export const SuggestionActive = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
}))

export const Label = styled('div')(({ theme }) => ({
  transform: 'translate(-8px, -14px) scale(.75)',
  backgroundColor: theme.palette.background.paper,
  width: 'fit-content',
  paddingLeft: theme.spacing(0.75),
  paddingRight: theme.spacing(0.75),
}))

export const Search = styled('div')(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(5),

  '& > div': {
    width: '100%',
  },
}))

export const SearchButtonWrapper = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: `repeat(auto-fit, minmax(${theme.spacing(2)}, 1fr))`,
  gridGap: theme.spacing(3),
  marginBottom: theme.spacing(2),
}))

export const SearchButton = styled(Button)(({ theme }) => ({
  color: theme.palette.common.white,
  border: `${theme.spacing(0.00125)} solid`,
  backgroundColor: theme.palette.secondary.main,

  '&:hover': {
    backgroundColor: 'white',
    border: `${theme.spacing(0.00125)} solid ${theme.palette.secondary.main}`,
    color: theme.palette.secondary.main,
  },
}))

export const SearchFormFields = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: `repeat(auto-fit, minmax(${theme.spacing(2)}, 1fr))`,
  gridGap: theme.spacing(2),
  marginTop: theme.spacing(2.5),
}))

export const SearchIcon = styled('img')(({ theme }) => ({
  height: theme.spacing(2.5),
  width: theme.spacing(2.5),
}))

export const CustomChangeHistory = styled(ChangeHistory)(({ theme }) => ({
  height: theme.spacing(2.5),
  width: theme.spacing(2.5),
}))

export const SearchSwitchSection = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
  overflow: 'visible',
}))

export const SearchSwitchWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: `${theme.spacing(0.0125)} 0`,
  border: `${theme.spacing(0.0125)} solid ${theme.palette.secondary.main}`,
  borderRadius: '5%',
  backgroundColor: theme.palette.secondary.light,
  padding: `${theme.spacing(0.0125)} ${theme.spacing(0.125)}`,

  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: ThemeProvider.palette.colorPalette.switchTracker.light,
    borderRadius: 20 / 2,
  },
}))

export const SearchSwitchLabel = styled(Typography)(({ theme }) => ({
  color: '#fff',
  marginLeft: `${theme.spacing(0.0625)}`,
}))

export const SearchSwitch = styled(Switch)(({ theme }) => ({
  width: theme.spacing(7.75),
  padding: theme.spacing(0.875),

  '& .MuiSvgIcon-root': {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: '50%',
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(0)',

    '&.Mui-checked': {
      transform: 'translateX(30px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: ThemeProvider.palette.colorPalette.switchTracker.light,
      },
    },
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: ThemeProvider.palette.colorPalette.switchTracker.light,
    borderRadius: 20 / 2,
  },
}))

export const ShowCurrentPin = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  left: theme.spacing(10),
  padding: `0 ${theme.spacing(0.125)}`,
  opacity: 0.8,
  backgroundColor: theme.palette.background.default,
}))

export const ZIndex = styled('div')(() => ({
  '& .Select-menu-outer': {
    zIndex: 999,
  },
}))
