import { RaRecord } from 'react-admin'

import { getNotNullElements } from '../../shared/Utils/convert'
import { addressFormat } from './CustomInputs/ChargegroupsMap/MapDisplay'

type LatLng = { latitude: number; longitude: number }
type LatLngShort = { lat: number; lng: number }

export const getLatLngShort: (
  boundaries: LatLng[] | undefined
) => LatLngShort[] | undefined = boundaries =>
  boundaries?.reduce((pre: LatLngShort[], cur: LatLng) => {
    pre.push({ lat: cur?.latitude, lng: cur?.longitude })
    return pre
  }, [] as LatLngShort[])

export const transform = (data: RaRecord): RaRecord => ({
  ...data,
  addressFormatted: addressFormat({
    addressPostCode: data?.addressPostCode,
    addressStreet: data?.addressStreet,
    addressCity: data?.addressCity,
    addressCountry: data?.addressCountry,
  }),
  approachDescriptions:
    (data?.approachDescriptions && getNotNullElements(data.approachDescriptions)) || null,
})
