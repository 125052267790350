import { Autocomplete, Chip, TextField } from '@mui/material'
import React, { Dispatch, SetStateAction } from 'react'

type StateControlledAutocompleteProps = {
  options: string[]
  value: string[]
  setValue: Dispatch<SetStateAction<string[]>>
  defaultValue?: string[]
}

const StateControlledAutocomplete: React.FC<StateControlledAutocompleteProps> = ({
  options,
  value,
  setValue,
  defaultValue,
}) => {
  return (
    <Autocomplete
      multiple
      id="tags-outlined"
      options={options}
      defaultValue={defaultValue}
      value={value}
      onChange={(_, values) => {
        return setValue(values)
      }}
      filterSelectedOptions
      renderTags={(tagValue: readonly string[]) =>
        tagValue.map((option: string, index: number) => {
          return (
            <Chip
              variant="outlined"
              label={option}
              onMouseDown={() => {
                setValue(prev => [...prev.slice(0, index), ...prev.slice(index + 1, prev.length)])
              }}
              // needs to be provided to show delete Icon
              onDelete={() => null}
              key={index}
            />
          )
        })
      }
      renderInput={params => <TextField {...params} label="Parameters" />}
    />
  )
}

export default StateControlledAutocomplete
