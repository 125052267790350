import React from 'react'
import { Create, CreateProps, TextInput, SimpleForm, email, required } from 'react-admin'

import { RoleInput } from './UsersInputs'
import { transform } from './UsersUtils'

const UsersCreate: React.FC<CreateProps> = props => (
  <Create {...props} transform={transform}>
    <SimpleForm
      defaultValues={{
        roles: [],
      }}
    >
      <TextInput source="mail" fullWidth isRequired validate={[required(), email()]} />
      <RoleInput source="roles" />
    </SimpleForm>
  </Create>
)

export default UsersCreate
