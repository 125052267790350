import { isEmpty } from 'lodash'
import React from 'react'
import { useTranslate, InputProps } from 'react-admin'
import { geocodeByLatLng, geocodeByPlaceId } from 'react-google-places-autocomplete'
import { useFormContext } from 'react-hook-form'

import { ThemeProvider } from '../../../../../../provider'
import SentryLoggerProvider from '../../../../../../provider/SentryLoggerProvider'
import { admin } from '../../../../../../types'
import { drag } from '../MapConstants'
import { addressFormat } from '../MapDisplay'
import { SearchButton } from '../MapStyles'
import { MapInputProps } from '../MapTypes'
import { getAddressComponents } from '../MapUtils'

const SaveAddressButton: React.FC<InputProps & MapInputProps> = ({
  currentPosition,
  setCurrentPosition,
  centerPin,
  showMarker,
  inputSave,
  setSave,
}) => {
  const translate = useTranslate()
  const { setValue } = useFormContext()

  const onClick = async () => {
    // Take over pin
    if (!showMarker.approach) {
      const LatLng = showMarker.pointPolygon
        ? [{ lat: centerPin.lat(), lng: centerPin.lng() }]
        : currentPosition.LatLng

      const results = await geocodeByLatLng({
        lat: centerPin.lat(),
        lng: centerPin.lng(),
      }).catch(err => {
        console.error(err)
        SentryLoggerProvider.error(err)
      })

      if (results) {
        const addressWrapper = await geocodeByPlaceId(
          !isEmpty(inputSave.place_id) ? inputSave.place_id : results[0].place_id
        )
        const addressItem = getAddressComponents(addressWrapper[0].address_components)

        setSave({
          save: '',
          place_id: '',
        })

        setValue('addressCity', addressItem?.addressCity || '')
        setValue('addressPostCode', addressItem?.addressPostCode || '')
        setValue('addressStreet', addressItem?.addressStreet || '')
        setValue('addressCountry', addressItem?.addressCountry || '')
        setValue('addressFormatted', (addressItem && addressFormat(addressItem)) || '')

        setCurrentPosition({
          ...currentPosition,
          type: drag,
          LatLng: LatLng,
          address: (addressItem && addressFormat(addressItem)) || '',
          approach: null,
        })
      }
    }
  }

  return (
    <SearchButton sx={{ backgroundColor: ThemeProvider.palette.secondary.main }} onClick={onClick}>
      <> {translate(`resources.${admin.station_chargegroups}.tags.saveAddress`)}</>
    </SearchButton>
  )
}

export default SaveAddressButton
