import { admin } from '../../types'

const deMessages = {
  resources: {
    [admin.station_types]: {
      name: 'Station Types',
      fields: {},
      tags: {
        addressSearch: 'Adresssuche',
        savePosition: 'Position übernehmen',
        reset: 'Zurücksetzen',
        return: 'Gehe zur Position',
        point: 'Punkt',
        polygon: 'Polygon',
        approach: 'Anfahrt',
        location: 'Standort anzeigen',
        showAddress: 'Adresse anzeigen',
        currentPoint: 'Aktueller Standort',
        currentAddress: 'Aktueller Adresse',
        currentApproach: 'Aktueller Anfahrtsstandort',
      },
    },
  },
  status: {
    status: 'Status Aktivierung',
    deactivate: 'Deaktivieren',
    activate: 'Aktivieren',
    extend: 'Verlängern',
    simStatus: 'Status Sim',
    error: 'Fehler',
  },
}

export default deMessages
