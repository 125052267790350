import { GetLockParams, LockParams, LocksDataProvider } from '@eclever/ra-enterprise/ra-realtime'
import { stringify } from 'query-string'
import { DataProvider, UpdateResult } from 'react-admin'

import { localizationsType } from '../components/shared/LocalizationInput/LocalizationUtils'
import systemConfig from '../config'
import * as type from '../types'
import { admin } from '../types'
import LockResource from '../utils/LockResource'
import authHttpClient from './AuthHttpClient'

/**
 * Maps react-admin queries to a simple REST API
 *
 * This REST dialect is similar to the one of FakeRest
 *
 * @see https://github.com/marmelab/FakeRest
 *
 * @example
 *
 * getList     => GET http://my.api.url/posts?sort=['title','ASC']&range=[0, 24]
 * getOne      => GET http://my.api.url/posts/123
 * getMany     => GET http://my.api.url/posts?filter={id:[123,456,789]}
 * update      => PUT http://my.api.url/posts/123
 * create      => POST http://my.api.url/posts
 * delete      => DELETE http://my.api.url/posts/123
 *
 * @example
 *
 * import * as React from "react";
 * import { Admin, Resource } from 'react-admin';
 * import simpleRestProvider from 'ra-data-simple-rest';
 *
 * import { PostList } from './posts';
 *
 * const App = () => (
 *     <Admin dataProvider={simpleRestProvider('http://path.to.my.api/')}>
 *         <Resource name="posts" list={PostList} />
 *     </Admin>
 * );
 *
 * export default App;
 */

// LocksDataProvider

function getDefaultBaseUrl(resource: string) {
  if (resource.startsWith('admin')) {
    if (resource.startsWith('admin/virtual')) {
      return systemConfig.virtualBackendBaseURL
    }

    if (resource.endsWith('/images')) {
      return systemConfig.fileUploadBackendBaseURL
    }

    return systemConfig.backendBaseURL
  }

  return systemConfig.appBackendBaseURL
}

const SimpleDataProvider = (
  getBaseUrl: (resource: string) => string = getDefaultBaseUrl,
  httpClient = authHttpClient
): DataProvider & LocksDataProvider => ({
  getList: (resource, params) => {
    const { page, perPage } = params.pagination
    const { field, order } = params.sort

    const query = {
      sort: JSON.stringify([field, order]),
      range: perPage >= 0 ? JSON.stringify([(page - 1) * perPage, page * perPage - 1]) : undefined,
      filter: JSON.stringify(params.filter),
    }
    const url = `${getBaseUrl(resource)}/${resource}?${stringify(query)}`

    return httpClient(url).then(({ json }) => {
      if (type.enums.includes(resource)) {
        return {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          data: json.data.sort().map((key: any) => ({
            key,
            id: key,
          })),
          total: Number(json.data.length),
        }
      }

      if (resource === admin.documents && params.filter.language) {
        return {
          data:
            params.filter.language &&
            json.data.filter(
              (document: { id: string; key: string; localizations: localizationsType[] }) =>
                document.localizations.filter((localization: localizationsType) =>
                  localization.language.includes(params.filter.language)
                ).length > 0
            ),
          total: Number(json.total),
        }
      }
      switch (resource) {
        case admin.usersMe:
          return {
            data: [{ ...json }],
            total: 1,
          }
        case admin.virtualVehiclesRecent:
          return {
            data: [{ id: json.id, ...json }],
            total: 1,
          }
        default:
          if (resource.startsWith('admin')) {
            return {
              data: json.data,
              total: Number(json.total),
            }
          }

          return {
            data: json,
            total: json?.length || 0,
          }
      }
    })
  },
  getOne: (resource, params) => {
    if (resource === type.admin.cPacks) {
      return Promise.resolve({ data: { id: params.id } })
    }
    return httpClient(`${getBaseUrl(resource)}/${resource}/${params.id}`).then(({ json }) => {
      if (json.id) {
        return {
          data: json,
        }
      }

      return {
        data: { id: params.id, ...json },
      }
    })
  },
  getMany: (resource, params) => {
    const query = {
      range: params.ids.length > 0 ? JSON.stringify([0, params.ids.length - 1]) : undefined,
      filter:
        params.ids.length > 0
          ? JSON.stringify({
              idList: params.ids,
            })
          : undefined,
    }

    const url = `${getBaseUrl(resource)}/${resource}?${stringify(query)}`
    return httpClient(url).then(({ json }) => {
      if (type.enums.includes(resource)) {
        return {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          data: json.data.sort().map((key: any) => ({
            key,
            id: key,
          })),
          total: Number(json.data.length),
        }
      }
      return {
        data: json.data,
      }
    })
  },

  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination
    const { field, order } = params.sort

    const query = {
      sort: JSON.stringify([field, order]),
      range: perPage >= 0 ? JSON.stringify([(page - 1) * perPage, page * perPage - 1]) : undefined,
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    }
    const url = `${getBaseUrl(resource)}/${resource}?${stringify(query)}`

    return httpClient(url).then(({ json }) => {
      return {
        total: Number(json.total),
        data: json.data,
      }
    })
  },

  update: (resource, params) => {
    if (resource === admin.virtualCpacks && !params.id.toString().endsWith('status')) {
      return httpClient(`${getBaseUrl(resource)}/${resource}/${params.id}/vehicle`, {
        method: 'PUT',
        body: JSON.stringify(params.data),
      }).then(({ json }) => {
        return { data: { ...params.data, id: json.id } }
      })
    }

    return httpClient(`${getBaseUrl(resource)}/${resource}/${params.id}`, {
      method: 'PUT',
      body: JSON.stringify(params.data),
    }).then(({ json }) => {
      if (!json?.id) {
        return { data: { ...params.data, id: params.id } }
      }

      return { data: { ...params.data, id: json.id } } as UpdateResult
    })
  },

  // simple-rest doesn't handle provide an updateMany route, so we fallback to calling update n times instead
  updateMany: (resource, params) =>
    Promise.all(
      params.ids.map(id =>
        httpClient(`${getBaseUrl(resource)}/${resource}/${id}`, {
          method: 'PUT',
          body: JSON.stringify(params.data),
        })
      )
    ).then(responses => {
      const data = { data: responses.map(({ json }) => json?.id) }
      if (data) {
        return data
      }

      return { data: params.ids }
    }),

  create: (resource, params) => {
    if (resource.endsWith('images')) {
      const bodyFormData = new FormData()
      bodyFormData.append('file', params.data)
      return httpClient(`${getBaseUrl(resource)}/${resource}`, {
        method: 'POST',
        body: bodyFormData,
      }).then(({ json }) => {
        if (Array.isArray(json)) {
          return {
            data: json[0],
          }
        }
        return {
          data: json,
        }
      })
    }

    return httpClient(`${getBaseUrl(resource)}/${resource}`, {
      method: 'POST',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({
      data: { ...params.data, id: json.id },
    }))
  },
  delete: (resource, params) => {
    return httpClient(`${getBaseUrl(resource)}/${resource}/${params.id}`, {
      method: 'DELETE',
      headers: new Headers({
        'Content-Type': 'text/plain',
      }),
    }).then(({ json }) => ({ data: { ...json, id: params.id } }))
  },
  // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
  deleteMany: (resource, params) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return Promise.all(
      params.ids.map(id =>
        httpClient(`${getBaseUrl(resource)}/${resource}/${id}`, {
          method: 'DELETE',
          body: JSON.stringify(params.meta),
          headers: new Headers({
            'Content-Type': 'text/plain',
          }),
        })
      )
    ).then(() => ({
      data: params.ids,
    }))
  },
  lock: (resource: string, data: LockParams) =>
    httpClient(
      `${getBaseUrl(resource)}/admin/station-locks/lock/${LockResource.getType(resource)}/${
        data.id
      }`,
      {
        method: 'PUT',
      }
    ).then(({ json }) => ({
      data: {
        identity: data.identity,
        resource: resource,
        recordId: data.id,
        createdAt: json.lockedAt,
        ...json,
      },
    })),
  unlock: (resource: string, data: LockParams) =>
    httpClient(
      `${getBaseUrl(resource)}/${admin.station_locks}/unlock/${LockResource.getType(resource)}/${
        data.id
      }`,
      {
        method: 'PUT',
      }
    ).then(({ json }) => ({
      data: {
        resource: resource,
        identity: data.identity,
        recordId: data.id,
        createdAt: json.lockedAt,
        ...json,
      },
    })),
  getLock: (resource: string, data: GetLockParams) =>
    httpClient(
      `${getBaseUrl(resource)}/${admin.station_locks}/status/${LockResource.getType(resource)}/${
        data.id
      }`,
      {
        method: 'GET',
      }
    ).then(({ json }) => ({
      data: {
        identity: json.lockUserId,
        resource: resource,
        recordId: data.id,
        ...json,
      },
    })),
  getLocks: (resource: string) => Promise.reject(),
})

export default SimpleDataProvider
