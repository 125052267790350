import React from 'react'
import { TextFieldProps, useRecordContext } from 'react-admin'

const RenderAddress: React.FC<TextFieldProps> = props => {
  const record = useRecordContext(props)
  return (
    <div>
      {record.country && record.postcode && record.city && record.street && record.number && (
        <span>
          {record.country}: {record.postcode} {record.city}, {record.street} {record.number}
        </span>
      )}
    </div>
  )
}

export default RenderAddress
