import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import PersonPinIcon from '@mui/icons-material/PersonPin'
import { Box, Typography, CircularProgress } from '@mui/material'
import React from 'react'
import {
  AppBar,
  AppBarProps,
  LocalesMenuButton,
  UserMenuProps,
  UserMenu,
  Logout,
  useGetList,
} from 'react-admin'

import { admin } from '../types'

const CustomUserMenu: React.FC<UserMenuProps> = props => {
  const {
    data: userData,
    isLoading: userLoading,
    error: userError,
  } = useGetList(admin.usersMe, { pagination: { page: 1, perPage: 2 } })
  const { className } = props

  if (userLoading) {
    return <CircularProgress />
  }

  if (userError || !userData) {
    return <>ERROR</>
  }

  return (
    <UserMenu {...props}>
      <Box sx={{ display: 'flex', flexDirection: 'row', padding: '1rem' }} className={className}>
        <PersonPinIcon sx={{ marginRight: '1rem' }} />
        <Typography> {userData[0]?.mail} </Typography>
      </Box>
      <Logout {...props} icon={<ExitToAppIcon />} />
    </UserMenu>
  )
}

const MyAppBar: React.FC<AppBarProps> = props => (
  <AppBar {...props} userMenu={<CustomUserMenu {...props} />}>
    <Box flex="1">
      <Typography variant="h6" id="react-admin-title"></Typography>
    </Box>
    <LocalesMenuButton
      languages={[
        { locale: 'en', name: 'English' },
        { locale: 'de', name: 'German' },
      ]}
    />
  </AppBar>
)

export default MyAppBar
