import {
  TextInput,
  ReferenceInput,
  AutocompleteInput,
  required,
  ReferenceArrayInput,
  SelectArrayInput,
  FormDataConsumer,
} from 'react-admin'

import { admin, InputElementType, InputType } from '../../../types'
import convertArrayToInputType from '../../shared/Utils/convert'

const general: InputElementType[] = [
  <ReferenceInput source="empId" reference={admin.emps} perPage={-1}>
    <AutocompleteInput optionText="name" isRequired validate={[required()]} fullWidth />
  </ReferenceInput>,
  <FormDataConsumer source="id">
    {({ formData, ...rest }) =>
      formData.id && (
        <TextInput source="id" fullWidth isRequired validate={[required()]} disabled {...rest} />
      )
    }
  </FormDataConsumer>,
  <TextInput source="name" fullWidth isRequired validate={[required()]} />,
  <TextInput source="nameAppStore" fullWidth />,
  <TextInput source="linkWebApp" fullWidth />,
  <ReferenceArrayInput
    label="accessMethods"
    reference="admin/enums/accessMethod"
    source="accessMethods"
  >
    <SelectArrayInput
      optionText="id"
      fullWidth
      isRequired
      validate={[required()]}
      parse={(event: string[]) => [...event].sort()}
    />
  </ReferenceArrayInput>,
]

const address: InputElementType[] = [
  <TextInput source="companyName" />,
  <TextInput source="street" />,
  <TextInput source="number" />,
  <TextInput source="postcode" />,
  <TextInput source="city" />,
  <TextInput source="country" />,
]

const contact: InputElementType[] = [
  <TextInput source="disorderHotline" />,
  <TextInput source="hotline" />,
  <TextInput source="mail" />,
]

const EmpNamesInputs: Record<string, InputType> = {
  general: general.reduce(convertArrayToInputType, {}),
  address: address.reduce(convertArrayToInputType, {}),
  contact: contact.reduce(convertArrayToInputType, {}),
}

export default EmpNamesInputs
