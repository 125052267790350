import { Edit, EditProps, RaRecord } from 'react-admin'

import TariffsForm from './TariffsForm'

export const transform = ({
  useAccessMethod,
  hasTime,
  acPricing,
  dcPricing,
  ...data
}: RaRecord): RaRecord => ({
  ...data,
  pricings: [...acPricing, ...dcPricing],
})

const TariffsEdit: React.FC<EditProps> = props => {
  return (
    <Edit {...props} transform={transform}>
      <TariffsForm />
    </Edit>
  )
}

export default TariffsEdit
