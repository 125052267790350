import DockIcon from '@mui/icons-material/Dock'

import VehicleCapabilitiesList from './VehicleCapabilitiesList'

const VehicleCapabilities = {
  options: { label: 'AvailableCPlugData' },
  list: VehicleCapabilitiesList,
  icon: DockIcon,
}

export default VehicleCapabilities
