import { Create, CreateProps, useNotify, useRefresh, useRedirect } from 'react-admin'

import { admin } from '../../../types'
import { transform } from './TariffsEdit'
import TariffsForm from './TariffsForm'

const TariffsCreate: React.FC<CreateProps> = props => {
  const notify = useNotify()
  const refresh = useRefresh()
  const redirect = useRedirect()

  const onSuccess = () => {
    notify('ra.action.save', { type: 'info' })
    redirect(`/${admin.tariffs}`)
    refresh()
  }

  return (
    <Create {...props} mutationOptions={{ onSuccess }} transform={transform}>
      <TariffsForm />
    </Create>
  )
}

export default TariffsCreate
