import { AccordionSection } from '@eclever/ra-enterprise/ra-form-layout'
import { LoadScript, Libraries } from '@react-google-maps/api';
import React from 'react'
import { ReferenceInput, SelectInput, SimpleForm, SimpleFormProps, required } from 'react-admin'

import systemConfig from '../../../config'
import { getIsoOptions } from '../../shared/LocalizationInput/LocalizationUtils'
import EditToolbar from '../../shared/Toolbar/EditToolbar'
import MapInput from './CustomInputs/ChargegroupsMap'
import { places } from './CustomInputs/ChargegroupsMap/MapConstants'
import ImageInput from './CustomInputs/ImageInput/ImageInput'
import {
  LanguagesInput,
  InternalizationInput,
} from './CustomInputs/LocalizationInput/InternalizationInput'

const libraries: Libraries = [places]

const StationChargegroupsForm: React.FC<Omit<SimpleFormProps, 'children'>> = props => (
  <SimpleForm
    {...props}
    toolbar={<EditToolbar />}
    defaultValues={{
      approachDescriptions: {},
      images: [],
      internalImages: [],
    }}
  >
    <AccordionSection label="Map" fullWidth defaultExpanded>
      <>
        {systemConfig.googleMapsApiKey && (
          <LoadScript googleMapsApiKey={systemConfig.googleMapsApiKey} libraries={libraries}>
            <MapInput resource={props.resource} isRequired validate={[required()]} source="" />
          </LoadScript>
        )}
      </>
    </AccordionSection>
    <AccordionSection label="Approach Descriptions" fullWidth defaultExpanded>
      <LanguagesInput source="approachDescriptions" label="Languages" choices={getIsoOptions()} />
      <InternalizationInput source="approachDescriptions" />
    </AccordionSection>
    <AccordionSection label="locationType" fullWidth defaultExpanded>
      <ReferenceInput source="locationType" reference="admin/enums/stationLocationType" fullWidth>
        <SelectInput optionText="id" isRequired validate={[required()]} fullWidth />
      </ReferenceInput>
    </AccordionSection>
    <AccordionSection label="Images" fullWidth defaultExpanded>
      <ImageInput source={''} />
    </AccordionSection>
  </SimpleForm>
)

export default StationChargegroupsForm
