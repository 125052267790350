import { GoogleMap, Marker, Polygon, Polyline } from '@react-google-maps/api'
import isEqual from 'lodash/isEqual'
import React, { useEffect, useRef, useState } from 'react'
import { InputProps } from 'react-admin'
import ReactDOM from 'react-dom'
import { useFormContext } from 'react-hook-form'

import LocationInactiveIcon from '../../../../../assets/Icons/LocationInactiveIcon'
import delete_input from '../../../../../assets/Icons/delete_input.svg'
import map_approach from '../../../../../assets/Icons/map_approach.svg'
import map_gps_pin from '../../../../../assets/Icons/map_gps_pin.svg'
import map_gps_point from '../../../../../assets/Icons/map_gps_point.svg'
import map_search_result_pin from '../../../../../assets/Icons/map_search_result_pin.svg'
import theme from '../../../../../provider/ThemeProvider'
import {
  location as locationConstant,
  drag,
  approach as approachConstant,
  address,
  drop,
  latlng,
} from './MapConstants'
import { CustomLocationInactiveIcon, ShowCurrentPin } from './MapStyles'
import { MapInputProps, LatLngProps } from './MapTypes'
import { calculatePolygonCenter, containerStyle, getValidData } from './MapUtils'

const MapInput: React.FC<InputProps & MapInputProps> = ({
  currentPosition,
  setCurrentPosition,
  centerPin,
  setCenterPin,
  showMarker: { pointPolygon, approach, showLocation },
}) => {
  const { getValues } = useFormContext()
  const value = getValues()
  const [centerPoint, setCenter] = useState<LatLngProps>({
    lat: centerPin.lat(),
    lng: centerPin.lng(),
  })
  const [currentMap, setMap] = useState<google.maps.Map | null>(null)
  const [location, setLocation] = useState<GeolocationCoordinates | null>(null)
  const [zoom, setZoom] = useState<number | undefined>(5)

  const validLocation = getValidData(value, locationConstant)
  const validLatLng = getValidData(value, latlng)

  const polygonRef = useRef<google.maps.Polygon | null>(null)
  const listenersRef = useRef<google.maps.MapsEventListener[]>([])

  useEffect(() => {
    if (currentMap && [approachConstant, drop, address].includes(currentPosition.type)) {
      if (currentPosition.type !== approachConstant) {
        setCenter(
          currentPosition.LatLng.length >= 3
            ? calculatePolygonCenter(currentPosition.LatLng)
            : currentPosition.LatLng[0]
        )
      } else if (currentPosition.type === approachConstant && currentPosition.approach) {
        setCenter(currentPosition.approach)
      }
      setZoom(20)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPosition, currentPosition.LatLng, setCurrentPosition])

  /**
   * Change the centerPin to the current center of the Map, when the user drag or zoom the Map
   */
  const handleBoundsChanged = React.useCallback(
    function callback() {
      const LatLng = currentMap && currentMap.getCenter()
      if (LatLng) {
        setCenterPin(LatLng)
      }
    },
    [currentMap, setCenterPin]
  )

  /**
   * Sets pin to the current location and zoom to the location
   */
  const handleSetLocation = React.useCallback(function callback(_event) {
    navigator.geolocation.getCurrentPosition(position => {
      setLocation(position.coords)
      setCenter({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      })
      setZoom(20)
    })
  }, [])

  /**
   * initalize google Map and add a location Button to the Map. Set zoom to 20 to the current position
   */
  const onLoad = React.useCallback(
    function callback(map) {
      setMap(map)

      // Set Location Icon
      const controlButtonDiv = document.createElement('div')
      ReactDOM.render(
        <CustomLocationInactiveIcon onClick={handleSetLocation}>
          <LocationInactiveIcon />
        </CustomLocationInactiveIcon>,
        controlButtonDiv
      )
      map.controls[google.maps.ControlPosition.TOP_RIGHT].push(controlButtonDiv)

      if (validLatLng) {
        setZoom(20)
      }
    },
    [handleSetLocation, validLatLng]
  )

  /**
   * Label center of polygon, current position or approach
   * @returns Label center of polygon, current position or approach as JSX.Element
   */
  const labelOnCenter = () => {
    let LatLng = ''

    if (pointPolygon || approach) {
      LatLng = `lat: ${centerPin.lat().toFixed(6)}, lng: ${centerPin.lng().toFixed(6)}`
    } else if (!pointPolygon && !approach) {
      const coordinates = calculatePolygonCenter(currentPosition.LatLng)
      LatLng = `lat: ${coordinates.lat.toFixed(6)}, lng: ${coordinates.lng.toFixed(6)}`
    }
    return <ShowCurrentPin variant="h6">{LatLng}</ShowCurrentPin>
  }

  /**
   * Call setPath with new edited path
   */
  const polygonOnEdit = React.useCallback(() => {
    if (polygonRef.current) {
      const nextPath = polygonRef.current
        .getPath()
        .getArray()
        .map(latLng => {
          return {
            lat: parseFloat(latLng.lat().toFixed(6)),
            lng: parseFloat(latLng.lng().toFixed(6)),
          }
        })
      setCurrentPosition({
        ...currentPosition,
        LatLng: nextPath,
      })
    }
  }, [currentPosition, setCurrentPosition])

  /**
   * Bind refs to current Polygon and listeners
   */
  const polygonOnLoad = React.useCallback(
    polygon => {
      polygonRef.current = polygon
      const path = polygon.getPath()
      listenersRef.current.push(
        path.addListener('set_at', polygonOnEdit),
        path.addListener('insert_at', polygonOnEdit),
        path.addListener('remove_at', polygonOnEdit)
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [polygonOnEdit]
  )

  /**
   *  Clean up polygon refs
   * */
  const polygonOnUnmount = React.useCallback(() => {
    listenersRef.current.forEach(lis => lis.remove())
    polygonRef.current = null
  }, [])

  /**
   *  Draw Polyline between approach and currentPoint or center of Polygon
   * */
  const polylineOnDraw: () => JSX.Element | undefined = () => {
    if (approach && (validLocation || validLatLng)) {
      let LatLng = undefined

      if (pointPolygon) {
        LatLng = validLatLng
      } else if (!pointPolygon && currentPosition.LatLng.length >= 3) {
        LatLng = calculatePolygonCenter(currentPosition.LatLng)
      }

      return (
        LatLng && (
          <Polyline
            path={[LatLng, { lat: centerPin.lat(), lng: centerPin.lng() }]}
            options={{
              strokeColor: 'green',
              strokeOpacity: 1,
              strokeWeight: 4,
            }}
          />
        )
      )
    }
  }

  return (
    <GoogleMap
      id="map"
      mapContainerStyle={containerStyle}
      center={centerPoint}
      zoom={zoom}
      onLoad={onLoad}
      onUnmount={() => setMap(null)}
      onZoomChanged={() => currentMap && setZoom(currentMap.getZoom())}
      onBoundsChanged={handleBoundsChanged}
      onDragStart={() => setCurrentPosition({ ...currentPosition, type: drag, address: null })}
    >
      {!pointPolygon && (
        <Polygon
          options={{
            fillColor:
              validLocation && isEqual(currentPosition.LatLng, validLocation)
                ? theme.palette.secondary.main
                : theme.palette.colorPalette.switchTracker.light,
          }}
          editable={!approach}
          path={currentPosition.LatLng}
          onMouseUp={polygonOnEdit}
          onDragEnd={polygonOnEdit}
          onLoad={polygonOnLoad}
          onUnmount={polygonOnUnmount}
        />
      )}

      {labelOnCenter()}
      {polylineOnDraw()}

      {pointPolygon && validLatLng && (
        <Marker
          icon={{
            url: map_search_result_pin,
            scaledSize: new google.maps.Size(60, 60),
            anchor: new google.maps.Point(15, 50),
          }}
          position={validLatLng}
          draggable={false}
        />
      )}

      {(pointPolygon || approach) && centerPin && (
        <Marker
          position={new window.google.maps.LatLng(centerPin.lat(), centerPin.lng())}
          icon={{
            url: approach ? map_approach : map_gps_pin,
            scaledSize: approach ? new google.maps.Size(60, 60) : new google.maps.Size(30, 30),
            anchor: approach ? new google.maps.Point(15, 50) : new google.maps.Point(20, 30),
          }}
        />
      )}

      {!pointPolygon &&
        !approach &&
        currentPosition.LatLng.map((pos, key) => (
          <Marker
            key={key}
            position={pos}
            onClick={() =>
              currentPosition.LatLng.length > 3 &&
              setCurrentPosition({
                ...currentPosition,
                LatLng: currentPosition.LatLng.filter((_, i) => i !== key),
              })
            }
            icon={{
              url: delete_input,
              scaledSize: new google.maps.Size(25, 25),
              anchor: new google.maps.Point(12.5, 35),
            }}
          ></Marker>
        ))}

      {location && showLocation && (
        <Marker
          zIndex={1}
          position={new window.google.maps.LatLng(location.latitude, location.longitude)}
          icon={{
            url: map_gps_point,
            scaledSize: new google.maps.Size(20, 20),
          }}
        />
      )}

      {location && showLocation && zoom && zoom <= 10 && (
        <Marker
          zIndex={2}
          position={new window.google.maps.LatLng(location.latitude, location.longitude)}
          icon={{
            url: map_gps_pin,
            anchor: new google.maps.Point(20, 50),
            scaledSize: new google.maps.Size(40, 40),
          }}
        />
      )}
    </GoogleMap>
  )
}

export default React.memo(MapInput)
