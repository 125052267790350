import iso from 'iso-639-1'

import { getAutoCompleteOptions, optionsType } from '../Utils'

export type localizationsType = { language: string; value: string }

/**
 * Creates a Array of all languages as optionType.
 * @returns optionsType[] as { id: string; name: string; firstLetter?: string }[]
 */
export const getIsoOptions: () => optionsType[] = () => getAutoCompleteOptions(iso.getAllCodes())

/**
 * Convert newValue as { id: string; name: string; firstLetter?: string } and old Value as { language: string; value: string } to localizationsType[]
 * @param values Array of { language: string; value: string } or { id: string; name: string; firstLetter?: string }
 * @returns localizationsType[] as { language: string; value: string }[]
 */
export const convertIsoOptionsToValues: (
  values: (optionsType | localizationsType)[]
) => localizationsType[] = values =>
  values.map(newValue => {
    if ((newValue as optionsType)?.id) {
      return { language: (newValue as optionsType).id, value: '' }
    }

    if ((newValue as localizationsType)?.language) {
      return {
        language: (newValue as localizationsType).language,
        value: (newValue as localizationsType).value,
      }
    }

    return { language: '', value: '' }
  })
