import { Paper } from '@mui/material'
import React from 'react'
import { TextFieldProps, useTranslate } from 'react-admin'

import { ThemeProvider } from '../../../provider'
import {
  Header as GridHeader,
  GridHeaderItem,
  GridItem,
  DateHeader,
  CustomDateField,
} from './Styles'
import { ChangelogType, UPDATE, CREATE, DELETE } from './Types'

const getColor: (type: ChangelogType) => string = type => {
  switch (type) {
    case UPDATE:
      return ThemeProvider.palette.colorPalette.changelog.update
    case CREATE:
      return ThemeProvider.palette.colorPalette.changelog.create
    case DELETE:
      return ThemeProvider.palette.colorPalette.changelog.delete
  }
}

const Header: React.FC<TextFieldProps & { type: ChangelogType }> = props => {
  const translate = useTranslate()
  const { type } = props

  return (
    <Paper
      elevation={3}
      style={{ padding: '2rem', marginBottom: '2rem', backgroundColor: getColor(type) }}
    >
      <GridHeader container>
        <GridHeaderItem item>{type}</GridHeaderItem>
        <GridItem>
          <span> {translate('changelog.createdAt')}: </span>
          <CustomDateField source="createdAt" showTime />
        </GridItem>
        <GridItem item>
          <span> {translate('changelog.updatedAt')}: </span>
          <CustomDateField source="updatedAt" showTime />
        </GridItem>
        <GridItem item>
          <span> {translate('changelog.author')}: </span>
          <DateHeader source="author" />
        </GridItem>
      </GridHeader>
    </Paper>
  )
}

export default Header
