import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { TableHead, TableBody, Table, TableRow, TableCell, Button } from '@mui/material/'
import React from 'react'
import { TextFieldProps, useRecordContext, useTranslate } from 'react-admin'

import { admin } from '../../../../types'
import { DescriptionField as DF } from '../StationChargepointsStyles'

const DescriptionCell: React.FC<{
  min: number
  max: number
  description?: { [key: string]: string }
}> = ({ min, max, description }) => {
  return (
    <>
      {description &&
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        Object.entries(description)
          .slice(min, max)
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .map(([key, value]) => (
            <TableRow key={key}>
              <TableCell>{key}</TableCell>
              <DF>
                <abbr title={value}>{value}</abbr>
              </DF>
            </TableRow>
          ))}
    </>
  )
}

const DescriptionTable: React.FC<{
  description?: { [key: string]: string }
  expanded: boolean
  setExpanded: React.Dispatch<React.SetStateAction<boolean>>
}> = ({ description, expanded, setExpanded }) => {
  const translate = useTranslate()

  return (
    <Table>
      <TableHead>
        {description && (
          <TableRow>
            <TableCell style={{ fontWeight: 'bold' }}>{translate('tags.language')}</TableCell>
            <TableCell style={{ fontWeight: 'bold' }}>{translate('tags.value')}</TableCell>
          </TableRow>
        )}
      </TableHead>
      <TableBody>
        <DescriptionCell min={0} max={3} description={description} />
        {expanded && description && (
          <DescriptionCell
            min={3}
            max={Object.keys(description)?.length || 0}
            description={description}
          />
        )}
        {description && Object.keys(description)?.length > 3 && (
          <TableRow>
            <TableCell style={{ borderBottom: 'none' }}>
              <Button
                style={{ marginTop: '1rem' }}
                onClick={() => setExpanded(!expanded)}
                variant="contained"
                color="secondary"
              >
                {translate('tags.expand')}
                <ExpandMoreIcon
                  style={{
                    transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
                    color: 'white',
                  }}
                />
              </Button>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  )
}

const DescriptionField: React.FC<TextFieldProps> = props => {
  const [appExpanded, setAppExpanded] = React.useState(false)
  const [interalExpanded, setInternalExpanded] = React.useState(false)
  const record = useRecordContext()
  const translate = useTranslate()

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>
            {' '}
            {translate(`resources.${admin.station_chargepoints}.fields.serviceDescription`)}{' '}
          </TableCell>
          <TableCell>
            {' '}
            {translate(`resources.${admin.station_chargepoints}.fields.appDescription`)}{' '}
          </TableCell>
          <TableCell>
            {' '}
            {translate(`resources.${admin.station_chargepoints}.fields.internalComment`)}{' '}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow key="serviceDescription">
          <TableCell rowSpan={999}>
            {record?.serviceDescription && JSON.stringify(record?.serviceDescription)}
          </TableCell>
        </TableRow>
        <TableRow key="appDescription">
          <TableCell rowSpan={999}>
            <DescriptionTable
              description={record?.appDescription}
              expanded={appExpanded}
              setExpanded={setAppExpanded}
            />
          </TableCell>
        </TableRow>
        <TableRow key="internalComment">
          <TableCell rowSpan={1}>
            <DescriptionTable
              description={record?.internalComment}
              expanded={interalExpanded}
              setExpanded={setInternalExpanded}
            />
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}

export default DescriptionField
