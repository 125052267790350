import { Button } from '@mui/material'
import type { Chart, ChartOptions } from 'chart.js'
import {
  CategoryScale,
  Chart as ChartJS,
  Decimation,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  TimeScale,
  Tooltip,
} from 'chart.js'
import 'chartjs-adapter-date-fns'
import zoomPlugin from 'chartjs-plugin-zoom'
import React, { useEffect, useRef } from 'react'
import { Line } from 'react-chartjs-2'

import { ChartJSData, Scales } from '../../utils/types'

ChartJS.register(
  LineElement,
  CategoryScale, // x axis
  LinearScale, // y axis
  PointElement,
  TimeScale,
  Decimation,
  zoomPlugin,
  Tooltip,
  Legend
)

const initialChartOptions: ChartOptions<'line'> = {
  parsing: false,
  interaction: {
    mode: 'index',
    intersect: false,
  },
  plugins: {
    legend: {
      display: true,
      position: 'top',
    },
    tooltip: {
      enabled: true,
    },
    decimation: {
      enabled: true,
      algorithm: 'lttb',
      samples: 30,
      threshold: 50,
    },
    zoom: {
      zoom: {
        wheel: {
          enabled: true,
        },
        pinch: {
          enabled: true,
        },
        mode: 'x',
      },
    },
  },
}

export const getYScales = (data: ChartJSData): Scales =>
  data.datasets.reduce(
    (obj, item, i) => ({
      ...obj,
      ...({
        ['y' + i]: {
          type: 'linear',
          display: true,
          position: 'left',
          ticks: {
            color: item.borderColor,
          },
        },
      } as Scales),
    }),
    { x: { type: 'time' } } as Scales
  )

const setScales = (
  chartRef: React.MutableRefObject<Chart<'line'> | undefined>,
  data: ChartJSData
) => {
  if (!chartRef.current) return
  chartRef.current.options.scales = getYScales(data)
  chartRef.current.update()
}

const resetZoom = (chartRef: React.MutableRefObject<Chart<'line'> | undefined>) => {
  if (!chartRef.current) return
  chartRef.current.resetZoom()
  chartRef.current.update()
}

const Graph = ({ data }: { data: ChartJSData }): JSX.Element => {
  const chartRef = useRef<Chart<'line'>>()

  useEffect(() => {
    setScales(chartRef, data)
  }, [data])

  return (
    <>
      <Line ref={chartRef} options={initialChartOptions} data={data} />
      <Button onClick={() => resetZoom(chartRef)}>Reset Zoom</Button>
    </>
  )
}

export default Graph
