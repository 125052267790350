import * as React from 'react'
import {
  RaRecord,
  Show,
  SimpleShowLayout,
  ShowProps,
  TextFieldProps,
  useRecordContext,
  useGetOne,
  Loading,
} from 'react-admin'

import { admin } from '../../../types'
import { ChangelogType, CREATE, DELETE, UPDATE } from '../../shared/Station/Types'
import DraftsAction from '../../shared/Toolbar/DraftAction'
import StationChargegroupsDraftsUpdate from './StationChargegroupsDraftsUpdate'

const UpdateField: React.FC<TextFieldProps & { type: ChangelogType }> = props => {
  const record: RaRecord = useRecordContext(props)

  const {
    data: oldData,
    isLoading: oldLoading,
    // eslint-disable-next-line react-hooks/rules-of-hooks
  } = useGetOne(admin.station_chargegroups, { id: record.parentChargegroupId })

  if (oldLoading) {
    return <Loading />
  }

  if (!record?.delete && record?.parentChargegroupId) {
    return <StationChargegroupsDraftsUpdate oldData={oldData} {...props} />
  }

  return <>error</>
}

const DraftElement: React.FC<TextFieldProps> = props => {
  const record: RaRecord = useRecordContext(props)

  if (record?.delete && record?.parentChargegroupId) {
    return <StationChargegroupsDraftsUpdate type={DELETE} {...props} />
  }

  if (!record?.parentChargegroupId) {
    return <StationChargegroupsDraftsUpdate type={CREATE} {...props} />
  }

  if (!record?.delete && record?.parentChargegroupId) {
    return <UpdateField type={UPDATE} {...props} />
  }

  return <></>
}

const StationChargegroupsDraftsShow: React.FC<ShowProps> = props => {
  return (
    <Show {...props} actions={<DraftsAction approvepath={admin.station_chargegroup_drafts} />}>
      <SimpleShowLayout>
        <DraftElement />
      </SimpleShowLayout>
    </Show>
  )
}

export default StationChargegroupsDraftsShow
