import { Modal } from '@mui/material'
import ImageEditor from '@toast-ui/react-image-editor'
import React, { useEffect } from 'react'
import { InputProps, useTranslate, useCreate } from 'react-admin'
import { useFormContext } from 'react-hook-form'
import 'tui-color-picker/dist/tui-color-picker.css'
import 'tui-image-editor/dist/tui-image-editor.css'

import { admin } from '../../../../../types'
import { Header, CustomButton } from './ImageStyles'
import { dataURItoBlob, getImages } from './ImageUtils'

const ImageEdit: React.FC<
  InputProps & {
    image: string
    isOpen: boolean
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  }
> = ({ image, isOpen, setIsOpen }) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const editorRef = React.createRef<any>()
  const [create, { data, isLoading }] = useCreate()
  const { getValues, setValue } = useFormContext()
  const value = getValues()
  const translate = useTranslate()

  const handleSaveFile = () => {
    const blob = dataURItoBlob(editorRef.current.getInstance().toDataURL())
    create(admin.station_chargegroupsImages, {
      data: new File([blob], `edited-${new Date().toString()}` + image, {
        type: 'image/jpeg',
        lastModified: new Date().getTime(),
      }),
    })
    setIsOpen(false)
  }

  useEffect(() => {
    if (data) {
      setValue(
        'internalImages',
        getImages(value.internalImages, image, {
          id: data.url,
          createdAt: new Date().toISOString(),
        })
      )
      setValue(
        'images',
        getImages(value.images, image, {
          id: data.url,
          createdAt: new Date().toISOString(),
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, data])

  return (
    <Modal
      style={{ width: '100%', height: '100%' }}
      open={isOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <>
        <Header>
          <CustomButton onClick={handleSaveFile}> {translate('ra.action.save')} </CustomButton>
          <CustomButton onClick={() => setIsOpen(false)}>
            {' '}
            {translate('ra.action.close')}{' '}
          </CustomButton>
        </Header>
        <ImageEditor
          ref={editorRef}
          includeUI={{
            loadImage: {
              path: `${image}?date=${new Date().toDateString()}`,
              name: image,
            },
            theme: {
              'header.display': 'none',
            },
            uiSize: {
              width: '100%',
              height: '90%',
            },
            menuBarPosition: 'bottom',
          }}
          usageStatistics={false}
        />
      </>
    </Modal>
  )
}

export default ImageEdit
