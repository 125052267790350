import { msPerStep } from '../CustomDataDisplay/MapConstants'

const moveCar = (
  path: { lat: number; lng: number; timestamp: number; distance: number }[],
  refs: {
    lineIndexRef: React.MutableRefObject<number>
    intervalRef: React.MutableRefObject<number>
    timeMultiplierRef: React.MutableRefObject<number>
    currentLineProgressRef: React.MutableRefObject<number>
  },
  setState: (
    value: React.SetStateAction<{
      isStarted: boolean
      interval: number
      overAllProgress: number
      currentLineProgress: number
      lineIndex: number
      timeMultiplier: number
    }>
  ) => void,
  setMarkerPosition: (
    value: React.SetStateAction<{
      lat: number
      lng: number
    }>
  ) => void
): void => {
  // when the ride is over
  if (refs.lineIndexRef.current + 1 >= path.length) {
    window.clearInterval(refs.intervalRef.current)
    setState(prevState => ({
      ...prevState,
      lineIndex: 0,
      currentLineProgress: 0,
      isStarted: false,
    }))
    setMarkerPosition({ lat: path[0].lat, lng: path[0].lng })
  }
  // if not paused
  else if (refs.timeMultiplierRef.current !== 0) {
    const msToTravel =
      path[refs.lineIndexRef.current + 1].timestamp - path[refs.lineIndexRef.current].timestamp

    const from = new google.maps.LatLng({
      lat: path[refs.lineIndexRef.current].lat,
      lng: path[refs.lineIndexRef.current].lng,
    })
    const to = new google.maps.LatLng({
      lat: path[refs.lineIndexRef.current + 1].lat,
      lng: path[refs.lineIndexRef.current + 1].lng,
    })

    const stepSize = (100 / ((msToTravel * 1000) / msPerStep)) * refs.timeMultiplierRef.current

    const markerPos = google.maps.geometry.spherical.interpolate(
      from,
      to,
      refs.currentLineProgressRef.current / 100
    )
    if (refs.currentLineProgressRef.current + stepSize > 100) {
      setState(prevState => ({
        ...prevState,
        lineIndex: prevState.lineIndex + 1,
        currentLineProgress: 0,
      }))
    } else {
      setState(prevState => ({
        ...prevState,
        currentLineProgress: prevState.currentLineProgress + stepSize,
      }))
    }
    setMarkerPosition({ lat: markerPos.lat(), lng: markerPos.lng() })
  } else {
    setMarkerPosition({
      lat: path[refs.lineIndexRef.current].lat,
      lng: path[refs.lineIndexRef.current].lng,
    })
  }
}

export default moveCar
