import iso from 'iso-639-1'

import { admin } from '../../../types'
import { OptionsType } from '../Types/options'

export type LocalizationsType = {
  language: string
  value: string
  firstLetter: string
}

export type OldLocalizationsType = {
  language: string
  value: string
}

export const markdownoptions = {
  previewStyle: 'tab',
  height: '43.75rem',
  width: '100%',
  initialEditType: 'markdown',
  useCommandShortcut: false,
  previewHighlight: true,
  hideModeSwitch: true,
  toolbarItems: [
    ['heading', 'bold', 'italic', 'strike'],
    ['hr', 'quote'],
    ['ul', 'ol', 'task', 'indent', 'outdent'],
    ['table', 'link'],
    ['code', 'codeblock'],
  ],
}

export const getLocalizations: (
  options: string[],
  oldValues: OldLocalizationsType[]
) => LocalizationsType[] = (options, oldValues) => {
  const languages =
    oldValues?.reduce(
      (pre: { [key: string]: string }, cur: OldLocalizationsType) => ({
        ...pre,
        [cur.language]: cur.value,
      }),
      {} as { [key: string]: string }
    ) || []

  return options
    .reduce((pre: LocalizationsType[], cur: string) => {
      const firstLetter = cur[0].toUpperCase()
      pre.push({
        language: cur.toLowerCase(),
        value: languages[cur] || '',
        firstLetter: /d/.test(firstLetter) ? '0-9' : firstLetter,
      })
      return pre
    }, [] as LocalizationsType[])
    .sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))
}

export const languageValidation: (
  message?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
) => (allValues: OldLocalizationsType[]) => any | undefined =
  (message = `resources.${admin.documents}.tags.validationError`) =>
  allValues => {
    if (
      allValues?.find(
        (localization: OldLocalizationsType) => localization.language.toLowerCase() === 'de'
      )
    ) {
      return undefined
    }

    return allValues?.map(() => ({
      language: '',
      value: message,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    })) as OldLocalizationsType[] as any
  }

/**
 * Creates a Array of languages
 * @param value localizationsType[] as { language: string; value: string }[]
 * @returns string[]
 */
export const getCurrentLanguagesArray: (value: OldLocalizationsType[]) => string[] = value =>
  (value &&
    value?.reduce((pre: string[], cur: { language: string; value: string }) => {
      pre.push(cur.language)
      return pre
    }, [] as string[])) ||
  []

export const getIsoOptions: (
  oldValues: OldLocalizationsType[]
) => LocalizationsType[] = oldValues => getLocalizations(iso.getAllCodes(), oldValues)

export const getLanguagesOptions: (options: string[]) => OptionsType[] = options =>
  options
    .reduce((pre: OptionsType[], cur: string) => {
      const firstLetter = cur[0].toUpperCase()
      pre.push({
        id: cur.toLowerCase(),
        name: cur.toLowerCase(),
        firstLetter: /d/.test(firstLetter) ? '0-9' : firstLetter,
      })
      return pre
    }, [] as OptionsType[])
    .sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))
