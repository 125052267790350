import React from 'react'
import { Create, CreateProps, RaRecord, useNotify, useRefresh, useRedirect } from 'react-admin'

import { admin } from '../../../types'
import { OperatorsForm } from './OperatorsForm'

const OperatorsCreate: React.FC<CreateProps> = props => {
  const notify = useNotify()
  const refresh = useRefresh()
  const redirect = useRedirect()

  const onSuccess = (data: RaRecord) => {
    notify('ra.action.update', { type: 'info' })
    redirect(`/${admin.operators}`)
    refresh()
  }

  return (
    <Create {...props} mutationOptions={{ onSuccess }}>
      <OperatorsForm />
    </Create>
  )
}

export default OperatorsCreate
