import { Paper, Typography, styled } from '@mui/material'

export const ChangeLogPaper = styled(Paper)(({ theme }) => ({
  boxShadow: `0 1px 1px 3px ${theme.palette.grey.A100}`,
  borderRadius: theme.spacing(2),
  margin: theme.spacing(2),
  padding: theme.spacing(2),
}))

export const ChangeLogHeader = styled(Typography)(({ theme }) => ({
  textShadow: `1px 1px 2px ${theme.palette.common.white}`,
  fontWeight: 'bold',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  marginBottom: theme.spacing(2),
}))

export const ChangeLogItemPaper = styled(Paper)(({ theme }) => ({
  boxShadow: `0px 1px 1px 2px ${theme.palette.grey[50]}`,
  borderRadius: theme.spacing(0.5),
  backgroundColor: theme.palette.grey[50],
}))
