import React from 'react'
import { TabbedFormProps, TabbedForm, FormTab, useRecordContext } from 'react-admin'

import useBreadCrumps from '../../../hooks/useBreadCrumps'
import { admin } from '../../../types'
import TariffsInputs from './TariffsInputs'

const TariffsForm: React.FC<Omit<TabbedFormProps, 'children'>> = props => {
  const record = useRecordContext()

  const { breadCrumps } = useBreadCrumps()
  const id = breadCrumps && breadCrumps[admin.empnames]

  return (
    <TabbedForm
      {...props}
      defaultValues={{
        useAccessMethod: record?.accessMethod || false,
        accessMethod: record?.accessMethod || null,
        empNameId: record?.empNameId || (!record?.accessMethod && id) || null,
        acPricing: record?.pricings?.filter((pricing: { ac: boolean }) => pricing?.ac),
        dcPricing: record?.pricings?.filter((pricing: { ac: boolean }) => !pricing?.ac),
      }}
    >
      {Object.keys(TariffsInputs).map(tab => (
        <FormTab label={tab} key={tab}>
          {Object.values(TariffsInputs[tab])}
        </FormTab>
      ))}
    </TabbedForm>
  )
}

export default TariffsForm
