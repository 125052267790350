import { Polyline, AddLocation } from '@mui/icons-material'
import { Switch } from '@mui/material'
import isEmpty from 'lodash/isEmpty'
import React, { useState } from 'react'
import { InputProps, useTranslate } from 'react-admin'
import GooglePlacesAutocomplete, {
  getLatLng,
  geocodeByPlaceId,
} from 'react-google-places-autocomplete'
import { useFormContext, useWatch } from 'react-hook-form'

import map_approach from '../../../../../assets/Icons/map_approach.svg'
import map_gps_pin from '../../../../../assets/Icons/map_gps_pin.svg'
import map_gps_point from '../../../../../assets/Icons/map_gps_point.svg'
import map_search_result_pin from '../../../../../assets/Icons/map_search_result_pin.svg'
import systemConfig from '../../../../../config'
import { SentryLoggerProvider } from '../../../../../provider'
import { admin } from '../../../../../types'
import ResetButton from './Buttons/ResetButton'
import ReturnButton from './Buttons/ReturnButton'
import ShowAddressButton from './Buttons/SaveAddressButton'
import SavePositionButton from './Buttons/SavePositionButton'
import { location, address, approach, drag, drop, latlng } from './MapConstants'
import MapDisplay from './MapDisplay'
import MapInput from './MapInput'
import {
  CustomChangeHistory,
  Search,
  SearchIcon,
  SearchButtonWrapper,
  SearchSwitch,
  SearchSwitchLabel,
  SearchSwitchSection,
  SearchSwitchWrapper,
} from './MapStyles'
import { PinProps, LatLngProps, ShowMarkerProps, MapInputProps } from './MapTypes'
import {
  basePolygon,
  basePosition,
  calculateCenter,
  checkedPointPolygon,
  getValidData,
} from './MapUtils'

const MapForm: React.FC<InputProps> = props => {
  const { getValues, setValue } = useFormContext()
  useWatch({ name: 'approachLatitude' })
  useWatch({ name: 'approachLongitude' })
  const value = getValues()

  const validLocation = getValidData(value, location)
  const validApproach = getValidData(value, approach)
  const validAddress = getValidData(value, address)
  const validLatLng = getValidData(value, latlng)

  const [showMarker, setMarker] = useState<ShowMarkerProps>({
    pointPolygon: checkedPointPolygon(value),
    approach: false,
  })

  const [currentPosition, setCurrentPosition] = useState<PinProps>({
    type: validLocation ? drop : drag,
    LatLng: basePosition(value),
    approach: validApproach || null,
    address: validAddress || null,
  })

  const [centerPin, setCenterPin] = useState<google.maps.LatLng>(
    new google.maps.LatLng(calculateCenter(value))
  )

  const [input, setInput] = useState('')
  const [inputSave, setSave] = useState({
    save: '',
    place_id: '',
  })

  const translate = useTranslate()

  const MapFormProps: MapInputProps = {
    ...props,
    currentPosition: currentPosition,
    setCurrentPosition: setCurrentPosition,
    centerPin: centerPin,
    setCenterPin: setCenterPin,
    setMarker: setMarker,
    showMarker: showMarker,
    inputSave: inputSave,
    setSave: setSave,
    setInput: setInput,
  }

  return (
    <>
      <SearchSwitchSection>
        <SearchSwitchWrapper>
          <SearchIcon src={map_search_result_pin} alt="" />
          <SearchSwitchLabel variant="h6" style={{ marginRight: '1rem' }}>
            {translate(`resources.${admin.station_chargegroups}.tags.point`)}
          </SearchSwitchLabel>
          <SearchSwitch
            checkedIcon={<Polyline />}
            icon={<AddLocation />}
            onClick={() => {
              setMarker({ pointPolygon: !showMarker.pointPolygon, approach: false })

              let LatLng: LatLngProps[] = []

              if (!showMarker.pointPolygon) {
                LatLng = [
                  {
                    lat: parseFloat(centerPin.lat().toFixed(6)),
                    lng: parseFloat(centerPin.lng().toFixed(6)),
                  },
                ]
              } else {
                LatLng = basePolygon(centerPin)
              }

              setCurrentPosition({
                ...currentPosition,
                type: drag,
                LatLng: LatLng,
                address: null,
              })
            }}
            checked={!showMarker.pointPolygon}
            value={showMarker.pointPolygon}
          />
          <CustomChangeHistory style={{ marginLeft: '1rem' }} />
          <SearchSwitchLabel variant="h6">
            {translate(`resources.${admin.station_chargegroups}.tags.polygon`)}
          </SearchSwitchLabel>
        </SearchSwitchWrapper>
        {((validLatLng && showMarker.pointPolygon) ||
          (validLocation && validLocation?.length >= 3 && !showMarker.pointPolygon)) && (
          <SearchSwitchWrapper>
            <Switch
              onClick={() => {
                if (showMarker.approach) {
                  setValue('approachLatitude', null)
                  setValue('approachLongitude', null)
                }
                setMarker({ ...showMarker, approach: !showMarker.approach })
              }}
              checked={showMarker.approach}
              value={showMarker.approach}
            />
            <SearchIcon src={map_approach} alt="" />
            <SearchSwitchLabel variant="h6">
              {translate(`resources.${admin.station_chargegroups}.tags.approach`)}
            </SearchSwitchLabel>
          </SearchSwitchWrapper>
        )}
        <SearchSwitchWrapper>
          <Switch
            onClick={() => setMarker({ ...showMarker, showLocation: !showMarker.showLocation })}
            checked={showMarker.showLocation ? showMarker.showLocation : false}
            value={showMarker.showLocation}
          />
          <SearchIcon src={map_gps_point} alt="" />
          <SearchIcon src={map_gps_pin} alt="" />
          <SearchSwitchLabel variant="h6">
            {translate(`resources.${admin.station_chargegroups}.tags.location`)}
          </SearchSwitchLabel>
        </SearchSwitchWrapper>
      </SearchSwitchSection>

      <MapInput source="" {...MapFormProps} />

      <Search>
        <GooglePlacesAutocomplete
          apiKey={systemConfig.googleMapsApiKey}
          selectProps={{
            styles: {
              menu: (provided: Record<string, unknown>) => ({ ...provided, zIndex: 2 }),
            },
            value: '',
            escapeClearsValue: true,
            inputValue: input,
            placeholder: isEmpty(inputSave.save)
              ? translate(`resources.${admin.station_chargegroups}.tags.addressSearch`)
              : inputSave.save,
            onFocus: () => {
              setInput(inputSave.save)
              setSave({
                ...inputSave,
                save: '',
              })
            },
            onInputChange: setInput,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onChange: (result: any) => {
              if (result) {
                setMarker({ ...showMarker, approach: false })
                geocodeByPlaceId(result.value.place_id)
                  .then(results => {
                    return getLatLng(results[0])
                  })
                  .then(res => {
                    result.label &&
                      setSave({
                        save: result.label,
                        place_id: result.value.place_id,
                      })
                    setCurrentPosition({
                      type: address,
                      LatLng: showMarker.pointPolygon
                        ? [
                            {
                              lat: parseFloat(res.lat.toFixed(6)),
                              lng: parseFloat(res.lng.toFixed(6)),
                            },
                          ]
                        : basePolygon(new google.maps.LatLng(res)),
                      approach: currentPosition.approach,
                      address: result.label,
                    })
                    return res
                  })
                  .catch(err => {
                    console.error(err)
                    SentryLoggerProvider.error(err)
                  })
              }
            },
          }}
        />
      </Search>
      <SearchButtonWrapper>
        <SavePositionButton source="" {...MapFormProps} />
        <ShowAddressButton source="" {...MapFormProps} />
        <ReturnButton source="" {...MapFormProps} />
        <ResetButton source="" {...MapFormProps} />
      </SearchButtonWrapper>
      {((validLatLng && showMarker.pointPolygon) ||
        (validLocation?.length >= 3 && !showMarker.pointPolygon)) && (
        <MapDisplay source="" {...MapFormProps} />
      )}
    </>
  )
}

export default React.memo(MapForm)
