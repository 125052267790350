import {
  Edit,
  EditProps,
  TabbedForm,
  FormTab,
  useNotify,
  useRefresh,
  useRedirect,
  RaRecord,
  useUpdate,
  TabbedFormProps,
  SaveButton,
  Toolbar,
  useRecordContext,
} from 'react-admin'

import { admin } from '../../../types'
import VehicleInput from './VehicleInput'

const VehicleForm: React.FC<Omit<TabbedFormProps, 'children'>> = props => {
  const record = useRecordContext()

  return (
    <TabbedForm
      {...props}
      defaultValues={{
        vehicleVariantIds: record?.vehicleVariant?.id ? [record.vehicleVariant.id] : [],
        permissions: {
          complete: false,
          anonymous: false,
        },
      }}
    >
      {Object.keys(VehicleInput).map((section, index) => (
        <FormTab key={`${section} ${index}`} label={section}>
          {Object.values(VehicleInput[section])}
        </FormTab>
      ))}
    </TabbedForm>
  )
}

const VehicleEdit: React.FC<EditProps> = props => {
  const notify = useNotify()
  const refresh = useRefresh()
  const redirect = useRedirect()
  const [update, { error }] = useUpdate()

  const onSuccess = (data: RaRecord) => {
    const { state, id } = data

    update(admin.virtualVehicles, { id: `${id}/status`, data: { ...state } })

    if (!error) {
      notify(`ra.notification.updated`, { type: 'info', messageArgs: { smart_count: 1 } })
      redirect(`/${admin.virtualVehicles}`)
      refresh()
    } else {
      notify(`ra.notification.error`, { type: 'info' })
    }
  }

  return (
    <Edit {...props} mutationMode="pessimistic" mutationOptions={{ onSuccess: onSuccess }}>
      <VehicleForm
        toolbar={
          <Toolbar>
            {' '}
            <SaveButton />{' '}
          </Toolbar>
        }
      />
    </Edit>
  )
}

export default VehicleEdit
