import { useCanAccess } from '@eclever/ra-enterprise/ra-rbac'
import React from 'react'
import {
  Datagrid,
  ListProps,
  NumberInput,
  ReferenceInput,
  AutocompleteInput,
  SelectInput,
  EditButton,
  sanitizeListRestProps,
  useListController,
} from 'react-admin'

import useBreadCrumps from '../../../hooks/useBreadCrumps'
import useFilters from '../../../hooks/useFilters'
import { admin } from '../../../types'
import BreadCrumbs from '../../shared/Components/BreadCrumbs'
import ColumnsFields from '../../shared/Components/ColumnsFields'
import FilterInputWrapper from '../../shared/Components/FilterInputWrapper'
import FilterWrapper from '../../shared/Components/FilterWrapper'
import Pagination from '../../shared/Components/Pagination'
import useSelectColumns from '../../shared/Components/SelectColumns/useSelectColumns'
import StickyColumnWrapper from '../../shared/Components/StickyColumnWrapper'
import List from '../../shared/Components/StyledComponents/List'
import SelectColumnsToolbar from '../../shared/Toolbar/SelectColumnsToolbar'
import VehicleVariantFields from './VehicleVariantFields'
import VehicleVariantInputs from './VehicleVariantInputs'

const variantInputFields = VehicleVariantInputs

const vehicleVariantsFilters = [
  <FilterInputWrapper source="id" validElement="number">
    <NumberInput source="id" />
  </FilterInputWrapper>,
  <FilterWrapper reference={admin.vehicle_designs} source="vehicleDesignId" alwaysOn>
    <NumberInput source="vehicleDesignId" disabled />
  </FilterWrapper>,
  <ReferenceInput source="chargingCurveGroupId" reference={admin.charging_curve_groups}>
    <SelectInput optionText="id" />
  </ReferenceInput>,
  <ReferenceInput source="consumptionCurveGroupId" reference={admin.consumption_curve_groups}>
    <SelectInput optionText="id" />
  </ReferenceInput>,
  <NumberInput source="listPrice" />,
  <ReferenceInput source="categoryType" reference="admin/enums/categoryType">
    <SelectInput optionText="id" />
  </ReferenceInput>,
  <ReferenceInput source="general3dDesign" reference="admin/enums/general3dDesign">
    <SelectInput optionText="id" />
  </ReferenceInput>,
  <ReferenceInput source="plugType" reference="admin/enums/plugType" alwaysOn fullWidth>
    <AutocompleteInput optionText="id" fullWidth />
  </ReferenceInput>,
  <NumberInput min={0} source="kw" />,
  <NumberInput min={0} source="ampere" />,
  <NumberInput min={0} source="phase" />,
  <NumberInput min={0} source="onlyCP1PhaseAmpere" />,
  <NumberInput min={0} source="maxDefaultSoc" />,
  <NumberInput min={0} source="efficiencyCharging" />,
  <ReferenceInput source="position" reference="admin/enums/position" fullWidth>
    <AutocompleteInput optionText="id" fullWidth />
  </ReferenceInput>,
  ...Object.values(variantInputFields['additional']),
  ...Object.values(variantInputFields['engine&Power']),
]

const columnsFields = ColumnsFields(VehicleVariantFields)

const VehicleVariantList: React.FC<ListProps> = props => {
  const { resource } = useListController()
  const { canAccess } = useCanAccess({
    action: 'edit',
    resource: resource,
  })
  const { breadCrumps } = useBreadCrumps()
  const { filter } = useFilters()
  const columns = useSelectColumns('vehicleVariant.list.columns', columnsFields)
  const id = breadCrumps[admin.vehicle_designs] || 0

  if (!id || !resource) {
    return <>ERROR</>
  }

  return (
    <>
      <BreadCrumbs resource={resource} />
      <List
        {...sanitizeListRestProps(props)}
        filter={filter[resource]}
        pagination={<Pagination />}
        filters={vehicleVariantsFilters}
        actions={
          <SelectColumnsToolbar
            reference={admin.vehicle_designs}
            preference="vehicleVariant.list.columns"
            columns={columnsFields}
          />
        }
        sx={{
          "& div[class*='RaListToolbar-toolbar']": {
            display: 'flex',
            justifyContent: 'flex-end',
            flexDirection: 'column-reverse',
          },
        }}
        empty={false}
      >
        <Datagrid isRowSelectable={() => canAccess}>
          {columns}
          <StickyColumnWrapper {...props}>{canAccess && <EditButton />}</StickyColumnWrapper>
        </Datagrid>
      </List>
    </>
  )
}

export default VehicleVariantList
