import { isEqual } from 'lodash'
import { RaRecord } from 'react-admin'

import {
  openingTimes,
  parkingTimes,
  parking,
  price,
  turnoffInformation,
  description,
} from './StationChargepointsDraftsConstants'

export const diffs: (
  oldData: RaRecord | undefined,
  newData: RaRecord | undefined,
  names: string[]
) => string[] = (oldData, newData, names) =>
  names?.reduce((pre: string[], cur: string) => {
    if (
      oldData &&
      cur in oldData &&
      newData &&
      cur in newData &&
      isEqual(oldData[cur], newData[cur])
    ) {
      pre.push(cur)
    }
    return pre
  }, [] as string[])

export const equalDiff: (
  oldData: RaRecord | undefined,
  newData: RaRecord | undefined,
  source: string
) => string[] = (oldData, newData, source) => {
  switch (source) {
    case openingTimes:
      return diffs(oldData, newData, ['openFullTime', 'openingHours', 'leaveAnytime'])
    case parkingTimes:
      return diffs(oldData, newData, ['parkingDuration', 'parkingHours'])
    case parking:
      return diffs(oldData, newData, [
        'parkingFree',
        'parkingEvOnly',
        'parkingBarrierFree',
        'parkingTrailer',
        'parkingDifficult',
      ])
    case price:
      return diffs(oldData, newData, [
        'pricingFree',
        'payingOnSite',
        'startingPrice',
        'consumptionPriceKwh',
        'consumptionPriceTime',
        'consumptionPriceTimePer',
      ])
    case turnoffInformation:
      return diffs(oldData, newData, ['turnoffTime', 'turnoffEnergy', 'turnoffPercentage'])
    case description:
      return diffs(oldData, newData, [
        'serviceDescription',
        'appDescription',
        'disorderDescription',
      ])
    case 'chargegroupId':
      return []
    default:
      return diffs(oldData, newData, [source])
  }
}
