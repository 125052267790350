import React from 'react'
import { useTranslate, InputProps } from 'react-admin'
import { useFormContext } from 'react-hook-form'

import { ThemeProvider } from '../../../../../../provider'
import { admin } from '../../../../../../types'
import { approach, location, address, drop, latlng } from '../MapConstants'
import { SearchButton } from '../MapStyles'
import { MapInputProps } from '../MapTypes'
import { castLatLng, getValidData } from '../MapUtils'

const ReturnButton: React.FC<InputProps & MapInputProps> = ({
  currentPosition,
  setCurrentPosition,
  showMarker,
}) => {
  const { getValues, setValue } = useFormContext()
  const value = getValues()

  const translate = useTranslate()

  const validLocation = getValidData(value, location)
  const validLatLng = getValidData(value, latlng)
  const validApproach = getValidData(value, approach)
  const validAddress = getValidData(value, address)

  const checkUsedOnce = () => {
    if (showMarker.approach) {
      return validApproach
        ? { backgroundColor: ThemeProvider.palette.secondary.main }
        : { backgroundColor: ThemeProvider.palette.colorPalette.changelog.update }
    }

    return (validLatLng && showMarker.pointPolygon) ||
      (validLocation?.length >= 3 && !showMarker.pointPolygon)
      ? { backgroundColor: ThemeProvider.palette.secondary.main }
      : { backgroundColor: ThemeProvider.palette.colorPalette.changelog.update }
  }

  const onClick = () => {
    if (
      !showMarker.approach &&
      ((validLatLng && showMarker.pointPolygon) ||
        (validLocation?.length >= 3 && !showMarker.pointPolygon))
    ) {
      const LatLng = showMarker.pointPolygon ? [validLatLng] : validLocation

      setValue('boundaries', validLocation ? castLatLng(validLocation) : null)
      setValue('addressFormatted', validAddress)

      setCurrentPosition({
        ...currentPosition,
        type: drop,
        LatLng: LatLng,
        address: validAddress ? validAddress : null,
      })
    }

    if (showMarker.approach && validApproach) {
      setValue('approachLatitude', validApproach.lat)
      setValue('approachLongitude', validApproach.lng)

      setCurrentPosition({
        ...currentPosition,
        type: approach,
        approach: {
          lat: validApproach.lat,
          lng: validApproach.lng,
        },
      })
    }
  }

  return (
    <SearchButton sx={{ ...checkUsedOnce() }} onClick={onClick}>
      <>{translate(`resources.${admin.station_chargegroups}.tags.return`)}</>
    </SearchButton>
  )
}

export default ReturnButton
