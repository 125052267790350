import React from 'react'
import {
  Edit,
  EditProps,
  useGetList,
  useNotify,
  useRefresh,
  useRedirect,
  useDataProvider,
  useEditController,
  Loading,
} from 'react-admin'
import { useMutation } from 'react-query'
import { useParams } from 'react-router-dom'

import { admin } from '../../../types'
import Timer from '../../shared/Components/TimerField/Timer'
import StationChargegroupsForm from './StationChargegroupsForm'
import { transform } from './StationChargegroupsUtils'

const StationChargegroupsEdit: React.FC<EditProps> = props => {
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const refresh = useRefresh()
  const redirect = useRedirect()
  const { resource, isLoading } = useEditController()
  const { id } = useParams()
  const {
    data: userData,
    isLoading: userLoading,
    error: userError,
  } = useGetList(admin.usersMe, { pagination: { page: 1, perPage: -1 } })
  const { mutate: setUnlock } = useMutation(
    [
      resource,
      'unlock',
      { id: id, resource: resource, recordId: id, identity: userData && userData[0]?.id },
    ],
    () =>
      dataProvider.unlock(resource, {
        id: id,
        resource: resource,
        recordId: id,
        identity: userData && userData[0]?.id,
      })
  )

  if (userLoading || isLoading) {
    return <Loading />
  }

  if (userError || !userData || !resource || !id) {
    return <>ERROR</>
  }

  const onSuccess = () => {
    setUnlock()
    notify('ra.action.save')
    redirect(`/${admin.station_chargegroups}`)
    refresh()
  }

  return (
    <>
      {!userLoading && resource === admin.station_chargegroups && (
        <Timer
          station="chargegroup"
          identity={userData[0].id as string}
          resource={resource}
          id={id}
        />
      )}
      <Edit
        {...props}
        mutationMode="pessimistic"
        mutationOptions={{ onSuccess }}
        transform={transform}
      >
        <StationChargegroupsForm resource={resource} />
      </Edit>
    </>
  )
}

export default StationChargegroupsEdit
