import { TableCell } from '@mui/material'
import { isEmpty } from 'lodash'
import * as React from 'react'
import { TextFieldProps, RaRecord } from 'react-admin'

import { CustomRow } from '../StationChargepointsDrafts/StationChargepointsDraftsStyles'
import { default as ChargegroupPropertyField } from './CustomFields/PropertiesField'
import { diffs as equalDiff } from './StationChargegroupsDraftsUtils'

const StationChargegroupsDraftsRow: React.FC<
  TextFieldProps & {
    name: string
    oldData?: RaRecord
    newData: RaRecord | undefined
  }
> = ({ name, oldData, newData, ...props }) => {
  const diffs = oldData && equalDiff(oldData, newData, [name])

  switch (name) {
    case 'approachPosition':
      if (equalDiff(oldData, newData, ['approachLatitude', 'approachLongitude'])?.length !== 0) {
        return <></>
      }
      break
    case 'map':
    case 'boundaries':
      if (equalDiff(oldData, newData, ['latitude', 'longitude'])?.length !== 0) {
        return <></>
      }
      break
    default:
      if (
        ['locked', 'lockUserId', 'lockedAt'].includes(name) ||
        // (oldData && isEmpty(oldData[name]) && !oldData[name]) ||
        // (newData && isEmpty(newData[name]) && !newData[name]) ||
        (diffs && diffs.length !== 0)
      ) {
        return <></>
      }
      break
  }

  return (
    <CustomRow key={name}>
      <TableCell width="15%" component="th" scope="row" style={{ fontWeight: 'bold' }}>
        {name}
      </TableCell>
      {!isEmpty(oldData) && (
        <TableCell component="th" scope="row">
          <ChargegroupPropertyField {...props} source={name} data={oldData} />
        </TableCell>
      )}
      <TableCell component="th" scope="row" align={oldData ? 'left' : 'center'}>
        <ChargegroupPropertyField {...props} source={name} data={newData} diffs={diffs} />
      </TableCell>
    </CustomRow>
  )
}

export default StationChargegroupsDraftsRow
