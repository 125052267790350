import { Chip } from '@mui/material'
import React from 'react'
import {
  Datagrid,
  ListProps,
  EmailField,
  TextField,
  BooleanField,
  NumberField,
  TextInput,
  SelectArrayInput,
  TextFieldProps,
  useRecordContext,
  RaRecord,
  ReferenceArrayInput,
  EditButton,
  EditButtonProps,
  sanitizeListRestProps,
} from 'react-admin'

import usePermissions from '../../hooks/usePermissions'
import { admin } from '../../types'
import Pagination from '../shared/Components/Pagination'
import StickyColumnWrapper from '../shared/Components/StickyColumnWrapper'
import List from '../shared/Components/StyledComponents/List'
import Toolbar from '../shared/Toolbar/Toolbar'
import { checkPermission } from './UsersUtils'

const UsersFilter = [
  <TextInput source="mail" alwaysOn />,
  <ReferenceArrayInput
    source="roles"
    reference="admin/enums/userRole"
    perPage={-1}
    fullWidth
    alwaysOn
  >
    <SelectArrayInput optionText="id" parse={(event: string[]) => [...event].sort()} />
  </ReferenceArrayInput>,
]

const RoleField: React.FC<TextFieldProps> = props => {
  const record: RaRecord = useRecordContext(props) || {}

  return (
    record?.roles?.map((role: string) => (
      <Chip style={{ margin: '0.5rem' }} key={role} label={role} />
    )) || <></>
  )
}

const EditField: React.FC<EditButtonProps & { currentRole: string[] }> = ({
  currentRole,
  ...props
}) => {
  const record: RaRecord = useRecordContext(props)

  return (
    <StickyColumnWrapper {...props}>
      {checkPermission(currentRole, record) && <EditButton {...props} />}
    </StickyColumnWrapper>
  )
}

const UsersList: React.FC<ListProps> = props => {
  const { getPermissions } = usePermissions()
  const role = getPermissions()

  if (role) {
    return (
      <>
        <List
          {...sanitizeListRestProps(props)}
          pagination={<Pagination />}
          filters={UsersFilter}
          actions={<Toolbar reference={admin.users} resource={admin.users} />}
          empty={false}
          bulkActionButtons={false}
        >
          <Datagrid>
            <NumberField source="id" />
            <EmailField source="mail" />
            <RoleField source="roles" />
            <TextField source="lastSeenAt" />
            <TextField source="createdAt" />
            <BooleanField source="confirmed" />
            <EditField currentRole={role} />
          </Datagrid>
        </List>
      </>
    )
  }

  return <></>
}

export default UsersList
