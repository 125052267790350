import React from 'react'
import { TabbedFormProps, TabbedForm, FormTab } from 'react-admin'

import useBreadCrumps from '../../../hooks/useBreadCrumps'
import { admin } from '../../../types'
import EmpNamesInputs from './EmpNamesInputs'

const EmpNamesForm: React.FC<Omit<TabbedFormProps, 'children'>> = props => {
  const { breadCrumps } = useBreadCrumps()
  const id = breadCrumps && breadCrumps[admin.emps]

  return (
    <TabbedForm
      defaultValues={{
        empId: id || 0,
        nameAppStore: '',
        linkWebApp: '',
        companyName: '',
        street: '',
        number: '',
        postcode: '',
        city: '',
        country: '',
        disorderHotline: '',
        hotline: '',
        mail: '',
      }}
      {...props}
    >
      {Object.keys(EmpNamesInputs).map(tab => (
        <FormTab label={tab} key={tab}>
          {Object.values(EmpNamesInputs[tab])}
        </FormTab>
      ))}
    </TabbedForm>
  )
}

export default EmpNamesForm
