import { captureException, init, showReportDialog, withScope } from '@sentry/react'

import systemConfig from '../config'

const SentryLoggerProvider = {
  initializeTracker: (
    sentryDSN = systemConfig.sentryDSN,
    appversion = systemConfig.webAppVersion,
    environment = systemConfig.environment
  ): void => {
    if (systemConfig.isProd) {
      init({
        dsn: sentryDSN,
        release: appversion,
        environment: environment,
        maxBreadcrumbs: 20,
        ignoreErrors: ['Non-Error promise rejection captured with value: Permissions Error'],
      })
      console.info('Sentry ready')
    }
  },
  error: (error: Error, info?: Record<string, unknown>): void => {
    if (systemConfig.isProd) {
      withScope(scope => {
        try {
          if (info) {
            scope.setExtras(info)
          }
        } catch (e) {
          captureException(e)
        }
        captureException(error)
      })
    } else if (systemConfig.isDev) {
      console.error('Error', error)
    }
  },
  log: (message: string, ...optionalParams: unknown[]): void => {
    console.log(message, optionalParams)
  },
  info: (message: string, ...optionalParams: unknown[]): void => {
    console.info(message, optionalParams)
  },
  warn: (message: string, ...optionalParams: unknown[]): void => {
    console.warn(message, optionalParams)
  },
  debug: (message: string, ...optionalParams: unknown[]): void => {
    console.debug(message, optionalParams)
  },
  showReport: (): void => {
    showReportDialog({
      lang: 'de',
    })
  },
}

export default SentryLoggerProvider
