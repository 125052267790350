import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Box, Collapse, Grid, Typography } from '@mui/material'
import { useState } from 'react'

import List from './List'

const COLLAPSED_LIST_LENGTH = 2

const showArrow = (isExpanded: boolean): JSX.Element => {
  if (isExpanded) {
    return <ExpandLessIcon />
  }
  return <ExpandMoreIcon />
}

const ChipList: React.FC<{ list: string[] }> = ({ list }) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const shortenedList = list.slice(0, COLLAPSED_LIST_LENGTH)
  const countAdditionalElements = list.length - COLLAPSED_LIST_LENGTH

  const toggleExpand = () => {
    setIsExpanded(prev => !prev)
  }

  return (
    <Box
      border={1}
      borderRadius={16}
      display={'flex'}
      p={1}
      paddingLeft={isExpanded ? 4 : undefined}
      paddingRight={isExpanded ? 4 : undefined}
      width={'fit-content'}
      onClick={toggleExpand}
    >
      <Collapse in={isExpanded} collapsedSize={35}>
        {
          <List list={isExpanded ? list : shortenedList} isExpanded={isExpanded}>
            {!isExpanded && list.length > COLLAPSED_LIST_LENGTH && (
              <Grid item>
                <Typography>+{countAdditionalElements}</Typography>
              </Grid>
            )}
          </List>
        }
      </Collapse>
      {list.length > COLLAPSED_LIST_LENGTH ? showArrow(isExpanded) : null}
    </Box>
  )
}

export default ChipList
