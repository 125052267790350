import React from 'react'
import {
  Edit,
  EditProps,
  useGetList,
  useNotify,
  useRefresh,
  useRedirect,
  useDataProvider,
  useEditController,
} from 'react-admin'
import { useMutation } from 'react-query'
import { useParams } from 'react-router-dom'

import { admin } from '../../../types'
import Timer from '../../shared/Components/TimerField/Timer'
import StationChargepointsForm from './StationChargepointsForm'
import { transform } from './StationChargepointsUtils'

const StationChargepointsEdit: React.FC<EditProps> = props => {
  const refresh = useRefresh()
  const notify = useNotify()
  const redirect = useRedirect()
  const dataProvider = useDataProvider()
  const {
    data: userData,
    isLoading: userLoading,
    error: userError,
  } = useGetList(admin.usersMe, { pagination: { page: 1, perPage: -1 } })
  const { id } = useParams()
  const { resource } = useEditController()
  const { mutate: setUnlock } = useMutation(
    [
      resource,
      'unlock',
      { id: id, resource: resource, recordId: id, identity: userData && userData[0]?.id },
    ],
    () =>
      dataProvider.unlock(resource, {
        id: id,
        resource: resource,
        recordId: id,
        identity: userData && userData[0]?.id,
      })
  )

  const onSuccess = () => {
    setUnlock()
    notify('ra.action.save')
    redirect(`/${admin.station_chargepoints}`)
    refresh()
  }

  if (userError || !userData || !resource || !id) {
    return <>error</>
  }

  return (
    <>
      {!userLoading && resource === admin.station_chargepoints && (
        <Timer station="chargepoint" identity={userData[0]?.id || ''} resource={resource} id={id} />
      )}
      <Edit
        {...props}
        mutationMode="pessimistic"
        mutationOptions={{ onSuccess }}
        transform={transform}
      >
        <StationChargepointsForm />
      </Edit>
    </>
  )
}

export default StationChargepointsEdit
