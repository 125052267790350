import { Box, Tab, Tabs, Typography } from '@mui/material'
import { useState } from 'react'
import { Loading, useTranslate } from 'react-admin'
import { useQuery } from 'react-query'

import { admin } from '../../../types'
import { DataHandlerProps } from './CBoxTypes'
import DataTable from './CustomDataDisplay/DataTable'
import LineGraph from './CustomDataDisplay/LineGraph'
import ErrorNoData from './ErrorNoData'
import { fetchCPackData } from './Querys/ShowQuerys'
import { getTableData } from './utils/dataFormatter'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

const CBoxDataHandler = ({
  startDate,
  endDate,
  activeParams,
  id,
}: DataHandlerProps): JSX.Element => {
  const [tab, setTab] = useState<number>(0)

  const { data, isLoading } = useQuery(
    [admin.cPacks, 'getOne', 'graph', startDate, endDate, activeParams],
    () => fetchCPackData(id, startDate, endDate, activeParams),
    {
      enabled: !!id && activeParams.length > 0,
      refetchOnWindowFocus: false,
    }
  )

  const translate = useTranslate()

  return (
    <>
      {isLoading && <Loading />}
      {data && (
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={tab}
              onChange={(_, newValue) => setTab(newValue)}
              aria-label="basic tabs example"
            >
              <Tab label={translate(`resources.${admin.cPacks}.dataTable`)} {...a11yProps(0)} />
              <Tab label={translate(`resources.${admin.cPacks}.graph`)} {...a11yProps(1)} />
            </Tabs>
          </Box>
          <TabPanel value={tab} index={0}>
            <DataTable data={getTableData(data)} keys={activeParams} />
          </TabPanel>
          <TabPanel value={tab} index={1}>
            <LineGraph data={data} />
          </TabPanel>
        </Box>
      )}
      {!data && <ErrorNoData />}
    </>
  )
}

export default CBoxDataHandler
