import { DataGrid, GridValueFormatterParams } from '@mui/x-data-grid'

import CustomPagination from '../CustomInputs/CustomPagination'

type DataTableProps = {
  data: { date: Date; [key: string]: unknown }[]
  keys: string[]
}

const vm = (params: GridValueFormatterParams<Date>): string => {
  return params.value.toLocaleString('de-DE')
}

const initialColumnsState = {
  field: 'date',
  valueFormatter: vm,
  width: 250,
}

const DataTable: React.FC<DataTableProps> = ({ data, keys }) => {
  return (
    <div style={{ height: 800, width: '100%' }}>
      <DataGrid
        columns={[initialColumnsState, ...keys.map(key => ({ field: key, width: 250 }))]}
        rows={data}
        getRowId={row => row.date.getTime()}
        components={{ Pagination: CustomPagination }}
      />
    </div>
  )
}

export default DataTable
