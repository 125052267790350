import React from 'react'
import { Edit, EditProps } from 'react-admin'

import ChargingCurveGroupsForm from './ChargingCurveGroupsForm'

const ChargingCurveGroupsEdit: React.FC<EditProps> = props => (
  <Edit {...props}>
    <ChargingCurveGroupsForm />
  </Edit>
)

export default ChargingCurveGroupsEdit
