const basePath = 'admin/enums'

const enums = [
  'accessMethod',
  'categoryType',
  'engineType',
  'fuelType',
  'general3dDesign',
  'position',
  'vehicleDrive',
  'plugType',
  'userRole',
  'documentKey',
  'stationAccessibility',
  'stationHistoryEvent',
  'stationCategoryType',
  'stationLocationType',
  'stationStatus',
  'period',
  'cPackUpdateState',
]
  .sort()
  .map(enumType => `${basePath}/${enumType}`)

export default enums
