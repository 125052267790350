import {
  RaRecord,
  NumberField,
  TextField,
  ReferenceField,
  ArrayField,
  Datagrid,
  FunctionField,
} from 'react-admin'

import { admin, FieldType } from '../../../types'

const TariffsFields: FieldType[] = [
  <NumberField source="id" />,
  <ReferenceField label="empName" source="empNameId" sortBy="empName" reference={admin.empnames}>
    <NumberField source="name" />
  </ReferenceField>,
  <ReferenceField source="accessMethod" reference="admin/enums/accessMethod">
    <TextField source="id" />
  </ReferenceField>,
  <TextField source="name" />,
  <ArrayField source="basicFees">
    <Datagrid>
      <TextField source="country" />
      <NumberField source="basicFee" />
      <TextField source="currency" />
      <NumberField source="per" />
      <TextField source="perPeriod" />
    </Datagrid>
  </ArrayField>,
  <FunctionField
    source="acPricingArrays"
    label="acPricingArrays"
    render={(record: RaRecord | undefined) =>
      `${record?.pricings?.filter((pricing: { ac: boolean }) => pricing?.ac).length || 0}`
    }
  />,
  <FunctionField
    source="dcPricingArrays"
    label="dcPricingArrays"
    render={(record: RaRecord | undefined) =>
      `${record?.pricings?.filter((pricing: { ac: boolean }) => !pricing?.ac).length || 0}`
    }
  />,
]

export default TariffsFields
