import { AccordionForm, AccordionFormPanel } from '@eclever/ra-enterprise/ra-form-layout'
import {
  Create,
  CreateProps,
  TextInput,
  BooleanInput,
  AutocompleteArrayInput,
  useNotify,
  useRefresh,
  useRedirect,
  ReferenceArrayInput,
  required,
  useCreateController,
} from 'react-admin'

import useBreadCrumps from '../../../hooks/useBreadCrumps'
import { admin, api } from '../../../types'
import NewInput from '../CPacks/CustomInputs/NewInput'

const VehicleCpacksCreate: React.FC<CreateProps> = props => {
  const notify = useNotify()
  const refresh = useRefresh()
  const redirect = useRedirect()
  const { resource } = useCreateController()
  const { breadCrumps } = useBreadCrumps()

  const onSuccess = () => {
    notify(`ra.message.save`)
    redirect(`/${admin.virtualCpacks}`)
    refresh()
  }

  return (
    <Create mutationOptions={{ onSuccess }} {...props}>
      <AccordionForm
        defaultValues={{
          cPack: {
            cPackId: resource === admin.virtualVehicles ? breadCrumps[admin.virtualCpacks] : null,
          },
          simActivate: {
            activated: false,
          },
          vehicle: {
            triggered: false,
            permissions: {
              complete: false,
              anonymous: false,
            },
            completeActivation: {
              triggered: false,
            },
          },
        }}
      >
        <AccordionFormPanel label="cPack" defaultExpanded>
          <TextInput
            source="cPack.cPackId"
            label="cPackId"
            isRequired
            validate={[required()]}
            fullWidth
          />
          <BooleanInput
            source="simActivate.activated"
            label="activated"
            isRequired
            validate={[required()]}
            fullWidth
          />
          <BooleanInput
            source="completeActivation.triggered"
            label="completeActivation triggered"
            isRequired
            validate={[required()]}
            fullWidth
          />
        </AccordionFormPanel>
        <AccordionFormPanel label="Vehicle" defaultExpanded>
          <BooleanInput
            source="vehicle.permissions.complete"
            label="Permissions complete"
            isRequired
            validate={[required()]}
            fullWidth
          />
          <BooleanInput
            source="vehicle.permissions.anonymous"
            label="Permissions anonymous"
            isRequired
            validate={[required()]}
            fullWidth
          />
          <ReferenceArrayInput
            label="vehicleVariantIds"
            source="vehicle.vehicleVariantIds"
            reference={admin.vehicle_variants}
            perPage={-1}
            sort={{ field: 'name', order: 'ASC' }}
            isRequired
            validate={[required()]}
            fullWidth
          >
            <AutocompleteArrayInput optionText="name" />
          </ReferenceArrayInput>
          <TextInput source="vehicle.name" label="name" fullWidth />
          <BooleanInput source="vehicle.scFree" label="scFree" fullWidth />
          <TextInput source="vehicle.licensePlate" label="licensePlate" fullWidth />
          <NewInput
            reference={api.vehicle_adapters}
            label="adapters"
            source="vehicle.adapters"
            fullWidth
          />
          <NewInput
            reference={api.vehicle_cables}
            label="cables"
            source="vehicle.cables"
            fullWidth
          />
        </AccordionFormPanel>
      </AccordionForm>
    </Create>
  )
}

export default VehicleCpacksCreate
