import { Grid, styled } from '@mui/material'
import {
  ArrayField,
  ArrayFieldProps,
  BooleanField,
  Datagrid,
  FunctionField,
  NumberField,
  ReferenceField,
  TextField,
  useRecordContext,
  RaRecord,
} from 'react-admin'

import { admin, FieldType } from '../../../types'

const StyledGrid = styled(Grid)(({ theme }) => ({
  minHeight: theme.spacing(2.5),
}))

const StyledGridWrapper = styled(Grid)(({ theme }) => ({
  gridGap: theme.spacing(1),
}))

const GridField: React.FC<ArrayFieldProps> = props => {
  const { source } = props
  const record: RaRecord = useRecordContext()

  if (record?.ac && record?.dc) {
    if (source && ['currentType'].includes(source)) {
      return (
        <StyledGridWrapper container direction="column">
          <StyledGrid item>
            <FunctionField label="AC" render={() => 'AC'} />
          </StyledGrid>
          <StyledGrid item>
            <FunctionField label="DC" render={() => 'DC'} />
          </StyledGrid>
        </StyledGridWrapper>
      )
    }

    return (
      <StyledGridWrapper container direction="column">
        <StyledGrid item>
          <NumberField {...props} source={`ac.${source}`} />
        </StyledGrid>
        <StyledGrid item>
          <NumberField {...props} source={`dc.${source}`} />
        </StyledGrid>
      </StyledGridWrapper>
    )
  }

  if (record?.ac) {
    if (source && ['currentType'].includes(source)) {
      return <FunctionField label="AC" render={() => 'AC'} />
    }

    return <TextField source={`ac.${source}`} />
  }

  if (record?.dc) {
    if (source && ['currentType'].includes(source)) {
      return <FunctionField label="DC" render={() => 'DC'} />
    }

    return <TextField source={`dc.${source}`} />
  }

  return <></>
}

const VehicleVariantFields: FieldType[] = [
  <TextField source="id" />,
  <TextField source="name" />,
  <ReferenceField source="vehicleDesignId" reference={admin.vehicle_designs} link={false}>
    <TextField source="name" />
  </ReferenceField>,
  <ArrayField source="plugs">
    <Datagrid>
      <TextField source="type" />
      <TextField source="position" />
      <GridField source="currentType" label="currentType" children={<></>} />
      <GridField source="kw" label="kw" children={<></>} />
      <GridField source="ampere" label="ampere" children={<></>} />
      <GridField source="phase" label="phase" children={<></>} />
      <GridField source="onlyCP1PhaseAmpere" label="Only CP1 phase ampere" children={<></>} />
      <GridField source="maxDefaultSoc" label="maxDefaultSoc" children={<></>} />
      <GridField source="efficiencyCharging" label="efficiencyCharging" children={<></>} />
    </Datagrid>
  </ArrayField>,
  <ReferenceField source="chargingCurveGroupId" reference={admin.charging_curve_groups}>
    <TextField source="name" />
  </ReferenceField>,
  <ReferenceField source="consumptionCurveGroupId" reference={admin.consumption_curve_groups}>
    <TextField source="name" />
  </ReferenceField>,
  <NumberField source="listPrice" />,
  <TextField source="categoryType" />,
  <TextField source="engineType" />,
  <TextField source="vehicleDrive" />,
  <NumberField source="doors" />,
  <NumberField source="airResistanceCwa" />,
  <NumberField source="emptyWeight" />,
  <NumberField source="endEnginePower" />,
  <NumberField source="endTourque" />,
  <NumberField source="zeroToHundredkmh" />,
  <NumberField source="hundredToTwohundredkmh" />,
  <NumberField source="topSpeed" />,
  <BooleanField source="hasFrontTrunk" />,
  <BooleanField source="trailHitch" />,
  <BooleanField source="trailHitchOnlyBikemount" />,
  <NumberField source="trailHitchLoadKg" />,
  <NumberField source="maxEEnginePower" />,
  <NumberField source="constantEEnginePower" />,
  <NumberField source="etorque" />,
  <NumberField source="batteryWeightKg" />,
  <NumberField source="minBatteryV" />,
  <NumberField source="maxBatteryV" />,
  <BooleanField source="batteryHeatSerie" />,
  <BooleanField source="batteryHeatAdditional" />,
  <NumberField source="bruttoKwh" />,
  <NumberField source="nettoKwh" />,
  <NumberField source="consumptionKwh100City" />,
  <NumberField source="consumptionKwh100Highway" />,
  <BooleanField source="acV2x" />,
  <BooleanField source="dcV2x" />,
  <TextField source="fillerNeckPosition" />,
  <NumberField source="fuelEnginePower" />,
  <NumberField source="fuelEngineTorque" />,
  <TextField source="fuel" />,
  <NumberField source="displacementCcm" />,
  <NumberField source="tankSizeKg" />,
  <NumberField source="tankPressureBar" />,
  <NumberField source="tankSizeL" />,
]

export default VehicleVariantFields
