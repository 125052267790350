import { Identifier } from 'react-admin'
import create from 'zustand'
import { persist } from 'zustand/middleware'

import { immer } from './middleware'

export type BreadCrumpsProps = {
  breadCrumps: { [key: string]: Identifier }
  addId: (name: string, id: Identifier | null) => void
  removeIds: (names: string[]) => void
  reset: () => void
}

const useBreadCrumps = create<BreadCrumpsProps>(
  persist(
    immer(set => ({
      breadCrumps: {},
      addId: (name: string, id: Identifier | null) => {
        if (id) {
          set(state => ({ breadCrumps: { ...state.breadCrumps, [name]: id } }))
        } else {
          set(state => ({
            breadCrumps: Object.fromEntries(
              Object.entries(state.breadCrumps).filter(([key, _value]) => id === key)
            ),
          }))
        }
      },
      removeIds: (names: string[]) =>
        set(state => ({
          breadCrumps: Object.fromEntries(
            Object.entries(state.breadCrumps).filter(([key, value]) => !names.includes(key))
          ),
        })),
      reset: () => set(() => ({ breadCrumps: {} })),
    })),
    {
      name: 'backoffice-breadCrumps',
      getStorage: () => sessionStorage,
    }
  )
)

export default useBreadCrumps
