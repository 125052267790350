import { styled } from '@mui/system'
import { TopToolbar } from 'react-admin'

export const ToolbarDraft = styled(TopToolbar)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.common.white,
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  display: 'flex',
  justifyContent: 'space-evenly',
  alignItems: 'center',
}))

export const General = styled(TopToolbar)(() => ({
  '&.MuiToolbar-root.MuiToolbar-regular.MuiToolbar-gutters:not(.MuiTablePagination-toolbar)': {
    flexDirection: 'row',
  },
}))
