import { Done as DoneIcon } from '@mui/icons-material'
import * as React from 'react'
import { useEffect } from 'react'
import {
  RaRecord,
  Loading,
  ToolbarProps,
  useTranslate,
  useCreate,
  useNotify,
  useRedirect,
  Button,
  EditButton,
  useRecordContext,
} from 'react-admin'

import { ThemeProvider } from '../../../provider'
import { admin } from '../../../types'
import RejectButton from '../Buttons/RejectButton'
import { ToolbarDraft } from './ToolbarStyles'

type DraftActionProps = {
  approvepath: typeof admin.station_chargepoint_drafts | typeof admin.station_chargegroup_drafts
}

const ApproveButton: React.FC<ToolbarProps & DraftActionProps> = props => {
  const translate = useTranslate()
  const record: RaRecord = useRecordContext(props)
  const { approvepath } = props
  const [create, { data, error, isLoading }] = useCreate()
  const notify = useNotify()
  const redirect = useRedirect()

  useEffect(() => {
    if (error) {
      notify(`ra.message.error`)
    }

    if (data) {
      notify(`tags.approve`)
      redirect(`/${approvepath}`)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error, isLoading])

  if (isLoading) {
    return <Loading />
  }

  return (
    <Button
      style={{ color: ThemeProvider.palette.colorPalette.changelog.create }}
      startIcon={<DoneIcon />}
      onClick={() => create(`${approvepath}/${record.id}/approve`)}
      label={translate('tags.approve')}
    />
  )
}

const DraftsAction: React.FC<Omit<ToolbarProps & DraftActionProps, 'sx'>> = props => {
  return (
    <ToolbarDraft {...props}>
      <RejectButton />
      <ApproveButton {...props} />
      <EditButton />
    </ToolbarDraft>
  )
}

export default DraftsAction
