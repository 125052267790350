import { Card, CardContent, Typography } from '@mui/material'
import { useTranslate } from 'react-admin'

import { admin } from '../../../types'

const ErrorNoData = (): JSX.Element => {
  const translate = useTranslate()
  return (
    <Card>
      <CardContent>
        <Typography variant="h5" component="div">
          {translate(`resources.${admin.cPacks}.noDataAvailable`)}
        </Typography>
        <Typography color="textSecondary" component="div">
          {translate(`resources.${admin.cPacks}.selectOtherTimespan`)}
        </Typography>
      </CardContent>
    </Card>
  )
}

export default ErrorNoData
