import { Typography } from '@mui/material'
import React from 'react'
import {
  ReferenceManyField,
  ReferenceManyFieldProps,
  useListContext,
  useRecordContext,
} from 'react-admin'

export function CountField(): JSX.Element {
  const { total } = useListContext()

  return (
    <Typography component="span" variant="body2">
      {total}
    </Typography>
  )
}

const ReferenceCountField: React.FC<Omit<ReferenceManyFieldProps, 'children'>> = props => {
  const record = useRecordContext()
  const { target } = props

  return (
    <ReferenceManyField {...props} filter={{ [target]: record.id || 0 }} perPage={-1}>
      <CountField />
    </ReferenceManyField>
  )
}
export default ReferenceCountField
