import DockIcon from '@mui/icons-material/Dock'

import StationTypesCreate from './StationTypesCreate'
import StationTypesEdit from './StationTypesEdit'
import StationTypesList from './StationTypesList'

const StationTypes = {
  options: { label: 'Charging Curve Groups' },
  list: StationTypesList,
  create: StationTypesCreate,
  edit: StationTypesEdit,
  icon: DockIcon,
}

export default StationTypes
