import { CircularProgress } from '@mui/material'
import React, { useEffect } from 'react'
import { useTranslate, InputProps, useDataProvider, RaRecord } from 'react-admin'
import { useFormContext } from 'react-hook-form'
import { useMutation } from 'react-query'

import ThemeProvider from '../../../../../../provider/ThemeProvider'
import { admin } from '../../../../../../types'
import { approach, location, address, drop, latlng } from '../MapConstants'
import { SearchButton } from '../MapStyles'
import { LatLngProps, MapInputProps } from '../MapTypes'
import { castLatLng, getValidData } from '../MapUtils'

const ResetButton: React.FC<InputProps & MapInputProps> = ({
  currentPosition,
  setCurrentPosition,
  setMarker,
  showMarker,
  ...props
}) => {
  const { resource } = props
  const dataProvider = useDataProvider()
  const { getValues, setValue } = useFormContext()
  const value = getValues()
  const {
    mutate: approve,
    data: chargeData,
    isLoading,
  } = useMutation([resource, 'getOne', { id: value?.id || 0 }], () =>
    dataProvider.getOne<RaRecord>(resource || '', { id: value.id })
  )

  useEffect(() => {
    value?.id && approve()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const translate = useTranslate()

  const newClass = () => {
    if (showMarker.approach) {
      return getValidData(chargeData?.data, approach)
        ? { backgroundColor: ThemeProvider.palette.secondary.main }
        : { backgroundColor: ThemeProvider.palette.colorPalette.changelog.update }
    }

    return getValidData(chargeData?.data, location)
      ? { backgroundColor: ThemeProvider.palette.secondary.main }
      : { backgroundColor: ThemeProvider.palette.colorPalette.changelog.update }
  }

  const onClick = () => {
    const validDataLocation = getValidData(chargeData?.data, location)
    const validDataApproach = getValidData(chargeData?.data, approach)
    const validDataAddress = getValidData(chargeData?.data, address)
    const validlatlng = getValidData(chargeData?.data, latlng)

    setMarker({
      ...showMarker,
      pointPolygon: !validDataLocation,
    })

    if (!showMarker.approach && (validDataLocation || validlatlng)) {
      let LatLng: LatLngProps[] | undefined = undefined

      if (showMarker.pointPolygon && validlatlng) {
        LatLng = [validlatlng]
      }

      if (!showMarker.pointPolygon && validDataLocation?.length >= 3) {
        LatLng = validDataLocation
      }

      if (LatLng) {
        setValue('latitude', validlatlng.lat)
        setValue('longitude', validlatlng.lng)
        setValue('boundaries', validDataLocation ? castLatLng(validDataLocation) : null)
        setValue('addressFormatted', validDataAddress)

        setCurrentPosition({
          ...currentPosition,
          type: drop,
          LatLng: validDataLocation || [validlatlng],
          address: validDataAddress ? validDataAddress : null,
        })
      }
    }

    if (showMarker.approach && validDataApproach) {
      setValue('approachLatitude', validDataApproach.lat)
      setValue('approachLongitude', validDataApproach.lng)

      setCurrentPosition({
        ...currentPosition,
        type: approach,
        approach: validDataApproach,
      })
    }
  }

  if (isLoading) {
    return <CircularProgress />
  }

  return (
    <SearchButton disabled={!value?.id} sx={{ ...newClass() }} onClick={value?.id && onClick}>
      <>{translate(`resources.${admin.station_chargegroups}.tags.reset`)}</>
    </SearchButton>
  )
}

export default ResetButton
