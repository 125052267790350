import { useStore } from 'react-admin'

import { ColumnsType, FieldType } from '../../../../types'

const useSelectColumns: (preferences: string, columns: ColumnsType) => (false | FieldType)[] = (
  preferences,
  columns
) => {
  const [columnsStore] = useStore<string[]>(preferences, Object.keys(columns))

  return (
    (columns &&
      Object.entries(columns)?.map(([key, value]) => columnsStore.includes(key) && value)) ||
    []
  )
}

export default useSelectColumns
