import { MultiLevelMenu } from '@eclever/ra-enterprise/ra-navigation'
import { canAccess } from '@eclever/ra-enterprise/ra-rbac'
import { Tooltip, styled } from '@mui/material'
import { MenuProps, usePermissions, useTranslate } from 'react-admin'

import packageJson from '../../package.json'
import useBreadCrumps from '../hooks/useBreadCrumps'
import useFilters from '../hooks/useFilters'
import { admin } from '../types'
import { Version } from './LayoutStyles'

export const MENU_WIDTH = 260
export const CLOSED_MENU_WIDTH = 55

const Sidebar = styled('div')(() => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}))

const SidebarMenu = styled('div')(() => ({
  flexGrow: 9,
  overflowX: 'scroll',
  '&::-webkit-scrollbar': { display: 'none' },
}))

const SidebarFooter = styled('div')(() => ({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'end',
  height: 'fit-content',
  paddingLeft: 6,
  zIndex: 1100,
}))
const Menu: React.FC<MenuProps> = ({
  classes: classesOverride,
  className,
  dense,
  hasDashboard,
  onMenuClick,
  ...props
}) => {
  const appVersion = packageJson.version
  const translate = useTranslate()
  const { isLoading, permissions } = usePermissions()
  const { reset: filterReset } = useFilters()
  const { reset: breadCrumpsReset } = useBreadCrumps()

  const reset = () => {
    filterReset()
    breadCrumpsReset()
  }

  return (
    <Sidebar>
      <SidebarMenu>
        <MultiLevelMenu {...props}>
          {
            <MultiLevelMenu.Item
              name="dashboard"
              to="/"
              label="Dashboard"
              onClick={() => reset()}
            />
          }
          {!isLoading && canAccess({ permissions, resource: admin.documents, action: 'list' }) && (
            <MultiLevelMenu.Item
              name="documents"
              to={`/${admin.documents}`}
              label="Documents"
              onClick={() => reset()}
            />
          )}
          {!isLoading &&
            [admin.emps, admin.empnames, admin.emp_operators, admin.operators].some(
              (resource: string) => canAccess({ permissions, resource: resource, action: 'list' })
            ) && (
              <MultiLevelMenu.Item
                name="enot"
                to={`/${admin.emps}`}
                label="ENOT"
                onClick={() => reset()}
              >
                {canAccess({ permissions, resource: admin.emps, action: 'list' }) && (
                  <MultiLevelMenu.Item
                    name="enot.emps"
                    to={`/${admin.emps}`}
                    label="Emps/-Names/Tariffs"
                    onClick={() => reset()}
                  />
                )}
                {canAccess({ permissions, resource: admin.emp_operators, action: 'list' }) && (
                  <MultiLevelMenu.Item
                    name="enot.emp-operators"
                    to={`/${admin.emp_operators}`}
                    label="Emp-Operators"
                    onClick={() => reset()}
                  />
                )}
                {canAccess({ permissions, resource: admin.operators, action: 'list' }) && (
                  <MultiLevelMenu.Item
                    name="enot.operators"
                    to={`/${admin.operators}`}
                    label="Operators"
                    onClick={() => reset()}
                  />
                )}
              </MultiLevelMenu.Item>
            )}
          {!isLoading &&
            [
              admin.station_types,
              admin.station_manufacturers,
              admin.station_chargegroups,
              admin.station_chargegroup_drafts,
              admin.station_chargepoints,
              admin.station_chargepoint_drafts,
            ].some((resource: string) =>
              canAccess({ permissions, resource: resource, action: 'list' })
            ) && (
              <MultiLevelMenu.Item
                name="station"
                to={`/${admin.station_chargegroups}`}
                label="Station"
                onClick={() => reset()}
              >
                {canAccess({
                  permissions,
                  resource: admin.station_manufacturers,
                  action: 'list',
                }) && (
                  <MultiLevelMenu.Item
                    name="station-manufacturers"
                    to={`/${admin.station_manufacturers}`}
                    label="Manufacturers/Types"
                    onClick={() => reset()}
                  />
                )}
                {canAccess({
                  permissions,
                  resource: admin.station_chargegroups,
                  action: 'list',
                }) && (
                  <MultiLevelMenu.Item
                    name="station-chargegroups"
                    to={`/${admin.station_chargegroups}`}
                    label="Chargegroups/-points"
                    onClick={() => reset()}
                  />
                )}
                {canAccess({
                  permissions,
                  resource: admin.station_chargegroup_drafts,
                  action: 'list',
                }) && (
                  <MultiLevelMenu.Item
                    name="station-chargegroup-drafts"
                    to={`/${admin.station_chargegroup_drafts}`}
                    label="Drafts - Chargegroup"
                    onClick={() => reset()}
                  />
                )}
                {canAccess({
                  permissions,
                  resource: admin.station_chargepoint_drafts,
                  action: 'list',
                }) && (
                  <MultiLevelMenu.Item
                    name="station-chargepoint-drafts"
                    to={`/${admin.station_chargepoint_drafts}`}
                    label="Drafts - Chargepoint"
                    onClick={() => reset()}
                  />
                )}
              </MultiLevelMenu.Item>
            )}
          {!isLoading && canAccess({ permissions, resource: admin.users, action: 'list' }) && (
            <MultiLevelMenu.Item
              name="users"
              to={`/${admin.users}`}
              label="Users"
              onClick={() => reset()}
            />
          )}
          {!isLoading &&
            [
              admin.vehicle_manufacturers,
              admin.vehicle_models,
              admin.vehicle_designs,
              admin.vehicle_variants,
              admin.charging_curve_groups,
              admin.consumption_curve_groups,
            ].some((resource: string) =>
              canAccess({ permissions, resource: resource, action: 'list' })
            ) && (
              <MultiLevelMenu.Item
                name="vehicles"
                to={`/${admin.vehicle_manufacturers}`}
                label="Vehicles"
                onClick={() => reset()}
              >
                {[
                  admin.vehicle_manufacturers,
                  admin.vehicle_models,
                  admin.vehicle_designs,
                  admin.vehicle_variants,
                ].filter((resource: string) =>
                  canAccess({ permissions, resource: resource, action: 'list' })
                ).length > 0 && (
                  <MultiLevelMenu.Item
                    name="vehicle"
                    to={`/${admin.vehicle_manufacturers}`}
                    label="Vehicle"
                    onClick={() => reset()}
                  />
                )}
                {canAccess({
                  permissions,
                  resource: admin.charging_curve_groups,
                  action: 'list',
                }) && (
                  <MultiLevelMenu.Item
                    name="charging-curve-groups"
                    to={`/${admin.charging_curve_groups}`}
                    label="Charging Curve Groups"
                    onClick={() => reset()}
                  />
                )}
                {canAccess({
                  permissions,
                  resource: admin.consumption_curve_groups,
                  action: 'list',
                }) && (
                  <MultiLevelMenu.Item
                    name="consumption-curve-groups"
                    to={`/${admin.consumption_curve_groups}`}
                    label="Consumption Curve Groups"
                    onClick={() => reset()}
                  />
                )}
                {canAccess({
                  permissions,
                  resource: admin.vehicle_capabilities,
                  action: 'list',
                }) && (
                  <MultiLevelMenu.Item
                    name="vehicle-capabilities"
                    to={`/${admin.vehicle_capabilities}`}
                    label="Vehicle Capabilities"
                    onClick={() => reset()}
                  />
                )}
              </MultiLevelMenu.Item>
            )}
          {!isLoading && (
            <MultiLevelMenu.Item
              name="virtual/cpacks"
              to={`/${admin.virtualCpacks}`}
              label="Virtual"
              onClick={() => reset()}
            />
          )}

          {!isLoading &&
            [admin.cPacks].filter((resource: string) =>
              canAccess({ permissions, resource: resource, action: 'list' })
            ).length > 0 && (
              <MultiLevelMenu.Item
                name="cBox"
                to={`/${admin.cPacks}`}
                label="cBox"
                onClick={() => reset()}
              />
            )}
        </MultiLevelMenu>
      </SidebarMenu>
      <SidebarFooter>
        <Tooltip title={translate('global.version') + ' :' + appVersion}>
          <Version>
            {translate('global.version')} {appVersion}
          </Version>
        </Tooltip>
        <Version>{translate('global.artist')}</Version>
      </SidebarFooter>
    </Sidebar>
  )
}

export default Menu
