import { useEffect, useState } from 'react'

import { ChartJSData } from '../../utils/types'
import Graph from './Graph'

type RawData = {
  parameter: string
  values: [number, number][]
}

type LineGraphProps = {
  data: RawData[]
}

const colors = [
  [54, 162, 235],
  [255, 39, 132],
  [75, 192, 192],
  [255, 159, 64],
  [153, 102, 255],
  [255, 205, 86],
  [201, 203, 207],
]

export const getGraphData = (data: RawData[]): ChartJSData => {
  const graphData = data.map((entry, index) => {
    const color = colors[index % colors.length]
    return {
      label: entry.parameter,
      data: entry.values.map(v => ({ x: v[1] * 1000, y: v[0] })),
      borderColor: `rgb(${color.toString()})`,
      backgroundColor: `rgba(${color.toString()}, 0.5)`,
      yAxisID: 'y' + index,
      xAxisID: 'x',
      hidden: false,
    }
  })

  return {
    datasets: graphData,
  }
}

const LineGraph = ({ data }: LineGraphProps): JSX.Element => {
  const [graphData, setGraphData] = useState<ChartJSData>()

  useEffect(() => {
    setGraphData(getGraphData(data))
  }, [data])

  return <>{graphData && <Graph data={graphData} />}</>
}

export default LineGraph
