import { styled, TableRow, Typography, Grid, Button, Paper, Box } from '@mui/material'

export const DescriptionField = styled(TableRow)(() => ({
  textOverflow: 'ellipsis',
  maxWidth: '50ch',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
}))

export const DrawerHeader = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(4),
}))

export const DrawerGridContainer = styled(Grid)(({ theme }) => ({
  margin: theme.spacing(2),
}))

export const MoreButton = styled(Button)(({ theme }) => ({
  width: theme.spacing(12),
  height: '50%',
}))

export const ImageField = styled('img')(({ theme }) => ({
  width: theme.spacing(20),
  height: theme.spacing(15),
  objectFit: 'cover',
}))

export const ImageBackground = styled(Box)(() => ({
  position: 'absolute',
  width: '50%',
  height: '50%',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  zIndex: 1300,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
}))

export const ImageFullscreen = styled('img')(() => ({
  objectFit: 'contain',
  position: 'fixed',
  left: '50%',
  top: '50%',
  height: '50%',
  width: '50%',
  transform: 'translate(-50%, -50%)',
}))

export const HideButton = styled(TableRow)(({ theme }) => ({
  display: 'flex',
  margin: 'auto',
  marginTop: theme.spacing(4),
  width: '100%',
}))

export const CustomPaper = styled(Paper)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}))

export const LabelDivider = styled(TableRow)(({ theme }) => ({
  padding: theme.spacing(1),
  width: '100%',
  fontWeight: 400,
  textAlign: 'center',
  // backgroundColor: "#323232",
  backgroundColor: 'gray',
  color: 'white',
  marginBottom: theme.spacing(3),
  borderRadius: theme.spacing(1),
  boxShadow: '1px 1px 1px gray',
}))
