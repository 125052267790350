import DockIcon from '@mui/icons-material/Dock'

import ConsumptionCurveGroupsCreate from './ConsumptionCurveGroupsCreate'
import ConsumptionCurveGroupsEdit from './ConsumptionCurveGroupsEdit'
import ConsumptionCurveGroupsList from './ConsumptionCurveGroupsList'

const ConsumptionCurveGroups = {
  options: { label: 'Consumption Curve Groups' },
  list: ConsumptionCurveGroupsList,
  create: ConsumptionCurveGroupsCreate,
  edit: ConsumptionCurveGroupsEdit,
  icon: DockIcon,
}

export default ConsumptionCurveGroups
