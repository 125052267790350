import React from 'react'
import { FieldProps, useRecordContext } from 'react-admin'

const UnitField: React.FC<{ unit: string; source: string } & FieldProps> = props => {
  const { source, unit } = props
  const record = useRecordContext()

  return record[source] && <span>{`${record[source]} ${unit}`}</span>
}

export default UnitField
