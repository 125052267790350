import { isEmpty } from 'lodash'
import { InputProps, TranslatableInputs, TextInput, useInput } from 'react-admin'

const TranslateInput: React.FC<InputProps> = props => {
  const {
    field: { value },
  } = useInput(props)
  const locales = value ? Object.keys(value) : []

  return (
    (locales && !isEmpty(locales) && (
      <TranslatableInputs defaultLocale={locales[0]} locales={locales}>
        <TextInput {...props} />
      </TranslatableInputs>
    )) ||
    null
  )
}

export default TranslateInput
