import React from 'react'
import {
  ReferenceInput,
  AutocompleteInput,
  NullableBooleanInput,
  TextInput,
  NumberInput,
  ReferenceArrayInput,
  SelectArrayInput,
  SelectInput,
} from 'react-admin'

import { admin } from '../../../types'
import FilterInputWrapper from '../../shared/Components/FilterInputWrapper'
import FilterWrapper from '../../shared/Components/FilterWrapper'
import RejectedFlagField from '../../shared/Components/RejectedFlagField'
import { TimeInput } from './CustomInputs/DayTimeInput'

const StationChargepointsFilter = [
  <NumberInput source="id" />,
  <FilterWrapper
    reference={admin.station_chargegroups}
    resource={admin.station_chargepoints}
    source="chargegroupId"
    alwaysOn
  >
    <NumberInput source="chargegroupId" alwaysOn />
  </FilterWrapper>,
  <ReferenceInput
    source="manufacturerId"
    reference={admin.station_manufacturers}
    fullWidth
    perPage={-1}
    sort={{ field: 'name', order: 'ASC' }}
  >
    <AutocompleteInput optionText="name" fullWidth />
  </ReferenceInput>,
  <ReferenceInput
    source="typeId"
    reference={admin.station_types}
    fullWidth
    perPage={-1}
    sort={{ field: 'name', order: 'ASC' }}
  >
    <AutocompleteInput optionText="name" />
  </ReferenceInput>,
  <FilterInputWrapper source="evseId" validElement="string">
    <TextInput source="" />
  </FilterInputWrapper>,
  <TextInput source="innogyId" />,
  <ReferenceInput source="plugType" reference="admin/enums/plugType" fullWidth perPage={-1}>
    <AutocompleteInput optionText="key" />
  </ReferenceInput>,
  <NullableBooleanInput source="hasCable" />,
  <NumberInput source="chargingPower" />,
  <NullableBooleanInput source="isWatercooled" />,
  <NullableBooleanInput source="loadManagement" />,
  <NumberInput source="minChargingPower" />,
  <NumberInput source="chargingVoltage" />,
  <NumberInput source="chargingCurrent" />,
  <NumberInput source="chargingPhases" />,
  <NullableBooleanInput source="singlePhaseCurrentLimitation" />,
  <NumberInput source="singlePhaseCurrentValue" />,
  <NullableBooleanInput source="teslaOnly" />,
  <FilterInputWrapper source="operatorId" validElement="select">
    <ReferenceInput
      label="Operator"
      source="operatorId"
      reference={admin.operators}
      fullWidth
      perPage={-1}
      sort={{ field: 'name', order: 'ASC' }}
      debounce={200}
      allowEmpty
    >
      <AutocompleteInput
        optionText={choice => `${choice.name} [${choice.evseOperatorId}]`}
        filterToQuery={searchText => ({ name: searchText })}
      />
    </ReferenceInput>
  </FilterInputWrapper>,
  <ReferenceInput
    source="categoryType"
    reference="admin/enums/stationCategoryType"
    perPage={-1}
    fullWidth
  >
    <AutocompleteInput optionText="key" fullWidth />
  </ReferenceInput>,
  <ReferenceArrayInput
    source="accessMethods"
    reference="admin/enums/accessMethod"
    perPage={-1}
    openOnFocus
    fullWidth
  >
    <SelectArrayInput optionText="id" fullWidth parse={(event: string[]) => [...event].sort()} />
  </ReferenceArrayInput>,
  <NullableBooleanInput source="openFullTime" />,
  <NullableBooleanInput source="parkingFullTime" />,
  <TimeInput source="parkingDuration" />,
  <NullableBooleanInput source="parkingFree" />,
  <NullableBooleanInput source="parkingEvOnly" />,
  <NullableBooleanInput source="parkingBarrierFree" />,
  <NullableBooleanInput source="parkingTrailer" />,
  <NullableBooleanInput source="parkingDifficult" />,
  <NullableBooleanInput source="pricingFree" />,
  <NullableBooleanInput source="payingOnSite" />,
  <NumberInput source="turnoffTime" />,
  <NumberInput source="turnoffEnergy" />,
  <NumberInput source="turnoffPercentage" />,
  <FilterInputWrapper source="service" validElement="arrayselect">
    <SelectInput
      source="service"
      choices={[
        { id: 'hubject', name: 'hubject' },
        { id: 'smartlab', name: 'smartlab' },
      ]}
    />
  </FilterInputWrapper>,
  <NullableBooleanInput source="hasDrafts" />,
  <RejectedFlagField source="rejected" drafts="admin/station-chargepoint-drafts" />,
]

export default StationChargepointsFilter
