import { styled, Theme } from '@mui/material'
import { TopToolbar, Button, CreateButton, EditButton } from 'react-admin'

export const MyTopToolbar = styled(TopToolbar)({
  '&.MuiToolbar-root.MuiToolbar-regular.MuiToolbar-gutters:not(.MuiTablePagination-toolbar)': {
    flexDirection: 'row',
  },
})

export const DrawerContent = styled('div')(() => ({
  width: 300,
}))

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const baseButtonInputStyles: (theme: Theme) => any = theme => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',
  textAlign: 'center',
  width: '100%',
  height: theme.spacing(6),
  position: 'relative',
  right: 'auto',
  left: 'auto',
  bottom: 'auto',
  top: 'auto',
  borderRadius: theme.spacing(1),
  color: '#fff',
  border: '1px solid',

  '&:hover': {
    backgroundColor: '#fff',
    color: '#000',
    border: '1px solid #000',
  },

  '& >span': {
    padding: 'none',
  },
})

export const CustomButton = styled(Button)`
  ${props => baseButtonInputStyles(props.theme)}
`
export const CustomCreateButton = styled(CreateButton)`
  ${props => baseButtonInputStyles(props.theme)}
`
export const CustomEditButton = styled(EditButton)`
  ${props => baseButtonInputStyles(props.theme)}
`
