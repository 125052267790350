import React from 'react'

import { ColumnsType, FieldType } from '../../../types'

export const customfields: (fields: FieldType[], sourceShort?: string) => ColumnsType = (
  fields,
  sourceShort = ''
) =>
  fields.reduce<ColumnsType>((o, field) => {
    const label = field.props.label
    if (o[field.props.source] && typeof label === 'string') {
      o[label] = React.cloneElement(field, {
        ...field.props,
        source: `${sourceShort}${field.props.source}`,
        key: label,
        label: field.props.label,
      })
    } else {
      o[field.props.source] = React.cloneElement(field, {
        ...field.props,
        source: `${sourceShort}${field.props.source}`,
        key: field.props.source,
        label: field.props.label,
      })
    }
    return o
  }, {})

const ColumnsFields: (fields: FieldType[]) => ColumnsType = fields => customfields(fields)

export default ColumnsFields
