export const CREATE = 'CREATE'
export const DELETE = 'DELETE'
export const UPDATE = 'UPDATE'

export type ChangelogType = typeof CREATE | typeof DELETE | typeof UPDATE

export interface CustomRecord {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any
}
