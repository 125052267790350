import { RaRecord } from 'react-admin'

export type selectoption = { id: string; name: string }
export type optionsType = { id: string; name: string; firstLetter: string }

export const getSelectOptions: (data: RaRecord[]) => selectoption[] = data =>
  Object.values(data)
    .sort()
    .reduce((prev, element) => {
      prev.push({ id: element.key, name: element.key })
      return prev
    }, [] as selectoption[])

export const getAutoCompleteOptions: (options: string[]) => optionsType[] = options =>
  options
    .reduce((pre: optionsType[], cur: string) => {
      const firstLetter = cur[0].toUpperCase()
      pre.push({
        id: cur.toLowerCase(),
        name: cur.toUpperCase(),
        firstLetter: /d/.test(firstLetter) ? '0-9' : firstLetter,
      })
      return pre
    }, [] as optionsType[])
    .sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))
