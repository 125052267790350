import { BooleanField, TextField, ArrayField, Datagrid } from 'react-admin'

import { FieldType } from '../../../types'
import { customfields } from '../../shared/Components/ColumnsFields'
import VehicleFields from '../Vehicle/VehicleFields'

const columnsFields = customfields(VehicleFields)

const CPacksFields: FieldType[] = [
  <TextField source="id" />,
  <TextField source="cPackId" />,
  <TextField source="state" />,
  <BooleanField source="alarm" />,
  <TextField source="signalStrength" />,
  <TextField source="softwareVersion" />,
  <ArrayField source="vehicles">
    <Datagrid key="vehicles">{Object.values(columnsFields)}</Datagrid>
  </ArrayField>,
]

export default CPacksFields
