import currencyToSymbolMap from 'currency-symbol-map/map'
import iso from 'iso-639-1'
import React from 'react'
import {
  NumberInput,
  ReferenceInput,
  TextInput,
  BooleanInput,
  AutocompleteInput,
  ReferenceArrayInput,
  maxValue,
  minValue,
  FormDataConsumer,
  required,
  SelectArrayInput,
  RaRecord,
} from 'react-admin'

import { admin, InputElementType, InputType } from '../../../types'
import { getAutoCompleteOptions } from '../../shared/Utils'
import { getLanguagesOptions } from '../../shared/Utils/localizations'
import { LanguagesInput } from '../StationChargegroups/CustomInputs/LocalizationInput/InternalizationInput'
import DayTimeInput, { TimeInput } from './CustomInputs/DayTimeInput'
import DependsInput, { DefaultInput } from './CustomInputs/DependsInput'
import OpenFullTimeInput from './CustomInputs/OpenFullTimeInput'
import TranslateInput from './CustomInputs/TranslateInput'
import { isVisible, plugs } from './CustomInputs/Utils'
import { CustomGrid, GridShort, GridThree } from './StationChargepointsStyles'

export const chargegroupsInputs = [
  <ReferenceInput
    key="chargegroupId"
    source="chargegroupId"
    reference={admin.station_chargegroups}
    sort={{ field: 'id', order: 'ASC' }}
    perPage={100}
  >
    <AutocompleteInput
      optionText="id"
      fullWidth
      isRequired
      validate={[required()]}
      filterToQuery={(searchText: string) => ({ id: Number(searchText) })}
      translateChoice={false}
    />
  </ReferenceInput>,
  // <MapInput label="Chargegroup Map" source="chargegroupId" />,
]

export const hardwareInputs: InputElementType[] = [
  <CustomGrid source="stations" key="stations">
    <DependsInput source="manufacturerId" depends={['typeId']}>
      <ReferenceInput
        source="manufacturerId"
        reference={admin.station_manufacturers}
        fullWidth
        perPage={-1}
        sort={{ field: 'name', order: 'ASC' }}
        allowEmpty
      >
        <AutocompleteInput optionText="name" fullWidth emptyValue={null} defaultValue={null} />
      </ReferenceInput>
    </DependsInput>
    <FormDataConsumer>
      {({ formData }) =>
        formData?.manufacturerId && (
          <ReferenceInput
            source="typeId"
            reference={admin.station_types}
            fullWidth
            perPage={-1}
            sort={{ field: 'name', order: 'ASC' }}
            filter={{ stationManufacturerId: formData?.manufacturerId }}
            defaultValue={null}
          >
            <AutocompleteInput optionText="name" emptyValue={null} defaultValue={null} />
          </ReferenceInput>
        )
      }
    </FormDataConsumer>
  </CustomGrid>,
]

export const mainInputs: InputElementType[] = [
  <TextInput source="evseId" key="evseId" fullWidth />,
  <TextInput source="innogyId" key="innogyId" fullWidth />,
  <CustomGrid source="plugs" key="plugs">
    <DependsInput
      source="plugType"
      depends={[
        'hasCable',
        'cableLength',
        'isWatercooled',
        'chargingPower',
        'loadManagement',
        'minChargingPower',
        'chargingVoltage',
        'chargingCurrent',
        'chargingPhases',
        'singlePhaseCurrentLimitation',
        'singlePhaseCurrentValue',
        'teslaOnly',
      ]}
    >
      <ReferenceInput source="plugType" reference="admin/enums/plugType" perPage={-1}>
        <AutocompleteInput optionText="key" fullWidth isRequired validate={[required()]} />
      </ReferenceInput>
    </DependsInput>
    <div style={{ width: '100%' }}>
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          formData?.plugType && (
            <DefaultInput
              {...rest}
              source="hasCable"
              disabled={!plugs[formData.plugType]['hasCable'].isModifiable}
              defaultValue={plugs[formData.plugType]['hasCable'].value}
              isRequired
              validate={[required()]}
            >
              <BooleanInput source="" />
            </DefaultInput>
          )
        }
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          formData?.hasCable &&
          formData?.plugType && (
            <NumberInput
              {...rest}
              source="cableLength"
              min={0}
              disabled={!plugs[formData.plugType]['cableLength'].isModifiable}
              validate={[minValue(0)]}
              fullWidth
            />
          )
        }
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          formData?.plugType && (
            <DefaultInput
              {...rest}
              source="isWatercooled"
              disabled={!plugs[formData.plugType]['isWatercooled'].isModifiable}
              defaultValue={plugs[formData.plugType]['isWatercooled'].value}
              fullWidth
            >
              <BooleanInput source="" />
            </DefaultInput>
          )
        }
      </FormDataConsumer>
    </div>
  </CustomGrid>,
  <CustomGrid source="loading" key="loading">
    <DependsInput source="loadManagement" depends={['minChargingPower']} defaultValue={false}>
      <BooleanInput source="loadManagement" defaultValue={false} fullWidth />
    </DependsInput>
    <FormDataConsumer>
      {({ formData }) =>
        formData?.loadManagement && (
          <NumberInput source="minChargingPower" min={0} validate={[minValue(0)]} fullWidth />
        )
      }
    </FormDataConsumer>
  </CustomGrid>,
  <FormDataConsumer source="chargingPower" key="chargingPower" fullWidth>
    {({ formData, ...rest }) =>
      isVisible(formData?.plugType, 'chargingPower') && (
        <NumberInput
          {...rest}
          source="chargingPower"
          min={0}
          validate={[required(), minValue(0)]}
          isRequired
          defaultValue={plugs[formData.plugType]['chargingPower'].value}
          disabled={!plugs[formData.plugType]['chargingPower'].isModifiable}
          fullWidth
        />
      )
    }
  </FormDataConsumer>,
  <FormDataConsumer source="chargingCurrent" key="chargingCurrent" fullWidth>
    {({ formData, ...rest }) =>
      isVisible(formData?.plugType, 'chargingCurrent') && (
        <NumberInput
          {...rest}
          source="chargingCurrent"
          min={0}
          validate={[required(), minValue(0)]}
          defaultValue={plugs[formData.plugType]['chargingCurrent'].value}
          disabled={!plugs[formData.plugType]['chargingCurrent'].isModifiable}
          fullWidth
          isRequired
        />
      )
    }
  </FormDataConsumer>,
  <FormDataConsumer source="chargingVoltage" key="chargingVoltage" fullWidth>
    {({ formData, ...rest }) =>
      isVisible(formData?.plugType, 'chargingVoltage') && (
        <NumberInput
          {...rest}
          source="chargingVoltage"
          min={0}
          validate={[required(), minValue(0)]}
          defaultValue={plugs[formData.plugType]['chargingVoltage'].value}
          disabled={!plugs[formData.plugType]['chargingVoltage'].isModifiable}
          fullWidth
          isRequired
        />
      )
    }
  </FormDataConsumer>,
  <CustomGrid source="phases" key="phases">
    <FormDataConsumer fullWidth>
      {({ formData, ...rest }) =>
        isVisible(formData?.plugType, 'chargingPhases') && (
          <DefaultInput
            {...rest}
            source="chargingPhases"
            min={1}
            max={3}
            step={1}
            validate={[minValue(1), maxValue(3)]}
            defaultValue={plugs[formData.plugType]['chargingPhases'].value}
            disabled={!plugs[formData.plugType]['chargingPhases'].isModifiable}
            fullWidth
          >
            <NumberInput source="" />
          </DefaultInput>
        )
      }
    </FormDataConsumer>
    <div>
      <FormDataConsumer>
        {({ formData }) =>
          isVisible(formData?.plugType, 'singlePhaseCurrentLimitation') &&
          formData?.chargingPhases > 1 && (
            <DependsInput
              source="singlePhaseCurrentLimitation"
              depends={['singlePhaseCurrentValue']}
            >
              <BooleanInput
                defaultValue={false}
                source="singlePhaseCurrentLimitation"
                disabled={!plugs[formData.plugType]['singlePhaseCurrentLimitation'].isModifiable}
                fullWidth
              />
            </DependsInput>
          )
        }
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData }) =>
          isVisible(formData?.plugType, 'singlePhaseCurrentValue') &&
          formData?.chargingPhases > 1 &&
          formData?.singlePhaseCurrentLimitation && (
            <NumberInput
              source="singlePhaseCurrentValue"
              min={0}
              disabled={!plugs[formData.plugType]['singlePhaseCurrentValue'].isModifiable}
              validate={[minValue(0)]}
              fullWidth
            />
          )
        }
      </FormDataConsumer>
    </div>
  </CustomGrid>,
  <FormDataConsumer source="teslaOnly" key="teslaOnly">
    {({ formData }) =>
      formData?.plugType && (
        <BooleanInput
          disabled={!plugs[formData.plugType]['teslaOnly'].isModifiable}
          defaultValue={false}
          source="teslaOnly"
          fullWidth
        />
      )
    }
  </FormDataConsumer>,
]

export const operatingInputs: InputElementType[] = [
  <ReferenceInput
    key="operatorId"
    label="Operator"
    source="operatorId"
    reference={admin.operators}
    perPage={-1}
    sort={{ field: 'name', order: 'ASC' }}
  >
    <AutocompleteInput
      optionText={(record: RaRecord) =>
        `${record.name} ${record.evseOperatorId ? '[' + record.evseOperatorId + ']' : ''}`
      }
      fullWidth
      isRequired
      validate={[required()]}
    />
  </ReferenceInput>,
  <ReferenceInput
    source="categoryType"
    key="categoryType"
    reference="admin/enums/stationCategoryType"
    perPage={-1}
  >
    <AutocompleteInput optionText="key" fullWidth isRequired validate={[required()]} />
  </ReferenceInput>,
  <DependsInput source="accessMethods" key="accessMethods" depends={['directPaymentLink']}>
    <ReferenceArrayInput
      source="accessMethods"
      reference="admin/enums/accessMethod"
      perPage={-1}
      openOnFocus
    >
      <SelectArrayInput
        optionText="id"
        fullWidth
        isRequired
        validate={[required()]}
        parse={(event: string[]) => [...event].sort()}
      />
    </ReferenceArrayInput>
  </DependsInput>,
  <FormDataConsumer source="directPaymentLink" key="directPaymentLink">
    {({ formData }) =>
      formData?.accessMethods &&
      formData?.accessMethods.includes('QR') && <TextInput source="directPaymentLink" fullWidth />
    }
  </FormDataConsumer>,
]

export const openingAndParkingTimesInputs: InputElementType[] = [
  <OpenFullTimeInput
    key="openFullTime"
    source="openFullTime"
    hoursProp="oh"
    extraProp="leaveAnytime"
  />,
  <FormDataConsumer key="leaveAnytime" source="leaveAnytime">
    {({ formData }) =>
      !formData?.openFullTime && (
        <BooleanInput defaultValue={false} source="leaveAnytime" label="leaveAnytime" fullWidth />
      )
    }
  </FormDataConsumer>,
  <FormDataConsumer key="openingHours" source="openingHours">
    {({ formData }) =>
      !formData?.openFullTime && <DayTimeInput label="Opening Hours" source="oh" fullWidth />
    }
  </FormDataConsumer>,
  <GridShort
    key="parking"
    source="parking"
    sx={{
      borderTop: '1px solid gray',
      paddingTop: '1rem',
      marginTop: '3rem',
    }}
  >
    <DependsInput source="parkingFullTime" depends={['parkingDuration', 'ph']}>
      <BooleanInput defaultValue={false} source="parkingFullTime" label="parkingFullTime" />
    </DependsInput>
    <FormDataConsumer>
      {({ formData }) =>
        !formData?.parkingFullTime && (
          <TimeInput
            sx={{ marginBottom: '2rem' }}
            source="parkingDuration"
            parse={value => (value ? value / 60 : null)}
            format={value => (value ? value * 60 : null)}
            fullWidth
          />
        )
      }
    </FormDataConsumer>
  </GridShort>,
  <FormDataConsumer key="parkingHours" source="parkingHours">
    {({ formData }) =>
      !formData?.parkingFullTime && <DayTimeInput label="Parking Hours" source="ph" fullWidth />
    }
  </FormDataConsumer>,
]

export const parkingInputs: InputElementType[] = [
  <BooleanInput defaultValue={false} key="parkingFree" source="parkingFree" fullWidth />,
  <BooleanInput defaultValue={false} key="parkingEvOnly" source="parkingEvOnly" fullWidth />,
  <BooleanInput
    defaultValue={false}
    key="parkingBarrierFree"
    source="parkingBarrierFree"
    fullWidth
  />,
  <BooleanInput defaultValue={false} key="parkingTrailer" source="parkingTrailer" fullWidth />,
  <BooleanInput defaultValue={false} key="parkingDifficult" source="parkingDifficult" fullWidth />,
]

export const pricingInputs: InputElementType[] = [
  <DependsInput
    key="pricingFree"
    source="pricingFree"
    depends={[
      'payingOnSite',
      'consumptionPriceCurrency',
      'startingPrice',
      'consumptionPriceKwh',
      'consumptionPriceTime',
      'consumptionPriceTimePer',
    ]}
  >
    <BooleanInput defaultValue={false} source="pricingFree" fullWidth />
  </DependsInput>,
  <FormDataConsumer key="payingOnSite" source="payingOnSite">
    {({ formData }) =>
      !formData?.pricingFree && (
        <BooleanInput defaultValue={false} source="payingOnSite" fullWidth />
      )
    }
  </FormDataConsumer>,
  <FormDataConsumer key="consumptionPriceCurrency" source="consumptionPriceCurrency">
    {({ formData }) =>
      !formData?.pricingFree &&
      formData?.payingOnSite && (
        <AutocompleteInput
          defaultValue={null}
          source="consumptionPriceCurrency"
          choices={getAutoCompleteOptions(Object.keys(currencyToSymbolMap))}
          fullWidth
        />
      )
    }
  </FormDataConsumer>,
  <FormDataConsumer key="startingPrice" source="startingPrice">
    {({ formData }) =>
      !formData?.pricingFree &&
      formData?.payingOnSite && (
        <NumberInput source="startingPrice" min={0} validate={[minValue(0)]} fullWidth />
      )
    }
  </FormDataConsumer>,
  <FormDataConsumer key="consumptionPriceKwh" source="consumptionPriceKwh">
    {({ formData }) =>
      !formData?.pricingFree &&
      formData?.payingOnSite && (
        <NumberInput source="consumptionPriceKwh" min={0} validate={[minValue(0)]} fullWidth />
      )
    }
  </FormDataConsumer>,
  <GridThree key="consumption" source="consumption">
    <FormDataConsumer>
      {({ formData }) =>
        !formData?.pricingFree &&
        formData?.payingOnSite && (
          <NumberInput source="consumptionPriceTime" min={0} validate={[minValue(0)]} fullWidth />
        )
      }
    </FormDataConsumer>
    <FormDataConsumer>
      {({ formData }) =>
        !formData?.pricingFree &&
        formData?.payingOnSite &&
        formData?.consumptionPriceTime && (
          <NumberInput
            source="consumptionPriceTimePer"
            min={0}
            validate={[minValue(0)]}
            fullWidth
          />
        )
      }
    </FormDataConsumer>
    <FormDataConsumer>
      {({ formData }) =>
        !formData?.pricingFree &&
        formData?.payingOnSite &&
        formData?.consumptionPriceTime && (
          <ReferenceInput
            source="consumptionPriceTimePerPeriod"
            reference="admin/enums/period"
            perPage={-1}
          >
            <AutocompleteInput optionText="key" fullWidth isRequired validate={[required()]} />
          </ReferenceInput>
        )
      }
    </FormDataConsumer>
  </GridThree>,
]

export const turnOffInformationInputs: InputElementType[] = [
  <NumberInput key="turnoffTime" source="turnoffTime" min={0} validate={[minValue(0)]} fullWidth />,
  <NumberInput
    key="turnoffEnergy"
    source="turnoffEnergy"
    min={0}
    validate={[minValue(0)]}
    fullWidth
  />,
  <NumberInput
    key="turnoffPercentage"
    source="turnoffPercentage"
    min={0}
    max={100}
    validate={[minValue(0), maxValue(100)]}
    fullWidth
  />,
]

export const descriptionInputs: InputElementType[] = [
  <GridShort key="appDescription" source="appDescription">
    <LanguagesInput
      label="appDescription Languages"
      source="appDescription"
      choices={getLanguagesOptions(iso.getAllCodes())}
      fullWidth
    />
    <TranslateInput label="appDescription Translate" source="appDescription" />
  </GridShort>,
  <GridShort key="internalComment" source="internalComment">
    <LanguagesInput
      label="internalComment Languages"
      source="internalComment"
      choices={getLanguagesOptions(iso.getAllCodes())}
      fullWidth
    />
    <TranslateInput label="internalComment Translate" source="internalComment" />
  </GridShort>,
]

const convertArrayToInputType = (o: InputType, field: InputElementType): InputType => {
  const label = field.props.label
  if (o[field.props.source] && typeof label === 'string') {
    o[label] = React.cloneElement(field, {
      key: `${o[label]}`,
      label: field.props.source,
      ...field.props,
    })
  } else {
    o[field.props.source] = React.cloneElement(field, {
      key: field.props.source,
      label: field.props.source,
      ...field.props,
    })
  }
  return o
}

export const StationChargepointsInputs: Record<string, InputType> = {
  chargegroups: chargegroupsInputs.reduce(convertArrayToInputType, {}),
  hardware: hardwareInputs.reduce(convertArrayToInputType, {}),
  main: mainInputs.reduce(convertArrayToInputType, {}),
  operating: operatingInputs.reduce(convertArrayToInputType, {}),
  openingAndParkingTimes: openingAndParkingTimesInputs.reduce(convertArrayToInputType, {}),
  parking: parkingInputs.reduce(convertArrayToInputType, {}),
  pricing: pricingInputs.reduce(convertArrayToInputType, {}),
  turnOffInformation: turnOffInformationInputs.reduce(convertArrayToInputType, {}),
  description: descriptionInputs.reduce(convertArrayToInputType, {}),
}

export default StationChargepointsInputs
