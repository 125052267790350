import React from 'react'
import {
  required,
  SimpleForm,
  TextInput,
  SimpleFormProps,
  FormDataConsumer,
  NumberInput,
} from 'react-admin'

const StationManufacturersForm: React.FC<Omit<SimpleFormProps, 'children'>> = props => (
  <SimpleForm {...props}>
    <FormDataConsumer>
      {({ formData, ...rest }) =>
        formData.id && (
          <NumberInput source="id" isRequired validate={[required()]} disabled {...rest} />
        )
      }
    </FormDataConsumer>
    <TextInput source="name" isRequired validate={[required()]} />
  </SimpleForm>
)

export default StationManufacturersForm
