import { FormControlLabel, FormGroup, styled, Switch } from '@mui/material'
import React, { useState } from 'react'
import {
  InputProps,
  useTranslate,
  useInput,
  useUpdate,
  Button as BT,
  useEditContext,
} from 'react-admin'

export const CustomFormGroup = styled(FormGroup)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: theme.spacing(4),
  width: '100%',
}))

const FlexItem = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}))

const ActivateButtonInput: React.FC<
  InputProps & { resourceName: string; requestBody: { [key: string]: string | number | boolean } }
> = ({ resourceName, requestBody, ...props }) => {
  const { source, label } = props
  const { record } = useEditContext()

  const [values, setValues] = useState<{ [key: string]: string | number | boolean }>(requestBody)
  const translate = useTranslate()
  const [approve, { isLoading }] = useUpdate(`${resourceName}/${record.id}`, {
    id: source,
    data: values,
  })
  const {
    field: { onChange },
  } = useInput(props)

  return (
    <>
      <CustomFormGroup>
        {Object.entries(values).map(
          ([key, value]) =>
            typeof value === 'boolean' && (
              <FormControlLabel
                key={key}
                label={key}
                control={
                  <Switch
                    checked={value}
                    onChange={() => setValues({ ...values, [key]: !value })}
                  />
                }
              />
            )
        )}
      </CustomFormGroup>
      <FlexItem>
        <BT
          onClick={() => {
            onChange(true)
            approve()
          }}
          variant="contained"
          disabled={isLoading}
        >
          <>
            {label} {translate('ra.action.update')} (Required)
          </>
        </BT>
      </FlexItem>
    </>
  )
}

export default ActivateButtonInput
