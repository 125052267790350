import React from 'react'
import { Edit, EditProps } from 'react-admin'

import VehicleManufacturerForm from './VehicleManufacturerForm'

const VehicleManufacturerEdit: React.FC<EditProps> = props => (
  <Edit {...props}>
    <VehicleManufacturerForm />
  </Edit>
)

export default VehicleManufacturerEdit
