import { styled, Tabs, Tab } from '@mui/material'

export const CustomTabs = styled(Tabs)(({ theme }) => ({
  // backgroundColor: theme.palette.divider,
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.spacing(1),

  '& .MuiTabs-indicator': {
    display: 'none',
  },

  '& .MuiTabs-scrollButtons': {
    backgroundColor: theme.palette.secondary.main,
  },
}))

export const TabItem = styled(Tab)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  minWidth: 'fit-content',
  padding: `${theme.spacing(0.125)}rem ${theme.spacing(0.25)}rem`,

  '&.Mui-selected': {
    backgroundColor: theme.palette.grey[300],

    '& > span': {
      color: theme.palette.common.white,
      fontWeight: 'bold',
    },
  },
}))
