import { admin } from '../../types'

const enMessages = {
  resources: {
    [admin.emps]: {
      name: 'Emps',
      fields: {
        id: 'ID',
        name: 'Name',
        externalIds: 'External Ids',
        amount: 'Empnames',
      },
    },
    [admin.empnames]: {
      name: 'Empnames',
      fields: {
        id: 'ID',
        name: 'Name',
        nameAppStore: 'App store name',
        linkWebApp: 'Webapp link',
        disorderHotline: 'Disorder hotline',
        hotline: 'Hotline',
        mail: 'E-Mail',
        accessMethods: 'Access Methods',
        companyName: 'Company Name',
        street: 'Street',
        number: 'Number',
        postcode: 'PostCode',
        city: 'City',
        country: 'Country',
        address: 'Address',
      },
    },
    [admin.operators]: {
      name: 'Operators',
      fields: {
        id: 'ID',
        name: 'Name',
        companyName: 'Company Name',
        street: 'Street',
        number: 'Number',
        postcode: 'Postcode',
        city: 'City',
        country: 'Country',
        disorderHotline: 'Disorder Hotline',
        hotline: 'Hotline',
        mail: 'E-Mail',
        address: 'Address',
        connect: 'Network Connections',
      },
    },
    [admin.tariffs]: {
      name: 'Tariffs',
      fields: {
        perPeriod: 'perPeriod',
        country: 'country',
        basicFee: 'basicFee',
        currency: 'currency',
        per: 'per',
      },
    },
    [admin.vehicle_manufacturers]: {
      name: 'Vehicle Manufacturers',
      fields: {
        id: 'ID',
        name: 'Name',
        isDefault: 'is Default',
      },
    },
    [admin.vehicle_models]: {
      name: 'Vehicle Models',
      fields: {
        id: 'ID',
        name: 'Name',
        vehicleManufacturerId: 'Manufacturer',
      },
    },
    [admin.vehicle_designs]: {
      name: 'Vehicle Designs',
      fields: {
        name: 'Name',
        fromProductionYear: 'Productionyear from',
        untilProductionYear: 'Productionyear until',
        imageId: 'imageId',
        vehicleModelId: 'vehicleModelId',
      },
    },
    [admin.vehicle_variants]: {
      name: 'Vehicle Variants',
      fields: {
        id: 'ID',
        name: 'Name',
        vehicleDesignId: 'Vehicle Design',
        plugs: 'Plugs',
        type: 'type',
        kw: 'kw',
        ampere: 'ampere',
        onlyCP1PhaseAmpere: 'onlyCP1PhaseAmpere',
        efficiencyCharging: 'efficiencyCharging',
        phase: 'phase',
        position: 'position',
        isDefault: 'isDefault',
        internalId: 'internalId',
        chargingCurveGroupId: 'chargingCurveGroupId',
        consumptionCurveGroupId: 'consumptionCurveGroupId',
        listPrice: 'listPrice',
        categoryType: 'categoryType',
        general3dDesign: 'general3dDesign',
        engineType: 'engineType',
        vehicleDrive: 'vehicleDrive',
        doors: 'doors',
        airResistanceCwa: 'airResistanceCwa',
        emptyWeight: 'emptyWeight',
        endEnginePower: 'endEnginePower',
        endTourque: 'endTourque',
        zeroToHundredkmh: 'zeroToHundredkmh',
        hundredToTwohundredkmh: 'hundredToTwohundredkmh',
        topSpeed: 'topSpeed',
        hasFrontTrunk: 'hasFrontTrunk',
        trailHitch: 'trailHitch',
        trailHitchOnlyBikemount: 'trailHitchOnlyBikemount',
        trailHitchLoadKg: 'trailHitchLoadKg',
        maxEEnginePower: 'maxEEnginePower',
        constantEEnginePower: 'constantEEnginePower',
        etorque: 'etorque',
        batteryWeightKg: 'batteryWeightKg',
        minBatteryV: 'minBatteryV',
        maxBatteryV: 'maxBatteryV',
        batteryHeatSerie: 'batteryHeatSerie',
        batteryHeatAdditional: 'batteryHeatAdditional',
        bruttoKwh: 'bruttoKwh',
        nettoKwh: 'nettoKwh',
        consumptionKwh100City: 'consumptionKwh100City',
        consumptionKwh100Highway: 'consumptionKwh100Highway',
        maxDefaultSoc: 'maxSocDC',
        acV2x: 'acV2x',
        dcV2x: 'dcV2x',
        fuelEnginePower: 'fuelEnginePower',
        fuelEngineTorque: 'fuelEngineTorque',
        fuel: 'fuel',
        displacementCcm: 'displacementCcm',
        tankSizeKg: 'tankSizeKg',
        tankPressureBar: 'tankPressureBar',
        tankSizeL: 'tankSizeL',
      },
    },
    [admin.virtualCpacks]: {
      name: 'Virtual Cpacks',
      fields: {
        id: 'ID',
        cPackId: 'cPack ID',
        reserved: 'Reserved',
        expiresIn: 'Expires In',
        status: 'Status',
        cBox: 'cBox',
        cPlug: 'cPlug',
        cPack: 'cPack',
      },
    },
    [admin.virtualVehicles]: {
      name: 'Virtual Vehicles',
      fields: {},
    },
    [admin.station_manufacturers]: {
      name: 'Station Manufacturers',
    },
    [admin.station_types]: {
      name: 'Station Types',
      fields: {},
    },
    [admin.station_chargegroups]: {
      name: 'Station Chargegroups',
      fields: {
        locationType: 'locationType',
        id: 'id',
        addressFormatted: 'addressFormatted',
        addressPostCode: 'addressPostCode',
        addressStreet: 'addressStreet',
        addressCity: 'addressCity',
        addressCountry: 'addressCountry',
        latitude: 'latitude',
        longitude: 'longitude',
        boundaries: 'boundaries',
        pinPosition: 'pinPosition',
        approachPosition: 'approachPosition',
        approachLatitude: 'approachLatitude',
        approachLongitude: 'approachLongitude',
        approachDescriptions: 'approachDescriptions',
        images: 'images',
        internalImages: 'internalImages',
        createdAt: 'createdAt',
        updatedAt: 'updatedAt',
        locked: 'locked',
        lockedAt: 'lockedAt',
        lockUserId: 'lockUserId',
        authorUserId: 'authorUserId',
      },
      tags: {
        more: 'More',
        visible: 'Visible',
        invisible: 'Invisible',
        hide: 'Hide',
        addressSearch: 'Search Address',
        savePosition: 'Save new Position',
        reset: 'reset',
        return: 'return to position',
        point: 'Point',
        polygon: 'Polygon',
        approach: 'Approach',
        location: 'Show location',
        saveAddress: 'Save Address',
        addressFormattedNotSaved: 'addressFormatted (NotSaved)',
        currentPoint: 'Current location',
        currentAddress: 'Current Address',
        currentApproach: 'Current Approach',
      },
    },
    [admin.station_chargepoints]: {
      name: 'Station Chargepoints',
      fields: {
        parkingFullTime: 'parkingFullTime',
        parkingDuration: 'parkingDuration',
        parkingFree: 'parkingFree',
        parkingEvOnly: 'parkingEvOnly',
        parkingBarrierFree: 'parkingBarrierFree',
        parkingTrailer: 'parkingTrailer',
        parkingDifficult: 'parkingDifficult',
        pricingFree: 'pricingFree',
        payingOnSite: 'payingOnSite',
        startingPrice: 'startingPrice',
        consumptionPriceCurrency: 'consumptionPriceCurrency',
        consumptionPriceKwh: 'consumptionPriceKwh',
        consumptionPriceTime: 'consumptionPriceTime',
        consumptionPriceTimePer: 'consumptionPriceTimePer',
        consumptionPriceTimePerPeriod: 'consumptionPriceTimePerPeriod',
        serviceDescription: 'serviceDescription',
        appDescription: 'appDescription',
        internalComment: 'internalComment',
        openFullTime: 'openFullTime',
        weekday: 'weekday',
        from: 'from',
        to: 'to',
        leaveAnytime: 'leaveAnytime',
        turnoffTime: 'turnoffTime',
        turnoffEnergy: 'turnoffEnergy',
        turnoffPercentage: 'turnoffPercentage',
      },
      tags: {
        info: {
          map: 'Click on a Pin or Polygon to save',
          search: 'Use search field to search after a location',
        },
      },
    },
    [admin.cPacks]: {
      noDataAvailable: 'No data available',
      dataTable: 'Data Table',
      graph: 'Graph',
      selectOtherTimespan: 'Please select another timespan or cPack',
      filter: {
        cPackId: 'cPack Id',
        cBox12V: 'cBox 12V',
        cPlug12V: 'cPlug 12V',
      },
      list: {
        id: 'id',
        name: 'name',
        vehicleName: 'vehicle name',
        licensePlate: 'license plate',
        variantId: 'variant id',
        mileage: 'mileage',
        standing: 'standing',
        charging: 'charging',
        firmware: 'firmware version',
        cBoxBattery: 'cBox SoC',
        cBox12VDetected: 'cBox 12V',
        cBoxSpannung: 'car 12V',
        cBoxRuntime: 'cBox runtime',
        cBoxTimestamp: 'cBox timestamp',
        cPlug12VDetected: 'cPlug 12V',
        cPlugSpannung: 'cPlug voltage',
        cPlugRuntime: 'cPlug runtime',
        cPlugTimestamp: 'cPlug timestamp',
        socHvBattery: 'SoC HV Battery',
      },
    },
    [admin.documents]: {
      tags: {
        validationError: 'German (Deutsch) language is required',
      },
    },
  },
  tags: {
    general: 'General',
    contact: 'Contact',
    info: 'INFORMATION',
    changelog: 'Changelog',
    none: 'None',
    all: 'All',
    value: 'value',
    language: 'language',
    number: 'number',
    expand: 'Expand',
    hide: 'Hide',
    approve: 'APPROVE',
    duplicate: 'DUPLICATE',
    recent: 'SET RECENT',
    resetfilter: 'RESET FILTER',
    reject: 'REJECT',
    rejectReverse: 'REJECT-REVERSE',
    rejected: 'REJECTED',
  },
  changelog: {
    name: 'Changelog',
    old: 'Old',
    new: 'New',
    id: 'ID',
    type: 'TYPE',
    value: 'VALUE',
    author: 'Author',
    revision: 'Revision',
    createdAt: 'Created at',
    updatedAt: 'Last updated at',
  },
  status: {
    status: 'Current Status',
    deactivate: 'Deactivate',
    activate: 'Activate',
    extend: 'Extend Reservation',
    simStatus: 'Status Sim',
  },
  global: {
    version: 'Version',
    artist: 'powered by eClever',
    error: 'Cant find',
  },
  lockmode: {
    extend: 'CONTINUE PROCESSING',
    close: 'CLOSE PROCESSING',
    abort: 'ABORT PROCESSING',
    discard: 'Discard changes',
    description: 'Hey are you alive?',
    processingClose: 'processing will be aborted in ',
  },
  dashboard: {
    buttons: {
      add: 'Add',
      updateTimeframe: 'Update timeframe',
      switchChart: 'Switch chart',
      pieChart: 'Pie chart',
      barChart: 'Bar chart',
    },
    labels: {
      cBox: 'cBox',
      startDate: 'Startdate',
      endDate: 'Enddate',
      type: 'Type',
    },
  },
}

export default enMessages
