import { raFormLayoutLanguageEnglish } from '@eclever/ra-enterprise/ra-form-layout'
import merge from 'lodash/merge'
import polyglotI18nProvider from 'ra-i18n-polyglot'
import englishMessages from 'ra-language-english'
import { resolveBrowserLocale, TranslationMessages } from 'react-admin'

import { deMessages, enMessages } from './i18nTranslation'

const messages: { [key: string]: TranslationMessages } = {
  en: merge({ ...englishMessages, ...raFormLayoutLanguageEnglish }, { ...enMessages }),
  de: merge(
    { ...englishMessages, ...raFormLayoutLanguageEnglish },
    { ...enMessages },
    { ...deMessages }
  ),
}

const i18nProvider = polyglotI18nProvider(
  locale => (messages[locale] ? messages[locale] : messages.de),
  resolveBrowserLocale()
)

export default i18nProvider
