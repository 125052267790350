export const address = 'address'
export const addressComponents = 'addressComponents'
export const approach = 'approach'
export const location = 'location'
export const latlng = 'latlng'
export const drag = 'drag'
export const drop = 'drop'

export type drawing = 'drawing'
export type geometry = 'geometry'
export type localContext = 'localContext'
export type visualization = 'visualization'
export const places = 'places'
