import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Stack } from '@mui/material'
import Link from '@mui/material/Link'
import { useState } from 'react'
import { Loading, useShowController } from 'react-admin'
import { useQuery } from 'react-query'

import { admin } from '../../../types'
import CBoxDataHandler from './CBoxDataHandler'
import Map from './CustomDataDisplay/Map'
import StateControlledAutocomplete from './CustomInputs/StateControlledAutocomplete'
import TimespanPicker from './CustomInputs/TimespanPicker'
import { fetchDates, fetchParamsAvailable } from './Querys/ShowQuerys'

const TODAY_START = new Date().setHours(0, 0, 0, 0)

const TODAY_END = new Date().setHours(23, 59, 59, 999)

const TWO_DAYS_MS = 172800000

const CBoxShow: React.FC = () => {
  const [startDate, setStartDate] = useState<Date>(new Date(TODAY_START))
  const [endDate, setEndDate] = useState<Date>(new Date(TODAY_END))
  const [activeParams, setActiveParams] = useState<string[]>([])

  const { record } = useShowController()

  const { data: daysAvailable } = useQuery(['daysAvailable', record], () => fetchDates(record.id), {
    enabled: !!(record && record?.id),
    refetchOnWindowFocus: false,
  })

  const { data: paramsAvailable, isLoading: isLoadingParamsAvailable } = useQuery(
    [admin.cPacks, 'paramsAvailable', startDate, endDate, record],
    () => fetchParamsAvailable(record.id, startDate, endDate),
    {
      enabled: !!(record && record?.id) && !!startDate && !!endDate,
      refetchOnWindowFocus: false,
    }
  )

  if (!record) return <Loading />

  const newProps = {
    startDate,
    endDate,
    activeParams,
    id: record.id,
  }

  return (
    <>
      {record && <p>{record.id}</p>}
      <Stack spacing={1}>
        <Link width={'fit-content'} href="/#/admin/cpacks">
          <ArrowBackIcon />
        </Link>

        {daysAvailable && (
          <TimespanPicker
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            maxTimeSpanMs={TWO_DAYS_MS}
            daysAvailable={daysAvailable}
          />
        )}

        <Map {...newProps} />

        {paramsAvailable && paramsAvailable.length > 0 && !isLoadingParamsAvailable && (
          <StateControlledAutocomplete
            options={paramsAvailable.map((option: string) => option)}
            value={activeParams}
            setValue={setActiveParams}
          />
        )}

        {isLoadingParamsAvailable && <Loading />}

        <CBoxDataHandler {...newProps} />
      </Stack>
    </>
  )
}

export default CBoxShow
