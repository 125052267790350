import React from 'react'
import { Create, CreateProps } from 'react-admin'

import EmpsForm from './EmpsForm'

const EmpsCreate: React.FC<CreateProps> = props => (
  <Create {...props}>
    <EmpsForm />
  </Create>
)

export default EmpsCreate
