import { Clear, Edit, Visibility } from '@mui/icons-material'
import { Box, Paper } from '@mui/material'
import { isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { InputProps, useNotify } from 'react-admin'
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd'
import { useFormContext, useController, useWatch } from 'react-hook-form'

import systemConfig from '../../../../../config'
import { admin } from '../../../../../types'
import ImageEdit from './ImageEdit'
import ImageShow from './ImageShow'
import {
  GridBox,
  CustomBox,
  Subtitle,
  CustomDate,
  ImageMini,
  ImageWrapper,
  DragWrapper,
} from './ImageStyles'
import ImageUploader from './ImageUploader'
import { getName } from './ImageUtils'

export type ColumnType = { [key: string]: string[] }

export type FileType = {
  cacheUrl: string
  file: File
}

export type ImageType = { id: string; createdAt: string }

const ImageInput: React.FC<InputProps> = props => {
  useWatch({ name: 'internalImages' })
  useWatch({ name: 'images' })
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isShow, setIsShow] = useState<boolean>(false)
  const [newImage, setnewImage] = useState<string | null>()

  const [currentImage, setCurrentImage] = useState<string>('')
  const notify = useNotify()

  const { getValues, setValue } = useFormContext()
  const value = getValues()
  const {
    field: { value: internalImages, onChange: internalImagesOnChange },
  } = useController({ name: 'internalImages' })

  useEffect(() => {
    if (!isEmpty(newImage)) {
      internalImagesOnChange([...internalImages, { id: newImage, createdAt: new Date() }])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newImage])

  const handleDrop = async (acceptedFiles: File[]) => {
    acceptedFiles?.forEach(async (file: File) => {
      const bodyFormData = new FormData()
      bodyFormData.append('file', file)
      fetch(`${systemConfig.backendBaseURL}/${admin.station_chargegroupsImages}`, {
        method: 'POST',
        body: bodyFormData,
      })
        .then(data => data.json())
        .then(json => {
          setnewImage(json[0].url)
          return json[0].url
        })
        .catch(e => {
          notify('Error: comment not approved', { type: 'warning' })
        })
      setnewImage(null)
    })
  }

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return
    const { source, destination } = result

    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = value[source.droppableId]
      const destColumn = value[destination.droppableId]
      const sourceItems = [...sourceColumn]
      const destItems = [...destColumn]
      const [removed] = sourceItems.splice(source.index, 1)
      destItems.splice(destination.index, 0, removed)

      setValue(source.droppableId, sourceItems)
      setValue(destination.droppableId, destItems)
    } else {
      const column = value[source.droppableId]
      const copiedItems = [...column]
      const [removed] = copiedItems.splice(source.index, 1)
      copiedItems.splice(destination.index, 0, removed)

      setValue(source.droppableId, copiedItems)
    }
  }

  return (
    <>
      <ImageShow isShow={isShow} setIsShow={setIsShow} image={currentImage} />
      <ImageEdit {...props} isOpen={isOpen} setIsOpen={setIsOpen} image={currentImage} />
      <ImageUploader handleDrop={handleDrop} />
      <Box>
        <DragDropContext onDragEnd={result => onDragEnd(result)}>
          <GridBox>
            {Object.entries({
              images: value?.images || [],
              internalImages: value?.internalImages || [],
            })?.map(([columnId, column]) => (
              <CustomBox key={columnId}>
                <Subtitle variant="h5"> {getName(columnId)} </Subtitle>
                <CustomBox margin={2} width="100%">
                  <Droppable droppableId={columnId} key={columnId}>
                    {(providedSuper, snapshotSuper) => (
                      <DragWrapper
                        {...providedSuper.droppableProps}
                        ref={providedSuper.innerRef}
                        style={{
                          background: snapshotSuper.isDraggingOver ? 'lightblue' : 'lightgrey',
                        }}
                      >
                        {column?.map((item: ImageType, index: number) => (
                          <Draggable
                            key={item.id}
                            draggableId={'draggable-' + item.id}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                key={item.id}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                ref={provided.innerRef}
                                style={{
                                  backgroundColor: snapshot.isDragging ? '#263B4A' : '#456C86',
                                  ...provided.draggableProps.style,
                                }}
                              >
                                <ImageWrapper>
                                  <div style={{ width: '80%', height: '100%' }}>
                                    <ImageMini src={item.id} alt="" />
                                    <CustomDate variant="h6">
                                      {' '}
                                      {new Date(item.createdAt).toUTCString()}{' '}
                                    </CustomDate>
                                  </div>
                                  <Paper
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      overflow: 'hidden',
                                      width: '20%',
                                    }}
                                  >
                                    <Clear
                                      onClick={() =>
                                        setValue(
                                          columnId,
                                          column.filter((image: ImageType) => image.id !== item.id)
                                        )
                                      }
                                      style={{ cursor: 'pointer' }}
                                    />
                                    <Edit
                                      onClick={() => {
                                        setCurrentImage(item.id)
                                        setIsOpen(true)
                                      }}
                                      style={{ cursor: 'pointer' }}
                                    />
                                    <Visibility
                                      onClick={() => {
                                        setCurrentImage(item.id)
                                        setIsShow(true)
                                      }}
                                      style={{ cursor: 'pointer' }}
                                    />
                                  </Paper>
                                </ImageWrapper>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {providedSuper.placeholder}
                      </DragWrapper>
                    )}
                  </Droppable>
                </CustomBox>
              </CustomBox>
            ))}
          </GridBox>
        </DragDropContext>
      </Box>
    </>
  )
}

export default ImageInput
