import produce, { Draft } from 'immer'
import { State, StateCreator } from 'zustand'

/**
 * Turn the set method into an immer proxy
 * @param config state config
 */
export const immer =
  <T extends State>(
    config: StateCreator<T, (fn: (draft: Draft<T>) => void) => void>
  ): StateCreator<T> =>
  (set, get, api) =>
    config(fn => set(produce(fn) as unknown as (state: T) => T), get, api)
