import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  SelectChangeEvent,
} from '@mui/material'
import {
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid'
import { useState } from 'react'

const DROPDOWN_VALUES = [
  { value: 25, label: '25' },
  { value: 50, label: '50' },
  { value: 100, label: '100' },
  { value: 500, label: '500' },
  { value: -1, label: 'All' },
]

const CustomPagination: React.FC = () => {
  const apiRef = useGridApiContext()
  const page = useGridSelector(apiRef, gridPageSelector)
  const pageCount = useGridSelector(apiRef, gridPageCountSelector)
  const [selectValue, setSelectValue] = useState<number>(100)

  const selectChanged = (event: SelectChangeEvent<number>) => {
    apiRef.current.setPageSize(event.target.value as number)
    if (event.target.value === -1) {
      apiRef.current.setPage(0)
    }
    setSelectValue(event.target.value as number)
  }

  return (
    <Box display={'flex'} alignContent={'flex-end'} alignItems={'center'}>
      <Box sx={{ minWidth: 120 }}>
        <FormControl fullWidth>
          <InputLabel id="cpack-pagination-select-label">PageSize</InputLabel>
          <Select
            labelId="cpack-pagination-select-label"
            id="cpack-pagination-select"
            value={selectValue}
            label="PageSize"
            onChange={selectChanged}
          >
            {DROPDOWN_VALUES.map(e => (
              <MenuItem value={e.value} key={e.value}>
                {e.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Pagination
        count={pageCount}
        page={page + 1}
        onChange={(_, value) => {
          apiRef.current.setPage(value - 1)
        }}
      />
    </Box>
  )
}

export default CustomPagination
