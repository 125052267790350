import { Slider } from '@mui/material'
import React from 'react'

const ProgressBar: React.FC<{ progress: number; setProgressBarValue: (a: number) => void }> = ({
  progress,
  setProgressBarValue,
}) => {
  return (
    <Slider
      onChange={(_, val) => typeof val === 'number' && setProgressBarValue(val)}
      value={progress}
    />
  )
}

export default ProgressBar
