import React from 'react'
import { Create, CreateProps } from 'react-admin'

import VehicleModelForm from './VehicleModelForm'

const VehicleModelCreate: React.FC<CreateProps> = props => (
  <Create {...props}>
    <VehicleModelForm />
  </Create>
)

export default VehicleModelCreate
