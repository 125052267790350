import { isNil } from 'lodash'
import React from 'react'
import { CreateProps, useNotify, useRedirect, Create } from 'react-admin'

import useBreadCrumps from '../../../hooks/useBreadCrumps'
import { admin } from '../../../types'
import StationTypesForm from './StationTypesForm'

const StationTypesCreate: React.FC<CreateProps> = props => {
  const { breadCrumps } = useBreadCrumps()
  const id = breadCrumps[admin.station_manufacturers]
  const redirect = useRedirect()
  const notify = useNotify()

  if (isNil(id)) {
    notify('ra.message.error')
    redirect(`/${admin.station_manufacturers}`)
  }

  return (
    <Create
      {...props}
      sx={{
        '& .MuiCard-root': {
          overflow: 'visible',
        },
      }}
    >
      <StationTypesForm
        defaultValues={{
          stationManufacturerId: id,
        }}
      />
    </Create>
  )
}

export default StationTypesCreate
