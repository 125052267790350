import DirectionsCarIcon from '@mui/icons-material/DirectionsCar'
import { ResourceProps } from 'react-admin'

import VehicleModelCreate from './VehicleModelCreate'
import VehicleModelEdit from './VehicleModelEdit'
import VehicleModelList from './VehicleModelList'

const VehicleModels: Omit<ResourceProps, 'name'> = {
  options: { label: 'Vehicle Models' },
  list: VehicleModelList,
  icon: DirectionsCarIcon,
  create: VehicleModelCreate,
  edit: VehicleModelEdit,
}

export default VehicleModels
