import { isEmpty } from 'lodash'
import React from 'react'

import { InputElementType, InputType } from '../../../types'

export const addSource: (o: InputElementType[], sourceShort: string) => InputElementType[] = (
  o,
  sourceShort
) =>
  o.map(one =>
    React.cloneElement(one, {
      ...one.props,
      label: one.props.label || one.props.source,
      source: `${sourceShort}.${one.props.source}`,
    })
  )

export const convertArrayToInputType = (o: InputType, field: InputElementType): InputType => {
  const label = field.props.label
  if (o[field.props.source] && typeof label === 'string') {
    o[label] = React.cloneElement(field, {
      key: label,
      label: field.props.source,
      ...field.props,
    })
  } else {
    o[field.props.source] = React.cloneElement(field, {
      key: field.props.source,
      label: field.props.source,
      ...field.props,
    })
  }
  return o
}

export const getNotNullElements: (data: {
  [key: string]: string
}) => { [key: string]: string } | null | undefined = data => {
  if ((data && isEmpty(data)) || !data) {
    return
  }

  const elements = Object.entries(data).reduce((pre, [key, value]) => {
    if (!isEmpty(value)) {
      return { ...pre, [key]: value }
    }
    return pre
  }, {})

  return isEmpty(elements) ? null : elements
}

export default convertArrayToInputType
