import { getPermissionsFromRoles } from '@eclever/ra-enterprise/ra-rbac'
import { AuthProvider } from 'ra-core'
import { fetchUtils } from 'react-admin'

import systemConfig from '../config'
import { StorageUtil } from '../utils'
import roles from './Roles'

const ECleverAuthProvider: AuthProvider = {
  login: ({ username, password }) => {
    return fetchUtils
      .fetchJson(systemConfig.backendBaseURL + '/admin/auth/login', {
        method: 'POST',
        body: JSON.stringify({ username, password }),
      })
      .then(({ json }) => {
        StorageUtil.setLocalStorageItem(systemConfig.tokenStorageKey, json.accessToken)
        StorageUtil.setLocalStorageItem(systemConfig.tokenRefreshStorageKey, json.refreshToken)
        StorageUtil.setLocalStorageItem(systemConfig.permissions, JSON.stringify(json.roles))
        StorageUtil.setLocalStorageItem(
          systemConfig.tokenDate,
          new Date().getTime() + json.expiresIn
        )
        return json
      })
  },
  logout: () => {
    StorageUtil.removeLocalStorageItem(systemConfig.tokenStorageKey)
    StorageUtil.removeLocalStorageItem(systemConfig.tokenRefreshStorageKey)
    StorageUtil.removeLocalStorageItem(systemConfig.permissions)
    StorageUtil.removeLocalStorageItem(systemConfig.tokenDate)
    return Promise.resolve()
  },
  checkAuth: () => {
    const expiresInDate = StorageUtil.getLocalStorageItem(systemConfig.tokenDate)
    if (expiresInDate && new Date().getTime() < parseInt(expiresInDate)) {
      return Promise.resolve()
    }
    if (StorageUtil.getLocalStorageItem(systemConfig.tokenRefreshStorageKey)) {
      return fetchUtils
        .fetchJson(systemConfig.backendBaseURL + '/admin/auth/refresh', {
          method: 'POST',
          body: JSON.stringify({
            refreshToken: StorageUtil.getLocalStorageItem(systemConfig.tokenRefreshStorageKey),
          }),
        })
        .then(({ json }) => {
          StorageUtil.setLocalStorageItem(systemConfig.tokenStorageKey, json.accessToken)
          StorageUtil.setLocalStorageItem(systemConfig.permissions, JSON.stringify(json.roles))
          StorageUtil.setLocalStorageItem(systemConfig.tokenRefreshStorageKey, json.refreshToken)
          StorageUtil.setLocalStorageItem(
            systemConfig.tokenDate,
            new Date().getTime() + json.expiresIn
          )
          return json
        })
    }
    StorageUtil.removeLocalStorageItem(systemConfig.tokenStorageKey)
    StorageUtil.removeLocalStorageItem(systemConfig.tokenRefreshStorageKey)
    StorageUtil.removeLocalStorageItem(systemConfig.permissions)
    return Promise.reject('Auth Error')
  },
  checkError: ({ status }) => {
    if (status === 401) {
      StorageUtil.removeLocalStorageItem(systemConfig.tokenStorageKey)
      StorageUtil.removeLocalStorageItem(systemConfig.tokenRefreshStorageKey)
      StorageUtil.removeLocalStorageItem(systemConfig.permissions)
      return Promise.reject('Check Error')
    }
    return Promise.resolve()
  },
  // getPermissions: () => Promise.resolve(),
  getPermissions: () => {
    const role = StorageUtil.getLocalStorageItem(systemConfig.permissions)
    return role
      ? Promise.resolve(
          getPermissionsFromRoles({
            roleDefinitions: { ...roles },
            // permissions for the current user
            userPermissions: [],
            // roles of the current user
            userRoles: JSON.parse(role),
          })
        )
      : Promise.reject('Permissions Error')
  },
  getRoles: () => {
    return Promise.resolve(roles)
  },
}

export default ECleverAuthProvider
