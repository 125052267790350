import React from 'react'
import { Create, CreateProps } from 'react-admin'

import ConsumptionCurveGroupsForm from './ConsumptionCurveGroupsForm'

const ConsumptionCurveGroupsCreate: React.FC<CreateProps> = props => (
  <Create {...props}>
    <ConsumptionCurveGroupsForm />
  </Create>
)

export default ConsumptionCurveGroupsCreate
