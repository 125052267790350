import React from 'react'
import { Edit, EditProps } from 'react-admin'

import EmpsForm from './EmpsForm'

const EmpsEdit: React.FC<EditProps> = props => (
  <Edit {...props}>
    <EmpsForm />
  </Edit>
)

export default EmpsEdit
