import { useCanAccess } from '@eclever/ra-enterprise/ra-rbac'
import { Chip } from '@mui/material'
import React from 'react'
import {
  Datagrid,
  ListProps,
  ChipField,
  InputProps,
  useRecordContext,
  RaRecord,
  ReferenceInput,
  SelectInput,
  EditButton,
  sanitizeListRestProps,
} from 'react-admin'

import useFilters from '../../hooks/useFilters'
import { admin } from '../../types'
import Pagination from '../shared/Components/Pagination'
import StickyColumnWrapper from '../shared/Components/StickyColumnWrapper'
import List from '../shared/Components/StyledComponents/List'
import Toolbar from '../shared/Toolbar/Toolbar'

const DocumentsFilter = [
  <ReferenceInput source="key" reference="admin/enums/documentKey" alwaysOn>
    <SelectInput optionText="id" />
  </ReferenceInput>,
]

const LanguageField: React.FC<InputProps> = props => {
  const record: RaRecord = useRecordContext(props)

  return (
    <>
      {record?.localizations?.map((localization: { [key: string]: string }) => (
        <Chip
          style={{ margin: '0.5rem' }}
          key={localization.language}
          label={localization.language}
          variant="outlined"
        />
      ))}
    </>
  )
}

const DocumentsList: React.FC<ListProps> = props => {
  const { filter } = useFilters()
  const { canAccess } = useCanAccess({
    action: 'edit',
    resource: admin.documents,
  })

  return (
    <List
      {...sanitizeListRestProps(props)}
      pagination={<Pagination />}
      filters={DocumentsFilter}
      filter={filter[admin.documents]}
      bulkActionButtons={false}
      exporter={false}
      empty={false}
      actions={<Toolbar reference={admin.documents} resource={admin.documents} />}
    >
      <Datagrid rowClick="edit">
        <ChipField source="key" />
        <LanguageField source="language" />
        <StickyColumnWrapper {...props}>{canAccess && <EditButton />}</StickyColumnWrapper>
      </Datagrid>
    </List>
  )
}

export default DocumentsList
