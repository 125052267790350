import { TableHead, TableBody, Table, TableRow, TableCell, TableSortLabel } from '@mui/material'
import React from 'react'
import {
  TextFieldProps,
  useTranslate,
  useRecordContext,
  RaRecord,
  useListContext,
} from 'react-admin'

import { admin } from '../../../../types'
import timer from '../../../../utils/timer'
import BooleanField from '../../../shared/Components/BooleanField'
import { getDirection } from '../StationChargepointsUtils'

const ParkingTimesField: React.FC<TextFieldProps> = props => {
  const record: RaRecord = useRecordContext(props)
  const translate = useTranslate()
  const { sort, setSort } = useListContext(props)

  return (
    <Table>
      <TableHead>
        <TableRow>
          {['parkingFullTime', 'parkingDuration', 'weekday', 'from', 'to'].map(recordName => (
            <TableCell key={recordName}>
              {recordName === 'parkingFullTime' ? (
                <TableSortLabel
                  direction={getDirection(sort, recordName)}
                  onClick={() => setSort({ field: recordName, order: sort.order })}
                  active={sort.field === recordName}
                >
                  {translate(`resources.${admin.station_chargepoints}.fields.${recordName}`)}{' '}
                </TableSortLabel>
              ) : (
                translate(`resources.${admin.station_chargepoints}.fields.${recordName}`)
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow key="parkingFullTime">
          <TableCell rowSpan={record?.parkingHours?.length + 2}>
            <BooleanField element={record?.parkingFullTime} />
          </TableCell>
        </TableRow>
        <TableRow key="parkingDuration">
          <TableCell rowSpan={record?.parkingHours?.length + 3}>
            {record?.parkingDuration && timer(record?.parkingDuration * 60)}
          </TableCell>
        </TableRow>
        {record?.parkingHours?.map(
          (key: { day: string; fromInMinutes: number; untilInMinutes: number }, index: number) => (
            <TableRow key={index}>
              <TableCell rowSpan={1}> {key.day} </TableCell>
              <TableCell rowSpan={1}> {timer(key.fromInMinutes)} </TableCell>
              <TableCell rowSpan={1}> {timer(key.untilInMinutes)} </TableCell>
            </TableRow>
          )
        )}
      </TableBody>
    </Table>
  )
}

export default ParkingTimesField
