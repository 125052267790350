import systemConfig from '../../../../config/SystemConfig'
import authHttpClient from '../../../../provider/AuthHttpClient'
import * as types from '../../../../types'

const baseUrl = systemConfig.backendBaseURL + `/${types.admin.cPacks}/`

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function fetchDates(id: string) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return authHttpClient(baseUrl + id + '/dates').then(res => res.json.data)
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function fetchMapData(id: string, from: Date, to: Date) {
  const params = encodeURIComponent(`{"from":${from.getTime() / 1000},"to":${to.getTime() / 1000}}`)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return authHttpClient(baseUrl + id + '/locations?filter=' + params).then(res => res.json.data)
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function fetchParamsAvailable(id: string, startDate: Date, endDate: Date) {
  const params = encodeURIComponent(
    `{"from":${startDate.getTime() / 1000},"to":${endDate.getTime() / 1000}}`
  )
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return authHttpClient(baseUrl + id + '/parameters?filter=' + params).then(res => res.json.data)
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function fetchCPackData(id: string, startDate: Date, endDate: Date, params: string[]) {
  const paramString = params.map(x => `"${x}"`).join(',')
  const param = encodeURIComponent(
    `{"from":${startDate.getTime() / 1000},"to":${
      endDate.getTime() / 1000
    },"params":[${paramString}]}`
  )
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return authHttpClient(baseUrl + id + '/values?filter=' + param).then(res => res.json.data)
}
