import { useCanAccess } from '@eclever/ra-enterprise/ra-rbac'
import React from 'react'
import {
  Datagrid,
  ListProps,
  Loading,
  TextField,
  useGetList,
  EditButton,
  NumberInput,
  NumberField,
  TextInput,
  sanitizeListRestProps,
  useListController,
} from 'react-admin'
import { useNavigate } from 'react-router-dom'

import useBreadCrumps from '../../../hooks/useBreadCrumps'
import useFilters from '../../../hooks/useFilters'
import { admin } from '../../../types'
import BreadCrumbs, { handleRowClick } from '../../shared/Components/BreadCrumbs'
import FilterInputWrapper from '../../shared/Components/FilterInputWrapper'
import FilterWrapper from '../../shared/Components/FilterWrapper'
import Pagination from '../../shared/Components/Pagination'
import StickyColumnWrapper from '../../shared/Components/StickyColumnWrapper'
import List from '../../shared/Components/StyledComponents/List'
import Toolbar from '../../shared/Toolbar/Toolbar'

const StationManufacturersFilter = [
  // <FilterInputWrapper source="id" validElement="number">
  //   <NumberInput source="id" alwaysOn />
  // </FilterInputWrapper>,
  <FilterWrapper
    source="id"
    reference={admin.station_manufacturers}
    resource={admin.station_manufacturers}
    alwaysOn
  >
    <NumberInput source="id" />
  </FilterWrapper>,
  <FilterInputWrapper source="name" validElement="string">
    <TextInput source="name" />
  </FilterInputWrapper>,
]

const StationManufacturersList: React.FC<ListProps> = props => {
  const navigate = useNavigate()
  const { resource } = useListController()
  const { addId } = useBreadCrumps()
  const { filter: filterResource, removeAllFilterOfResource } = useFilters()
  const { data, isLoading, error } = useGetList(admin.station_types, {
    pagination: { page: 1, perPage: -1 },
  })
  const { isLoading: loadAccess, canAccess } = useCanAccess({
    action: 'edit',
    resource: resource,
  })

  if (isLoading || loadAccess) {
    return <Loading />
  }

  if (error) {
    return <>ERROR</>
  }

  return (
    <>
      <BreadCrumbs resource={resource} />
      <List
        {...sanitizeListRestProps(props)}
        filter={filterResource[resource]}
        pagination={<Pagination />}
        filters={StationManufacturersFilter}
        actions={<Toolbar reference={resource} resource={resource} />}
        empty={false}
      >
        <Datagrid
          rowClick={id =>
            handleRowClick(
              id,
              addId,
              removeAllFilterOfResource,
              admin.station_types,
              resource,
              navigate
            )
          }
          isRowSelectable={record =>
            canAccess && data?.filter(item => item.stationManufacturerId === record.id).length === 0
          }
        >
          <NumberField source="id" />
          <TextField source="name" />
          <StickyColumnWrapper {...props}>{canAccess && <EditButton />}</StickyColumnWrapper>
        </Datagrid>
      </List>
    </>
  )
}

export default StationManufacturersList
