import React from 'react'
import {
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  SimpleFormProps,
  TextInput,
} from 'react-admin'

import useBreadCrumps from '../../../hooks/useBreadCrumps'
import { admin } from '../../../types'

const VehicleModelForm: React.FC<Omit<SimpleFormProps, 'children'>> = props => {
  const { breadCrumps } = useBreadCrumps()
  const manufacturersId = breadCrumps && breadCrumps[admin.vehicle_manufacturers]

  if (!manufacturersId) {
    return <>ERROR</>
  }

  return (
    <SimpleForm
      {...props}
      defaultValues={{
        vehicleManufacturerId: manufacturersId,
      }}
    >
      <ReferenceInput
        label="Manufacturer"
        source="vehicleManufacturerId"
        reference={admin.vehicle_manufacturers}
        defaultValue={manufacturersId}
        perPage={-1}
      >
        <SelectInput optionText="name" fullWidth disabled validate={[required()]} />
      </ReferenceInput>
      <TextInput source="name" fullWidth validate={[required()]} />
    </SimpleForm>
  )
}

export default VehicleModelForm
