import { useCanAccess } from '@eclever/ra-enterprise/ra-rbac'
import { Chip } from '@mui/material'
import React from 'react'
import {
  TextField,
  ListProps,
  FunctionField,
  RaRecord,
  NumberField,
  useGetList,
  Loading,
  Datagrid,
  EditButton,
  TextInput,
  sanitizeListRestProps,
  NumberInput,
  useListController,
} from 'react-admin'
import { useNavigate } from 'react-router-dom'

import useBreadCrumps from '../../../hooks/useBreadCrumps'
import useFilters from '../../../hooks/useFilters'
import { admin } from '../../../types'
import BreadCrumbs, { handleRowClick } from '../../shared/Components/BreadCrumbs'
import FilterInputWrapper from '../../shared/Components/FilterInputWrapper'
import FilterWrapper from '../../shared/Components/FilterWrapper'
import Pagination from '../../shared/Components/Pagination'
import ReferenceCountField from '../../shared/Components/ReferenceCountField'
import StickyColumnWrapper from '../../shared/Components/StickyColumnWrapper'
import List from '../../shared/Components/StyledComponents/List'
import Toolbar from '../../shared/Toolbar/Toolbar'

const EmpFilter = [
  <FilterWrapper reference={admin.emps} source="id" alwaysOn>
    <NumberInput source="id" />
  </FilterWrapper>,
  <FilterInputWrapper source="name" validElement="string">
    <TextInput source="" />
  </FilterInputWrapper>,
  <FilterInputWrapper source="externalId" validElement="string">
    <TextInput source="" />
  </FilterInputWrapper>,
]

const EmpsList: React.FC<ListProps> = props => {
  const navigate = useNavigate()
  const { resource } = useListController()
  const { filter, removeAllFilterOfResource } = useFilters()
  const { data, isLoading, error } = useGetList(admin.empnames, {
    pagination: { page: 1, perPage: -1 },
  })
  const { addId } = useBreadCrumps()
  const { isLoading: loadAccess, canAccess } = useCanAccess({
    action: 'edit',
    resource: admin.emps,
  })

  if (isLoading || loadAccess) {
    return <Loading />
  }

  if (error) {
    return <>ERROR</>
  }

  return (
    <>
      <BreadCrumbs resource={resource} />
      <List
        {...sanitizeListRestProps(props)}
        filter={filter[resource]}
        pagination={<Pagination />}
        filters={EmpFilter}
        actions={<Toolbar reference={admin.emps} resource={admin.emps} />}
        empty={false}
      >
        <Datagrid
          rowClick={id =>
            handleRowClick(id, addId, removeAllFilterOfResource, admin.empnames, resource, navigate)
          }
          isRowSelectable={record =>
            (canAccess &&
              data &&
              Object.values(data).filter((item: RaRecord) => item.empId === record.id).length ===
                0) ||
            false
          }
        >
          <NumberField source="id" />
          <TextField source="name" />
          <ReferenceCountField
            label="amount"
            reference={admin.empnames}
            source="id"
            target="empId"
            sortable={false}
          />
          <FunctionField
            source="externalIds"
            render={(record: RaRecord | undefined) => (
              <div>
                {record &&
                  record?.externalIds?.map((value: string) => (
                    <Chip
                      key={value}
                      style={{ margin: '0.5rem' }}
                      label={`${value}`}
                      variant="outlined"
                    />
                  ))}
              </div>
            )}
          />
          <StickyColumnWrapper {...props}>{canAccess && <EditButton />}</StickyColumnWrapper>
        </Datagrid>
      </List>
    </>
  )
}

export default EmpsList
