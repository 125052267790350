import React from 'react'
import {
  Create,
  CreateProps,
  useNotify,
  useRefresh,
  useRedirect,
  useCreateContext,
} from 'react-admin'

import { admin } from '../../../types'
import StationChargegroupsForm from './StationChargegroupsForm'
import { transform } from './StationChargegroupsUtils'

const StationChargegroupsCreate: React.FC<CreateProps> = props => {
  const { resource } = useCreateContext()
  const notify = useNotify()
  const refresh = useRefresh()
  const redirect = useRedirect()

  const onSuccess = () => {
    notify('ra.action.create')
    redirect(`/${admin.station_chargegroups}`)
    refresh()
  }

  return (
    <Create {...props} mutationOptions={{ onSuccess }} transform={transform}>
      <StationChargegroupsForm resource={resource} />
    </Create>
  )
}

export default StationChargegroupsCreate
