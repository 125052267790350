import { ResourceProps } from 'react-admin'

import CPacksCreate from './CPacksCreate'
import CPacksEdit from './CPacksEdit'
import CPacksList from './CPacksList'

const CPacks: Omit<ResourceProps, 'name'> = {
  options: { label: 'Virtual cPacks' },
  list: CPacksList,
  create: CPacksCreate,
  edit: CPacksEdit,
}

export default CPacks
