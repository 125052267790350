import { Clear, Done } from '@mui/icons-material/'
import { Tooltip } from '@mui/material/'
import React from 'react'
import { FieldProps } from 'react-admin'

const BooleanField: React.FC<FieldProps & { element: boolean | undefined }> = ({
  className,
  element,
}) => (
  <Tooltip className={className} title=" ">
    {element ? (
      <span>
        <Done data-testid="true" fontSize="small" />
      </span>
    ) : (
      <span>
        <Clear data-testid="false" fontSize="small" />
      </span>
    )}
  </Tooltip>
)

export default BooleanField
