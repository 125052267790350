import { admin } from '../types'

const roles = {
  BACKOFFICE_OWNER: [
    {
      resource: '*',
      action: '*',
    },
  ],
  BACKOFFICE_ADMIN: [
    {
      resource: '*',
      action: '*',
    },
  ],
  BACKOFFICE_ROLEMANAGERS_READ: [
    {
      resource: admin.users,
      action: ['read', 'list'],
    },
  ],
  BACKOFFICE_ROLEMANAGERS_WRITE: [
    {
      resource: admin.users,
      action: '*',
    },
  ],
  BACKOFFICE_VEHICLES_READ: [
    {
      resource: admin.vehicle_models,
      action: ['read', 'list'],
    },
    {
      resource: admin.vehicle_variants,
      action: ['read', 'list'],
    },
    {
      resource: admin.vehicle_designs,
      action: ['read', 'list'],
    },
    {
      resource: admin.vehicle_manufacturers,
      action: ['read', 'list'],
    },
    {
      resource: admin.charging_curve_groups,
      action: ['read', 'list'],
    },
    {
      resource: admin.consumption_curve_groups,
      action: ['read', 'list'],
    },
  ],
  BACKOFFICE_VEHICLES_WRITE: [
    {
      resource: admin.vehicle_models,
      action: '*',
    },
    {
      resource: admin.vehicle_variants,
      action: '*',
    },
    {
      resource: admin.vehicle_designs,
      action: '*',
    },
    {
      resource: admin.vehicle_manufacturers,
      action: '*',
    },
    {
      resource: admin.charging_curve_groups,
      action: '*',
    },
    {
      resource: admin.consumption_curve_groups,
      action: '*',
    },
  ],
  BACKOFFICE_STATIONS_READ: [
    {
      resource: admin.station_types,
      action: ['read', 'list'],
    },
    {
      resource: admin.station_manufacturers,
      action: ['read', 'list'],
    },
    {
      resource: admin.station_chargegroups,
      action: ['read', 'list'],
    },
    {
      resource: admin.station_chargepoints,
      action: ['read', 'list'],
    },
    {
      resource: admin.station_chargegroup_drafts,
      action: ['read', 'list'],
    },
    {
      resource: admin.station_chargepoint_drafts,
      action: ['read', 'list'],
    },
    {
      resource: admin.emps,
      action: ['read', 'list'],
    },
    {
      resource: admin.empnames,
      action: ['read', 'list'],
    },
    {
      resource: admin.emp_operators,
      action: ['read', 'list'],
    },
    {
      resource: admin.operators,
      action: ['read', 'list'],
    },
  ],
  BACKOFFICE_STATIONS_WRITE: [
    {
      resource: admin.station_types,
      action: '*',
    },
    {
      resource: admin.station_manufacturers,
      action: '*',
    },
    {
      resource: admin.station_chargegroups,
      action: '*',
    },
    {
      resource: admin.station_chargepoints,
      action: '*',
    },
    {
      resource: admin.station_chargegroup_drafts,
      action: '*',
    },
    {
      resource: admin.station_chargepoint_drafts,
      action: '*',
    },
    {
      resource: admin.emps,
      action: '*',
    },
    {
      resource: admin.empnames,
      action: '*',
    },
    {
      resource: admin.emp_operators,
      action: '*',
    },
    {
      resource: admin.operators,
      action: '*',
    },
  ],
  BACKOFFICE_VIRTUAL_CPACKS_READ: [
    {
      resource: admin.virtualCpacks,
      action: ['read', 'list'],
    },
    {
      resource: admin.virtualVehicles,
      action: ['read', 'list'],
    },
  ],
  BACKOFFICE_VIRTUAL_CPACKS_WRITE: [
    {
      resource: admin.virtualCpacks,
      action: '*',
    },
    {
      resource: admin.virtualVehicles,
      action: '*',
    },
  ],
}

export default roles
