import { ControlPointDuplicate } from '@mui/icons-material'
import { Modal, Box, TextField, Autocomplete } from '@mui/material'
import { isEmpty } from 'lodash'
import { useState, useEffect } from 'react'
import {
  Button,
  useTranslate,
  useCreate,
  useGetList,
  LinearProgress,
  RaRecord,
  useNotify,
  ToolbarProps,
  useListController,
  useRefresh,
} from 'react-admin'

import { admin } from '../../types'

const DuplicateButton: React.FC<ToolbarProps> = props => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [selected, setSelected] = useState<RaRecord | null>(null)
  const [input, setInput] = useState<string>('')
  const [copyValue, setCopyValue] = useState<string>('')
  const { resource } = useListController(props)
  const translate = useTranslate()
  const notfiy = useNotify()
  const [create, { isLoading: createLoading, error: createError }] = useCreate()
  const {
    data,
    error: getListError,
    refetch,
  } = useGetList(resource || '', {
    pagination: { page: 1, perPage: 100 },
    sort: { field: 'id', order: 'ASC' },
    filter: { id: (!isEmpty(input) && Number(input)) || undefined },
  })
  const [loaded, setLoaded] = useState<boolean>(false)
  const refresh = useRefresh()

  useEffect(() => {
    refresh()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded])

  const handleClick = () => {
    setLoaded(false)
    const parseCopyValue = parseInt(copyValue)
    if (!isNaN(parseCopyValue) && selected) {
      for (let i = 0; i < parseCopyValue; i++) {
        if (resource === admin.station_chargepoints) {
          create(
            resource,
            { data: { ...selected, evseId: null, directPaymentLink: null } },
            { onSuccess: () => setLoaded(true) }
          )
        }
      }
      setIsOpen(false)
    } else {
      notfiy('ra.message.error', { type: 'error' })
    }
  }

  if (createError || getListError || !resource) {
    return <p>ERROR</p>
  }

  if (createLoading) {
    return <LinearProgress />
  }

  return (
    <>
      <Button
        startIcon={<ControlPointDuplicate />}
        variant="text"
        onClick={() => setIsOpen(!isOpen)}
        label={translate('tags.duplicate')}
      />
      <Modal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          style={{
            padding: 10,
            position: 'absolute',
            display: 'grid',
            alignItems: 'stretch',
            gridTemplateColumns: 'repeat(3, 1fr)',
            gridGap: 10,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%,-50%)',
            backgroundColor: 'white',
            borderRadius: 10,
          }}
        >
          <TextField
            value={copyValue}
            onChange={e => setCopyValue(e.target.value)}
            variant="outlined"
            placeholder="Duplicate Number"
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          />
          <Autocomplete
            value={selected}
            options={data || []}
            getOptionLabel={(option: RaRecord) => `${option.id}`}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={params => (
              <TextField {...params} variant="outlined" label={resource} fullWidth />
            )}
            onInputChange={(_event: React.SyntheticEvent, value: string) => {
              setInput(value)
              refetch()
            }}
            onChange={(_event, newValues) => setSelected(newValues)}
          />
          <Button variant="outlined" onClick={handleClick} label={translate('tags.duplicate')} />
        </Box>
      </Modal>
    </>
  )
}

export default DuplicateButton
