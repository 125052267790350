import { Chip, Grid } from '@mui/material'
import * as React from 'react'
import {
  FieldProps,
  RaRecord,
  TextField,
  ReferenceField,
  BooleanField,
  FunctionField,
} from 'react-admin'

import { admin } from '../../../../types'
import {
  DescriptionField,
  OpeningTimesField,
  ParkingTimesField,
  WrapperField,
} from '../../StationChargepoints/CustomFields'
import {
  openingTimes,
  parkingTimes,
  parking,
  price,
  turnoffInformation,
  description,
} from '../StationChargepointsDraftsConstants'

const PropertiesField: React.FC<
  FieldProps & {
    source: string
    data: RaRecord | undefined
    diffs?: string[]
  }
> = ({ source, data, diffs, ...props }) => {
  const newProps = { ...props, record: data }

  switch (source) {
    case description:
      return <DescriptionField {...newProps} />
    case openingTimes:
      return <OpeningTimesField {...newProps} />
    case parking:
      return (
        <WrapperField
          {...newProps}
          fieldName={[
            'parkingFree',
            'parkingEvOnly',
            'parkingBarrierFree',
            'parkingTrailer',
            'parkingDifficult',
          ]}
        />
      )
    case parkingTimes:
      return <ParkingTimesField {...newProps} />
    case price:
      return (
        <WrapperField
          {...newProps}
          fieldName={[
            'pricingFree',
            'payingOnSite',
            'consumptionPriceCurrency',
            'startingPrice',
            'consumptionPriceKwh',
            'consumptionPriceTime',
            'consumptionPriceTimePer',
            'consumptionPriceTimePerPeriod',
          ]}
        />
      )
    case turnoffInformation:
      return (
        <WrapperField
          {...newProps}
          fieldName={['turnoffTime', 'turnoffEnergy', 'turnoffPercentage']}
        />
      )
    case 'accessMethods':
      return (
        <FunctionField
          {...newProps}
          render={(record: RaRecord | undefined) => (
            <>
              {record && record?.accessMethods && (
                <Grid container direction="row" spacing={1}>
                  {record?.accessMethods.map((method: string) => (
                    <Grid item key={method}>
                      <Chip label={method} />
                    </Grid>
                  ))}
                </Grid>
              )}
            </>
          )}
        />
      )
    case 'chargegroupId':
      return (
        <ReferenceField
          label="chargeGroupId"
          source="chargegroupId"
          reference={admin.station_chargegroups}
        >
          <TextField source="id" />
        </ReferenceField>
      )
    case 'manufacturerId':
      return (
        <ReferenceField
          {...newProps}
          label="Manufacturer"
          source="manufacturerId"
          reference={admin.station_manufacturers}
          link={false}
        >
          <TextField source="name" />
        </ReferenceField>
      )
    case 'buildType':
      return (
        <ReferenceField
          {...newProps}
          label="buildType"
          source="buildType"
          reference={admin.station_types}
          link={false}
        >
          <TextField source="name" />
        </ReferenceField>
      )
    case 'operatorId':
      return (
        <ReferenceField
          {...newProps}
          label="Operator"
          source="operatorId"
          reference={admin.operators}
        >
          <TextField source="name" />
        </ReferenceField>
      )
    default:
      if (
        [
          'chargepointStatus',
          'hasCable',
          'isWatercooled',
          'loadManagement',
          'singlePhaseCurrentLimitation',
          'teslaOnly',
        ].includes(source)
      ) {
        return <BooleanField {...newProps} source={source} />
      }

      return <TextField {...newProps} source={source} />
  }
}

export default PropertiesField
