import {
  BooleanField,
  Datagrid,
  List,
  ListProps,
  NumberField,
  Pagination,
  TextField,
  TextInput,
  AutocompleteArrayInput,
  NumberInput,
  BooleanInput,
} from 'react-admin'

import ChipListField from './ChipListField'

const choices = [
  //climate
  { id: 'insideActualTemp', name: 'insideActualTemp' },
  { id: 'insideTempSetting', name: 'insideTempSetting' },
  { id: 'insideTempSettingMax', name: 'insideTempSettingMax' },
  { id: 'insideTempSettingMin', name: 'insideTempSettingMin' },
  { id: 'ventilationOn', name: 'ventilationOn' },
  { id: 'acCompressorOn', name: 'acCompressorOn' },
  { id: 'heatingOn', name: 'heatingOn' },
  { id: 'outsideAirPressure', name: 'outsideAirPressure' },
  { id: 'outsideAirTemp', name: 'outsideAirTemp' },
  { id: 'consumptionManufacturer', name: 'consumptionManufacturer' },
  { id: 'eMotorTemp', name: 'eMotorTemp' },
  { id: 'eMotorActualCurrent', name: 'eMotorActualCurrent' },
  { id: 'recuperationStrength', name: 'recuperationStrength' },
  { id: 'rangeManufacturer', name: 'rangeManufacturer' },
  { id: 'rangeCalculated', name: 'rangeCalculated' },
  { id: 'hvBatteryVoltage', name: 'hvBatteryVoltage' },
  { id: 'hvBatteryCurrent', name: 'hvBatteryCurrent' },
  { id: 'hvBatteryTemp', name: 'hvBatteryTemp' },
  { id: 'hvCellVoltageMin', name: 'hvCellVoltageMin' },
  { id: 'hvCellVoltageMax', name: 'hvCellVoltageMax' },
  { id: 'hvCellVoltageAvg', name: 'hvCellVoltageAvg' },
  { id: 'hvCellVoltages', name: 'hvCellVoltages' },
  { id: 'hvCellTemperatureMin', name: 'hvCellTemperatureMin' },
  { id: 'hvCellTemperatureMax', name: 'hvCellTemperatureMax' },
  { id: 'hvCellTemperatureAvg', name: 'hvCellTemperatureAvg' },
  { id: 'hvCellTemperatures', name: 'hvCellTemperatures' },
  { id: 'hvCellDeviationVoltage', name: 'hvCellDeviationVoltage' },
  { id: 'hvChargePortOpen', name: 'hvChargePortOpen' },
  { id: 'hvPlugInChargePort', name: 'hvPlugInChargePort' },
  { id: 'hvPilotCurrent', name: 'hvPilotCurrent' },
  { id: 'hvChargePower', name: 'hvChargePower' },
  { id: 'hvChargeVoltage', name: 'hvChargeVoltage' },
  { id: 'hvChargeCurrent', name: 'hvChargeCurrent' },
  { id: 'hvChargePhasen', name: 'hvChargePhasen' },
  { id: 'hvChargeRate', name: 'hvChargeRate' },
  { id: 'hvChargeTimeToFullManufacturer', name: 'hvChargeTimeToFullManufacturer' },
  { id: 'hvChargeEnergyAddedManufacturer', name: 'hvChargeEnergyAddedManufacturer' },
  { id: 'hvChargeTimeToFull', name: 'hvChargeTimeToFull' },
  { id: 'hvChargePortTemp', name: 'hvChargePortTemp' },
  { id: 'hvChargePortLocked', name: 'hvChargePortLocked' },
  { id: 'hvChargePortFreeAfterLoading', name: 'hvChargePortFreeAfterLoading' },
  { id: 'hvActualDriveBatteryAh', name: 'hvActualDriveBatteryAh' },
  { id: 'hvActualDriveBatteryKwh', name: 'hvActualDriveBatteryKwh' },
  { id: 'hvCoolingTemp', name: 'hvCoolingTemp' },
  { id: 'hvCoolingPumpOn', name: 'hvCoolingPumpOn' },
  { id: 'hvCoolingFanOn', name: 'hvCoolingFanOn' },
  { id: 'hvCoolingPumpRpm', name: 'hvCoolingPumpRpm' },
  { id: 'hvCoolingPumpCurrent', name: 'hvCoolingPumpCurrent' },
  { id: 'hvHeatingOn', name: 'hvHeatingOn' },
  { id: 'hvAcDcChargerCurrent', name: 'hvAcDcChargerCurrent' },
  { id: 'hvAcDcChargerVoltage', name: 'hvAcDcChargerVoltage' },
  { id: 'hvAcDcChargerPumpOn', name: 'hvAcDcChargerPumpOn' },
  { id: 'hvPowerElectronicTemp', name: 'hvPowerElectronicTemp' },
  { id: 'hvPowerElectronicPumpOn', name: 'hvPowerElectronicPumpOn' },
  { id: 'hvSocActualBms', name: 'hvSocActualBms' },
  { id: 'hvSocActualDisplay', name: 'hvSocActualDisplay' },
  { id: 'hvSocLimitAc', name: 'hvSocLimitAc' },
  { id: 'hvSocLimitDc', name: 'hvSocLimitDc' },
  { id: 'hvSocLimitAcMax', name: 'hvSocLimitAcMax' },
  { id: 'hvSocLimitDcMax', name: 'hvSocLimitDcMax' },
  { id: 'hvSocLimitAcMin', name: 'hvSocLimitAcMin' },
  { id: 'hvSocLimitDcMin', name: 'hvSocLimitDcMin' },
  { id: 'hvSocLimitAcStd', name: 'hvSocLimitAcStd' },
  { id: 'hvSocLimitDcStd', name: 'hvSocLimitDcStd' },
  { id: 'hvStateOfHealth', name: 'hvStateOfHealth' },
  { id: 'hvAvailableChargePower', name: 'hvAvailableChargePower' },
  { id: 'hvAvailableDischargePower', name: 'hvAvailableDischargePower' },
  { id: 'dcDcConverterTemp', name: 'dcDcConverterTemp' },
  { id: 'rpmEmotor', name: 'rpmEmotor' },
  { id: 'rpmTransmission', name: 'rpmTransmission' },
  { id: 'consumptionManufacturer', name: 'consumptionManufacturer' },
  { id: 'manufacturer', name: 'manufacturer' },
  { id: 'model', name: 'model' },
  { id: 'registrationMonth', name: 'registrationMonth' },
  { id: 'registrationYear', name: 'registrationYear' },
  { id: 'vin', name: 'vin' },
  { id: 'color', name: 'color' },
  { id: 'softwareVersion', name: 'softwareVersion' },
  { id: 'odoMeter', name: 'odoMeter' },
  { id: 'user', name: 'user' },
  { id: 'displayName', name: 'displayName' },
  { id: 'ignitionOn', name: 'ignitionOn' },
  { id: 'speed', name: '' },
  { id: 'tilt', name: 'tilt' },
  { id: 'breakPedalPosition', name: 'breakPedalPosition' },
  { id: 'endActualTorque', name: 'endActualTorque' },
  { id: 'endActualPower', name: 'endActualPower' },
  { id: 'steeringAngel', name: 'steeringAngel' },
  { id: 'gearPosition', name: 'gearPosition' },
  { id: 'throttlePosition', name: 'throttlePosition' },
  { id: 'actualDrivingMode', name: 'actualDrivingMode' },
  { id: 'locked', name: 'locked' },
  { id: 'lockedStatusSupported', name: 'lockedStatusSupported' },
  { id: 'doorFlOpen', name: 'doorFlOpen' },
  { id: 'windowFlOpen', name: 'windowFlOpen' },
  { id: 'doorFrOpen', name: 'doorFrOpen' },
  { id: 'windowFrOpen', name: 'windowFrOpen' },
  { id: 'doorBlOpen', name: 'doorBlOpen' },
  { id: 'windowBlOpen', name: 'windowBlOpen' },
  { id: 'doorBrOpen', name: 'doorBrOpen' },
  { id: 'windowBrOpen', name: 'windowBrOpen' },
  { id: 'frontTrunkOpen', name: 'frontTrunkOpen' },
  { id: 'trunkOpen', name: 'trunkOpen' },
  { id: 'sunRononeressureFR', name: 'tirePressureFR' },
  { id: 'tirePressureBL', name: 'tirePressureBL' },
  { id: 'tirePressureBr', name: 'tirePressureBr' },
  { id: 'trailerOperationOn', name: 'trailerOperationOn' },
]

const VehicleCapabilitiesFilter = [
  <TextInput source="name" alwaysOn />,
  <NumberInput source="firmware" label={'Mindest Firmware'} />,
  <BooleanInput source="obdTriggersAlarm" defaultValue={true} />,
  <BooleanInput source="noDataWithoutIgnition" defaultValue={true} />,
  <BooleanInput source="obdTriggersWarningWhileStanding" defaultValue={true} />,
  <AutocompleteArrayInput source="readableTags" choices={choices} />,
  <AutocompleteArrayInput source="unreadableTags" choices={choices} />,
]

const VehicleCapabilitiesList: React.FC<ListProps> = props => {
  return (
    <List {...props} filters={VehicleCapabilitiesFilter} pagination={<Pagination />}>
      <Datagrid>
        <NumberField source="id" sortable={false} />
        <TextField source="name" />
        <NumberField source="firmware" label={'Mindest Firmware'} />
        <BooleanField source="obdTriggersAlarm" />
        <BooleanField source="obdTriggersWarningWhileStanding" />
        <BooleanField source="noDataWithoutIgnition" />
        <ChipListField source="obd.climate" label="Climate" sortable={false} />
        <ChipListField source="obd.eMotor" label="E-Motor" sortable={false} />
        <ChipListField source="obd.basicCarInfo" label="Basic Car Info" sortable={false} />
        <ChipListField source="obd.driveState" label="Drive State" sortable={false} />
        <ChipListField source="obd.bodyAndChassis" label="Body And Chassis" sortable={false} />
      </Datagrid>
    </List>
  )
}

export default VehicleCapabilitiesList
