import { FieldProps, NullableBooleanInput, useListController } from 'react-admin'

import { admin } from '../../../types'

const RejectedFlagField: React.FC<
  FieldProps & {
    drafts: typeof admin.station_chargegroup_drafts | typeof admin.station_chargepoint_drafts
  }
> = ({ drafts, ...props }) => {
  const { resource } = useListController()
  const { source } = props

  if (!resource || !source || resource !== drafts) {
    return null
  }

  return <NullableBooleanInput source={source} />
}

export default RejectedFlagField
