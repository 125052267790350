import React from 'react'
import {
  BooleanInput,
  SimpleFormProps,
  required,
  SimpleForm,
  TextInput,
  FormDataConsumer,
} from 'react-admin'

import EditToolbar from '../../shared/Toolbar/EditToolbar'

const VehicleManufacturerForm: React.FC<Omit<SimpleFormProps, 'children'>> = props => {
  return (
    <SimpleForm {...props} toolbar={<EditToolbar />} defaultValues={{ name: '', isDefault: false }}>
      <FormDataConsumer source="id">
        {({ formData, ...rest }) =>
          formData.id && (
            <TextInput source="id" isRequired validate={[required()]} disabled {...rest} />
          )
        }
      </FormDataConsumer>
      <BooleanInput source="isDefault" />
      <TextInput source="name" validate={[required()]} />
    </SimpleForm>
  )
}

export default VehicleManufacturerForm
