import currencyToSymbolMap from 'currency-symbol-map/map'
import {
  TextInput,
  ReferenceInput,
  AutocompleteInput,
  required,
  FormDataConsumer,
  ArrayInput,
  SimpleFormIterator,
  NumberInput,
  BooleanInput,
  AutocompleteArrayInput,
} from 'react-admin'

import { admin, InputElementType, InputType } from '../../../types'
import { TimeInput } from '../../Stations/StationChargepoints/CustomInputs/DayTimeInput'
import GridBox from '../../shared/Components/GridBox'
import convertArrayToInputType from '../../shared/Utils/convert'
import countriesArray from '../../shared/Utils/getCountries'
import DependsInput from './CustomInputs/DependsInput'
import ValidEmpOperators from './CustomInputs/ValidEmpOperators'

const countries = countriesArray()
const currencies = Object.keys(currencyToSymbolMap).map((curreny: string) => ({
  id: curreny.toUpperCase(),
  name: curreny.toUpperCase(),
}))

const general: InputElementType[] = [
  <FormDataConsumer key="id" source="id">
    {({ formData, ...rest }) =>
      formData.id && (
        <TextInput source="id" fullWidth isRequired validate={[required()]} disabled {...rest} />
      )
    }
  </FormDataConsumer>,
  <DependsInput source="useAccessMethod" depends={['empNameId', 'accessMethod']}>
    <BooleanInput source="useAccessMethod" />
  </DependsInput>,
  <FormDataConsumer key="empName" label="empName" fullWidth>
    {({ formData, ...rest }) =>
      !formData.useAccessMethod && (
        <ReferenceInput
          {...rest}
          source="empNameId"
          sort={{ field: 'name', order: 'ASC' }}
          reference={admin.empnames}
          perPage={-1}
          fullWidth
          isRequired
          validate={[required()]}
        >
          <AutocompleteInput optionText="name" fullWidth isRequired validate={[required()]} />
        </ReferenceInput>
      )
    }
  </FormDataConsumer>,
  <FormDataConsumer key="accessMethod" source="accessMethod" fullWidth>
    {({ formData, ...rest }) =>
      formData.useAccessMethod && (
        <ReferenceInput
          {...rest}
          fullWidth
          label="accessMethod"
          reference="admin/enums/accessMethod"
          source="accessMethod"
          isRequired
          validate={[required()]}
        >
          <AutocompleteInput optionText="id" fullWidth isRequired validate={[required()]} />
        </ReferenceInput>
      )
    }
  </FormDataConsumer>,
  <TextInput source="name" fullWidth isRequired validate={[required()]} />,
  <ArrayInput key="basicFees" source="basicFees" fullWidth>
    <SimpleFormIterator>
      <AutocompleteInput
        fullWidth
        source="country"
        choices={countries}
        isRequired
        validate={[required()]}
      />
      <FormDataConsumer key="basicFees">
        {({ getSource }) =>
          getSource && (
            <GridBox>
              <NumberInput
                label="basicFee"
                source={getSource('basicFee')}
                fullWidth
                isRequired
                validate={[required()]}
              />
              <AutocompleteInput
                source={getSource('currency')}
                label="currency"
                choices={currencies}
                fullWidth
                isRequired
                validate={[required()]}
              />
              <NumberInput
                label="per"
                source={getSource('per')}
                fullWidth
                isRequired
                validate={[required()]}
              />
              <ReferenceInput
                fullWidth
                reference="admin/enums/period"
                source={getSource('perPeriod')}
                label="perPeriod"
                isRequired
                validate={[required()]}
              >
                <AutocompleteInput optionText="id" fullWidth isRequired validate={[required()]} />
              </ReferenceInput>
            </GridBox>
          )
        }
      </FormDataConsumer>
    </SimpleFormIterator>
  </ArrayInput>,
]

const pricing: (ac: boolean, name: string) => InputElementType[] = (ac, name) => [
  <ArrayInput key={name} source={name} fullWidth>
    <SimpleFormIterator>
      <BooleanInput
        source="ac"
        label="ac"
        defaultValue={ac}
        fullWidth
        isRequired
        validate={[required()]}
        disabled
      />
      <FormDataConsumer key="countries" fullWidth>
        {({ getSource }) =>
          getSource && (
            <GridBox sx={{ gridTemplateColumns: '1fr 2fr' }}>
              <BooleanInput
                source={getSource('includeCountries')}
                label="includeCountries"
                defaultValue={true}
              />
              <AutocompleteArrayInput
                source={getSource('countries')}
                label="countries"
                choices={countries}
                fullWidth
              />
            </GridBox>
          )
        }
      </FormDataConsumer>
      <FormDataConsumer key="operators" fullWidth>
        {({ getSource }) =>
          getSource && (
            <GridBox sx={{ gridTemplateColumns: '1fr 2fr' }}>
              <BooleanInput
                source={getSource('includeOperators')}
                label="includeOperators"
                defaultValue={true}
                fullWidth
              />
              <ValidEmpOperators source={getSource('operators')} label="operators" />
            </GridBox>
          )
        }
      </FormDataConsumer>
      <AutocompleteInput
        source="currency"
        choices={currencies}
        fullWidth
        isRequired
        validate={[required()]}
      />

      <NumberInput
        source="startingPrice"
        label="startingPrice"
        fullWidth
        isRequired
        validate={[required()]}
      />
      <NumberInput
        source="consumptionPrice"
        label="consumptionPrice"
        fullWidth
        isRequired
        validate={[required()]}
      />
      <ArrayInput source="penalties" label="penalties" fullWidth>
        <SimpleFormIterator key="penalties">{Object.values(penalities)}</SimpleFormIterator>
      </ArrayInput>
    </SimpleFormIterator>
  </ArrayInput>,
]

const penalities: InputElementType[] = [
  <FormDataConsumer key="fee" fullWidth>
    {({ getSource }) =>
      getSource && (
        <GridBox>
          <NumberInput
            source={getSource('fee')}
            label="fee"
            isRequired
            validate={[required()]}
            fullWidth
          />
          <NumberInput
            source={getSource('per')}
            label="per"
            isRequired
            validate={[required()]}
            fullWidth
          />
          <ReferenceInput
            reference="admin/enums/period"
            source={getSource('perPeriod')}
            label="perPeriod"
            isRequired
            validate={[required()]}
            fullWidth
          >
            <AutocompleteInput optionText="id" fullWidth isRequired validate={[required()]} />
          </ReferenceInput>
        </GridBox>
      )
    }
  </FormDataConsumer>,
  <FormDataConsumer key="after" fullWidth>
    {({ getSource }) =>
      getSource && (
        <GridBox>
          <NumberInput
            source={getSource('after')}
            label="after"
            isRequired
            validate={[required()]}
            fullWidth
          />
          <NumberInput source={getSource('until')} label="until" fullWidth />
          <ReferenceInput
            fullWidth
            reference="admin/enums/period"
            source={getSource('afterPeriod')}
            label="afterPeriod"
            isRequired
            validate={[required()]}
          >
            <AutocompleteInput optionText="id" fullWidth isRequired validate={[required()]} />
          </ReferenceInput>
        </GridBox>
      )
    }
  </FormDataConsumer>,
  <FormDataConsumer key="time">
    {({ scopedFormData, getSource }) =>
      getSource && (
        <GridBox>
          <DependsInput
            source="hasTime"
            depends={[getSource('validFrom'), getSource('validUntil')]}
          >
            <BooleanInput
              defaultValue={scopedFormData?.validFrom || scopedFormData?.validUntil ? true : false}
              label="hasTime"
              source={getSource('hasTime')}
            />
          </DependsInput>
          {scopedFormData?.hasTime && (
            <TimeInput label="validFrom" source={getSource('validFrom')} fullWidth />
          )}
          {scopedFormData?.hasTime && (
            <TimeInput label="validUntil" source={getSource('validUntil')} fullWidth />
          )}
        </GridBox>
      )
    }
  </FormDataConsumer>,
]

const TariffsInputs: Record<string, InputType> = {
  general: general.reduce(convertArrayToInputType, {}),
  acPricing: pricing(true, 'acPricing').reduce(convertArrayToInputType, {}),
  dcPricing: pricing(false, 'dcPricing').reduce(convertArrayToInputType, {}),
}

export default TariffsInputs
