import { ImageType } from './ImageInput'

export const dataURItoBlob: (dataURI: string) => Blob = dataURI => {
  const byteString = atob(dataURI.split(',')[1])
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

  const ab = new ArrayBuffer(byteString.length)
  const ia = new Uint8Array(ab)
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }

  return new Blob([ab], { type: mimeString })
}

export const getImages: (
  recordImages: ImageType[],
  oldImage: string,
  newImage: ImageType
) => ImageType[] = (recordImages, oldImage, newImage) =>
  recordImages.map((image: ImageType) => {
    if (oldImage === image.id) {
      return newImage
    }
    return image
  })

const images = 'images'
const internalImages = 'internalImages'

export const getName: (value: string) => string = value => {
  switch (value) {
    case images:
      return 'Visible'
    case internalImages:
      return 'Invisible'
    default:
      return ''
  }
}
