import React from 'react'
import { FieldProps, useRecordContext } from 'react-admin'

const LinkRecordField: React.FC<{ uri: string; source: string } & FieldProps> = props => {
  const { source, uri } = props
  const record = useRecordContext()

  return <a href={uri + record[source]}>{record[source]}</a>
}

export default LinkRecordField
