import React from 'react'
import { Create, CreateProps } from 'react-admin'

import DocumentsForm from './DocumentsForm'

const DocumentsCreate: React.FC<CreateProps> = props => (
  <Create {...props}>
    <DocumentsForm />
  </Create>
)

export default DocumentsCreate
