import { Box, TextField, Chip, Autocomplete } from '@mui/material'
import isEmpty from 'lodash/isEmpty'
import React, { useState, useEffect } from 'react'
import { AutocompleteInputProps, InputProps, useInput } from 'react-admin'

import { CustomTabs, TabItem } from '../../../../shared/LocalizationInput/LocalizationStyles'
import { castInput, convertIsoOptionsToValues } from './InternalizationUtils'

export const LanguagesInput: React.FC<InputProps & AutocompleteInputProps> = ({ ...props }) => {
  const { field } = useInput(props)
  const { onChange: onChangeForm, value: valueInput } = field
  const { label, choices } = props
  const groupedChoices =
    (choices && [...choices].sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))) || []

  return (
    <Autocomplete
      {...field}
      clearOnEscape
      disableCloseOnSelect
      multiple
      limitTags={5}
      value={castInput(valueInput)}
      options={groupedChoices}
      groupBy={option => option?.firstLetter}
      getOptionLabel={option => option?.id}
      isOptionEqualToValue={(option, value) => option?.id === value?.id}
      renderInput={params => (
        <TextField {...params} variant="standard" label={label} placeholder={`${label}`} />
      )}
      onChange={(_event, newValues) => onChangeForm(convertIsoOptionsToValues(newValues))}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <Chip
            label={option?.id}
            {...getTagProps({ index })}
            onClick={() => {
              const filterValue = { ...valueInput }
              delete filterValue[option?.id]
              onChangeForm({ ...filterValue })
            }}
          />
        ))
      }
    />
  )
}

export const InternalizationInput: React.FC<InputProps> = props => {
  const { field } = useInput(props)
  const { onChange, value: formValue } = field
  const [value, setValue] = useState<string | null>(formValue ? Object.keys(formValue)[0] : null)
  const locales = (formValue && Object.keys(formValue)?.sort()) || []
  const valueExists = value && !locales.includes(value) ? locales[0] : value

  // Issue onChange type conflict: https://github.com/mui-org/material-ui/issues/17454
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange: (event: React.SyntheticEvent, newValue: string) => void = (
    event,
    newValue
  ) => {
    event.preventDefault()
    setValue(newValue)
  }

  useEffect(() => {
    if (!value || !locales.includes(value)) {
      setValue(locales[0])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValue])

  return !isEmpty(locales) ? (
    <Box sx={{ flexGrow: 1, display: 'flex', height: 200 }}>
      <CustomTabs
        orientation="vertical"
        variant="scrollable"
        value={valueExists}
        aria-label="Vertical tabs example"
        onChange={handleChange}
      >
        {locales.map((locale: string) => (
          <TabItem
            value={locale}
            key={locale}
            label={locale}
            id={`vertical-tab-${locale}`}
            aria-controls={`vertical-tabpanel-${locale}`}
          />
        ))}
      </CustomTabs>
      {formValue &&
        Object.entries(formValue).map(([key, languageValue]) => (
          <div
            key={key}
            role="tabpanel"
            hidden={value !== key}
            id={`vertical-tabpanel-${key}`}
            aria-labelledby={`vertical-tab-${key}`}
            style={{ width: '100%', height: '100%' }}
          >
            {value === key && (
              <Box sx={{ p: 3 }}>
                <TextField
                  fullWidth
                  label={key.toUpperCase()}
                  value={languageValue}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    onChange({
                      ...formValue,
                      [key]: event.currentTarget.value,
                    })
                  }}
                />
              </Box>
            )}
          </div>
        ))}
    </Box>
  ) : (
    <></>
  )
}
