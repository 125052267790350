// v2/admin
export const emps = 'admin/emps'
export const empnames = 'admin/empnames'
export const emp_operators = 'admin/emp-operators'
export const tariffs = 'admin/tariffs'
export const pricing = 'admin/pricing'
export const penalties = 'admin/penalties'
export const operators = 'admin/operators'
export const vehicle_manufacturers = 'admin/vehicle-manufacturers'
export const vehicle_models = 'admin/vehicle-models'
export const vehicle_designs = 'admin/vehicle-designs'
export const vehicle_designsImages = 'admin/vehicle-designs/images'
export const vehicle_variants = 'admin/vehicle-variants'
export const virtualCpacksNewVehicle_cpack = 'admin/virtual/cpacks/new/vehicle-cpack'
export const virtualCpacks = 'admin/virtual/cpacks'
export const virtualVehicles = 'admin/virtual/vehicles'
export const virtualVehiclesRecent = 'admin/virtual/vehicles/recent'
export const station_types = 'admin/station-types'
export const station_manufacturers = 'admin/station-manufacturers'
export const station_chargegroups = 'admin/station-chargegroups'
export const station_chargegroupsImages = 'admin/station-chargegroups/images'
export const station_chargegroup_drafts = 'admin/station-chargegroup-drafts'
export const station_chargepoints = 'admin/station-chargepoints'
export const station_chargepoint_drafts = 'admin/station-chargepoint-drafts'
export const charging_curve_groups = 'admin/charging-curve-groups'
export const consumption_curve_groups = 'admin/consumption-curve-groups'
export const vehicle_capabilities = 'admin/vehicle-capabilities'
export const users = 'admin/users'
export const usersMe = 'admin/users/me'
export const station_locks = 'admin/station-locks'
export const documents = 'admin/documents'
export const cPacks = 'admin/cpacks'
