import React from 'react'
import { Edit, EditProps } from 'react-admin'

import DocumentsForm from './DocumentsForm'

const DocumentsEdit: React.FC<EditProps> = props => (
  <Edit {...props}>
    <DocumentsForm />
  </Edit>
)

export default DocumentsEdit
