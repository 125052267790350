import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import React from 'react'
import { TextFieldProps, useTranslate, useRecordContext, RaRecord } from 'react-admin'

import { admin } from '../../../../types'

const BoundariesField: React.FC<Omit<TextFieldProps, 'children'>> = props => {
  const translate = useTranslate()
  const record: RaRecord = useRecordContext(props)

  return (
    <>
      {record?.latitude && record?.longitude && (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell> {translate('tags.number')} </TableCell>
              <TableCell>
                {' '}
                {translate(`resources.${admin.station_chargegroups}.fields.latitude`)}{' '}
              </TableCell>
              <TableCell>
                {' '}
                {translate(`resources.${admin.station_chargegroups}.fields.longitude`)}{' '}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!record?.boundaries && record?.latitude && record?.longitude && (
              <TableRow key={`${record?.latitude}, ${record?.longitude}`}>
                <TableCell> {1} </TableCell>
                <TableCell> {record?.latitude} </TableCell>
                <TableCell> {record?.longitude} </TableCell>
              </TableRow>
            )}
            {record?.boundaries?.map(
              (item: { latitude: number; longitude: number }, index: number) => (
                <TableRow key={JSON.stringify(item) + index}>
                  <TableCell> {index + 1} </TableCell>
                  <TableCell> {item.latitude} </TableCell>
                  <TableCell> {item.longitude} </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      )}
    </>
  )
}

export default BoundariesField
