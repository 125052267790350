export const openingTimes = 'openingTimes'
export const parkingTimes = 'parkingTimes'
export const parking = 'parking'
export const price = 'price'
export const turnoffInformation = 'turnoffInformation'
export const description = 'description'

export type AttributeType =
  | typeof openingTimes
  | typeof parkingTimes
  | typeof parking
  | typeof price
  | typeof turnoffInformation
  | typeof description
