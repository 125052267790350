import { styled } from '@mui/material'
import React from 'react'
import {
  TextInput,
  SelectInput,
  SimpleFormProps,
  ReferenceInput,
  required,
  SimpleForm,
  NumberInput,
  FormDataConsumer,
} from 'react-admin'

import useBreadCrumps from '../../../hooks/useBreadCrumps'
import { admin } from '../../../types'
import EditToolbar from '../../shared/Toolbar/EditToolbar'
import ImageInput from './CustomInputs/ImageInput'

const VehicleDesignForm: React.FC<Omit<SimpleFormProps, 'children'>> = props => {
  const { breadCrumps } = useBreadCrumps()
  const vehicleModelId = breadCrumps && breadCrumps[admin.vehicle_models]

  if (!vehicleModelId) {
    return <>ERROR</>
  }

  return (
    <SimpleForm
      toolbar={<EditToolbar />}
      {...props}
      defaultValues={{
        vehicleModelId: vehicleModelId,
      }}
    >
      <FormDataConsumer source="id">
        {({ formData, ...rest }) =>
          formData.id && (
            <TextInput source="id" isRequired validate={[required()]} disabled {...rest} />
          )
        }
      </FormDataConsumer>
      <ReferenceInput
        label="Model"
        source="vehicleModelId"
        reference={admin.vehicle_models}
        perPage={-1}
      >
        <SelectInput optionText="name" fullWidth disabled validate={[required()]} />
      </ReferenceInput>
      <TextInput source="name" validate={[required()]} fullWidth />
      <NumberInput source="fromProductionYear" validate={[required()]} fullWidth />
      <NumberInput source="untilProductionYear" fullWidth />
      <ReferenceInput source="general3dDesign" reference="admin/enums/general3dDesign">
        <SelectInput optionText="id" isRequired validate={[required()]} fullWidth />
      </ReferenceInput>
      <FlexItem>
        <ImageInput source="image" accept="image/*" fieldName="Front View" fullWidth />
        <ImageInput source="imageCharging" accept="image/*" fieldName="Charge View" fullWidth />
      </FlexItem>
    </SimpleForm>
  )
}

const FlexItem = styled('div')(() => ({
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  gap: '6rem',
}))

export default VehicleDesignForm
