const timer: (timer: number) => string = (time: number) => {
  // Hours, minutes and seconds
  const hrsNumber = 3600
  const minNumber = 60

  const hrs = ~~(time / hrsNumber)
  const mins = ~~((time % hrsNumber) / minNumber)
  const secs = ~~time % minNumber

  // Output like "1:01" or "4:03:59" or "123:03:59"
  let ret = ''
  if (hrs > 0) {
    ret += '' + hrs + ':' + (mins < 10 ? '0' : '')
  }
  if (mins < 10) {
    ret += '0' + mins + ':' + (secs < 10 ? '0' : '')
  } else {
    ret += '' + mins + ':' + (secs < 10 ? '0' : '')
  }
  ret += '' + secs
  return ret
}

export const revertTime: (timerNumber: string) => number = timerNumber => {
  const result = timerNumber.split(':')
  const hrs = Number(result[0])
  const min = Number(result[1])

  if (isNaN(hrs) || isNaN(min)) {
    return 0
  }

  return hrs * 60 + min
}

export const getCurrentTime: (time: number) => Date = time => {
  const hrsNumber = 3600
  const minNumber = 60

  const hrs = ~~((time % hrsNumber) / minNumber)
  const mins = ~~time % minNumber
  const date = new Date()
  return new Date(date.getFullYear(), date.getMonth(), date.getDay(), hrs, mins, 0, 0)
}

export default timer
