import { Link } from '@mui/material'
import { stringify } from 'query-string'
import React from 'react'
import {
  BooleanField,
  NumberField,
  TextField,
  ReferenceField,
  DateField,
  InputProps,
  RaRecord,
  useRecordContext,
  useListController,
  FunctionField,
} from 'react-admin'
import { useNavigate } from 'react-router-dom'

import useBreadCrumps from '../../../hooks/useBreadCrumps'
import useFilters from '../../../hooks/useFilters'
import { admin, ColumnsType, FieldType } from '../../../types'
import { handleRowClick } from '../../shared/Components/BreadCrumbs'
import {
  OpeningTimesField,
  WrapperField,
  ParkingTimesField,
  DescriptionField,
} from './CustomFields'
import ChipsField from './CustomFields/ChipsField'

const createLink = (reference: string, filterElement: number | string) => {
  const query = {
    range: JSON.stringify([0, 9]),
    filter: JSON.stringify({ id: filterElement }),
  }
  const displayQuery = {
    displayedFilters: JSON.stringify({ id: filterElement }),
  }
  return `/${reference}?${stringify(displayQuery)}&${stringify(query)}`
}

const IdField: React.FC<InputProps> = props => {
  const navigate = useNavigate()
  const record: RaRecord = useRecordContext(props)
  const { addId } = useBreadCrumps()
  const { removeAllFilterOfResource } = useFilters()
  const { resource } = useListController()

  if (!resource) {
    return <>ERROR</>
  }

  const handeClick = () =>
    // setFilters({}, {}, false)
    record &&
    handleRowClick(
      record.chargegroupId,
      addId,
      removeAllFilterOfResource,
      admin.station_chargegroups,
      admin.station_chargegroups,
      navigate
    )

  return (
    <Link href={`#/${admin.station_chargegroups}`} onClick={handeClick}>
      <TextField {...props} />
    </Link>
  )
}

const StationChargepointsFields: FieldType[] = [
  <TextField source="id" />,
  <IdField label="chargeGroupId" source="chargegroupId" />,
  <ReferenceField
    label="addressFormatted"
    source="chargegroupId"
    reference={admin.station_chargegroups}
    link={false}
    sortable={false}
  >
    <TextField source="addressFormatted" />
  </ReferenceField>,
  <ReferenceField
    label="Manufacturer"
    source="manufacturerId"
    sortBy="manufacturerName"
    reference={admin.station_manufacturers}
    link={(record, reference) => createLink(reference, record.manufacturerId)}
  >
    <TextField source="name" />
  </ReferenceField>,
  <ReferenceField
    sortBy="typeName"
    label="Build Type"
    source="typeId"
    reference={admin.station_types}
    link={false}
  >
    <TextField source="name" />
  </ReferenceField>,
  // <BooleanField source="chargepointStatus" />,
  // <DateField source="statusLastChange" />,
  <TextField source="evseId" />,
  <TextField source="innogyId" />,
  // <ImageField source="euLabel" sortable={false} />,
  <TextField source="plugType" />,
  <BooleanField source="hasCable" />,
  <NumberField source="cableLength" />,
  <NumberField source="chargingPower" />,
  <BooleanField source="isWatercooled" />,
  <BooleanField source="loadManagement" />,
  <NumberField source="minChargingPower" />,
  <NumberField source="chargingVoltage" />,
  <NumberField source="chargingCurrent" />,
  <NumberField source="chargingPhases" />,
  <BooleanField source="singlePhaseCurrentLimitation" />,
  <NumberField source="singlePhaseCurrentValue" />,
  <BooleanField source="teslaOnly" />,
  <ReferenceField
    label="Operator"
    source="operatorId"
    sortBy="operatorName"
    reference={admin.operators}
    link={(record, reference) => createLink(reference, record.operatorId)}
  >
    <FunctionField
      sortBy="name"
      render={(record: RaRecord) =>
        `${record.name} ${record.evseOperatorId ? '[' + record.evseOperatorId + ']' : ''}`
      }
    />
  </ReferenceField>,
  <TextField source="categoryType" />,
  <ChipsField sortable={false} source="accessMethods" />,
  <TextField sortable={false} source="directPaymentLink" />,
  <OpeningTimesField sortable={false} source="openingTimes" />,
  <ParkingTimesField sortable={false} source="parkingTimes" />,
  <WrapperField
    sortable={false}
    sortableItems={['parkingFree']}
    source="parking"
    fieldName={[
      'parkingFree',
      'parkingEvOnly',
      'parkingBarrierFree',
      'parkingTrailer',
      'parkingDifficult',
    ]}
  />,
  <WrapperField
    sortable={false}
    sortableItems={['pricingFree']}
    label="price"
    source="price"
    fieldName={[
      'pricingFree',
      'payingOnSite',
      'consumptionPriceCurrency',
      'startingPrice',
      'consumptionPriceKwh',
      'consumptionPriceTime',
      'consumptionPriceTimePer',
      'consumptionPriceTimePerPeriod',
    ]}
  />,
  <WrapperField
    sortable={false}
    source="turnoffInformation"
    fieldName={['turnoffTime', 'turnoffEnergy', 'turnoffPercentage']}
  />,

  <DescriptionField source="description" />,

  <DateField source="createdAt" showTime />,
  <DateField source="updatedAt" showTime />,
  <TextField source="author" />,
  <DateField sortable={false} source="lockedAt" showTime />,
  <TextField source="lockUserId" />,
  <BooleanField source="locked" />,
  <BooleanField source="hasDrafts" />,
]

export const getSource: ColumnsType = StationChargepointsFields.reduce<ColumnsType>((o, field) => {
  if (
    [
      'id',
      'createdAt',
      'updatedAt',
      'author',
      'lockedAt',
      'lockUserId',
      'locked',
      'hasDrafts',
    ].includes(field.props.source)
  ) {
    return o
  }

  o[field.props.source] = field
  return o
}, {})

export default StationChargepointsFields
