import { TextField } from '@mui/material'
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import React from 'react'
import {
  ArrayInput,
  SelectArrayInput,
  InputProps,
  required,
  minValue,
  SimpleFormIterator,
  useInput,
  ArrayInputProps,
  TextInputProps,
} from 'react-admin'

import { getCurrentTime } from '../../../../utils/timer'

export const dayChoices = [
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
  'SUNDAY',
].map((key: string) => ({ id: key, name: key }))

export const TimeInput: React.FC<InputProps & TextInputProps> = props => {
  const {
    field: { name, value, onChange, ...rest },
  } = useInput(props)

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <TimePicker
        {...rest}
        ampm={false}
        value={getCurrentTime(value)}
        onChange={(event: Date | null) =>
          event && onChange(event.getHours() * 60 + event?.getMinutes())
        }
        renderInput={params => <TextField {...params} />}
      />
    </LocalizationProvider>
  )
}

const dayMapping: { [key: string]: number } = {
  MONDAY: 0,
  TUESDAY: 1,
  WEDNESDAY: 2,
  THURSDAY: 3,
  FRIDAY: 4,
  SATURDAY: 5,
  SUNDAY: 6,
}

export const DayTimeInput: React.FC<Omit<InputProps & ArrayInputProps, 'children'>> = props => {
  return (
    <ArrayInput {...props}>
      <SimpleFormIterator>
        <SelectArrayInput
          label="day"
          source="day"
          choices={dayChoices}
          fullWidth
          isRequired
          parse={(days: string[]) => [...days].sort((a, b) => dayMapping[a] - dayMapping[b])}
          validate={[required(), minValue(0)]}
        />
        <TimeInput
          sx={{
            marginBottom: '2rem',
          }}
          source="fromInMinutes"
          label="from"
          fullWidth
          isRequired
          defaultValue={0}
          validate={[required(), minValue(0)]}
        />
        <TimeInput
          sx={{
            marginBottom: '2rem',
          }}
          source="untilInMinutes"
          label="until"
          fullWidth
          isRequired
          defaultValue={0}
          validate={[required(), minValue(0)]}
        />
      </SimpleFormIterator>
    </ArrayInput>
  )
}

export default DayTimeInput
