import { useCanAccess } from '@eclever/ra-enterprise/ra-rbac'
import React from 'react'
import {
  Datagrid,
  EditButton,
  ListProps,
  TextField,
  Loading,
  RaRecord,
  sanitizeListRestProps,
  NumberInput,
  TextInput,
} from 'react-admin'
import { useNavigate } from 'react-router-dom'

import useFilters from '../../../hooks/useFilters'
import useVehicleBreadCrumps from '../../../hooks/useVehicleBreadCrumps'
import { admin } from '../../../types'
import BreadCrumbs, { handleRowClick } from '../../shared/Components/BreadCrumbs'
import FilterInputWrapper from '../../shared/Components/FilterInputWrapper'
import FilterWrapper from '../../shared/Components/FilterWrapper'
import Pagination from '../../shared/Components/Pagination'
import StickyColumnWrapper from '../../shared/Components/StickyColumnWrapper'
import List from '../../shared/Components/StyledComponents/List'
import Toolbar from '../../shared/Toolbar/Toolbar'

const vehicleModelFilters = [
  <FilterInputWrapper source="id" validElement="number">
    <NumberInput source="id" />
  </FilterInputWrapper>,
  <FilterWrapper reference={admin.vehicle_manufacturers} source="vehicleManufacturerId" alwaysOn>
    <NumberInput source="vehicleManufacturerId" disabled />
  </FilterWrapper>,
  <TextInput source="name" label="VehicleModelName" alwaysOn />,
]

const VehicleModelList: React.FC<ListProps> = props => {
  const navigate = useNavigate()
  const { addId, data, resource, loading, error } = useVehicleBreadCrumps(
    props,
    admin.vehicle_designs,
    admin.vehicle_manufacturers,
    admin.vehicle_models
  )
  const { filter, removeAllFilterOfResource } = useFilters()

  const { isLoading: loadAccess, canAccess } = useCanAccess({
    action: 'edit',
    resource: resource,
  })

  if (loading || loadAccess) {
    return <Loading />
  }

  if (error) {
    return <>ERROR</>
  }

  return (
    <>
      <BreadCrumbs resource={resource} />
      <List
        {...sanitizeListRestProps(props)}
        filter={filter[resource]}
        pagination={<Pagination />}
        filters={vehicleModelFilters}
        actions={
          <Toolbar reference={admin.vehicle_manufacturers} resource={admin.vehicle_models} />
        }
        empty={false}
      >
        <Datagrid
          rowClick={row =>
            handleRowClick(
              row,
              addId,
              removeAllFilterOfResource,
              admin.vehicle_designs,
              resource,
              navigate
            )
          }
          isRowSelectable={record =>
            canAccess &&
            data?.filter((item: RaRecord) => item.vehicleModelId === record.id).length === 0
          }
        >
          <TextField source="id" />
          <TextField source="name" />
          <StickyColumnWrapper {...props}>{canAccess && <EditButton />}</StickyColumnWrapper>
        </Datagrid>
      </List>
    </>
  )
}

export default VehicleModelList
