import { TableCell } from '@mui/material'
import { isEmpty } from 'lodash'
import * as React from 'react'
import { TextFieldProps, RaRecord } from 'react-admin'

import PropertiesField from './CustomFields/PropertiesField'
import { CustomRow } from './StationChargepointsDraftsStyles'
import { equalDiff } from './StationChargepointsDraftsUtils'

const StationChargepointsDraftsRow: React.FC<
  TextFieldProps & {
    name: string
    oldData?: RaRecord
    newData: RaRecord | undefined
  }
> = ({ name, oldData, newData, ...props }) => {
  const diffs = oldData && equalDiff(oldData, newData, name)

  if (
    ['locked', 'lockUserId', 'lockedAt'].includes(name) ||
    // (oldData && isEmpty(oldData[name]) && !oldData[name]) ||
    // (newData && isEmpty(newData[name]) && !newData[name]) ||
    (diffs && diffs.length !== 0)
  ) {
    return <></>
  }

  return (
    <CustomRow key={name}>
      <TableCell width="15%" component="th" scope="row" style={{ fontWeight: 'bold' }}>
        {name}
      </TableCell>
      {!isEmpty(oldData) && (
        <TableCell component="th" scope="row">
          <PropertiesField {...props} source={name} data={oldData} />
        </TableCell>
      )}
      <TableCell component="th" scope="row" align={oldData ? 'left' : 'center'}>
        <PropertiesField {...props} source={name} data={newData} diffs={diffs} />
      </TableCell>
    </CustomRow>
  )
}

export default StationChargepointsDraftsRow
