import React from 'react'
import {
  Create,
  CreateProps,
  TextInput,
  SimpleForm,
  useNotify,
  useRefresh,
  useRedirect,
  required,
} from 'react-admin'

import { admin } from '../../../types'

const CPacksCreate: React.FC<CreateProps> = props => {
  const notify = useNotify()
  const refresh = useRefresh()
  const redirect = useRedirect()

  const onSuccess = () => {
    notify(`ra.notification.created`)
    redirect(`/${admin.virtualCpacks}`)
    refresh()
  }

  return (
    <Create {...props} mutationOptions={{ onSuccess }}>
      <SimpleForm>
        <TextInput source="cPackId" isRequired validate={[required()]} />
      </SimpleForm>
    </Create>
  )
}

export default CPacksCreate
