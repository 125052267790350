import { Box, Button, Paper, Typography, styled } from '@mui/material'

export const Modalbox = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: theme.palette.common.white,
  border: 'none',
}))

export const ButtonWrapper = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridGap: theme.spacing(3),
  gridTemplateColumns: 'repeat(2, 1fr)',
}))

export const Description = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  fontWeight: 'bolder',
  marginBottom: theme.spacing(4),
}))

export const CustomButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.common.white,
  borderRadius: theme.spacing(2),
  padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,

  '&:hover': {
    color: theme.palette.secondary.main,
  },
}))
