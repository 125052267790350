import merge from 'lodash/merge'
import { defaultTheme } from 'react-admin'

export const colorPalette = {
  switchTracker: {
    light: '#8796A5',
    dark: '#aab4be',
  },
  changelog: {
    update: '#ffbf00',
    create: '#90d943',
    delete: '#e47f9f',
  },
  lineGraph: {
    axis: 'rgba(255, 255, 255, 0.2)',
    background: 'white',
    lineColors: [
      '#FF0000',
      '#FF8900',
      '#408f00',
      '#0d9499',
      '#009AFF',
      '#0025FF',
      '#B200FF',
      '#54A15A',
      '#A15454',
      '#BE9400',
      '#00BE94',
    ],
  },
  pieChart: {
    sliceColors: ['#98abc5', '#8a89a6', '#7b6888', '#6b486b', '#a05d56'],
  },
}

const ThemeProvider = merge({}, defaultTheme, {
  palette: {
    colorPalette,
  },
})

export default ThemeProvider
