import { isEqual } from 'lodash'
import { RaRecord } from 'react-admin'

export const diffs: (
  oldData: RaRecord | undefined,
  newData: RaRecord | undefined,
  names: string[]
) => string[] = (oldData, newData, names) =>
  names?.reduce((pre: string[], cur: string) => {
    if (
      oldData &&
      cur in oldData &&
      newData &&
      cur in newData &&
      isEqual(oldData[cur], newData[cur])
    ) {
      pre.push(cur)
    }
    return pre
  }, [] as string[])
