import COUPE from '../../../assets/car_placeholder/coupe_front_view.png'
import HATCH from '../../../assets/car_placeholder/hatch_front_view.png'
import KOMBI from '../../../assets/car_placeholder/kombi_front_view.png'
import LIMO from '../../../assets/car_placeholder/limo_front_view.png'
import MINIVAN from '../../../assets/car_placeholder/minivan_front_view.png'
import SMART from '../../../assets/car_placeholder/smart_front_view.png'
import SUV from '../../../assets/car_placeholder/suv_front_view.png'
import TRANSPORTER from '../../../assets/car_placeholder/transporter_front_view.png'

export const imageFallback: (general3dDesign: string) => string = general3dDesign => {
  switch (general3dDesign) {
    case 'SUV':
      return SUV
    case 'HATCH':
      return HATCH
    case 'COUPE':
      return COUPE
    case 'LIMO':
      return LIMO
    case 'TRANSPORTER':
      return TRANSPORTER
    case 'MINIVAN':
      return MINIVAN
    case 'SMART':
      return SMART
    case 'KOMBI':
      return KOMBI
    default:
      return general3dDesign
  }
}
