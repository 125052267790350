import { useEffect } from 'react'
import { InputProps, BooleanInput, useInput } from 'react-admin'
import { useController } from 'react-hook-form'

const OpenFullTimeInput: React.FC<InputProps & { hoursProp: 'oh' | 'ph'; extraProp: string }> = ({
  hoursProp,
  extraProp,
  ...props
}) => {
  const { source } = props
  const {
    field: { value },
  } = useInput(props)
  const {
    field: { onChange: hoursOnChange },
  } = useController({ name: hoursProp })
  const {
    field: { onChange: extraPropOnChange },
  } = useController({ name: extraProp })

  useEffect(() => {
    if (value) {
      hoursOnChange(undefined)

      if (['openFullTime', 'parkingFullTime'].includes(source)) {
        extraPropOnChange(false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  return <BooleanInput {...props} fullWidth />
}

export default OpenFullTimeInput
