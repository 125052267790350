import packageInfo from '../../package.json'

const {
  NODE_ENV,
  REACT_APP_ENVIRONMENT = 'development',
  REACT_APP_BACKEND_BASEURL,
  REACT_APP_SENTRYKEY,
  REACT_APP_GMAPS_TOKEN,
  REACT_APP_API_VERSION,
  REACT_APP_SERVICE_WORKER = 'true',
  REACT_APP_SERVICE_WORKER_PRECACHE = 'true',
  REACT_APP_VIRTUAL_BACKEND_BASEURL,
  REACT_APP_FILE_UPLOAD_BACKEND_BASEURL,
  REACT_APP_API_BACKEND_BASEURL,
} = process.env

const backendBaseURL =
  REACT_APP_BACKEND_BASEURL || `https://${REACT_APP_ENVIRONMENT}.k8s.eclever.net/v2`

const systemConfig = {
  isDev: process.env.NODE_ENV === 'development',
  isTest: process.env.NODE_ENV === 'test',
  isProd: process.env.NODE_ENV === 'production',
  env: NODE_ENV,
  environment: REACT_APP_ENVIRONMENT,
  webAppVersion: packageInfo.version,
  apiVersion: REACT_APP_API_VERSION || packageInfo.version,
  backendBaseURL,
  appBackendBaseURL: REACT_APP_API_BACKEND_BASEURL || backendBaseURL,
  virtualBackendBaseURL: REACT_APP_VIRTUAL_BACKEND_BASEURL || backendBaseURL,
  fileUploadBackendBaseURL: REACT_APP_FILE_UPLOAD_BACKEND_BASEURL || backendBaseURL,
  apiTimeout: 6e4,
  serviceWorker: REACT_APP_SERVICE_WORKER === 'true',
  serviceWorkerPrecache: REACT_APP_SERVICE_WORKER_PRECACHE === 'true',
  sentryDSN: REACT_APP_SENTRYKEY,
  googleMapsApiKey: REACT_APP_GMAPS_TOKEN,
  tokenStorageKey: 'backoffice-auth-token',
  tokenRefreshStorageKey: 'backoffice-auth-refresh-token',
  permissions: 'backoffice-permissions',
  roles: 'backoffice-roles',
  tokenDate: 'backoffice-endAuth',
}

export default systemConfig
