import { Switch, CircularProgress, FormControlLabel } from '@mui/material'
import { isEmpty } from 'lodash'
import { useEffect } from 'react'
import {
  useGetOne,
  InputProps,
  TextInputProps,
  useUpdate,
  useInput,
  useEditContext,
} from 'react-admin'

import { CustomFormGroup } from './ActivateButtonInput'

const StatusInput: React.FC<Omit<TextInputProps & InputProps, 'children'>> = props => {
  const { resource, record } = useEditContext()
  const { data, isLoading, error } = useGetOne(resource || '', { id: `${record.id}/status` })
  const [update, { isLoading: updateLoading, error: updateError }] = useUpdate()
  const {
    field: { onChange },
  } = useInput(props)

  useEffect(() => {
    onChange(data)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  if (isLoading || updateLoading) {
    return <CircularProgress />
  }

  if (error || updateError || !data || isEmpty(data)) {
    return <>ERROR</>
  }

  const handleClick = (name: string) => {
    update(resource, {
      id: `${record.id}/status`,
      data: { ...data, [name]: !data[name] },
      previousData: data,
    })
  }

  return (
    <CustomFormGroup>
      <FormControlLabel
        control={<Switch checked={data.cPack} onChange={() => handleClick('cPack')} />}
        label="cPack"
      />
      <FormControlLabel
        control={<Switch checked={data.cBox} onChange={() => handleClick('cBox')} />}
        label="cBox"
      />
      <FormControlLabel
        control={<Switch checked={data.cPlug} onChange={() => handleClick('cPlug')} />}
        label="cPlug"
      />
    </CustomFormGroup>
  )
}

export default StatusInput
