import React from 'react'
import { Edit, EditProps } from 'react-admin'

import VehicleVariantForm from './VehicleVariantForm'
import { transformVariant } from './VehicleVariantInputs'

const VehicleVariantEdit: React.FC<EditProps> = props => (
  <Edit {...props} transform={transformVariant}>
    <VehicleVariantForm />
  </Edit>
)

export default VehicleVariantEdit
