import RssFeedIcon from '@mui/icons-material/RssFeed'
import { ResourceProps } from 'react-admin'

import UsersCreate from './UsersCreate'
import UsersEdit from './UsersEdit'
import UsersList from './UsersList'

const Users: Omit<ResourceProps, 'name'> = {
  options: { label: 'Users' },
  list: UsersList,
  create: UsersCreate,
  edit: UsersEdit,
  icon: RssFeedIcon,
}

export default Users
