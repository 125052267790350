import create from 'zustand'
import { persist } from 'zustand/middleware'

import { immer } from './middleware'

export type FilterProps = {
  filter: { [resource: string]: { [fieldName: string]: string | number | boolean } }
  addFilter: (
    resource: string,
    fieldName: string,
    element: string | number | boolean | null
  ) => void
  removeFilter: (resource: string, fieldName: string) => void
  removeAllFilterOfResource: (resource: string) => void
  reset: () => void
}

const useFiters = create<FilterProps>(
  persist(
    immer(set => ({
      filter: {},
      addFilter: (
        resource: string,
        fieldName: string,
        element: string | number | boolean | null
      ) => {
        if (element) {
          set(state => ({
            filter: {
              ...state.filter,
              [resource]: { ...state.filter[resource], [fieldName]: element },
            },
          }))
        } else {
          set(state => ({
            filter: {
              ...state.filter,
              [resource]:
                state?.filter[resource] &&
                Object.fromEntries(
                  Object.entries(state.filter[resource]).filter(
                    ([key, _value]) => key !== fieldName
                  )
                ),
            },
          }))
        }
      },
      removeFilter: (resource: string, fieldName: string) =>
        set(state => ({
          filter: {
            ...state.filter,
            [resource]: Object.fromEntries(
              Object.entries(state.filter[resource]).filter(([key, _value]) => key !== fieldName)
            ),
          },
        })),
      removeAllFilterOfResource: (resource: string) =>
        set(state => ({
          filter: Object.fromEntries(
            Object.entries(state.filter).filter(([key, _value]) => resource !== key)
          ),
        })),
      reset: () => set(() => ({ filter: {} })),
    })),
    {
      name: 'backoffice-filter',
      getStorage: () => sessionStorage,
    }
  )
)

export default useFiters
