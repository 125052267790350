import { styled } from '@mui/material'
import React, { Children } from 'react'
import { FieldProps, sanitizeFieldRestProps } from 'react-admin'

const StickyColumn = styled('div')(({ theme }) => ({
  display: 'grid',
  alignItems: 'center',
  gridGap: theme.spacing(2),
}))

const StickyColumnWrapper: React.FC<FieldProps> = ({ ...props }) => {
  const { children, className, source, emptyText, ...rest } = props

  return (
    <StickyColumn
      {...sanitizeFieldRestProps(rest)}
      sx={{ gridTemplateColumns: `repeat(${Children.count(children)}, 1fr)` }}
    >
      {React.Children.map(
        children,
        item => item && React.cloneElement(item as React.ReactElement<unknown>, { ...props })
      )}
    </StickyColumn>
  )
}

StickyColumnWrapper.defaultProps = {
  headerClassName: 'customHeaderClassName',
  cellClassName: 'customHeaderClassName',
}

export default StickyColumnWrapper
