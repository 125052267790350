import { BooleanField, ImageField, NumberField, ReferenceField, TextField } from 'react-admin'

import { FieldType } from '../../../types'

export const VehicleFields: FieldType[] = [
  <TextField source="id" />,
  <TextField source="name" />,
  <TextField source="licensePlate" />,
  <ImageField source="image" />,
  <TextField source="fromProductionYear" />,
  <TextField source="model" />,
  <TextField source="manufacturer" />,
  <ReferenceField
    label="general3dDesign"
    source="general3dDesign"
    reference="admin/enums/general3dDesign"
  >
    <TextField source="id" />
  </ReferenceField>,
  <NumberField source="mileage" />,
  <NumberField source="batteryLevel" />,
  <NumberField source="soc" />,
  <NumberField source="range" />,
  <TextField source="lastUpdateLabel" />,
  <BooleanField source="recent" />,
]

export default VehicleFields
