import { Badge, TextField, Box } from '@mui/material'
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay'
import { de } from 'date-fns/locale'
import React, { Dispatch, SetStateAction } from 'react'

type TimespanPickerProps = {
  startDate: Date | undefined
  endDate: Date | undefined
  setStartDate: Dispatch<SetStateAction<Date>>
  setEndDate: Dispatch<SetStateAction<Date>>
  maxTimeSpanMs?: number
  daysAvailable: string[]
}

const DAY_MS = 86400000

const TimespanPicker: React.FC<TimespanPickerProps> = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  maxTimeSpanMs,
  daysAvailable,
}) => {
  const renderDay = (date: Date, _value: unknown, DayComponentProps: PickersDayProps<Date>) => {
    const isAvailable = daysAvailable.find(d => {
      const dayIsMatching = new Date(d).getDate() === date.getDate()
      const monthIsMatching = new Date(d).getMonth() === date.getMonth()
      const yearIsMatching = new Date(d).getFullYear() === date.getFullYear()
      return dayIsMatching && monthIsMatching && yearIsMatching
    })

    return (
      <Badge
        key={date.toString()}
        overlap="circular"
        badgeContent={isAvailable && !DayComponentProps.outsideCurrentMonth ? '🟢' : undefined}
      >
        <PickersDay {...DayComponentProps} />
      </Badge>
    )
  }

  const onChangeStartDate = (date: Date | null) => {
    if (!date) return

    adjustEndDate(date)
    setStartDate(date)
  }

  const adjustStartDate = (newEndDate: Date) => {
    if (startDate && !isStartDateBeforeEndDate(startDate, newEndDate)) {
      const timeSpan = maxTimeSpanMs || DAY_MS
      setStartDate(new Date(newEndDate.getTime() - timeSpan))
    } else if (startDate && maxTimeSpanMs && !isTimespanAllowed(startDate, newEndDate)) {
      setStartDate(new Date(newEndDate.getTime() - maxTimeSpanMs))
    }
  }

  const onChangeEndDate = (date: Date | null) => {
    if (!date) return

    adjustStartDate(date)
    setEndDate(date)
  }

  const adjustEndDate = (newStartDate: Date) => {
    if (endDate && !isStartDateBeforeEndDate(newStartDate, endDate)) {
      const timeSpan = maxTimeSpanMs || DAY_MS
      setEndDate(new Date(newStartDate.getTime() + timeSpan))
    } else if (endDate && maxTimeSpanMs && !isTimespanAllowed(newStartDate, endDate)) {
      setEndDate(new Date(newStartDate.getTime() + maxTimeSpanMs))
    }
  }

  const isTimespanAllowed = (start: Date, end: Date) =>
    maxTimeSpanMs && maxTimeSpanMs !== 0 && end.getTime() - start.getTime() < maxTimeSpanMs

  const isStartDateBeforeEndDate = (start: Date, end: Date) => start.getTime() < end.getTime()

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={de}>
        <Box display={'flex'} columnGap={1}>
          <DateTimePicker
            renderInput={props => <TextField {...props} />}
            label="Start"
            value={startDate}
            onChange={onChangeStartDate}
            renderDay={renderDay}
          />
          <DateTimePicker
            renderInput={props => <TextField {...props} />}
            label="End"
            value={endDate || null}
            renderDay={renderDay}
            onChange={onChangeEndDate}
          />
        </Box>
      </LocalizationProvider>
    </>
  )
}

export default TimespanPicker
